import { Input, Tooltip } from "antd";
import { useContext, useState } from "react";
import { AppContext } from "../../helpers/app-context";

let debounceTimeout = null;

export const CustomSearch = (props) => {
  const {
    onSearch,
    name,
    placeholder,
    searchType,
    searchValue,
    setSearchValue,
  } = props;
  const ctx = useContext(AppContext);
  const [searchItem, setSearchItem] = useState(
    ctx.moduleState.searchValue ? ctx.moduleState.searchValue : null,
  );

  const debounceSearch = (value) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    if (searchType === "manual") {
      setSearchValue(value);
    }
    setSearchItem(value);
    debounceTimeout = setTimeout(() => {
      onSearch(value);
    }, 500);
  };
  return (
    <div className="user-input relative">
      {searchType === "manual" ? (
        <Tooltip
          trigger={["hover"]}
          title={
            placeholder
              ? placeholder
              : "Search by Customer Name, Location Head, Product, Bank and App No."
          }
          placement="bottomRight"
        >
          <Input
            type="text"
            className="search-input pl-8"
            name={name}
            placeholder={
              placeholder
                ? placeholder
                : "Search by Customer Name, Bank and App No."
            }
            prefix={
              <img
                src="/images/icon/search.svg"
                alt="search"
                className="verticle-center absolute"
                style={{
                  left: "8px",
                }}
              />
            }
            value={searchValue}
            onChange={(e) => debounceSearch(e.target.value)}
            allowClear={{
              clearIcon: (
                <img
                  src="/images/icon/close.svg"
                  className="pt-1 search-icon-clear"
                  style={{ width: "12px", height: "12px" }}
                  alt="close"
                />
              ),
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip
          trigger={["hover"]}
          title={
            placeholder
              ? placeholder
              : "Search by Customer Name, Location Head, Product, Bank and App No."
          }
          placement="bottomRight"
        >
          <Input
            type="text"
            className="search-input pl-8"
            name={name}
            placeholder={
              placeholder
                ? placeholder
                : "Search by Customer Name, Bank and App No."
            }
            prefix={
              <img
                src="/images/icon/search.svg"
                alt="search"
                className="verticle-center absolute"
                style={{
                  left: "8px",
                }}
              />
            }
            value={searchItem}
            onChange={(e) => debounceSearch(e.target.value)}
            allowClear={{
              clearIcon: (
                <img
                  src="/images/icon/close.svg"
                  className="pt-1 search-icon-clear"
                  style={{ width: "12px", height: "12px" }}
                  alt="close"
                />
              ),
            }}
          />
        </Tooltip>
      )}
    </div>
  );
};
