import { useContext, useEffect, useState } from "react";
import { UPLOAD_DATA_TYPE } from "../../helpers/constant";
import { AppContext } from "../../helpers/app-context";
import { CustomButton } from "../common/CustomButton";
import { CustomDataTable } from "../common/CustomDataTable";
import { CustomDatepicker } from "../common/CustomDatepicker";
import { CustomDrawer } from "../common/CustomDrawer";
import { CustomSearch } from "../common/CustomSearch";
import {
  columnsBank,
  columnsEstimate,
  columnsSubvention,
} from "../tableHeaders/DuplicateDataHeader";
import {
  DUPLICATE_DATA_BANK_ACTION,
  DUPLICATE_DATA_BANK_DETAIL_VIEW,
  DUPLICATE_DATA_ESTIMATE_ACTION,
  DUPLICATE_DATA_ESTIMATE_DETAIL_VIEW,
  DUPLICATE_DATA_SUBVENTION_ACTION,
  DUPLICATE_DATA_SUBVENTION_DETAIL_VIEW,
} from "../../helpers/api-endpoints";
import {
  FromDatePickerParams,
  SortedOrderBy,
  ToDatePickerParams,
} from "../../helpers/functions";
import { NoData } from "../common/NoData";
import { NoInternet } from "../common/NoInternet";

export const DuplicateDataContainer = (props) => {
  const {
    data,
    eventKey,
    loading,
    searchValue,
    totalRecords,
    pageSize,
    currentPage,
    getTableData,
    setSearchValue,
    setCurrentPage,
    fromDateValue,
    toDataValue,
    setFromDateValue,
    setToDataValue,
    setSortByValue,
    setOrderByValue,
    setPageSize,
    getTotalCount,
    hideNoData,
    initialData,
    parentData,
    selectedTab,
  } = props;
  const ctx = useContext(AppContext);
  const [openDrawable, setOpenDrawable] = useState(false);
  const [drawableData, setDrawableData] = useState([]);
  const [drawerLoader, setDrawerLoader] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedSidePanelId, setSelectedSidePanelId] = useState(0);

  const get_upload_details = async (uid_no) => {
    const response = await ctx.HttpGet(
      eventKey === UPLOAD_DATA_TYPE.BANK
        ? DUPLICATE_DATA_BANK_DETAIL_VIEW + uid_no
        : eventKey === UPLOAD_DATA_TYPE.ESTIMATE
        ? DUPLICATE_DATA_ESTIMATE_DETAIL_VIEW + uid_no
        : eventKey === UPLOAD_DATA_TYPE.SUBVENTION &&
          DUPLICATE_DATA_SUBVENTION_DETAIL_VIEW + uid_no,
    );
    if (response.status) {
      setOpenDrawable(true);
      setDrawerLoader(false);
      setDrawableData(
        eventKey === UPLOAD_DATA_TYPE.BANK
          ? response?.Bank_upload_data_duplicate[0]
          : eventKey === UPLOAD_DATA_TYPE.ESTIMATE
          ? response?.Estimate_upload_data_duplicate[0]
          : eventKey === UPLOAD_DATA_TYPE.SUBVENTION &&
            response?.Subvention_upload_data_duplicate[0],
      );
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleApproveReject = async (checkbox = false, type, id) => {
    let response = "";
    if (checkbox) {
      response = await ctx.HttpPost(
        eventKey === UPLOAD_DATA_TYPE.BANK
          ? DUPLICATE_DATA_BANK_ACTION + type
          : eventKey === UPLOAD_DATA_TYPE.ESTIMATE
          ? DUPLICATE_DATA_ESTIMATE_ACTION + type
          : eventKey === UPLOAD_DATA_TYPE.SUBVENTION &&
            DUPLICATE_DATA_SUBVENTION_ACTION + type,
        { data: JSON.stringify(selectedRowKeys) },
      );
      if (response.status) {
        setSelectedRowKeys([]);
        ctx.showToastAlert({ type: "success", message: response.message });
        getTotalCount();
        getTableData(null, null, null, 1, pageSize);
        setSelectedRowKeys([]);
        setSelectedSidePanelId(0);
      }
    } else {
      response = await ctx.HttpPost(
        eventKey === UPLOAD_DATA_TYPE.BANK
          ? DUPLICATE_DATA_BANK_ACTION + type
          : eventKey === UPLOAD_DATA_TYPE.ESTIMATE
          ? DUPLICATE_DATA_ESTIMATE_ACTION + type
          : eventKey === UPLOAD_DATA_TYPE.SUBVENTION &&
            DUPLICATE_DATA_SUBVENTION_ACTION + type,
        { data: JSON.stringify(id) },
      );
      if (response.status) {
        setSelectedRowKeys([]);
        ctx.showToastAlert({ type: "success", message: response.message });
        setOpenDrawable(false);
        setSelectedRowKeys([]);
        setSelectedSidePanelId(0);
        getTotalCount();
        getTableData(null, null, null, 1, pageSize);
      }
    }
  };
  return !ctx.isOnline ? (
    <NoInternet
      onClick={() => {
        getTableData();
        getTotalCount();
      }}
    />
  ) : loading || (ctx.isOnline && initialData) ? (
    <>
      <div className="flex items-center justify-between px-4 py-2">
        <CustomSearch
          placeholder="Search by Customer Name, Bank and App No."
          onSearch={(value) => {
            if (value.length >= 3) {
              setSearchValue(value);
              setCurrentPage(1);
              getTableData(value, null, null, 1, pageSize);
            } else if (value.length === 0) {
              setSearchValue("");
              getTableData(null, null, null, currentPage, pageSize);
            }
          }}
        />
        <div className="flex items-center">
          <CustomDatepicker
            value={[fromDateValue, toDataValue]}
            onChangeDatePicker={(date, dateString) => {
              if (date) {
                setFromDateValue(FromDatePickerParams(date));
                setToDataValue(ToDatePickerParams(date));
                getTableData(
                  searchValue,
                  null,
                  null,
                  1,
                  pageSize,
                  FromDatePickerParams(date),
                  ToDatePickerParams(date),
                );
              } else {
                setFromDateValue(null);
                setToDataValue(null);
                getTableData(searchValue, null, null, 1, pageSize, null, null);
              }
            }}
          />
        </div>
      </div>
      <CustomDataTable
        rowKey="uid_no"
        columns={
          eventKey === UPLOAD_DATA_TYPE.BANK
            ? columnsBank
            : eventKey === UPLOAD_DATA_TYPE.ESTIMATE
            ? columnsEstimate
            : eventKey === UPLOAD_DATA_TYPE.SUBVENTION
            ? columnsSubvention
            : ""
        }
        data={data}
        className="cust_datatable"
        rowSelectionBox={{
          // onClickRowSelectRadio: true,
          type: "checkbox",
          enable: true,
        }}
        rowSelection={rowSelection}
        rowClickable={true}
        tableHeight={"calc(100vh - 285px)"}
        loading={loading}
        handleRowClick={(rowData) => {
          get_upload_details(rowData.uid_no);
          setSelectedSidePanelId(rowData.uid_no);
        }}
        totalRecords={totalRecords}
        pageSize={pageSize}
        currentPage={currentPage}
        onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
          if (sorterOptions && sorterOptions.order) {
            setOrderByValue(SortedOrderBy(sorterOptions.order));
            setSortByValue(sorterOptions.field);
          }
          setPageSize(pageSizeOptions.pageSize);
          setCurrentPage(pageSizeOptions.current);
          getTableData(
            searchValue,
            sorterOptions.order ? sorterOptions.field : "",
            SortedOrderBy(sorterOptions.order),
            pageSizeOptions.current,
            pageSizeOptions.pageSize,
          );
        }}
        parentData={parentData}
        selectedTab={selectedTab}
      />
      {!loading && data && selectedRowKeys.length > 0 && (
        <div className="absolute bottom-2.5 z-10 pl-2">
          {/* <CustomButton
            className="btn-light-red font-bold py-2 px-4 rounded fs-16 mx-3 w-28"
            title="Reject"
            onClick={() => handleApproveReject(true,"reject",null)}
          /> */}
          <CustomButton
            className="btn-primary font-bold py-2 px-4 rounded fs-16 w-28"
            title="Approve"
            onClick={() => handleApproveReject(true, "approve", null)}
          />
          <CustomButton
            className="btn-light-red font-bold py-2 px-4 rounded fs-16 w-28 ml-3"
            title="Reject"
            onClick={() => handleApproveReject(true, "reject", null)}
          />
        </div>
      )}

      {openDrawable && (
        <CustomDrawer
          placement="right"
          setOpenDrawable={setOpenDrawable}
          data={drawableData}
          width={683}
          loading={drawerLoader}
          selectedTab={eventKey}
          bodyStyle={{ maxHeight: "calc(100vh - 115px)" }}
          buttonEnabled={true}
          secondaryBtnClick={() =>
            handleApproveReject(false, "reject", selectedSidePanelId)
          }
          primaryBtnClick={() =>
            handleApproveReject(false, "approve", selectedSidePanelId)
          }
        />
      )}
    </>
  ) : (
    <NoData eventKey={UPLOAD_DATA_TYPE.ESTIMATE} hideNoData={hideNoData} />
  );
};
