import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  UPLOADED_DATA_DETAILS_BANK,
  UPLOADED_DATA_DETAILS_SUBVENTION,
} from "../../helpers/api-endpoints";
import { AppContext } from "../../helpers/app-context";
import { MY_TASK, UPLOAD_DATA_TYPE } from "../../helpers/constant";
import { ConvertId, NumberConversion } from "../../helpers/functions";
import { DisableNumberChecks } from "../tableHeaders/DisableNumberChecks";

import { CustomDrawer } from "./CustomDrawer";
import { SkeletonLoader } from "./SkeletonLoader";

export const RevenueMappedDetails = (props) => {
  const { title, mappedData, matchpercentage, style, loading } = props;
  const location = useLocation();
  const ctx = useContext(AppContext);
  const [openDrawable, setOpenDrawable] = useState(false);
  const [drawableData, setDrawableData] = useState(null);
  const [selectedColumnsData, setSelectedColumnsData] = useState([]);
  const [selectedTab, setSelectedtab] = useState("");

  useEffect(() => {
    getSelectedMappedDetails();
  }, [mappedData]);

  const getSelectedMappedDetails = () => {
    let res = [];
    mappedData &&
      mappedData?.subvention_data &&
      Object.keys(mappedData?.subvention_data[0]).map((item, index) => {
        res.push({
          key: item,
          subvention: mappedData?.subvention_data[0][item],
        });
      });
    mappedData &&
      mappedData?.bank_data &&
      Object.keys(mappedData?.bank_data[0]).map((item, index) => {
        res.forEach((object) => {
          object.bank = mappedData?.bank_data[0][object.key];
        });
      });
    setSelectedColumnsData(res);
  };

  const getBankDetails = async () => {
    const response = await ctx.HttpGet(
      UPLOADED_DATA_DETAILS_BANK + location.state.id,
    );
    if (response.status) {
      setDrawableData(response.Bank_upload_data[0]);
      setOpenDrawable(true);
    }
  };

  const getSubventionDetails = async (id) => {
    const response = await ctx.HttpGet(
      UPLOADED_DATA_DETAILS_SUBVENTION + location.state.subvention_id,
    );
    if (response.status) {
      setDrawableData(response.Subvention_upload_data[0]);
      setOpenDrawable(true);
    }
  };

  return (
    <div className="col-span-12 md:col-span-7 border-t">
      <div className="col-span-8">
        <div className="grid grid-cols-5 bg-white">
          <div className="col-span-3">
            <h2 className="fs-20 p-4 col-span-4 font-semibold">{title}</h2>
          </div>
          {location.state.activeTab === MY_TASK.UNRECONCILED &&
            matchpercentage && (
              <div className="relative col-span-2">
                <h3
                  className={`absolute bottom-0 status-chip font-semibold -ml-1	 ${
                    matchpercentage >= 70
                      ? "green"
                      : matchpercentage >= 50 && matchpercentage < 70
                      ? "yellow"
                      : "red"
                  }`}
                >
                  {matchpercentage}% Match
                </h3>
              </div>
            )}

          <div className="col-span-5">
            <div className="grid grid-cols-5 border-b border-t">
              <div className="border-r p-2 col-span-1 fs-14 secondary-color-text-l1 font-semibold">
                Column Name
              </div>
              <div className=" p-2 fs-14 col-span-2 secondary-color-text-l1 font-semibold">
                <SkeletonLoader loading={loading} style={{ height: "20px" }}>
                  Mapped Bank UID No. #B{ConvertId(location?.state?.id)}
                </SkeletonLoader>
              </div>
              <div className=" p-2 fs-14 col-span-2 lightgrey-bg-color secondary-color-text-l1 font-semibold -ml-1">
                <SkeletonLoader loading={loading} style={{ height: "20px" }}>
                  Mapped Subvention UID No. #S
                  {ConvertId(location?.state?.subvention_id)}
                </SkeletonLoader>
              </div>
            </div>
          </div>
        </div>
        <div
          className="grid grid-cols-1 border-b-2 overflow-y-auto"
          style={style ? { ...style } : { height: "calc(100vh - 235px)" }}
        >
          {selectedColumnsData.map((item, index) => (
            <div className="grid grid-cols-5" key={index}>
              <div className="fs-14 col-span-1 pt-2 pl-3 pr-2 border-r secondary-color-text-l1 font-semibold capitalize">
                {item?.key ? item?.key?.split("_").join(" ") : "-"}
              </div>
              <div className="fs-14 col-span-2 py-2 pl-3 pr-2 font-semibold">
                {item?.bank
                  ? DisableNumberChecks.includes(item.key)
                    ? item?.bank
                    : isNaN(item?.bank)
                    ? item?.bank
                    : NumberConversion(item.bank)
                  : "-"}
              </div>
              <div className="fs-14 col-span-2 py-2 pl-3 pr-2 lightgrey-bg-color font-semibold">
                {item?.subvention
                  ? DisableNumberChecks.includes(item.key)
                    ? item?.subvention
                    : isNaN(item?.subvention)
                    ? item?.subvention
                    : NumberConversion(item.subvention)
                  : "-"}
              </div>
            </div>
          ))}
          <div className="grid grid-cols-5">
            <div className="fs-14 col-span-1 pt-2 pl-3 pr-2 border-r secondary-color-text-l1 font-semibold"></div>
            <div
              className="col-span-2 primary-text-color fs-14 font-semibold py-2 pl-3 pr-2 cursor-pointer"
              onClick={() => {
                getBankDetails();
                setSelectedtab(UPLOAD_DATA_TYPE.BANK);
              }}
            >
              View More Bank Details
            </div>
            <div
              className="col-span-2 primary-text-color fs-14 font-semibold py-2 pl-3 pr-2 cursor-pointer"
              onClick={() => {
                getSubventionDetails();
                setSelectedtab(UPLOAD_DATA_TYPE.SUBVENTION);
              }}
            >
              View More Subvention Details
            </div>
          </div>
        </div>
      </div>
      {openDrawable && (
        <CustomDrawer
          setOpenDrawable={setOpenDrawable}
          data={drawableData}
          selectedTab={selectedTab}
        />
      )}
    </div>
  );
};
