export const DisableNumberChecks = [
  "App_No",
  "Contact_no",
  "Aadhar",
  "PAN",
  "Pincode",
  "DMA_code",
  "Invoice_no/NEFT_no",
  "sr_no"
];
