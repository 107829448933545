import React, { useState } from "react";
import { Drawer } from "antd";

export const Notifications = (props) => {
  const { title, placement, setOpenDrawable, data, width = 683 } = props;
  const [open, setOpen] = useState(data ? true : false);
  const [notifications, setNotifications] = useState(data)
  const onClose = () => {
    setOpen(false);
    // passed extra state to update prevs component state to false for on clicking event.
    setOpenDrawable(false);
  };
  return (
    <Drawer
      title={title}
      placement={placement}
      closable={false}
      onClose={onClose}
      open={open}
      width={window.innerWidth > 900 ? width : window.innerWidth - 100}
      className="custom-notification-drawer-main"
      extra={
        <>
          <button
            type="button"
            aria-label="Close"
            onClick={onClose}
            className="ant-drawer-close"
          >
            <img
              src="/images/icon/close.svg"
              alt="close"
              style={{
                width: "16px",
              }}
            />
          </button>
        </>
      }
    >
      <div className="grid grid-cols-1 gap-2 px-4 py-5">
        {notifications && notifications.map((item,index) => {
          return (
            <div className="border-b" key={index}>
                <div className="fs-14 pb-2 secondary-color-text-l1 flex justify-between">
                    {item.historyFlag}
                    {/* {
                        index === 0 ? <span className="fs-14 primary-text-color cursor-pointer" onClick={()=>setNotifications([])}>Clear All</span> : ''
                    } */}
                    </div>
              <div className="fs-14">{item.title}</div>
              <div className="fs-14 secondary-color-text-l1 pt-1 pb-3">{item.dateTime}</div>
            </div>
          );
        })}
      </div>
    </Drawer>
  );
};
