import { COLUMNS_WIDTH } from "../../helpers/constant";

export const CollectionHeader = [
  {
    title: "UID No.",
    dataIndex: "id",
    key: "bankData",
  },
  {
    dataIndex: "Month",
  },
  {
    dataIndex: "Bank",
    ellipsis: true,
    width: COLUMNS_WIDTH.BANK,
  },
  {
    dataIndex: "Product",
    ellipsis: true,
  },
  {
    dataIndex: "Customer_name",
    width: COLUMNS_WIDTH.CUSTOMER_NAME,
    ellipsis: true,
  },
  {
    dataIndex: "App_No",
    ellipsis: true,
    width: COLUMNS_WIDTH.APP_NO,
  },
  {
    dataIndex: "Net_disbursal",
    ellipsis: true,
    key: "number",
  },
  {
    title: "LI Premium",
    dataIndex: "LI_Premium",
    ellipsis: true,
    key: "number",
  },
  {
    dataIndex: "Revenue",
    key: "number",
  },
  {
    dataIndex: "Outstanding",
    key: "number",
  },
  {
    dataIndex: "Location_head",
    ellipsis: true,
  },
  {
    dataIndex: "Location",
    ellipsis: true,
  },
  {
    dataIndex: "Total_subvention",
    width: "160px",
    key: "number",
  },
  {
    dataIndex: "Estimated_month",
    width: "170px",
  },
  {
    dataIndex: "TDS",
    key: "number",
  },
  {
    dataIndex: "Collection",
    key: "number",
  },
  {
    dataIndex: "Collection1",
    key: "number",
  },
  {
    dataIndex: "Total_collection",
    width: "170px",
    key: "number",
  },
  {
    dataIndex: "Collection_date",
    ellipsis: true,
  },
  {
    dataIndex: "Collection_date1",
    width: "170px",
  },
];
