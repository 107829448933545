import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import { AppContext } from "../helpers/app-context";
import { UPLOAD_DATA_TYPE, PAGINATION_DETAILS } from "../helpers/constant";
import { UploadedData } from "./tableContainer/UploadedData";
import { Header } from "./layout/Header";
import { SkeletonLoader } from "./common/SkeletonLoader";
import {
  INITIATE_RECONCILIATION,
  UPLOADED_DATA_VIEW_BANK,
  UPLOADED_DATA_VIEW_ESTIMATE,
  UPLOADED_DATA_VIEW_SUBVENTION,
  UPLOAD_DATA_TOTAL_COUNTS,
  UPLOAD_FILE_BANK,
  UPLOAD_FILE_ESTIMATE,
  UPLOAD_FILE_SUBVENTION,
} from "../helpers/api-endpoints";
import { CustomButton } from "./common/CustomButton";

export const Home = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const ctx = useContext(AppContext);
  const [bankList, setBankList] = useState({});
  const [estimateList, setEstimateList] = useState({});
  const [subventionlist, setSubventionList] = useState({});
  const [fileId, setFileId] = useState();
  const [totalCountRes, setTotalCountRes] = useState();
  const [uploadLoader, setUploadLoader] = useState(false);
  const [informative, setInformative] = useState(
    location?.state?.uploadMessage,
  );
  const [selectedTab, setSelectedTab] = useState(
    location?.state?.eventKey
      ? location?.state?.eventKey
      : UPLOAD_DATA_TYPE.BANK,
  );

  //loader visibility
  const [dataLoading, setDataLoading] = useState(true);
  const [titleLoading, setTitleLoading] = useState(true);
  const [disable, setDisable] = useState(false);

  // pagination
  const [pageSize, setPageSize] = useState(
    ctx?.sortAndPagination?.home?.[selectedTab]?.size,
  );
  const [currentPage, setCurrentPage] = useState(
    ctx?.sortAndPagination?.home?.[selectedTab]?.page,
  );
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [fromDateValue, setFromDateValue] = useState("");
  const [toDataValue, setToDataValue] = useState("");
  const [exportFullPageLoader, setExportFullPageLoader] = useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const [exportProgressBar, setExportProgressBar] = useState(0);

  // No data found and table no data screen handle
  const [initialData, setInitialData] = useState(false);

  //initially hide no data screen before api call
  const [hideNoData, setHideNoData] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const handleDisableBackButton = () => {
      window.history.pushState(null, "", window.location.href);
    };
    handleDisableBackButton();
    window.addEventListener("popstate", handleDisableBackButton);
    return () => {
      window.removeEventListener("popstate", handleDisableBackButton);
    };
  }, []);

  useEffect(() => {
    getTotalCount();
    ctx.checkReconsile();
  }, [ctx.isOnline]);

  useEffect(() => {
    if (
      (ctx.reconStatus?.intiate_status === 0 &&
        ctx.reconStatus?.new_file === 1) ||
      (ctx.reconStatus?.intiate_status == null &&
        ctx.reconStatus?.new_file == 1) ||
      (ctx.reconStatus?.intiate_status == 2 && ctx.reconStatus?.new_file == 1)
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [ctx.reconStatus]);

  useEffect(() => {
    getListData();
    //checkReconsile();
  }, [selectedTab, ctx.isOnline]);

  const HeaderElement = () => (
    <div className="flex">
      Uploaded Data ({totalCountRes?.total_rows ? totalCountRes?.total_rows : 0}
      )
      {totalCountRes?.total_rows ? (
        <div className="flex items-end gap-2">
          <CustomButton
            title={
              disable
                ? ctx.reconStatus.intiate_status === 1
                  ? "Reconciliation In Progress"
                  : "Initiate Reconciliation"
                : "Initiate Reconciliation"
            }
            className="btn-primary ml-3 px-5 fs-14"
            onClick={getInitiateReconciliation}
            disabled={disable}
          />{" "}
          <h2 className="fs-12">
            {ctx.reconStatus.last_reconcile
              ? `Last Reconciled started on : ${ctx.reconStatus.last_reconcile}`
              : ""}
            <br />
            {ctx.reconStatus.last_reconcile_end
              ? `Last Reconciled ended on : ${ctx.reconStatus.last_reconcile_end}`
              : ""}
          </h2>
        </div>
      ) : (
        ""
      )}
    </div>
  );
  const onChange = (key) => {
    setSelectedTab(key);
    setOrderByValue(ctx?.sortAndPagination?.home?.[key]?.orderBy || "");
    setSortByValue(ctx?.sortAndPagination?.home?.[key]?.sortBy || "");
    setPageSize(ctx?.sortAndPagination?.home?.[key]?.size || 10);
    setCurrentPage(ctx?.sortAndPagination?.home?.[key]?.page || 1);
    setInitialData(false);
    setDataLoading(false);
    setSearchValue("");
    setFromDateValue("");
    setToDataValue("");
    setBankList({});
    setEstimateList({});
    setSubventionList({});
    setHideNoData(true);
    setProgressBar(0);
    setExportProgressBar(0);
  };
  const uploadFile = async (e) => {
    setUploadLoader(true);
    let formData = new FormData();
    formData.append("profile", e);
    if (selectedTab === UPLOAD_DATA_TYPE.BANK) {
      const response = await ctx.HttpPost(
        UPLOAD_FILE_BANK,
        formData,
        false,
        (data) => {
          //Set the progress value to show the progress bar
          setProgressBar(Math.round((100 * data.loaded) / data.total));
        },
      );
      setUploadLoader(false);
      if (response.status) {
        ctx.checkReconsile();
        ctx.showToastAlert({ type: "success", message: response.message });
        setFileId(response.file_no);
        navigate("/temp", {
          state: {
            eventKey: UPLOAD_DATA_TYPE.BANK,
            fileId: response.file_no,
            errorEntries: {
              disbursalAndPremium:
                response?.bankAmountCount.disbursalAndPremiumCount,
              revenue: response?.bankAmountCount.Revenue,
              Net_disbursal: response?.bankAmountCount.Net_disbursal,
              LI_Premium: response?.bankAmountCount.LI_Premium,
              PF_collected_amt: response?.bankAmountCount.PF_collected_amt,
              Bill_amount: response?.bankAmountCount.Bill_amount,
              GST: response?.bankAmountCount.GST,
              Invoice_amount: response?.bankAmountCount.Invoice_amount,
              TDS: response?.bankAmountCount.TDS,
              Collection: response?.bankAmountCount.Collection,
              Collection1: response?.bankAmountCount.Collection1,
              Total_collection: response?.bankAmountCount.Total_collection,
              Outstanding: response?.bankAmountCount.Outstanding,
              Subvention: response?.bankAmountCount.Subvention,
              Subvention2: response?.bankAmountCount.Subvention2,
              Subvention3: response?.bankAmountCount.Subvention3,
              Other_subvention4: response?.bankAmountCount.Other_subvention4,
              Other_subvention5: response?.bankAmountCount.Other_subvention5,
              Total_subvention: response?.bankAmountCount.Total_subvention,
              Month: response?.bankAmountCount?.Month,
              Company: response?.bankAmountCount?.Company,
            },
          },
        });
        setProgressBar(0);
      } else if (!response.status) {
        ctx.showToastAlert({ type: "error", message: response.message });
      }
    } else if (selectedTab === UPLOAD_DATA_TYPE.ESTIMATE) {
      const response = await ctx.HttpPost(
        UPLOAD_FILE_ESTIMATE,
        formData,
        false,
        (data) => {
          //Set the progress value to show the progress bar
          setProgressBar(Math.round((100 * data.loaded) / data.total));
        },
      );
      setUploadLoader(false);
      if (response.status) {
        ctx.checkReconsile();
        ctx.showToastAlert({ type: "success", message: response.message });
        setFileId(response.file_no);
        navigate("/temp", {
          state: {
            eventKey: UPLOAD_DATA_TYPE.ESTIMATE,
            fileId: response.file_no,
            errorEntries: {
              subventionAmountEstimate:
                response?.subventionAmountEstimateCount?.subventionAmountCount,
              revenue: response?.subventionAmountEstimateCount?.Revenue,
              net_monthly_income:
                response?.subventionAmountEstimateCount?.Net_monthly_income,
              net_disbursal:
                response?.subventionAmountEstimateCount?.Net_disbursal,
              invoice_amount:
                response?.subventionAmountEstimateCount?.Invoice_amount,
              gst: response?.subventionAmountEstimateCount?.GST,
              Month: response?.subventionAmountEstimateCount?.Month,
              Company: response?.subventionAmountEstimateCount?.Company,
            },
          },
        });
        setProgressBar(0);
      } else if (!response.status) {
        ctx.showToastAlert({ type: "error", message: response.message });
      }
    } else if (selectedTab === UPLOAD_DATA_TYPE.SUBVENTION) {
      const response = await ctx.HttpPost(
        UPLOAD_FILE_SUBVENTION,
        formData,
        false,
        (data) => {
          //Set the progress value to show the progress bar
          setProgressBar(Math.round((100 * data.loaded) / data.total));
        },
      );
      setUploadLoader(false);
      if (response.status) {
        ctx.checkReconsile();
        ctx.showToastAlert({ type: "success", message: response.message });
        setFileId(response.file_no);
        navigate("/temp", {
          state: {
            eventKey: UPLOAD_DATA_TYPE.SUBVENTION,
            fileId: response.file_no,
            errorEntries: {
              payeeName: response?.payeeNameSubventionCount,
              subventionAmount:
                response?.subventionAmountCount?.subventionAmountCount,
              disb: response?.subventionAmountCount?.disbCount,
              Month: response?.subventionAmountCount?.Month,
              Company: response?.subventionAmountCount?.Company,
            },
          },
        });
        setProgressBar(0);
      } else if (!response.status) {
        ctx.showToastAlert({ type: "error", message: response.message });
      }
    }
  };

  const getTotalCount = async () => {
    setTitleLoading(true);
    const response = await ctx.HttpGet(UPLOAD_DATA_TOTAL_COUNTS);
    if (response.status) {
      setTotalCountRes(response);
    } else {
      setTotalCountRes({});
    }
    setTitleLoading(false);
  };

  const getInitiateReconciliation = async () => {
    setDisable(true);
    const response = await ctx.HttpGet(INITIATE_RECONCILIATION);
    if (response.status) {
      ctx.checkReconsile();
      ctx.showToastAlert({ type: "success", message: response.message });
    } else {
      ctx.showToastAlert({ type: "error", message: response.message });
    }
  };

  const getBankUploadedData = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
    fromDateBy = fromDateValue,
    toDataBy = toDataValue,
  ) => {
    if (!dataLoading) {
      setDataLoading(true);
    }
    setHideNoData(false);
    const sortData = ctx?.sortAndPagination?.home?.bank;
    let queryParams =
      count === 0
        ? sortData
        : {
            searchItem: searchBy,
            sortBy: sortBy || sortData?.sortBy,
            orderBy: orderBy || sortData?.orderBy,
            page: currentpage,
            size: pagesize,
            fromDate: fromDateBy,
            toDate: toDataBy,
          };
    ctx?.setSortAndPagination((prev) => ({
      ...prev,
      home: {
        ...prev?.home,
        bank: {
          ...queryParams,
          searchItem: null,
          fromDate: null,
          toDate: null,
        },
      },
      status: true,
    }));
    setCount((prev) => prev + 1);
    const response = await ctx.HttpGet(UPLOADED_DATA_VIEW_BANK, queryParams);
    setDataLoading(false);
    if (response.status) {
      setBankList(response);
      if (!initialData && Object.keys(response)?.length) {
        setInitialData(true);
      }
    } else {
      setBankList({});
      !searchBy &&
        !fromDateBy &&
        !toDataBy &&
        totalCountRes?.Bank_total > 0 &&
        getBankUploadedData(
          null,
          "",
          "",
          PAGINATION_DETAILS.CURRENT_PAGE,
          PAGINATION_DETAILS.PAGE_SIZE,
          "",
          "",
        );
    }
  };
  const getEstimateUploadedData = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
    fromDateBy = fromDateValue,
    toDataBy = toDataValue,
  ) => {
    setDataLoading(true);
    setHideNoData(false);
    const sortData = ctx?.sortAndPagination?.home?.estimate;
    let queryParams =
      count === 0
        ? sortData
        : {
            searchItem: searchBy,
            sortBy: sortBy || sortData?.sortBy,
            orderBy: orderBy || sortData?.orderBy,
            page: currentpage,
            size: pagesize,
            fromDate: fromDateBy,
            toDate: toDataBy,
          };
    ctx?.setSortAndPagination((prev) => ({
      ...prev,
      home: {
        ...prev?.home,
        estimate: {
          ...queryParams,
          searchItem: null,
          fromDate: null,
          toDate: null,
        },
      },
      status: true,
    }));
    setCount((prev) => prev + 1);
    const response = await ctx.HttpGet(
      UPLOADED_DATA_VIEW_ESTIMATE,
      queryParams,
    );
    setDataLoading(false);
    if (response.status) {
      setEstimateList(response);
      if (!initialData && Object.keys(response)?.length) {
        setInitialData(true);
      }
    } else {
      setEstimateList({});
      !searchBy &&
        !fromDateBy &&
        !toDataBy &&
        totalCountRes?.Estimate_total > 0 &&
        getEstimateUploadedData(
          null,
          "",
          "",
          PAGINATION_DETAILS.CURRENT_PAGE,
          PAGINATION_DETAILS.PAGE_SIZE,
          "",
          "",
        );
    }
  };
  const getSubventionUploadedData = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
    fromDateBy = fromDateValue,
    toDataBy = toDataValue,
  ) => {
    setDataLoading(true);
    setHideNoData(false);
    const sortData = ctx?.sortAndPagination?.home?.subvention;
    let queryParams =
      count === 0
        ? sortData
        : {
            searchItem: searchBy,
            sortBy: sortBy || sortData?.sortBy,
            orderBy: orderBy || sortData?.orderBy,
            page: currentpage,
            size: pagesize,
            fromDate: fromDateBy,
            toDate: toDataBy,
          };
    ctx?.setSortAndPagination((prev) => ({
      ...prev,
      home: {
        ...prev?.home,
        subvention: {
          ...queryParams,
          searchItem: null,
          fromDate: null,
          toDate: null,
        },
      },
      status: true,
    }));
    setCount((prev) => prev + 1);
    const response = await ctx.HttpGet(
      UPLOADED_DATA_VIEW_SUBVENTION,
      queryParams,
    );
    setDataLoading(false);
    if (response.status) {
      setSubventionList(response);
      if (!initialData && Object.keys(response)?.length) {
        setInitialData(true);
      }
    } else {
      setSubventionList({});
      !searchBy &&
        !fromDateBy &&
        !toDataBy &&
        totalCountRes?.Subvention_total > 0 &&
        getSubventionUploadedData(
          null,
          "",
          "",
          PAGINATION_DETAILS.CURRENT_PAGE,
          PAGINATION_DETAILS.PAGE_SIZE,
          "",
          "",
        );
    }
  };

  const getListData = () => {
    if (selectedTab === UPLOAD_DATA_TYPE.BANK) {
      getBankUploadedData();
    } else if (selectedTab === UPLOAD_DATA_TYPE.ESTIMATE) {
      getEstimateUploadedData();
    } else if (selectedTab === UPLOAD_DATA_TYPE.SUBVENTION) {
      getSubventionUploadedData();
    }
  };
  const items = [
    {
      key: UPLOAD_DATA_TYPE.BANK,
      label: (
        <SkeletonLoader loading={titleLoading} style={{ height: "20px" }}>
          {`Bank (${
            totalCountRes?.Bank_total ? totalCountRes?.Bank_total : 0
          })`}
        </SkeletonLoader>
      ),
      children: (
        <UploadedData
          parentData={"home"}
          selectedTab={selectedTab}
          data={bankList?.Bank_upload_data}
          eventKey={UPLOAD_DATA_TYPE.BANK}
          uploadMessage={location?.state?.uploadMessage}
          uploadFile={uploadFile}
          setExportFullPageLoader={setExportFullPageLoader}
          setExportProgressBar={setExportProgressBar}
          setDataLoading={setDataLoading}
          loading={dataLoading}
          initialData={initialData}
          fileId={fileId}
          informative={informative}
          setInformative={setInformative}
          totalRecords={bankList?.total_page_size}
          pageSize={pageSize}
          currentPage={currentPage}
          searchValue={searchValue}
          fromDateValue={fromDateValue}
          toDataValue={toDataValue}
          getTableData={getBankUploadedData}
          getTotalCount={getTotalCount}
          setSearchValue={setSearchValue}
          setCurrentPage={setCurrentPage}
          setFromDateValue={setFromDateValue}
          setToDataValue={setToDataValue}
          setSortByValue={setSortByValue}
          setOrderByValue={setOrderByValue}
          setPageSize={setPageSize}
          hideNoData={hideNoData}
        />
      ),
    },
    {
      key: UPLOAD_DATA_TYPE.ESTIMATE,
      label: (
        <SkeletonLoader loading={titleLoading} style={{ height: "20px" }}>
          {`Estimate (${
            totalCountRes?.Estimate_total ? totalCountRes?.Estimate_total : 0
          })`}
        </SkeletonLoader>
      ),
      children: (
        <UploadedData
          parentData={"home"}
          selectedTab={selectedTab}
          data={estimateList?.Estimate_upload_data}
          eventKey={UPLOAD_DATA_TYPE.ESTIMATE}
          uploadMessage={location?.state?.uploadMessage}
          uploadFile={uploadFile}
          setExportFullPageLoader={setExportFullPageLoader}
          setExportProgressBar={setExportProgressBar}
          setDataLoading={setDataLoading}
          loading={dataLoading}
          initialData={initialData}
          fileId={fileId}
          informative={informative}
          setInformative={setInformative}
          totalRecords={estimateList?.total_page_size}
          pageSize={pageSize}
          currentPage={currentPage}
          searchValue={searchValue}
          fromDateValue={fromDateValue}
          toDataValue={toDataValue}
          getTableData={getEstimateUploadedData}
          getTotalCount={getTotalCount}
          setSearchValue={setSearchValue}
          setCurrentPage={setCurrentPage}
          setFromDateValue={setFromDateValue}
          setToDataValue={setToDataValue}
          setSortByValue={setSortByValue}
          setOrderByValue={setOrderByValue}
          setPageSize={setPageSize}
          hideNoData={hideNoData}
        />
      ),
    },
    {
      key: UPLOAD_DATA_TYPE.SUBVENTION,
      label: (
        <SkeletonLoader style={{ height: "20px" }} loading={titleLoading}>
          {`Subvention (${
            totalCountRes?.Subvention_total
              ? totalCountRes?.Subvention_total
              : 0
          })`}
        </SkeletonLoader>
      ),
      children: (
        <UploadedData
          parentData={"home"}
          selectedTab={selectedTab}
          data={subventionlist?.Subvention_upload_data}
          eventKey={UPLOAD_DATA_TYPE.SUBVENTION}
          uploadMessage={location?.state?.uploadMessage}
          uploadFile={uploadFile}
          setExportFullPageLoader={setExportFullPageLoader}
          setExportProgressBar={setExportProgressBar}
          setDataLoading={setDataLoading}
          loading={dataLoading}
          initialData={initialData}
          fileId={fileId}
          informative={informative}
          setInformative={setInformative}
          totalRecords={subventionlist?.total_page_size}
          pageSize={pageSize}
          currentPage={currentPage}
          searchValue={searchValue}
          fromDateValue={fromDateValue}
          toDataValue={toDataValue}
          getTableData={getSubventionUploadedData}
          getTotalCount={getTotalCount}
          setSearchValue={setSearchValue}
          setCurrentPage={setCurrentPage}
          setFromDateValue={setFromDateValue}
          setToDataValue={setToDataValue}
          setSortByValue={setSortByValue}
          setOrderByValue={setOrderByValue}
          setPageSize={setPageSize}
          hideNoData={hideNoData}
        />
      ),
    },
  ];
  return (
    <>
      {uploadLoader || exportFullPageLoader ? (
        <div className="loader flex flex-col">
          <img
            src="/images/upload-loader.gif"
            alt="loader"
            className={`${exportFullPageLoader && "rotate180"}`}
          />
          <h3>
            {uploadLoader
              ? progressBar !== 100
                ? "Uploading File"
                : " Processing file"
              : exportFullPageLoader && "Downloading File"}
          </h3>
          {/* progress bar for upload */}
          {uploadLoader && progressBar !== 100 && (
            <div
              className="bg-gray-200 rounded-full dark:bg-gray-700"
              style={{ width: "15%", marginTop: "2rem" }}
            >
              <div
                className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                style={{ width: `${progressBar}%`, transition: "500ms all" }}
              >
                {progressBar}%
              </div>
            </div>
          )}

          {/* progress bar for export */}
          {!uploadLoader &&
            exportFullPageLoader &&
            exportProgressBar !== 100 && (
              <div
                className="bg-gray-200 rounded-full dark:bg-gray-700"
                style={{ width: "15%", marginTop: "2rem" }}
              >
                <div
                  className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                  style={{
                    width: `${exportProgressBar}%`,
                    transition: "500ms all",
                  }}
                >
                  {exportProgressBar}%
                </div>
              </div>
            )}
        </div>
      ) : (
        <>
          <Header title={<HeaderElement />} />
          <Tabs
            defaultActiveKey={selectedTab}
            items={items}
            onChange={onChange}
            className="custom-tabs"
          />
        </>
      )}
    </>
  );
};
