export const columnLabels = [
  "Month",
  "Bank",
  "Product",
  "Customer_name",
  "App_No",
  "Net_disbursal",
  "Disbursal",
  "LI_Premium",
  "Revenue",
  "Location",
  "Outstanding",
  "Location_head",
  "Total_subvention",
  "Subvention",
  "Estimated_month",
];
