import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import { useNavigate } from "react-router-dom";
import { AppContext } from "../helpers/app-context";
import { CustomInput } from "./common/CustomInput";
import { SignInLeftSide } from "./common/SignInLeftSide";
import { CustomButton } from "./common/CustomButton";
import { FORGOT_PASSWORD } from "../helpers/api-endpoints";

let forgotPasswordScema = yup.object().shape({
  email: yup
    .string()
    .max(100, "Email address should not be more than 100 characters")
    .email("Please enter valid email address")
    .required("Email address is required")
    .trim(),
});

export const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const ctx = useContext(AppContext);
  const [forgotPasswordEmailActive, setForgotPasswordEmailActive] =
    useState(false);
  // Formik Initialization
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordScema, // validation schema
    onSubmit: async (values) => {
      const result = await ctx.HttpPut(FORGOT_PASSWORD, values);
      if (result.status === true) {
        ctx.showToastAlert({ type: "success", message: result.message });
        navigate("/email-sent", {
          state: values,
        });
      }
      if (result.status === false) {
        ctx.showToastAlert({ type: "error", message: result.message });
      }
    },
  });
  useEffect(() => {
    if (formik.values.email.length > 0) {
      setForgotPasswordEmailActive(true);
    }
  }, [formik]);
  return (
    <div className="flex items-center h-screen">
      <SignInLeftSide />
      <div className="mx-auto loginscreen-left max-w-[350px]">
        <div className="font-semibold fs-40 highlight-text-color pb-2">
          Forgot Password
        </div>
        <div
          className={`fs-16 secondary-color-text-l1 ${
            forgotPasswordEmailActive ? "pb-4" : "pb-2"
          }`}
        >
          Enter email associated with your account and we’ll send you a link to
          reset your password.
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-8">
            <CustomInput
              type="email"
              id="email"
              label="Email Address"
              name="email"
              errors={
                formik.touched.email && formik.errors.email ? "error" : ""
              }
              onChange={formik.handleChange("email")}
              onBlur={() => {
                formik.handleBlur("email");
                setForgotPasswordEmailActive(false);
              }}
              value={formik.values.email}
              onFocus={() => {
                setForgotPasswordEmailActive(true);
              }}
              heightClass={"min-h-[42px]"}
            />
            <div className="error">
              {formik.touched.email && formik.errors.email}
            </div>
          </div>
          <CustomButton
            type="submit"
            title="Send Email"
            className="btn-primary w-full mt-10"
            onClick={formik.handleSubmit}
          />
        </form>
        <div
          className="mt-[27px] primary-text-color fs-14 font-semibold text-center cursor-pointer hover:underline"
          onClick={() => navigate("/login")}
        >
          Back to Login
        </div>
      </div>
    </div>
  );
};
