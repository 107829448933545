import React, { useState } from "react";
import { CustomUpload } from "./CustomUpload";
import { Dropdown } from "antd";

export const CustomDropdownUpload = (props) => {
  const { uploadFile, templateDownload } = props;
  const [dropdownClick, setDropdownClick] = useState(false);
  
  const items = [
    {
      key: "1",
      label: <CustomUpload dropdownFlag={true} uploadFile={uploadFile} />,
      icon: (
        <img
          className="pt-1 pr-3"
          src="/images/icon/upload-menu.svg"
          alt="upload-icon"
        />
      ),
    },
    {
      key: "2",
      label: (
        <label
          className="flex flex-col cursor-pointer"
          onClick={templateDownload}
        >
          Download Template
          <span className={"text-[10px] text-[#A0A4B3]"}>
            Add data using the template
          </span>
        </label>
      ),
      icon: (
        <img
          className="pr-3"
          src="/images/icon/download.svg"
          alt="download-icon"
        />
      ),
    },
  ];
  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={["click"]}
      onClick={() => setDropdownClick(!dropdownClick)}
    >
      <label className="upload-btn btn w-max mx-auto cursor-pointer">
        <label className="cursor-pointer">
          <img
            src="/images/icon/upload-file.svg"
            alt="upload"
            className="mr-2"
          />
        </label>
        Upload
        <span
          className={`${
            dropdownClick ? "p-0 pt-2.5 " : "p-0 pb-1.5 "
          } transition duration-75 ease-in-out pl-2 flex `}
        >
          <img
            className={`${dropdownClick && "rotate-180"} `}
            src="/images/icon/up-angle.svg"
            alt="up-arrow"
          />
        </span>
      </label>
    </Dropdown>
  );
};
