import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UPLOAD_DATA_TYPE } from "../../helpers/constant";
import { AppContext } from "../../helpers/app-context";
import { CustomButton } from "../common/CustomButton";
import { CustomDataTable } from "../common/CustomDataTable";
import { CustomDatepicker } from "../common/CustomDatepicker";
import { CustomDrawer } from "../common/CustomDrawer";
import { CustomSearch } from "../common/CustomSearch";
import {
  columnsBank,
  columnsEstimate,
  columnsSubvention,
} from "../tableHeaders/UploadedDataHeader";
import {
  DOWNLOAD_BANK_TEMPLATE,
  DOWNLOAD_ESTIMATE_TEMPLATE,
  DOWNLOAD_SUBVENTION_TEMPLATE,
  EXPORT_UPLOADED_DATA_BANK,
  EXPORT_UPLOADED_DATA_ESTIMATE,
  EXPORT_UPLOADED_DATA_SUBVENTION,
  UPLOADED_DATA_DETAILS_BANK,
  UPLOADED_DATA_DETAILS_ESTIMATE,
  UPLOADED_DATA_DETAILS_SUBVENTION,
} from "../../helpers/api-endpoints";
import {
  DownloadFileBlob,
  DownloadFileAttached,
  FromDatePickerParams,
  SortedOrderBy,
  ToDatePickerParams,
} from "../../helpers/functions";
import { CustomDropdownUpload } from "../common/CustomDropdownUpload";
import { NoData } from "../common/NoData";
import { NoInternet } from "../common/NoInternet";
import { CustomDropdownExports } from "../common/CustomDropdownExports";
export const UploadedData = (props) => {
  const {
    parentData,
    selectedTab,
    data,
    uploadMessage,
    eventKey,
    uploadFile,
    setExportFullPageLoader,
    setExportProgressBar,
    loading,
    initialData,
    fileId,
    informative,
    setInformative,
    searchValue,
    totalRecords,
    pageSize,
    currentPage,
    fromDateValue,
    toDataValue,
    getTableData,
    getTotalCount,
    setSearchValue,
    setCurrentPage,
    setFromDateValue,
    setToDataValue,
    setSortByValue,
    setOrderByValue,
    setPageSize,
    hideNoData,
  } = props;
  const ctx = useContext(AppContext);
  const navigate = useNavigate();
  const [openDrawable, setOpenDrawable] = useState(false);
  const [drawableData, setDrawableData] = useState([]);
  const [filterableData, setFilterableData] = useState(data);
  const [exportBtnDisable, setExportBtnDisable] = useState(false);
  useEffect(() => {
    setFilterableData(data);
  }, [data]);
  const get_upload_details = async (uid_no) => {
    const response = await ctx.HttpGet(
      eventKey === UPLOAD_DATA_TYPE.BANK
        ? UPLOADED_DATA_DETAILS_BANK + uid_no
        : eventKey === UPLOAD_DATA_TYPE.ESTIMATE
        ? UPLOADED_DATA_DETAILS_ESTIMATE + uid_no
        : eventKey === UPLOAD_DATA_TYPE.SUBVENTION &&
          UPLOADED_DATA_DETAILS_SUBVENTION + uid_no,
    );
    if (response.status) {
      setOpenDrawable(true);
      setDrawableData(
        eventKey === UPLOAD_DATA_TYPE.BANK
          ? response?.Bank_upload_data[0]
          : eventKey === UPLOAD_DATA_TYPE.ESTIMATE
          ? response?.Estimate_upload_data[0]
          : eventKey === UPLOAD_DATA_TYPE.SUBVENTION &&
            response?.Subvention_upload_data[0],
      );
    }
  };
  const exportedData = async (
    searchBy = searchValue,
    fromDate = fromDateValue,
    toData = toDataValue,
    type,
  ) => {
    setExportBtnDisable(true);
    setExportFullPageLoader(true);
    let queryParams = {
      searchItem: searchBy,
      fromDate: fromDate,
      toDate: toData,
      type: type,
    };
    setExportProgressBar(0);
    const progressSteps = [24, 78, 87, 90]; // Arbitrary progress values at different stages
    let currentStep = 0;
    const progressInterval = setInterval(() => {
      setExportProgressBar(progressSteps[currentStep]);
      currentStep += 1;

      if (currentStep >= progressSteps.length) {
        clearInterval(progressInterval); // Stop the progress update once all steps are completed
      }
    }, 1000); // Adjust the interval based on your needs

    const response = await ctx.HttpGet(
      eventKey === UPLOAD_DATA_TYPE.BANK
        ? EXPORT_UPLOADED_DATA_BANK
        : eventKey === UPLOAD_DATA_TYPE.ESTIMATE
        ? EXPORT_UPLOADED_DATA_ESTIMATE
        : eventKey === UPLOAD_DATA_TYPE.SUBVENTION &&
          EXPORT_UPLOADED_DATA_SUBVENTION,
      queryParams,
      true,
    );

    if (response && typeof response.data === "string") {
      setExportProgressBar(100);
      if (!response.headers && response.status == 200) {
        DownloadFileBlob(response.data, "uploaded_data_" + eventKey, "csv");
        ctx.showToastAlert({ type: "success", message: response.message });
      } else {
        DownloadFileAttached(response.data, "uploaded_data_" + eventKey, "csv");
        ctx.showToastAlert({
          type: "success",
          message: "Downloaded successfully",
        });
      }
    } else if (typeof response.data === "object" && !response.data.status) {
      ctx.showToastAlert({
        type: "error",
        message: response.data.message,
      });
    }
    setExportFullPageLoader(false);
    setExportBtnDisable(false);
  };
  const templateDownload = async () => {
    const response = await ctx.HttpGet(
      eventKey === UPLOAD_DATA_TYPE.BANK
        ? DOWNLOAD_BANK_TEMPLATE
        : eventKey === UPLOAD_DATA_TYPE.ESTIMATE
        ? DOWNLOAD_ESTIMATE_TEMPLATE
        : eventKey === UPLOAD_DATA_TYPE.SUBVENTION &&
          DOWNLOAD_SUBVENTION_TEMPLATE,
      null,
    );
    if (response.status) {
      DownloadFileBlob(response.data, "template_data_" + eventKey, "csv");
      ctx.showToastAlert({ type: "success", message: response.message });
    }
  };

  const optionsUploadBankExportItems = [
    {
      key: "item-1",
      label: (
        <div
          onClick={() =>
            exportedData(searchValue, fromDateValue, toDataValue, "all")
          }
        >
          Bank Upload
        </div>
      ),
    },
    {
      key: "item-2",
      label: (
        <div
          onClick={() =>
            exportedData(
              searchValue,
              fromDateValue,
              toDataValue,
              "bank_matches",
            )
          }
        >
          Bank Matches
        </div>
      ),
    },
  ];
  const optionsUploadEstimateExportItems = [
    {
      key: "item-1",
      label: (
        <div
          onClick={() =>
            exportedData(searchValue, fromDateValue, toDataValue, "all")
          }
        >
          Estimate Upload
        </div>
      ),
    },
    {
      key: "item-2",
      label: (
        <div
          onClick={() =>
            exportedData(
              searchValue,
              fromDateValue,
              toDataValue,
              "estimate_matches",
            )
          }
        >
          Estimate Matches
        </div>
      ),
    },
  ];
  const optionsUploadSubventionExportItems = [
    {
      key: "item-1",
      label: (
        <div
          onClick={() =>
            exportedData(searchValue, fromDateValue, toDataValue, "all")
          }
        >
          Subvention Upload
        </div>
      ),
    },
    {
      key: "item-2",
      label: (
        <div
          onClick={() =>
            exportedData(
              searchValue,
              fromDateValue,
              toDataValue,
              "subvention_matches",
            )
          }
        >
          Subvention Matches
        </div>
      ),
    },
  ];
  return !ctx.isOnline ? (
    <NoInternet
      onClick={() => {
        getTableData();
        getTotalCount();
      }}
    />
  ) : (
    <>
      {loading || (ctx.isOnline && initialData) ? (
        <>
          <div className="flex items-center justify-between px-4 py-2">
            <CustomSearch
              placeholder={
                eventKey === UPLOAD_DATA_TYPE.SUBVENTION
                  ? "Search by Customer Name, Bank, Agreement No."
                  : "Search by Customer Name, Bank and App No."
              }
              onSearch={(value) => {
                if (value.length >= 3) {
                  setSearchValue(value);
                  setCurrentPage(1);
                  getTableData(value, null, null, 1, pageSize);
                } else if (value.length === 0) {
                  setSearchValue("");
                  getTableData(null, null, null, currentPage, pageSize);
                }
              }}
              searchType="manual"
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            <div className="flex items-center">
              <CustomDatepicker
                value={[fromDateValue, toDataValue]}
                onChangeDatePicker={(date, dateString) => {
                  if (date) {
                    setFromDateValue(FromDatePickerParams(date));
                    setToDataValue(ToDatePickerParams(date));
                    getTableData(
                      searchValue,
                      null,
                      null,
                      1,
                      pageSize,
                      FromDatePickerParams(date),
                      ToDatePickerParams(date),
                    );
                  } else {
                    setFromDateValue(null);
                    setToDataValue(null);
                    getTableData(
                      searchValue,
                      null,
                      null,
                      1,
                      pageSize,
                      null,
                      null,
                    );
                  }
                }}
              />
              <div className="mx-4">
                <CustomDropdownExports
                  disabled={!(data && data.length > 0)}
                  onExportClick={() =>
                    eventKey === UPLOAD_DATA_TYPE.BANK
                      ? exportedData(
                          searchValue,
                          fromDateValue,
                          toDataValue,
                          "all",
                        )
                      : eventKey === UPLOAD_DATA_TYPE.ESTIMATE
                      ? exportedData(
                          searchValue,
                          fromDateValue,
                          toDataValue,
                          "all",
                        )
                      : eventKey === UPLOAD_DATA_TYPE.SUBVENTION &&
                        exportedData(
                          searchValue,
                          fromDateValue,
                          toDataValue,
                          "all",
                        )
                  }
                  dropdown={false}
                />
              </div>

              {/* <div
                className={`mx-4 export-btn ${
                  !data || exportBtnDisable ? "custom-disabled" : ""
                }`}
                onClick={() => {
                  exportedData();
                }}
              >
                <img src="/images/icon/export.svg" alt="export" />
              </div> */}
              <CustomDropdownUpload
                uploadFile={uploadFile}
                templateDownload={templateDownload}
              />
            </div>
          </div>
          <CustomDataTable
            parentData={parentData}
            selectedTab={selectedTab}
            rowKey="uid_no"
            columns={
              eventKey === UPLOAD_DATA_TYPE.BANK
                ? columnsBank
                : eventKey === UPLOAD_DATA_TYPE.ESTIMATE
                ? columnsEstimate
                : eventKey === UPLOAD_DATA_TYPE.SUBVENTION
                ? columnsSubvention
                : ""
            }
            data={filterableData}
            className="cust_datatable"
            rowClickable={true}
            tableHeight={"calc(100vh - 285px)"}
            loading={loading}
            handleRowClick={(rowData) => {
              get_upload_details(rowData.uid_no);
            }}
            totalRecords={totalRecords}
            pageSize={pageSize}
            currentPage={currentPage}
            onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
              if (sorterOptions && sorterOptions.order) {
                setOrderByValue(SortedOrderBy(sorterOptions.order));
                setSortByValue(sorterOptions.field);
              }
              setPageSize(pageSizeOptions.pageSize);
              setCurrentPage(pageSizeOptions.current);
              getTableData(
                searchValue,
                sorterOptions.order ? sorterOptions.field : "",
                SortedOrderBy(sorterOptions.order),
                pageSizeOptions.current,
                pageSizeOptions.pageSize,
              );
            }}
          />
        </>
      ) : (
        <NoData
          hideNoData={hideNoData}
          templateDownload={templateDownload}
          dropdownFlag={true}
          isUpload={true}
          eventKey={UPLOAD_DATA_TYPE.BANK}
          fileId={fileId}
          uploadFile={uploadFile}
        />
      )}
      {uploadMessage && informative ? (
        <div className="informative-chip rounded h-[47px]">
          <div className="pr-3">
            {uploadMessage} To download failed entries again.
          </div>
          <CustomButton
            className="ml-5 mr-8 goto-upload-history-btn fs-14"
            title="Go to Upload History"
            style={{
              width: "180px",
              maxHeight: "34px",
            }}
            onClick={() =>
              navigate("/upload-history", { state: { eventKey: eventKey } })
            }
          />
          <img
            src="/images/icon/close.svg"
            alt="close"
            className="informative-close cursor-pointer"
            onClick={() => setInformative(false)}
          />
        </div>
      ) : (
        ""
      )}
      {openDrawable && (
        <CustomDrawer
          setOpenDrawable={setOpenDrawable}
          data={drawableData}
          selectedTab={eventKey}
        />
      )}
    </>
  );
};
