import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { REVENUE_THRESHOLD_APPROVE_REJECT } from "../../helpers/api-endpoints";
import { AppContext } from "../../helpers/app-context";
import { MY_TASK } from "../../helpers/constant";

import { CustomButton } from "./CustomButton";
import { SkeletonLoader } from "./SkeletonLoader";

export const RevenueTreshold = (props) => {
  const { detailData } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const ctx = useContext(AppContext);
  const { revenueThresholdDetails, loading } = props;
  const Is_disabled = useState(detailData?.Is_disabled);
  const Is_approve = useState(detailData?.Is_approve);

  const {
    current_subvention_percentage_of_total_revenue,
    current_total_subvention,
    new_subvention_amount,
    post_approval_total_subvention,
    revenue_threshold_percentage,
    total_revenue,
  } = revenueThresholdDetails;

  const getRevenueThresholdApproveReject = async (status) => {
    const response = await ctx.HttpGet(
      REVENUE_THRESHOLD_APPROVE_REJECT +
        location?.state?.id +
        "/" +
        location?.state?.subvention_id +
        "/" +
        status,
      {
        user_id: ctx?.userInfo?.id,
        user_name: ctx?.userInfo?.username,
      },
    );
    if (status === "Approve" && response.status) {
      ctx.showToastAlert({ type: "success", message: response.message });
      navigate("/my-tasks", {
        state: {
          selectedTab: MY_TASK.REVENUE_THRESHOLD,
        },
      });
    } else if (status === "Reject" && !response.status) {
      ctx.showToastAlert({ type: "success", message: response.message });
      navigate("/my-tasks", {
        state: {
          selectedTab: MY_TASK.REVENUE_THRESHOLD,
        },
      });
    }
  };

  return (
    <div className="">
      <div className="">
        <h2 className="fs-20 p-4 col-span-4 font-semibold lightgrey-bg-color">
          Revenue Threshold
        </h2>
      </div>
      <SkeletonLoader
        loading={loading}
        style={{ width: "100%" }}
        className="p-3"
      >
        <div
          className="py-4 px-4"
          style={{ maxHeight: "calc(100vh - 240px)", overflow: "auto" }}
        >
          <div className="grid grid-cols-1 lg:grid-cols-2 py-1 px-6 border rounded-lg mb-2">
            <h5 className="col-span-1 fs-16 font-semibold flex items-center">
              Total Revenue
            </h5>
            <div className="lg:text-center fs-32 font-semibold">
              {total_revenue}
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-5 py-2 px-6 border rounded-lg mb-2">
            <div className="col-span-2">
              <h5 className="fs-16 font-semibold pb-1">
                Current
                <br />
                Total Subvention
              </h5>
              <div className="fs-32 font-semibold">
                {current_total_subvention}
              </div>
            </div>
            <div className="col-span-3 lg:pl-4">
              <h5 className="fs-16 font-semibold flex justify-center items-center pb-1">
                Current Subvention Percentage of Total Revenue
              </h5>
              <div className="fs-32 font-semibold">
                {current_subvention_percentage_of_total_revenue}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-5 py-2 px-6 border rounded-lg mb-2">
            <div className="lg:col-span-5">
              <h2 className="fs-16 font-semibold">New Subvention Amount</h2>
              <div className="fs-32 font-semibold primary-text-color">
                {new_subvention_amount}
              </div>
            </div>
            <div className="lg:col-span-2">
              <h5 className="fs-16 font-semibold pb-1">
                Post Approval
                <br />
                Total Subvention
              </h5>
              <div className="fs-32 font-semibold">
                {post_approval_total_subvention}
              </div>
              <span className="fs-16 secondary-color-text-l1">
                ({current_total_subvention} + {new_subvention_amount})
              </span>
            </div>
            <div className="lg:col-span-3 lg:pl-4">
              <h5 className="fs-16 font-semibold pb-1">
                Revenue Threshold Percentage
              </h5>
              <div className="fs-32 font-semibold lightred-text-color">
                {revenue_threshold_percentage}
              </div>
            </div>
          </div>
        </div>
      </SkeletonLoader>
      <div className="grid lg:grid-cols-12 py-1 px-9 border-t">
        <div className="col-span-7">
          <h5 className="fs-16 font-semibold">
            Subvention Amount to
            <br />
            be approved
          </h5>
          <SkeletonLoader loading={loading}>
            <div className="fs-32 font-semibold">{new_subvention_amount}</div>
          </SkeletonLoader>
        </div>
        <div className="col-span-3 text-right m-auto">
          {Is_disabled && (
            <CustomButton
              type="button"
              title="Reject"
              className="btn-light-red font-bold py-2 px-4 rounded fs-16"
              onClick={() => getRevenueThresholdApproveReject("Reject")}
              // onClick={() => navigate("/email-sent")}
            />
          )}
        </div>
        <div className="col-span-2 text-right m-auto">
          {Is_approve && (
            <CustomButton
              type="button"
              title="Approve"
              className="btn-primary font-bold py-2 px-4 rounded fs-16"
              onClick={() => getRevenueThresholdApproveReject("Approve")}
              // onClick={() => navigate("/email-sent")}
            />
          )}
        </div>
      </div>
    </div>
  );
};
