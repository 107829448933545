import { useContext, useEffect, useState } from "react";
import { Tabs } from "antd";

import { CustomDrawer } from "./common/CustomDrawer";
import { Header } from "./layout/Header";
import { PAGINATION_DETAILS, UPLOAD_DATA_TYPE } from "../helpers/constant";
import { AppContext } from "../helpers/app-context";
import {
  CLOSED_RECORDS_BANK,
  CLOSED_RECORDS_BANK_DETAILS,
  CLOSED_RECORDS_COUNT,
  CLOSED_RECORDS_ESTIMATE,
  CLOSED_RECORDS_ESTIMATE_DETAILS,
  CLOSED_RECORDS_SUBVENTION,
  CLOSED_RECORDS_SUBVENTION_DETAILS,
  EXPORT_CLOSED_DATA_BANK,
  EXPORT_CLOSED_DATA_ESTIMATE,
  EXPORT_CLOSED_DATA_SUBVENTION,
} from "../helpers/api-endpoints";
import { DownloadFileAttached, DownloadFileBlob } from "../helpers/functions";
import { SkeletonLoader } from "./common/SkeletonLoader";
import { ClosedRecordsTable } from "./tableContainer/ClosedRecordTable";

export const ClosedRecords = (props) => {
  const ctx = useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState(UPLOAD_DATA_TYPE.BANK);
  const [openDrawable, setOpenDrawable] = useState(false);
  const [drawableData, setDrawableData] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);
  const [titleLoading, setTitleLoading] = useState(true);
  const [initialData, setInitialData] = useState(false);
  const [totalCountRes, setTotalCountRes] = useState(0);
  const [bankClosedRecords, setBankClosedRecords] = useState({});
  const [estimateClosedRecords, setEstimateClosedRecords] = useState({});
  const [subventionClosedRecords, setSubventionClosedRecords] = useState({});
  const [pageSize, setPageSize] = useState(
    ctx?.sortAndPagination?.closed_records?.[selectedTab]?.size,
  );
  const [currentPage, setCurrentPage] = useState(
    ctx?.sortAndPagination?.closed_records?.[selectedTab]?.page,
  );
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [fromDateValue, setFromDateValue] = useState("");
  const [toDataValue, setToDataValue] = useState("");
  const [exportBtnDisable, setExportBtnDisable] = useState(false);

  //initially hide no data screen before api call
  const [hideNoData, setHideNoData] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getTotalCount();
    ctx.checkReconsile();
  }, [ctx.isOnline]);

  useEffect(() => {
    if (selectedTab === UPLOAD_DATA_TYPE.BANK) {
      getClosedRecordBank();
    } else if (selectedTab === UPLOAD_DATA_TYPE.ESTIMATE) {
      getClosedRecordForEstimate();
    } else if (selectedTab === UPLOAD_DATA_TYPE.SUBVENTION) {
      getClosedRecordForSubvention();
    }
  }, [selectedTab, ctx.isOnline]);

  const getClosedRecordBank = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
    fromDateBy = fromDateValue,
    toDataBy = toDataValue,
  ) => {
    if (!dataLoading) {
      setDataLoading(true);
    }
    setHideNoData(false);

    const sortData = ctx?.sortAndPagination?.closed_records?.[selectedTab];
    let queryParams =
      count === 0
        ? sortData
        : {
            searchItem: searchBy,
            sortBy: sortBy || sortData?.sortBy,
            orderBy: orderBy || sortData?.orderBy,
            page: currentpage,
            size: pagesize,
            fromDate: fromDateBy,
            toDate: toDataBy,
          };
    ctx?.setSortAndPagination((prev) => ({
      ...prev,
      closed_records: {
        ...prev?.closed_records,
        [selectedTab]: {
          ...queryParams,
          searchItem: null,
          fromDate: null,
          toDate: null,
        },
      },
      status: true,
    }));
    setCount((prev) => prev + 1);
    const response = await ctx.HttpGet(CLOSED_RECORDS_BANK, queryParams);
    setDataLoading(false);
    if (response.status) {
      setBankClosedRecords(response);
      if (!initialData && Object.keys(response)?.length) {
        setInitialData(true);
      }
    } else {
      setBankClosedRecords({});
      !searchBy &&
        !fromDateBy &&
        !toDataBy &&
        totalCountRes?.Bank_total > 0 &&
        getClosedRecordBank(
          null,
          "",
          "",
          PAGINATION_DETAILS.CURRENT_PAGE,
          PAGINATION_DETAILS.PAGE_SIZE,
          "",
          "",
        );
    }
  };

  const exportedData = async (
    searchBy = searchValue,
    fromDate = fromDateValue,
    toData = toDataValue,
  ) => {
    setExportBtnDisable(true);
    let queryParams = {
      searchItem: searchBy,
      fromDate: fromDate,
      toDate: toData,
    };
    const response = await ctx.HttpGet(
      selectedTab === UPLOAD_DATA_TYPE.BANK
        ? EXPORT_CLOSED_DATA_BANK
        : selectedTab === UPLOAD_DATA_TYPE.ESTIMATE
        ? EXPORT_CLOSED_DATA_ESTIMATE
        : selectedTab === UPLOAD_DATA_TYPE.SUBVENTION &&
          EXPORT_CLOSED_DATA_SUBVENTION,
      queryParams,
      true,
    );

    if (response && typeof response.data === "string") {
      if (!response.headers && response.status == 200) {
        DownloadFileBlob(response.data, "Closed_Data_" + selectedTab, "csv");
        ctx.showToastAlert({ type: "success", message: response.message });
      } else {
        DownloadFileAttached(
          response.data,
          "Closed_Data_" + selectedTab,
          "csv",
        );
        ctx.showToastAlert({
          type: "success",
          message: "Downloaded successfully",
        });
      }
    } else if (typeof response.data === "object" && !response.data.status) {
      ctx.showToastAlert({
        type: "error",
        message: response.data.message,
      });
    }
    setExportBtnDisable(false);
  };

  const getClosedRecordForEstimate = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
    fromDateBy = fromDateValue,
    toDataBy = toDataValue,
  ) => {
    setDataLoading(true);
    setHideNoData(false);

    const sortData = ctx?.sortAndPagination?.closed_records?.[selectedTab];
    let queryParams =
      count === 0
        ? sortData
        : {
            searchItem: searchBy,
            sortBy: sortBy || sortData?.sortBy,
            orderBy: orderBy || sortData?.orderBy,
            page: currentpage,
            size: pagesize,
            fromDate: fromDateBy,
            toDate: toDataBy,
          };
    ctx?.setSortAndPagination((prev) => ({
      ...prev,
      closed_records: {
        ...prev?.closed_records,
        [selectedTab]: {
          ...queryParams,
          searchItem: null,
          fromDate: null,
          toDate: null,
        },
      },
      status: true,
    }));
    setCount((prev) => prev + 1);
    const response = await ctx.HttpGet(CLOSED_RECORDS_ESTIMATE, queryParams);
    setDataLoading(false);
    if (response.status) {
      setEstimateClosedRecords(response);
      if (!initialData && Object.keys(response)?.length) {
        setInitialData(true);
      }
    } else {
      setEstimateClosedRecords({});
      !searchBy &&
        !fromDateBy &&
        !toDataBy &&
        totalCountRes?.Estimate_total > 0 &&
        getClosedRecordForEstimate(
          null,
          "",
          "",
          PAGINATION_DETAILS.CURRENT_PAGE,
          PAGINATION_DETAILS.PAGE_SIZE,
          "",
          "",
        );
    }
  };
  const getClosedRecordForSubvention = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
    fromDateBy = fromDateValue,
    toDataBy = toDataValue,
  ) => {
    setDataLoading(true);
    setHideNoData(false);

    const sortData = ctx?.sortAndPagination?.closed_records?.[selectedTab];
    let queryParams =
      count === 0
        ? sortData
        : {
            searchItem: searchBy,
            sortBy: sortBy || sortData?.sortBy,
            orderBy: orderBy || sortData?.orderBy,
            page: currentpage,
            size: pagesize,
            fromDate: fromDateBy,
            toDate: toDataBy,
          };
    ctx?.setSortAndPagination((prev) => ({
      ...prev,
      closed_records: {
        ...prev?.closed_records,
        [selectedTab]: {
          ...queryParams,
          searchItem: null,
          fromDate: null,
          toDate: null,
        },
      },
      status: true,
    }));
    setCount((prev) => prev + 1);
    const response = await ctx.HttpGet(CLOSED_RECORDS_SUBVENTION, queryParams);
    setDataLoading(false);
    if (response.status) {
      setSubventionClosedRecords(response);
      if (!initialData && Object.keys(response)?.length) {
        setInitialData(true);
      }
    } else {
      setSubventionClosedRecords({});
      !searchBy &&
        !fromDateBy &&
        !toDataBy &&
        totalCountRes?.Subvention_total > 0 &&
        getClosedRecordForSubvention(
          null,
          "",
          "",
          PAGINATION_DETAILS.CURRENT_PAGE,
          PAGINATION_DETAILS.PAGE_SIZE,
          "",
          "",
        );
    }
  };

  const getTotalCount = async () => {
    setTitleLoading(true);
    const response = await ctx.HttpGet(CLOSED_RECORDS_COUNT);
    setTitleLoading(false);
    if (response.status) {
      setTotalCountRes(response);
    } else {
      setTotalCountRes({});
    }
  };

  const get_clsoed_record_details = async (uid_no) => {
    if (selectedTab === UPLOAD_DATA_TYPE.BANK) {
      const response = await ctx.HttpGet(CLOSED_RECORDS_BANK_DETAILS + uid_no);
      if (response.status) {
        setOpenDrawable(true);
        setDrawableData(response?.Bank_closed_data[0]);
      }
    } else if (selectedTab === UPLOAD_DATA_TYPE.ESTIMATE) {
      const response = await ctx.HttpGet(
        CLOSED_RECORDS_ESTIMATE_DETAILS + uid_no,
      );
      if (response.status) {
        setOpenDrawable(true);
        setDrawableData(response?.Estimate_closed_data[0]);
      }
    } else if (selectedTab === UPLOAD_DATA_TYPE.SUBVENTION) {
      const response = await ctx.HttpGet(
        CLOSED_RECORDS_SUBVENTION_DETAILS + uid_no,
      );
      if (response.status) {
        setOpenDrawable(true);
        setDrawableData(response?.Subvention_closed_data[0]);
      }
    }
  };

  const items = [
    {
      key: UPLOAD_DATA_TYPE.BANK,
      label: (
        <SkeletonLoader
          loading={titleLoading}
          style={{ height: "20px" }}
        >{`Bank (${
          totalCountRes?.Bank_total ? totalCountRes?.Bank_total : 0
        })`}</SkeletonLoader>
      ),
      children: (
        <ClosedRecordsTable
          selectedData={bankClosedRecords}
          selectedTab={selectedTab}
          dataLoading={dataLoading}
          initialData={initialData}
          pageSize={pageSize}
          searchValue={searchValue}
          currentPage={currentPage}
          get_clsoed_record_details={get_clsoed_record_details}
          setSearchValue={setSearchValue}
          setCurrentPage={setCurrentPage}
          setFromDateValue={setFromDateValue}
          fromDateValue={fromDateValue}
          toDataValue={toDataValue}
          setToDataValue={setToDataValue}
          setOrderByValue={setOrderByValue}
          setSortByValue={setSortByValue}
          setPageSize={setPageSize}
          getTotalCount={getTotalCount}
          getTableData={getClosedRecordBank}
          exportedData={exportedData}
          exportBtnDisable={exportBtnDisable}
          hideNoData={hideNoData}
          parentData={"closed-records"}
        />
      ),
    },
    {
      key: UPLOAD_DATA_TYPE.ESTIMATE,
      label: (
        <SkeletonLoader
          loading={titleLoading}
          style={{ height: "20px" }}
        >{`Estimate (${
          totalCountRes?.Estimate_total ? totalCountRes?.Estimate_total : 0
        })`}</SkeletonLoader>
      ),
      children: (
        <ClosedRecordsTable
          getTotalCount={getTotalCount}
          selectedData={estimateClosedRecords}
          selectedTab={selectedTab}
          dataLoading={dataLoading}
          initialData={initialData}
          pageSize={pageSize}
          searchValue={searchValue}
          currentPage={currentPage}
          get_clsoed_record_details={get_clsoed_record_details}
          setSearchValue={setSearchValue}
          setCurrentPage={setCurrentPage}
          fromDateValue={fromDateValue}
          toDataValue={toDataValue}
          setFromDateValue={setFromDateValue}
          setToDataValue={setToDataValue}
          setOrderByValue={setOrderByValue}
          setSortByValue={setSortByValue}
          setPageSize={setPageSize}
          getTableData={getClosedRecordForEstimate}
          exportedData={exportedData}
          exportBtnDisable={exportBtnDisable}
          hideNoData={hideNoData}
          parentData={"closed-records"}
        />
      ),
    },
    {
      key: UPLOAD_DATA_TYPE.SUBVENTION,
      label: (
        <SkeletonLoader
          loading={titleLoading}
          style={{ height: "20px" }}
        >{`Subvention (${
          totalCountRes?.Subvention_total ? totalCountRes?.Subvention_total : 0
        })`}</SkeletonLoader>
      ),
      children: (
        <ClosedRecordsTable
          getTotalCount={getTotalCount}
          selectedData={subventionClosedRecords}
          selectedTab={selectedTab}
          dataLoading={dataLoading}
          initialData={initialData}
          pageSize={pageSize}
          searchValue={searchValue}
          currentPage={currentPage}
          get_clsoed_record_details={get_clsoed_record_details}
          setSearchValue={setSearchValue}
          setCurrentPage={setCurrentPage}
          fromDateValue={fromDateValue}
          toDataValue={toDataValue}
          setFromDateValue={setFromDateValue}
          setToDataValue={setToDataValue}
          setOrderByValue={setOrderByValue}
          setSortByValue={setSortByValue}
          setPageSize={setPageSize}
          getTableData={getClosedRecordForSubvention}
          exportedData={exportedData}
          exportBtnDisable={exportBtnDisable}
          hideNoData={hideNoData}
          parentData={"closed-records"}
        />
      ),
    },
  ];
  const onChange = (key) => {
    setOrderByValue(
      ctx?.sortAndPagination?.closed_records?.[key]?.orderBy || "",
    );
    setSortByValue(ctx?.sortAndPagination?.closed_records?.[key]?.sortBy || "");
    setPageSize(ctx?.sortAndPagination?.closed_records?.[key]?.size || 10);
    setCurrentPage(ctx?.sortAndPagination?.closed_records?.[key]?.page || 1);

    setSelectedTab(key);
    setBankClosedRecords({});
    setEstimateClosedRecords({});
    setSubventionClosedRecords({});
    setInitialData(false);
    setSearchValue("");
    setFromDateValue("");
    setToDataValue("");
    setDrawableData(null);
    setHideNoData(true);
  };
  return (
    <>
      <Header
        title={`Closed Data (${
          totalCountRes?.total_rows ? totalCountRes?.total_rows : 0
        })`}
      />
      <Tabs
        defaultActiveKey={selectedTab}
        items={items}
        onChange={onChange}
        className="custom-tabs"
      />
      {openDrawable && (
        <CustomDrawer
          setOpenDrawable={setOpenDrawable}
          data={drawableData}
          selectedTab={selectedTab}
        />
      )}
    </>
  );
};
