import dateFormat from "dateformat";

export const FormatDate = (text, value, format) => {
  if (text) {
    return dateFormat(text, format ? format : "MMM DD, YYYY, HH:mm A");
  } else {
    return value;
  }
};

export const DownloadFileBlob = (
  fileData,
  fileName,
  fileExtensions = "xlsx"
) => {
  var a = document.createElement("a"); //Create <a>
  a.href = "data:application/;base64," + fileData; //Base64 Goes here
  a.download = `${fileName}.${fileExtensions}`; //File name Here
  a.click(); //Downloaded file
};
export const DownloadFileAttached = (
  fileData,
  fileName,
  fileExtensions = "xlsx"
) => {
  var a = document.createElement("a"); //Create <a>
  const url = window.URL.createObjectURL(new Blob([fileData]));
  a.href = url; //Base64 Goes here
  a.download = `${fileName}.${fileExtensions}`; //File name Here
  a.click(); //Downloaded file
};

export const SortedOrderBy = (value) => {
  if (value === "ascend") {
    return "asc";
  } else if (value === "descend") {
    return "desc";
  } else {
    return "";
  }
};

export const DatePickerParams = (value) => {
  if (value) {
    return {
      s_year: dateFormat(value[0].$d, "yyyy"),
      e_year: dateFormat(value[1].$d, "yyyy"),
      s_month: dateFormat(value[0].$d, "mm"),
      e_month: dateFormat(value[1].$d, "mm"),
    };
  }
};

export const FromDatePickerParams = (value, picker) => {
  if (value && picker === "date") {
    return (
      dateFormat(value[0].$d, "yyyy") +
      "-" +
      dateFormat(value[0].$d, "mm") +
      "-" +
      dateFormat(value[0].$d, "dd")
    );
  } else if (value) {
    return (
      dateFormat(value[0].$d, "yyyy") + "-" + dateFormat(value[0].$d, "mm")
    );
  }
};
export const ToDatePickerParams = (value, picker) => {
  if (value && picker === "date") {
    return (
      dateFormat(value[1].$d, "yyyy") +
      "-" +
      dateFormat(value[1].$d, "mm") +
      "-" +
      dateFormat(value[1].$d, "dd")
    );
  } else if (value) {
    return (
      dateFormat(value[1].$d, "yyyy") + "-" + dateFormat(value[1].$d, "mm")
    );
  }
};

export const ConvertId = (value) => {
  if (value?.toString()?.length <= 3) {
    return ("000" + value).slice(-4);
  } else {
    return value;
  }
};

export const NumberConversion = (value) => {
  if (value) {
    return Number(value).toLocaleString("en-IN");
  }
};

export function isAlphanumeric(text) {
  const pattern = /^[a-zA-Z0-9]+$/;
  return pattern.test(text);
}
