import React from "react";
import { Input, Modal, Select, Switch, Tag } from "antd";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../helpers/app-context";
import { AUTO_RECONCILED_FLAG_UPDATE } from "../../helpers/api-endpoints";
import { useFormik } from "formik";
import * as yup from "yup";

let pmsOptions = [
  {
    value: ">",
    label: "Greater Than(>)",
  },
  {
    value: "<",
    label: "Less Than (<)",
  },
  {
    value: "=",
    label: "Equals To (=)",
  },
];
let smsOptions = [
  {
    value: ">",
    label: "Greater Than(>)",
  },
  {
    value: "<",
    label: "Less Than (<)",
  },
  {
    value: "=",
    label: "Equals To (=)",
  },
];
let reconcileSchema = yup.object().shape({
  pms: yup.number().required(),
  sms: yup.number().required(),
});
function ReconcileSetting({ isModalOpen, setIsModalOpen }) {
  const ModalContentReconcileSetting = () => {
    const ctx = useContext(AppContext);
    const [isEditAble, setIsEditAble] = useState(false);

    const formik = useFormik({
      initialValues: {
        pms: 0,
        sms: 0,
        pms_sign: "",
        sms_sign: "",
        is_toggle: false,
      },
      validationSchema: reconcileSchema, // validation schema
      onSubmit: async (values) => {
        const response = await ctx.HttpPost(
          AUTO_RECONCILED_FLAG_UPDATE,
          values,
        );
        if (response.status) {
          ctx.getReconcileSettingsData();
          ctx.showToastAlert({
            type: "success",
            message: response.message,
          });
          setIsModalOpen(false);
        }
      },
    });
    const manageSwtich = async (check) => {
      if (!check) {
        setIsEditAble(false);
        const response = await ctx.HttpPost(AUTO_RECONCILED_FLAG_UPDATE, {
          ...formik.values,
          is_toggle: false,
        });
        if (response.status) {
          ctx.getReconcileSettingsData();
          ctx.showToastAlert({
            type: "success",
            message: response.message,
          });
          // setIsModalOpen(false);
        }
      }
    };
    useEffect(() => {
      formik.setFieldValue("pms", ctx?.reconcileSettings?.pms);
      formik.setFieldValue("sms", ctx?.reconcileSettings?.sms);
      formik.setFieldValue("pms_sign", ctx?.reconcileSettings?.pms_sign);
      formik.setFieldValue("sms_sign", ctx?.reconcileSettings?.sms_sign);
      formik.setFieldValue("is_toggle", ctx?.reconcileSettings?.is_toggle);
      return () => console.log("out");
    }, [ctx?.reconcileSettings]);

    return (
      <>
        <div className="grid grid-cols-2 gap-2 pt-2">
          <div>
            Manual/Auto &nbsp;
            <Switch
              className="setting"
              checked={formik.values.is_toggle}
              onChange={(isChecked) => {
                manageSwtich(isChecked);
                formik.setFieldValue("is_toggle", isChecked);
              }}
            />
          </div>
          {formik.values?.is_toggle ? (
            isEditAble ? (
              <div className="text-right">
                <Tag
                  color="#55acee"
                  className={`${
                    !formik.isValid ? " tag-disabled " : "cursor-pointer"
                  } `}
                  onClick={() => {
                    if (!formik.isValid) {
                      return null;
                    } else {
                      setIsEditAble(!isEditAble);
                      formik.handleSubmit();
                      setIsModalOpen(false);
                    }
                  }}
                  style={{ padding: "4px 14px" }}
                >
                  Save
                </Tag>
              </div>
            ) : (
              <div className="text-right">
                <Tag
                  color="#55acee"
                  className="cursor-pointer"
                  onClick={() => setIsEditAble(!isEditAble)}
                  style={{ padding: "4px 14px" }}
                >
                  Edit
                </Tag>
              </div>
            )
          ) : (
            ""
          )}
        </div>

        {formik.values?.is_toggle ? (
          <form
            onSubmit={formik.handleSubmit}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     formik.handleSubmit();
            //   }
            // }}
          >
            <div className="grid grid-cols-1 gap-2 pt-2">
              <div className="grid grid-cols-2 gap-2">
                <div className="col-span-2 primary-color-text-l1 fs-14 font-semibold">
                  PMS
                </div>
                <div>
                  <Select
                    disabled={isEditAble ? false : true}
                    className="w-full"
                    name="pms_sign"
                    value={formik.values.pms_sign}
                    onSelect={(e) => {
                      formik.setFieldValue("pms_sign", e);
                    }}
                    placeholder="Select PMS"
                    options={pmsOptions}
                    defaultValue={pmsOptions.find(
                      (val) => val.value == formik.values.pms_sign,
                    )}
                  />
                </div>
                <div>
                  <Input
                    type="number"
                    className={`form-control form-input pr-4"`}
                    onChange={
                      (e) => {
                        if (!isNaN(e.target.value)) {
                          e.target.value === ""
                            ? formik.setFieldValue(e.target.name, "")
                            : Math.abs(e.target.value) > 100
                            ? formik.setFieldValue(e.target.name, 100)
                            : Math.abs(e.target.value) >= 0 &&
                              Math.abs(e.target.value) <= 100
                            ? formik.setFieldValue(
                                e.target.name,
                                Math.abs(e.target.value),
                              )
                            : formik.setFieldValue(
                                e.target.name,
                                Math.abs(e.target.value),
                              );
                        }
                      }
                      // formik.handleChange("pms")
                    }
                    value={formik.values.pms}
                    min={"0"}
                    max={"100"}
                    id="pms"
                    name="pms"
                    placeholder="PMS %"
                    disabled={isEditAble ? false : true}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div className="col-span-2 primary-color-text-l1 fs-14 font-semibold">
                  SMS
                </div>
                <div>
                  <Select
                    disabled={isEditAble ? false : true}
                    className="w-full"
                    name="sms_sign"
                    value={formik.values.sms_sign}
                    onSelect={(e) => {
                      formik.setFieldValue("sms_sign", e);
                    }}
                    onBlur={(_) => {
                      // onBlur("sms_sign");
                    }}
                    placeholder="Select SMS"
                    options={smsOptions}
                    defaultValue={smsOptions.find(
                      (val) => val.value == formik.values.sms_sign,
                    )}
                  />
                </div>
                <div>
                  <Input
                    type="number"
                    className={`form-control form-input pr-4"`}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        e.target.value === ""
                          ? formik.setFieldValue(e.target.name, "")
                          : Math.abs(e.target.value) > 100
                          ? formik.setFieldValue(e.target.name, 100)
                          : Math.abs(e.target.value) >= 0 &&
                            Math.abs(e.target.value) <= 100
                          ? formik.setFieldValue(
                              e.target.name,
                              Math.abs(e.target.value),
                            )
                          : formik.setFieldValue(
                              e.target.name,
                              Math.abs(e.target.value),
                            );
                      }
                    }}
                    value={formik.values.sms}
                    id="sms"
                    min={"0"}
                    max={"100"}
                    name="sms"
                    placeholder="SMS %"
                    disabled={isEditAble ? false : true}
                  />
                </div>
              </div>
            </div>
            {/* {isEditAble ? (
              <div className="text-center pt-4">
                <CustomButton
                  type="submit"
                  title="Apply"
                  className="btn-primary"
                  onClick={formik.handleSubmit}
                />
              </div>
            ) : (
              ""
            )} */}
          </form>
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <Modal
      title="Reconcile Setting"
      open={isModalOpen}
      // onOk={handleOk}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
    >
      <ModalContentReconcileSetting />
    </Modal>
  );
}

export default React.memo(ReconcileSetting);
