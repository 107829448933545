import { Outlet } from "react-router-dom";
import { SideMenu } from "./SideMenu";

export const Layout = () => {
  return (
    <div>
      <SideMenu />
      <div className="main-content">
        <Outlet />
      </div>
    </div>
  );
};
