import React from "react";
import { CustomButton } from "./CustomButton";

export const NoInternet = (props) => {
  const {onClick} = props;
  return (
    <div className="flex flex-col items-center justify-center tab-container">
      <div className="text-center">
        {/* <img src={internetcut} alt="No Internet Logo" className="mx-auto" /> */}
        <div className="mt-4 mb-2 primary-text-color-l3 fs-14">
          No Internet Connection
        </div>
        <div className="primary-text-color-l3 fs-14">Please check your internet connection and try again</div>
      </div>
      <div className="pt-6">
        <CustomButton title='Try again' className="upload-btn primary-text-color" onClick={onClick}  />
      </div>
    </div>
  );
};
