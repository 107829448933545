import { useContext, useEffect, useState } from "react";
import { Dropdown } from "antd";
import { CustomDataTable } from "./common/CustomDataTable";
import { CustomDatepicker } from "./common/CustomDatepicker";
import { CustomDrawer } from "./common/CustomDrawer";
import { CustomSearch } from "./common/CustomSearch";
import { Header } from "./layout/Header";
import { AppContext } from "../helpers/app-context";
import {
  COLLECTION_DATA_VIEW,
  COLLECTION_DATA_UPLOAD,
  COLLECTION_DATA_DETAILS,
  EXPORT_COLLECTION_DATA,
  DOWNLOAD_COLLECTION_TEMPLATE,
} from "../helpers/api-endpoints";
import { CustomUpload } from "./common/CustomUpload";
import { PAGINATION_DETAILS, COLLECTION_DATA_TYPE } from "../helpers/constant";
import { CollectionHeader } from "./tableHeaders/CollectionHeader";
import {
  DownloadFileAttached,
  DownloadFileBlob,
  FromDatePickerParams,
  SortedOrderBy,
  ToDatePickerParams,
} from "../helpers/functions";
import { NoData } from "./common/NoData";
import { NoInternet } from "./common/NoInternet";
import { CustomDropdownUpload } from "./common/CustomDropdownUpload";
import { CustomButton } from "./common/CustomButton";

export const CollectionData = (props) => {
  const ctx = useContext(AppContext);
  const [openDrawable, setOpenDrawable] = useState(false);
  const [drawableData, setDrawableData] = useState(null);
  const [initialData, setInitialData] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [collectionData, setCollectionData] = useState({});
  const [uploadLoader, setUploadLoader] = useState(false);
  const [fileId, setFileId] = useState();
  const [pageSize, setPageSize] = useState(
    ctx?.sortAndPagination?.collection_data?.collection_data?.size,
  );
  const [currentPage, setCurrentPage] = useState(
    ctx?.sortAndPagination?.collection_data?.collection_data?.page,
  );
  const [searchValue, setSearchValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [fromDateValue, setFromDateValue] = useState("");
  const [toDataValue, setToDataValue] = useState("");
  const [exportBtnDisable, setExportBtnDisable] = useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const [exportFullPageLoader, setExportFullPageLoader] = useState(false);
  const [exportProgressBar, setExportProgressBar] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getCollectionsData();
    ctx.checkReconsile();
  }, [ctx.isOnline]);

  const getCollectionsData = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
    fromDateBy = fromDateValue,
    toDataBy = toDataValue,
  ) => {
    setDataLoading(true);

    const sortData = ctx?.sortAndPagination?.collection_data?.collection_data;
    let queryParams =
      count === 0
        ? sortData
        : {
            searchItem: searchBy,
            sortBy: sortBy || sortData?.sortBy,
            orderBy: orderBy || sortData?.orderBy,
            page: currentpage,
            size: pagesize,
            fromDate: fromDateBy,
            toDate: toDataBy,
          };

    ctx?.setSortAndPagination((prev) => ({
      ...prev,
      collection_data: {
        ...prev?.collection_data,
        collection_data: {
          ...queryParams,
          searchItem: null,
          fromDate: null,
          toDate: null,
        },
      },
      status: true,
    }));
    setCount((prev) => prev + 1);

    const response = await ctx.HttpGet(COLLECTION_DATA_VIEW, queryParams);
    setDataLoading(false);
    if (response.status) {
      setExportBtnDisable(false);
      setCollectionData(response);
      if (!initialData && Object.keys(response)?.length) {
        setInitialData(true);
      }
    } else {
      setExportBtnDisable(true);
      setCollectionData({});
      !searchBy &&
        !fromDateBy &&
        !toDataBy &&
        collectionData?.total_rows > 0 &&
        getCollectionsData(
          null,
          "",
          "",
          PAGINATION_DETAILS.CURRENT_PAGE,
          PAGINATION_DETAILS.PAGE_SIZE,
          "",
          "",
        );
    }
  };

  const get_collection_details = async (uid_no) => {
    const response = await ctx.HttpGet(COLLECTION_DATA_DETAILS + uid_no);
    if (response.status) {
      setOpenDrawable(true);
      setDrawableData(response?.Collection_upload_data[0]);
    }
  };
  const uploadFile = async (e) => {
    setUploadLoader(true);
    let formData = new FormData();
    formData.append("profile", e);
    const response = await ctx.HttpPost(
      COLLECTION_DATA_UPLOAD,
      formData,
      false,
      (data) => {
        //Set the progress value to show the progress bar
        setProgressBar(Math.round((100 * data.loaded) / data.total));
      },
    );
    setUploadLoader(false);
    if (response.status) {
      ctx.showToastAlert({ type: "success", message: response.message });
      setFileId(response.file_no);
      getCollectionsData();
      // navigate("/collection-data");
    } else if (!response.status) {
      ctx.showToastAlert({ type: "error", message: response.message });
    }
    setProgressBar(0);
  };

  const exportedData = async (
    searchBy = searchValue,
    fromDate = fromDateValue,
    toData = toDataValue,
  ) => {
    setExportProgressBar(0);
    setExportBtnDisable(true);
    setExportFullPageLoader(true);
    let queryParams = {
      searchItem: searchBy,
      fromDate: fromDate,
      toDate: toData,
    };

    const progressSteps = [24, 78, 87, 90]; // Arbitrary progress values at different stages
    let currentStep = 0;
    const progressInterval = setInterval(() => {
      setExportProgressBar(progressSteps[currentStep]);
      currentStep += 1;

      if (currentStep >= progressSteps.length) {
        clearInterval(progressInterval); // Stop the progress update once all steps are completed
      }
    }, 200); // Adjust the interval based on your needs
    const response = await ctx.HttpGet(
      EXPORT_COLLECTION_DATA,
      queryParams,
      true,
    );
    setExportProgressBar(100);
    setExportFullPageLoader(false);
    setExportBtnDisable(false);
    if (response && typeof response.data === "string") {
      if (!response.headers && response.status == 200) {
        DownloadFileBlob(response.data, "collection_data", "csv");
        ctx.showToastAlert({ type: "success", message: response.message });
      } else {
        DownloadFileAttached(response.data, "collection_data", "csv");
        ctx.showToastAlert({
          type: "success",
          message: "Downloaded successfully",
        });
      }
    } else if (typeof response.data === "object" && !response.data.status) {
      ctx.showToastAlert({
        type: "error",
        message: response.data.message,
      });
    }
  };
  const templateDownload = async () => {
    const response = await ctx.HttpGet(DOWNLOAD_COLLECTION_TEMPLATE, null);
    if (response.status) {
      DownloadFileBlob(response.data, "template_data_" + "collection", "csv");
      ctx.showToastAlert({ type: "success", message: response.message });
    }
  };

  return (
    <>
      <>
        {uploadLoader || exportFullPageLoader ? (
          <div className="loader flex flex-col">
            <img
              src="/images/upload-loader.gif"
              alt="loader"
              className={`${exportFullPageLoader && "rotate180"}`}
            />
            <h3>
              {uploadLoader
                ? progressBar !== 100
                  ? "Uploading File"
                  : " Processing file"
                : exportFullPageLoader && "Downloading File"}
            </h3>
            {uploadLoader && progressBar !== 100 && (
              <div
                className="bg-gray-200 rounded-full dark:bg-gray-700"
                style={{ width: "15%", marginTop: "2rem" }}
              >
                <div
                  className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                  style={{ width: `${progressBar}%`, transition: "500ms all" }}
                >
                  {progressBar}%
                </div>
              </div>
            )}

            {/* progress bar for export */}
            {!uploadLoader &&
              exportFullPageLoader &&
              exportProgressBar !== 100 && (
                <div
                  className="bg-gray-200 rounded-full dark:bg-gray-700"
                  style={{ width: "15%", marginTop: "2rem" }}
                >
                  <div
                    className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                    style={{
                      width: `${exportProgressBar}%`,
                      transition: "500ms all",
                    }}
                  >
                    {exportProgressBar}%
                  </div>
                </div>
              )}
          </div>
        ) : (
          <Header
            title={`Collection Data (${
              collectionData?.total_rows ? collectionData?.total_rows : 0
            })`}
          />
        )}
      </>
      {!ctx.isOnline ? (
        <NoInternet
          onClick={() => {
            getCollectionsData();
          }}
        />
      ) : dataLoading || (ctx.isOnline && initialData) ? (
        <>
          <div className="flex items-center justify-between px-4 py-2">
            <CustomSearch
              placeholder="Search by Customer Name, Bank and App No."
              onSearch={(value) => {
                if (value.length >= 3) {
                  setSearchValue(value);
                  setCurrentPage(1);
                  getCollectionsData(value, null, null, 1, pageSize);
                } else if (value.length === 0) {
                  setSearchValue("");
                  getCollectionsData(null, null, null, 1, pageSize);
                }
              }}
            />
            <div className="flex items-center">
              <div className="flex items-center">
                <CustomDatepicker
                  value={[fromDateValue, toDataValue]}
                  onChangeDatePicker={(date, dateString) => {
                    if (date) {
                      setFromDateValue(FromDatePickerParams(date));
                      setToDataValue(ToDatePickerParams(date));
                      getCollectionsData(
                        searchValue,
                        null,
                        null,
                        1,
                        pageSize,
                        FromDatePickerParams(date),
                        ToDatePickerParams(date),
                      );
                    } else {
                      setFromDateValue(null);
                      setToDataValue(null);
                      getCollectionsData(
                        searchValue,
                        null,
                        null,
                        1,
                        pageSize,
                        null,
                        null,
                      );
                    }
                  }}
                />
                <div className={`mx-4 rounded fs-14`}>
                  <CustomButton
                    type="button"
                    title="Export"
                    disabled={
                      collectionData?.Collection_upload_data?.length === 0 ||
                      (exportBtnDisable && true)
                    }
                    className="btn-primary btn font-bold py-2 px-4 rounded fs-14"
                    onClick={exportedData}
                  />
                  {/* Export */}
                  {/* <img src="/images/icon/export.svg" alt="export" /> */}
                </div>
              </div>
              <CustomUpload uploadFile={uploadFile} />
            </div>
          </div>
          <CustomDataTable
            rowKey="id"
            columns={CollectionHeader}
            data={collectionData?.Collection_upload_data}
            rowClickable={true}
            totalRecords={collectionData?.total_rows}
            loading={dataLoading}
            tableHeight={"calc(100vh - 242px)"}
            pageSize={pageSize}
            currentPage={currentPage}
            handleRowClick={(rowData) => {
              get_collection_details(rowData.id);
            }}
            onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
              if (sorterOptions && sorterOptions.order) {
                setOrderByValue(SortedOrderBy(sorterOptions.order));
                setSortByValue(sorterOptions.field);
              }
              setPageSize(pageSizeOptions.pageSize);
              setCurrentPage(pageSizeOptions.current);
              getCollectionsData(
                searchValue,
                sorterOptions.order ? sorterOptions.field : "",
                SortedOrderBy(sorterOptions.order),
                pageSizeOptions.current,
                pageSizeOptions.pageSize,
              );
            }}
            parentData={"collection-data"}
            selectedTab={"collection_data"}
          />
        </>
      ) : (
        <NoData
          templateDownload={templateDownload}
          dropdownFlag={false}
          isUpload={true}
          uploadFile={uploadFile}
        />
      )}
      {openDrawable && (
        <CustomDrawer
          setOpenDrawable={setOpenDrawable}
          data={drawableData}
          selectedTab={COLLECTION_DATA_TYPE.COLLECTION_DATA}
        />
      )}
    </>
  );
};
