import { UPLOAD_DATA_TYPE } from "../../helpers/constant";
import {
  FromDatePickerParams,
  SortedOrderBy,
  ToDatePickerParams,
} from "../../helpers/functions";
import { CustomDataTable } from "../common/CustomDataTable";
import { CustomDatepicker } from "../common/CustomDatepicker";
import { CustomSearch } from "../common/CustomSearch";
import { NoData } from "../common/NoData";
import { AppContext } from "../../helpers/app-context";
import { NoInternet } from "../common/NoInternet";
import {
  columnsBank,
  columnsEstimate,
  columnsSubvention,
} from "../tableHeaders/ClosedRecordsHeader";
import { useContext } from "react";

export const ClosedRecordsTable = (props) => {
  const {
    selectedData,
    selectedTab,
    dataLoading,
    initialData,
    pageSize,
    searchValue,
    currentPage,
    get_clsoed_record_details,
    setSearchValue,
    setCurrentPage,
    fromDateValue,
    toDataValue,
    setFromDateValue,
    setToDataValue,
    setOrderByValue,
    setSortByValue,
    setPageSize,
    getTableData,
    exportedData,
    exportBtnDisable,
    getTotalCount,
    hideNoData,
    parentData,
  } = props;
  const ctx = useContext(AppContext);

  return !ctx.isOnline ? (
    <NoInternet
      onClick={() => {
        getTableData(null, null, null, currentPage, pageSize);
        getTotalCount();
      }}
    />
  ) : dataLoading || (ctx.isOnline && initialData) ? (
    <>
      <div className="flex items-center justify-between px-4 py-2">
        <CustomSearch
          placeholder="Search by Customer Name, Bank and App No."
          onSearch={(value) => {
            if (value.length >= 3) {
              setSearchValue(value);
              setCurrentPage(1);
              getTableData(value, null, null, 1, pageSize);
            } else if (value.length === 0) {
              setSearchValue("");
              getTableData(null, null, null, currentPage, pageSize);
            }
          }}
        />
        <div className="flex items-center">
          <CustomDatepicker
            value={[fromDateValue, toDataValue]}
            onChangeDatePicker={(date, dateString) => {
              if (date) {
                setFromDateValue(FromDatePickerParams(date));
                setToDataValue(ToDatePickerParams(date));
                getTableData(
                  searchValue,
                  null,
                  null,
                  1,
                  pageSize,
                  FromDatePickerParams(date),
                  ToDatePickerParams(date),
                );
              } else {
                setFromDateValue(null);
                setToDataValue(null);
                getTableData(searchValue, null, null, 1, pageSize, null, null);
              }
            }}
          />
          {/* <div className="export-btn">
            <img src="/images/icon/calendar.svg" alt="calendar" />
          </div> */}
          <div
            className={`ml-4 btn-primary p-2 rounded fs-14 cursor-pointer ${
              selectedTab === UPLOAD_DATA_TYPE.BANK
                ? selectedData?.Bank_closed_data?.length > 0
                  ? exportBtnDisable
                    ? "custom-disabled"
                    : ""
                  : "custom-disabled"
                : selectedTab === UPLOAD_DATA_TYPE.ESTIMATE
                ? selectedData?.Estimate_closed_data?.length > 0
                  ? exportBtnDisable
                    ? "custom-disabled"
                    : ""
                  : "custom-disabled"
                : selectedTab === UPLOAD_DATA_TYPE.SUBVENTION &&
                  selectedData?.Subvention_closed_data?.length > 0
                ? exportBtnDisable
                  ? "custom-disabled"
                  : ""
                : "custom-disabled"
            }`}
            onClick={() => {
              exportedData();
            }}
          >
            Export
            {/* <img src="/images/icon/export.svg" alt="export" /> */}
          </div>
        </div>
      </div>
      <CustomDataTable
        columns={
          selectedTab === UPLOAD_DATA_TYPE.BANK
            ? columnsBank
            : selectedTab === UPLOAD_DATA_TYPE.ESTIMATE
            ? columnsEstimate
            : selectedTab === UPLOAD_DATA_TYPE.SUBVENTION && columnsSubvention
        }
        data={
          selectedTab === UPLOAD_DATA_TYPE.BANK
            ? selectedData?.Bank_closed_data
            : selectedTab === UPLOAD_DATA_TYPE.ESTIMATE
            ? selectedData?.Estimate_closed_data
            : selectedTab === UPLOAD_DATA_TYPE.SUBVENTION &&
              selectedData?.Subvention_closed_data
        }
        // data={bankClosedRecords}
        className="mt-1"
        tableHeight={"calc(100vh - 281px)"}
        rowClickable={true}
        totalRecords={
          selectedTab === UPLOAD_DATA_TYPE.BANK
            ? selectedData?.total_page_size
            : selectedTab === UPLOAD_DATA_TYPE.ESTIMATE
            ? selectedData?.total_page_size
            : selectedTab === UPLOAD_DATA_TYPE.SUBVENTION &&
              selectedData?.total_page_size
        }
        loading={dataLoading}
        pageSize={pageSize}
        currentPage={currentPage}
        handleRowClick={(rowData) => {
          get_clsoed_record_details(rowData.uid_no);
        }}
        onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
          if (sorterOptions && sorterOptions.order) {
            setOrderByValue(SortedOrderBy(sorterOptions.order));
            setSortByValue(sorterOptions.field);
          }
          setPageSize(pageSizeOptions.pageSize);
          setCurrentPage(pageSizeOptions.current);
          getTableData(
            searchValue,
            sorterOptions.field,
            SortedOrderBy(sorterOptions.order),
            pageSizeOptions.current,
            pageSizeOptions.pageSize,
          );
        }}
        parentData={parentData}
        selectedTab={selectedTab}
      />
    </>
  ) : (
    <NoData hideNoData={hideNoData} />
  );
};
