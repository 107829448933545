import { useContext } from "react";
import { AppContext } from "../../helpers/app-context";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
export const CustomDatepicker = (props) => {
  const { onChangeDatePicker, value, picker } = props;
  const monthFormat = "YYYY - MMM";
  const dateFormat = "YYYY/MM/DD";
  const ctx = useContext(AppContext);

  const disabledDate = (current) => {
    // Get the current year, month, and date
    const currentYear = current.year();
    const currentMonth = current.month();
    const currentDate = current.date();

    // Otherwise, disable future dates
    return picker === "date"
      ? currentYear > dayjs().year() ||
          (currentYear === dayjs().year() && currentMonth > dayjs().month()) ||
          (currentYear === dayjs().year() &&
            currentMonth === dayjs().month() &&
            currentDate > dayjs().date())
      : "";
  };

  return (
    <RangePicker
      picker={picker === "date" ? "date" : "month"}
      format={picker === "date" ? dateFormat : monthFormat}
      disabledDate={disabledDate}
      onChange={onChangeDatePicker}
      style={{ width: "225px" }}
      defaultValue={
        ctx.moduleState.fromDate
          ? [
              dayjs(
                ctx.moduleState.fromDate,
                picker === "date" ? dateFormat : "YYYY-MM"
              ),
              dayjs(
                ctx.moduleState.toDate,
                picker === "date" ? dateFormat : "YYYY-MM"
              ),
            ]
          : null
      }
      value={
        value && value[0] && value[1]
          ? [
              dayjs(value[0], picker === "date" ? dateFormat : "YYYY-MM"),
              dayjs(value[1], picker === "date" ? dateFormat : "YYYY-MM"),
            ]
          : null
      }
    />
  );
};
