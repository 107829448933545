import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Tabs } from "antd";
import { AppContext } from "../helpers/app-context";
import { UPLOAD_DATA_TYPE, PAGINATION_DETAILS } from "../helpers/constant";
import { Header } from "./layout/Header";
import { SkeletonLoader } from "./common/SkeletonLoader";
import {
  DUPLICATE_DATA_BANK_VIEW,
  DUPLICATE_DATA_ESTIMATE_VIEW,
  DUPLICATE_DATA_SUBVENTION_VIEW,
  DUPLICATE_DATA_TOTAL_COUNTS,
} from "../helpers/api-endpoints";
import { DuplicateDataContainer } from "./tableContainer/DuplicateDataContainer";

export const DuplicateData = (props) => {
  const location = useLocation();
  const ctx = useContext(AppContext);
  const [bankList, setBankList] = useState({});
  const [estimateList, setEstimateList] = useState({});
  const [subventionlist, setSubventionList] = useState({});
  const [totalCountRes, setTotalCountRes] = useState();
  const [titleLoading, setTitleLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(
    location?.state?.eventKey
      ? location?.state?.eventKey
      : UPLOAD_DATA_TYPE.BANK,
  );
  const [pageSize, setPageSize] = useState(
    ctx?.sortAndPagination?.duplicate_data?.[selectedTab]?.size,
  );
  const [currentPage, setCurrentPage] = useState(
    ctx?.sortAndPagination?.duplicate_data?.[selectedTab]?.page,
  );
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [fromDateValue, setFromDateValue] = useState("");
  const [toDataValue, setToDataValue] = useState("");

  const [dataLoading, setDataLoading] = useState(true);
  const [initialData, setInitialData] = useState(false);

  //initially hide no data screen before api call
  const [hideNoData, setHideNoData] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getTotalCount();
    ctx.checkReconsile();
  }, [ctx.isOnline]);

  useEffect(() => {
    if (selectedTab === UPLOAD_DATA_TYPE.BANK) {
      getBankUploadedData();
    } else if (selectedTab === UPLOAD_DATA_TYPE.ESTIMATE) {
      getEstimateUploadedData();
    } else if (selectedTab === UPLOAD_DATA_TYPE.SUBVENTION) {
      getSubventionUploadedData();
    }
  }, [selectedTab, ctx.isOnline]);

  const HeaderElement = () => (
    <div>
      Duplicate Data (
      {totalCountRes?.total_rows ? totalCountRes?.total_rows : 0})
    </div>
  );
  const onChange = (key) => {
    setSelectedTab(key);
    setOrderByValue(
      ctx?.sortAndPagination?.duplicate_data?.[key]?.orderBy || "",
    );
    setSortByValue(ctx?.sortAndPagination?.duplicate_data?.[key]?.sortBy || "");
    setPageSize(ctx?.sortAndPagination?.duplicate_data?.[key]?.size || 10);
    setCurrentPage(ctx?.sortAndPagination?.duplicate_data?.[key]?.page || 1);
    setInitialData(false);
    setBankList({});
    setEstimateList({});
    setSubventionList({});
    setSearchValue("");
    setFromDateValue("");
    setToDataValue("");
    setHideNoData(true);
  };

  const getTotalCount = async () => {
    setTitleLoading(true);
    const response = await ctx.HttpGet(DUPLICATE_DATA_TOTAL_COUNTS);
    setTitleLoading(false);
    if (response.status) {
      setTotalCountRes(response);
    } else {
      setTotalCountRes({});
    }
  };

  const getBankUploadedData = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
    fromDateBy = fromDateValue,
    toDataBy = toDataValue,
  ) => {
    if (!dataLoading) {
      setDataLoading(true);
    }
    setHideNoData(false);
    const sortData = ctx?.sortAndPagination?.duplicate_data?.bank;
    let queryParams =
      count === 0
        ? sortData
        : {
            searchItem: searchBy,
            sortBy: sortBy || sortData?.sortBy,
            orderBy: orderBy || sortData?.orderBy,
            page: currentpage,
            size: pagesize,
            fromDate: fromDateBy,
            toDate: toDataBy,
          };
    ctx?.setSortAndPagination((prev) => ({
      ...prev,
      duplicate_data: {
        ...prev?.duplicate_data,
        bank: {
          ...queryParams,
          searchItem: null,
          fromDate: null,
          toDate: null,
        },
      },
      status: true,
    }));
    setCount((prev) => prev + 1);
    const response = await ctx.HttpGet(DUPLICATE_DATA_BANK_VIEW, queryParams);
    setDataLoading(false);
    if (response.status) {
      setBankList(response);
      if (!initialData && Object.keys(response)?.length) {
        setInitialData(true);
      }
    } else {
      setBankList({});
      setInitialData(false);
      // !searchBy &&
      //   !fromDateBy &&
      //   !toDataBy &&
      //   totalCountRes?.Bank_total > 0 &&
      //   getBankUploadedData(
      //     null,
      //     "",
      //     "",
      //     PAGINATION_DETAILS.CURRENT_PAGE,
      //     PAGINATION_DETAILS.PAGE_SIZE,
      //     "",
      //     "",
      //   );
    }
  };
  const getEstimateUploadedData = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
    fromDateBy = fromDateValue,
    toDataBy = toDataValue,
  ) => {
    setDataLoading(true);
    setHideNoData(false);
    const sortData = ctx?.sortAndPagination?.duplicate_data?.estimate;
    let queryParams =
      count === 0
        ? sortData
        : {
            searchItem: searchBy,
            sortBy: sortBy || sortData?.sortBy,
            orderBy: orderBy || sortData?.orderBy,
            page: currentpage,
            size: pagesize,
            fromDate: fromDateBy,
            toDate: toDataBy,
          };

    ctx?.setSortAndPagination((prev) => ({
      ...prev,
      duplicate_data: {
        ...prev?.duplicate_data,
        estimate: {
          ...queryParams,
          searchItem: null,
          fromDate: null,
          toDate: null,
        },
      },
      status: true,
    }));
    setCount((prev) => prev + 1);
    const response = await ctx.HttpGet(
      DUPLICATE_DATA_ESTIMATE_VIEW,
      queryParams,
    );
    setDataLoading(false);
    if (response.status) {
      setEstimateList(response);
      if (!initialData && Object.keys(response)?.length) {
        setInitialData(true);
      }
    } else {
      setEstimateList({});
      setInitialData(false);
      // !searchBy &&
      //   !fromDateBy &&
      //   !toDataBy &&
      //   totalCountRes?.Estimate_total > 0 &&
      //   getEstimateUploadedData(
      //     null,
      //     "",
      //     "",
      //     PAGINATION_DETAILS.CURRENT_PAGE,
      //     PAGINATION_DETAILS.PAGE_SIZE,
      //     "",
      //     "",
      //   );
    }
  };

  const getSubventionUploadedData = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
    fromDateBy = fromDateValue,
    toDataBy = toDataValue,
  ) => {
    setDataLoading(true);
    setHideNoData(false);
    const sortData = ctx?.sortAndPagination?.duplicate_data?.subvention;
    let queryParams =
      count === 0
        ? sortData
        : {
            searchItem: searchBy,
            sortBy: sortBy || sortData?.sortBy,
            orderBy: orderBy || sortData?.orderBy,
            page: currentpage,
            size: pagesize,
            fromDate: fromDateBy,
            toDate: toDataBy,
          };

    ctx?.setSortAndPagination((prev) => ({
      ...prev,
      duplicate_data: {
        ...prev?.duplicate_data,
        subvention: {
          ...queryParams,
          searchItem: null,
          fromDate: null,
          toDate: null,
        },
      },
      status: true,
    }));
    setCount((prev) => prev + 1);
    const response = await ctx.HttpGet(
      DUPLICATE_DATA_SUBVENTION_VIEW,
      queryParams,
    );
    setDataLoading(false);
    if (response.status) {
      setSubventionList(response);
      if (!initialData && Object.keys(response)?.length) {
        setInitialData(true);
      }
    } else {
      setSubventionList({});
      setInitialData(false);
      // !searchBy &&
      //   !fromDateBy &&
      //   !toDataBy &&
      //   totalCountRes?.Subvention_total > 0 &&
      //   getSubventionUploadedData(
      //     null,
      //     "",
      //     "",
      //     PAGINATION_DETAILS.CURRENT_PAGE,
      //     PAGINATION_DETAILS.PAGE_SIZE,
      //     "",
      //     "",
      //   );
    }
  };

  const items = [
    {
      key: UPLOAD_DATA_TYPE.BANK,
      label: (
        <SkeletonLoader loading={titleLoading} style={{ height: "20px" }}>
          {`Bank (${
            totalCountRes?.Bank_total ? totalCountRes?.Bank_total : 0
          })`}
        </SkeletonLoader>
      ),
      children: (
        <DuplicateDataContainer
          parentData={"duplicate-data"}
          selectedTab={UPLOAD_DATA_TYPE.BANK}
          getTotalCount={getTotalCount}
          data={bankList?.Bank_upload_data_duplicate}
          eventKey={UPLOAD_DATA_TYPE.BANK}
          totalCount={location?.state?.count}
          loading={dataLoading}
          initialData={initialData}
          totalRecords={bankList?.total_page_size}
          pageSize={pageSize}
          currentPage={currentPage}
          searchValue={searchValue}
          getTableData={getBankUploadedData}
          setSearchValue={setSearchValue}
          setCurrentPage={setCurrentPage}
          fromDateValue={fromDateValue}
          toDataValue={toDataValue}
          setFromDateValue={setFromDateValue}
          setToDataValue={setToDataValue}
          setSortByValue={setSortByValue}
          setOrderByValue={setOrderByValue}
          setPageSize={setPageSize}
          hideNoData={hideNoData}
        />
      ),
    },
    {
      key: UPLOAD_DATA_TYPE.ESTIMATE,
      label: (
        <SkeletonLoader loading={titleLoading} style={{ height: "20px" }}>
          {`Estimate (${
            totalCountRes?.Estimate_total ? totalCountRes?.Estimate_total : 0
          })`}
        </SkeletonLoader>
      ),
      children: (
        <DuplicateDataContainer
          parentData={"duplicate-data"}
          selectedTab={UPLOAD_DATA_TYPE.ESTIMATE}
          getTotalCount={getTotalCount}
          data={estimateList?.Estimate_upload_data_duplicate}
          eventKey={UPLOAD_DATA_TYPE.ESTIMATE}
          totalCount={location?.state?.count}
          loading={dataLoading}
          initialData={initialData}
          totalRecords={estimateList?.total_page_size}
          pageSize={pageSize}
          currentPage={currentPage}
          searchValue={searchValue}
          getTableData={getEstimateUploadedData}
          setSearchValue={setSearchValue}
          setCurrentPage={setCurrentPage}
          fromDateValue={fromDateValue}
          toDataValue={toDataValue}
          setFromDateValue={setFromDateValue}
          setToDataValue={setToDataValue}
          setSortByValue={setSortByValue}
          setOrderByValue={setOrderByValue}
          setPageSize={setPageSize}
          hideNoData={hideNoData}
        />
      ),
    },
    {
      key: UPLOAD_DATA_TYPE.SUBVENTION,
      label: (
        <SkeletonLoader style={{ height: "20px" }} loading={titleLoading}>
          {`Subvention (${
            totalCountRes?.Subvention_total
              ? totalCountRes?.Subvention_total
              : 0
          })`}
        </SkeletonLoader>
      ),
      children: (
        <DuplicateDataContainer
          parentData={"duplicate-data"}
          selectedTab={UPLOAD_DATA_TYPE.SUBVENTION}
          getTotalCount={getTotalCount}
          data={subventionlist?.Subvention_upload_data_duplicate}
          eventKey={UPLOAD_DATA_TYPE.SUBVENTION}
          totalCount={location?.state?.count}
          loading={dataLoading}
          initialData={initialData}
          totalRecords={subventionlist?.total_page_size}
          pageSize={pageSize}
          currentPage={currentPage}
          searchValue={searchValue}
          getTableData={getSubventionUploadedData}
          setSearchValue={setSearchValue}
          setCurrentPage={setCurrentPage}
          fromDateValue={fromDateValue}
          toDataValue={toDataValue}
          setFromDateValue={setFromDateValue}
          setToDataValue={setToDataValue}
          setSortByValue={setSortByValue}
          setOrderByValue={setOrderByValue}
          setPageSize={setPageSize}
          hideNoData={hideNoData}
        />
      ),
    },
  ];

  return (
    <>
      <Header title={<HeaderElement />} />
      <Tabs
        defaultActiveKey={selectedTab}
        items={items}
        onChange={onChange}
        className="custom-tabs"
      />
    </>
  );
};
