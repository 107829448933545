import { useEffect } from "react";
import { Navigate, useLocation } from "react-router";

import { PAGE_NAME, TOKEN_NAME } from "../../helpers/constant";

export const OpenRoutes = ({ children }) => {
  // commented need confirmation
  // const location = useLocation();
  // const firstPathname = location.pathname === "/" ? "HOME" : location.pathname.split("/")[1].replace("-", "_").toUpperCase();
  // useEffect(() => {
  //   document.title = PAGE_NAME[firstPathname];
  // }, [location.pathname]);
  const getTokenFromLs = localStorage.getItem(TOKEN_NAME);
  return getTokenFromLs === null ? (
    children
  ) : (
    <Navigate to="/home" replace={true} />
  );
};
