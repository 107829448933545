import { UPLOAD_DATA_TYPE } from "../../helpers/constant";
import { SortedOrderBy } from "../../helpers/functions";
import { CustomDataTable } from "../common/CustomDataTable";
import { NoData } from "../common/NoData";
import { NoInternet } from "../common/NoInternet";
import { UploadHistoryHeader } from "../tableHeaders/UploadHistoryHeader";
import { AppContext } from "../../helpers/app-context";
import { useContext } from "react";

export const UploadHistoryTable = (props) => {
  const {
    selectedTab,
    dataLoading,
    data,
    pageSize,
    currentPage,
    exportedData,
    setOrderByValue,
    setSortByValue,
    setPageSize,
    setCurrentPage,
    getTotalCount,
    getTableData,
    hideNoData,
    parentData,
  } = props;
  const ctx = useContext(AppContext);
  return !ctx.isOnline ? (
    <NoInternet
      onClick={() => {
        getTotalCount();
        getTableData(null, null, null, currentPage, pageSize);
      }}
    />
  ) : dataLoading || (ctx.isOnline && Object.keys(data).length) ? (
    <CustomDataTable
      rowKey="indexId"
      columns={UploadHistoryHeader}
      data={
        selectedTab === UPLOAD_DATA_TYPE.BANK
          ? data?.Bank_history_data
          : selectedTab === UPLOAD_DATA_TYPE.ESTIMATE
          ? data?.Estimate_history_data
          : selectedTab === UPLOAD_DATA_TYPE.SUBVENTION &&
            data?.Subvention_history_data
      }
      className="mt-1"
      loading={dataLoading}
      totalRecords={data?.total_page_size}
      pageSize={pageSize}
      currentPage={currentPage}
      onItemClick={(item, message) => exportedData(message, item)}
      onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
        if (sorterOptions && sorterOptions.order) {
          setOrderByValue(SortedOrderBy(sorterOptions.order));
          setSortByValue(sorterOptions.field);
        }
        setPageSize(pageSizeOptions.pageSize);
        setCurrentPage(pageSizeOptions.current);
        getTableData(
          sorterOptions.field,
          SortedOrderBy(sorterOptions.order),
          pageSizeOptions.current,
          pageSizeOptions.pageSize,
        );
      }}
      parentData={parentData}
      selectedTab={selectedTab}
    />
  ) : (
    <NoData hideNoData={hideNoData} />
  );
};
