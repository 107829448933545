import React, { useContext, useEffect, useState } from "react";
import { Tabs } from "antd";

import { AppContext } from "../helpers/app-context";
import { UPLOAD_DATA_TYPE, PAGINATION_DETAILS } from "../helpers/constant";
import { Header } from "./layout/Header";
import {
  EXPORT_TEMP_DATA_BANK,
  EXPORT_TEMP_DATA_ESTIMATE,
  EXPORT_TEMP_DATA_SUBVENTION,
  EXPORT_UPLOADED_DATA_BANK,
  EXPORT_UPLOADED_DATA_ESTIMATE,
  EXPORT_UPLOADED_DATA_SUBVENTION,
  UPLOADED_HISTORY_BANK,
  UPLOADED_HISTORY_ESTIMATE,
  UPLOADED_HISTORY_SUBVENTION,
  UPLOAD_HISTORY_TOTAL_COUNTS,
} from "../helpers/api-endpoints";
import { SkeletonLoader } from "./common/SkeletonLoader";
import { useLocation } from "react-router-dom";
import { DownloadFileAttached, DownloadFileBlob } from "../helpers/functions";
import { UploadHistoryTable } from "./tableContainer/UploadHistoryTable";

export const UploadHistory = (props) => {
  const ctx = useContext(AppContext);
  const location = useLocation();
  const [bankList, setBankList] = useState({});
  const [estimateList, setEstimateList] = useState({});
  const [subventionlist, setSubventionList] = useState({});
  const [totalCountRes, setTotalCountRes] = useState();

  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [selectedTab, setSelectedTab] = useState(
    location?.state?.eventKey
      ? location?.state?.eventKey
      : UPLOAD_DATA_TYPE.BANK,
  );
  const [pageSize, setPageSize] = useState(
    ctx?.sortAndPagination?.upload_history?.[selectedTab]?.size,
  );
  const [currentPage, setCurrentPage] = useState(
    ctx?.sortAndPagination?.upload_history?.[selectedTab]?.page,
  );
  const [dataLoading, setDataLoading] = useState(true);
  const [titleLoading, setTitleLoading] = useState(true);

  //initially hide no data screen before api call
  const [hideNoData, setHideNoData] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getTotalCount();
    ctx.checkReconsile();
  }, [ctx.isOnline]);

  useEffect(() => {
    if (selectedTab === UPLOAD_DATA_TYPE.BANK) {
      getBankUploadedHistoryData();
    } else if (selectedTab === UPLOAD_DATA_TYPE.ESTIMATE) {
      getEstimateUploadedHistoryData();
    } else if (selectedTab === UPLOAD_DATA_TYPE.SUBVENTION) {
      getSubventionUploadedHistoryData();
    }
  }, [selectedTab, ctx.isOnline]);

  const exportedData = async (type, id) => {
    ctx.showToastAlert({ type: "info", message: "File download in progress" });
    const response = await ctx.HttpGet(
      type === "success"
        ? selectedTab === UPLOAD_DATA_TYPE.BANK
          ? EXPORT_UPLOADED_DATA_BANK + "/" + id + `?type=all`
          : selectedTab === UPLOAD_DATA_TYPE.ESTIMATE
          ? EXPORT_UPLOADED_DATA_ESTIMATE + "/" + id + `?type=all`
          : selectedTab === UPLOAD_DATA_TYPE.SUBVENTION &&
            EXPORT_UPLOADED_DATA_SUBVENTION + "/" + id + `?type=all`
        : type === "duplicate"
        ? selectedTab === UPLOAD_DATA_TYPE.BANK
          ? EXPORT_UPLOADED_DATA_BANK + "/" + id + `?type=duplicate`
          : selectedTab === UPLOAD_DATA_TYPE.ESTIMATE
          ? EXPORT_UPLOADED_DATA_ESTIMATE + "/" + id + `?type=duplicate`
          : selectedTab === UPLOAD_DATA_TYPE.SUBVENTION &&
            EXPORT_UPLOADED_DATA_SUBVENTION + "/" + id + `?type=duplicate`
        : type === "unsuccess" && selectedTab === UPLOAD_DATA_TYPE.BANK
        ? EXPORT_TEMP_DATA_BANK + id
        : selectedTab === UPLOAD_DATA_TYPE.ESTIMATE
        ? EXPORT_TEMP_DATA_ESTIMATE + id
        : selectedTab === UPLOAD_DATA_TYPE.SUBVENTION
        ? EXPORT_TEMP_DATA_SUBVENTION + id
        : "",
      null,
      type === "success" || type === "duplicate",
    );

    if (response && typeof response.data === "string") {
      if (type === "unsuccess") {
        DownloadFileBlob(
          response.data,
          type === "success"
            ? "Successful_data_" + selectedTab
            : "Unsuccessful_data_" + selectedTab,
          "xlsx",
        );
        ctx.showToastAlert({ type: "success", message: response.message });
      } else {
        DownloadFileAttached(
          response.data,
          type === "success"
            ? "Successful_data_" + selectedTab
            : type === "duplicate"
            ? "Duplicate_data_" + selectedTab
            : "Unsuccessful_data_" + selectedTab,
          "csv",
        );
        ctx.showToastAlert({
          type: "success",
          message: "Downloaded successfully",
        });
      }
    } else if (typeof response.data === "object" && !response.data.status) {
      ctx.showToastAlert({
        type: "error",
        message: response.data.message,
      });
    }

    // if (response.status) {
    //   DownloadFileBlob(
    //     response.data,
    //     type === "success"
    //       ? "Successful_data_" + selectedTab
    //       : "Unsuccessful_data_" + selectedTab,
    //     "csv",
    //   );
    //   ctx.showToastAlert({ type: "success", message: response.message });
    // }
  };

  const getBankUploadedHistoryData = async (
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
  ) => {
    if (!dataLoading) {
      setDataLoading(true);
    }
    setHideNoData(false);

    const sortData = ctx?.sortAndPagination?.upload_history?.bank;
    let queryParams =
      count === 0
        ? sortData
        : {
            sortBy: sortBy || sortData?.sortBy,
            orderBy: orderBy || sortData?.orderBy,
            page: currentpage,
            size: pagesize,
          };
    ctx?.setSortAndPagination((prev) => ({
      ...prev,
      upload_history: {
        ...prev?.upload_history,
        bank: {
          ...queryParams,
          searchItem: null,
          fromDate: null,
          toDate: null,
        },
      },
      status: true,
    }));
    setCount((prev) => prev + 1);
    const response = await ctx.HttpGet(UPLOADED_HISTORY_BANK, queryParams);
    setDataLoading(false);
    if (response.status) {
      setBankList(response);
    } else {
      setBankList({});
      totalCountRes?.Bank_total > 0 &&
        getBankUploadedHistoryData(
          "",
          "",
          PAGINATION_DETAILS.CURRENT_PAGE,
          PAGINATION_DETAILS.PAGE_SIZE,
        );
    }
  };
  const getEstimateUploadedHistoryData = async (
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
  ) => {
    setDataLoading(true);
    setHideNoData(false);
    const sortData = ctx?.sortAndPagination?.upload_history?.estimate;
    let queryParams =
      count === 0
        ? sortData
        : {
            sortBy: sortBy || sortData?.sortBy,
            orderBy: orderBy || sortData?.orderBy,
            page: currentpage,
            size: pagesize,
          };
    ctx?.setSortAndPagination((prev) => ({
      ...prev,
      upload_history: {
        ...prev?.upload_history,
        estimate: {
          ...queryParams,
          searchItem: null,
          fromDate: null,
          toDate: null,
        },
      },
      status: true,
    }));
    setCount((prev) => prev + 1);
    const response = await ctx.HttpGet(UPLOADED_HISTORY_ESTIMATE, queryParams);
    setDataLoading(false);
    if (response.status) {
      setEstimateList(response);
    } else {
      setEstimateList({});
      totalCountRes?.Estimate_total > 0 &&
        getEstimateUploadedHistoryData(
          "",
          "",
          PAGINATION_DETAILS.CURRENT_PAGE,
          PAGINATION_DETAILS.PAGE_SIZE,
        );
    }
  };
  const getSubventionUploadedHistoryData = async (
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
  ) => {
    setDataLoading(true);
    setHideNoData(false);
    const sortData = ctx?.sortAndPagination?.upload_history?.subvention;
    let queryParams =
      count === 0
        ? sortData
        : {
            sortBy: sortBy || sortData?.sortBy,
            orderBy: orderBy || sortData?.orderBy,
            page: currentpage,
            size: pagesize,
          };
    ctx?.setSortAndPagination((prev) => ({
      ...prev,
      upload_history: {
        ...prev?.upload_history,
        subvention: {
          ...queryParams,
          searchItem: null,
          fromDate: null,
          toDate: null,
        },
      },
      status: true,
    }));
    setCount((prev) => prev + 1);
    const response = await ctx.HttpGet(
      UPLOADED_HISTORY_SUBVENTION,
      queryParams,
    );
    setDataLoading(false);
    if (response.status) {
      setSubventionList(response);
    } else {
      setSubventionList({});
      totalCountRes?.Subvention_total > 0 &&
        getSubventionUploadedHistoryData(
          "",
          "",
          PAGINATION_DETAILS.CURRENT_PAGE,
          PAGINATION_DETAILS.PAGE_SIZE,
        );
    }
  };

  const getTotalCount = async () => {
    setTitleLoading(true);
    const response = await ctx.HttpGet(UPLOAD_HISTORY_TOTAL_COUNTS);
    if (response.status) {
      setTotalCountRes(response);
    } else {
      setTotalCountRes({});
    }
    setTitleLoading(false);
  };

  const items = [
    {
      key: "bank",
      label: (
        <SkeletonLoader
          loading={titleLoading}
          style={{ height: "20px" }}
        >{`Bank (${
          totalCountRes?.Bank_total ? totalCountRes?.Bank_total : 0
        })`}</SkeletonLoader>
      ),
      children: (
        <UploadHistoryTable
          getTotalCount={getTotalCount}
          dataLoading={dataLoading}
          data={bankList}
          pageSize={pageSize}
          currentPage={currentPage}
          selectedTab={UPLOAD_DATA_TYPE.BANK}
          exportedData={exportedData}
          setOrderByValue={setOrderByValue}
          setSortByValue={setSortByValue}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          getTableData={getBankUploadedHistoryData}
          hideNoData={hideNoData}
          parentData={"upload-history"}
        />
      ),
    },
    {
      key: "estimate",
      label: (
        <SkeletonLoader
          loading={titleLoading}
          style={{ height: "20px" }}
        >{`Estimate (${
          totalCountRes?.Estimate_total ? totalCountRes?.Estimate_total : 0
        })`}</SkeletonLoader>
      ),
      children: (
        <UploadHistoryTable
          getTotalCount={getTotalCount}
          dataLoading={dataLoading}
          data={estimateList}
          pageSize={pageSize}
          currentPage={currentPage}
          selectedTab={UPLOAD_DATA_TYPE.ESTIMATE}
          exportedData={exportedData}
          setOrderByValue={setOrderByValue}
          setSortByValue={setSortByValue}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          getTableData={getEstimateUploadedHistoryData}
          hideNoData={hideNoData}
          parentData={"upload-history"}
        />
      ),
    },
    {
      key: "subvention",
      label: (
        <SkeletonLoader
          loading={titleLoading}
          style={{ height: "20px" }}
        >{`Subvention (${
          totalCountRes?.Subvention_total ? totalCountRes?.Subvention_total : 0
        })`}</SkeletonLoader>
      ),
      children: (
        <UploadHistoryTable
          getTotalCount={getTotalCount}
          dataLoading={dataLoading}
          data={subventionlist}
          pageSize={pageSize}
          currentPage={currentPage}
          selectedTab={UPLOAD_DATA_TYPE.SUBVENTION}
          exportedData={exportedData}
          setOrderByValue={setOrderByValue}
          setSortByValue={setSortByValue}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          getTableData={getSubventionUploadedHistoryData}
          hideNoData={hideNoData}
          parentData={"upload-history"}
        />
      ),
    },
  ];

  const onChange = (key) => {
    setSelectedTab(key);
    setOrderByValue(
      ctx?.sortAndPagination?.upload_history?.[key]?.orderBy || "",
    );
    setSortByValue(ctx?.sortAndPagination?.upload_history?.[key]?.sortBy || "");
    setPageSize(ctx?.sortAndPagination?.upload_history?.[key]?.size || 10);
    setCurrentPage(ctx?.sortAndPagination?.upload_history?.[key]?.page || 1);
    setBankList({});
    setEstimateList({});
    setSubventionList({});
    setHideNoData(true);
  };
  return (
    <>
      <Header
        title={`Upload History (${
          totalCountRes?.total_rows ? totalCountRes?.total_rows : 0
        })`}
      />
      <Tabs
        defaultActiveKey={selectedTab}
        items={items}
        onChange={onChange}
        className="custom-tabs"
      />
    </>
  );
};
