import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Popover, Progress } from "antd";
import { AppContext } from "../../helpers/app-context";
import { Notifications } from "../common/Notifications";
import { TOKEN_NAME } from "../../helpers/constant";
import { SkeletonLoader } from "../common/SkeletonLoader";
import ReconcileSetting from "./ReconcileSetting";

export const Header = (props) => {
  const { title, backarrow = false, backArrowClick } = props;
  const [openDrawable, setOpenDrawable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showProgress, setShowProgress] = useState(false);
  const navigate = useNavigate();
  const ctx = useContext(AppContext);
  const localData = localStorage.getItem(TOKEN_NAME)
    ? JSON.parse(ctx.getDecrypted(localStorage.getItem(TOKEN_NAME)))
    : "";
  const handleLogout = () => {
    ctx.AppLogout(true, {
      email: ctx.userInfo?.email ? ctx.userInfo?.email : localData?.email,
    });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (ctx.reconStatus.progress === 100) {
      setTimeout(() => {
        setShowProgress(false);
      }, 3000);
    } else if (
      ctx.reconStatus.intiate_status === 1 &&
      ctx.reconStatus.progress < 100
    ) {
      setShowProgress(true);
    }
  }, [ctx.reconStatus.intiate_status, ctx.reconStatus.progress]);

  useEffect(() => {
    ctx.getReconcileSettingsData();
  }, [navigate]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 200);
  }, [props, navigate]);

  const notificationData = [
    {
      historyFlag: "Today",
      title:
        "50 entries got Reconciled, 30 entries got Unreconciled from #B001 and #S001.",
      dateTime: "18 Dec 2022, 8:00 PM",
    },
    {
      historyFlag: "",
      title:
        "Revenue Threshold reached for #B0009 and #S00010. Please approve the threshold entry.",
      dateTime: "18 Dec 2022, 8:00 PM",
    },
    {
      historyFlag: "This Week",
      title:
        "50 entries got Reconciled, 30 entries got Unreconciled from #B001 and #S001.",
      dateTime: "15 Dec 2022, 8:00 PM",
    },
    {
      historyFlag: "Older",
      title:
        "50 entries got Reconciled, 30 entries got Unreconciled from #B001 and #S001.",
      dateTime: "10 Dec 2022, 8:00 PM",
    },
    {
      historyFlag: "",
      title:
        "50 entries got Reconciled, 30 entries got Unreconciled from #B001 and #S001.",
      dateTime: "10 Dec 2022, 8:00 PM",
    },
  ];

  const LogoutContent = (props) => {
    const { handleLogout } = props;
    return (
      <>
        <div
          className="p-3"
          style={{
            // boxShadow: "0px 1px 0px #d3d3e2",
            borderBottom: "1px solid #F2F2F2",
          }}
        >
          <div className="primary-text-color-l3 fs-14 font-semibold capitalize">
            {ctx?.userInfo?.username
              ? ctx?.userInfo?.username
              : localData?.username}
          </div>
          <div
            className="fs-12"
            style={{
              color: "#A5A5B1",
            }}
          >
            {ctx?.userInfo?.roles ? ctx?.userInfo?.roles : localData?.roles}
          </div>
        </div>
        <div
          className={`p-3 cursor-pointer ${
            showProgress ? " div-disabled " : ""
          }`}
          style={{
            borderBottom: "1px solid #F2F2F2",
          }}
          onClick={() => (showProgress ? "" : setIsModalOpen(true))}
        >
          Manage Reconcile
        </div>
        <div onClick={handleLogout} className="cursor-pointer">
          <div className="p-2 primary-text-color-l3 fs-16 flex items-center rounded-md rounded-t-none	 hover:bg-[#f4f4f8]">
            <img src="/images/icon/logout.svg" alt="logout" className="mr-2" />
            <span className="pl-1.5">Logout</span>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="header p-3">
      <SkeletonLoader
        loading={loading}
        shape={"default"}
        className="mt-1"
        style={{ width: "200px", height: "24px" }}
      >
        <>
          <div
            className={`fs-24 font-semibold + ${
              backarrow ? "flex justify-center" : ""
            } `}
          >
            {backarrow ? (
              <img
                src="/images/icon/back-arrow.svg"
                alt="Go Back"
                className="pr-4 cursor-pointer"
                onClick={() => {
                  if (typeof backArrowClick === "function") {
                    backArrowClick();
                  } else {
                    navigate(-1);
                  }
                }}
              />
            ) : (
              ""
            )}

            {title}
          </div>
          <div className="flex">
            {/* <img
              src="/images/icon/notification.svg"
              alt="notification"
              className="pr-4 cursor-pointer"
              onClick={(e) => setOpenDrawable(true)}
            /> */}
            {showProgress && (
              <div className="px-9 fs-12 font-semibold secondary-color-text-l1 showprogressbar">
                {ctx.reconStatus.intiate_status === 1
                  ? "Reconciliation In Progress"
                  : "Reconciliation Completed"}
                &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {ctx.reconStatus.progress}%
                <Progress percent={ctx.reconStatus.progress} showInfo={false} />
              </div>
            )}
            <Popover
              title={<LogoutContent handleLogout={handleLogout} />}
              trigger="hover"
              overlayClassName="logoutTooltip"
              placement="bottomRight"
            >
              <div className="profile-icon cursor-pointer capitalize">
                {ctx?.userInfo?.username?.charAt(0)
                  ? ctx?.userInfo?.username?.charAt(0)
                  : localData?.username?.charAt(0)}
              </div>
            </Popover>
          </div>
        </>
      </SkeletonLoader>

      {openDrawable && (
        <Notifications
          title="Notifications"
          placement="right"
          setOpenDrawable={setOpenDrawable}
          data={notificationData}
          width={498}
        />
      )}
      <ReconcileSetting
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};
