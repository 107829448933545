import React, { useCallback, useContext, useState } from "react";
import {
  FromDatePickerParams,
  NumberConversion,
  SortedOrderBy,
  ToDatePickerParams,
} from "../../helpers/functions";
import { AppContext } from "../../helpers/app-context";
import { NoData } from "../common/NoData";
import { CustomSearch } from "../common/CustomSearch";
import { CustomDatepicker } from "../common/CustomDatepicker";
import { MY_TASK } from "../../helpers/constant";
import { CustomButton } from "../common/CustomButton";
import {
  UNRECONCILED_ESTIMATE_REMARKS,
  UNRECONCILED_ESTIMATE_REMARKS_HISTORY,
  UNRECONCILED_MANUAL_RECONCILED_ESTIMATE,
  UNRECONCILED_MANUAL_RECONCILED_SUBVENTION,
  UNRECONCILED_SUBVENTION_REMARKS,
  UNRECONCILED_SUBVENTION_REMARKS_HISTORY,
  UNRECONCILED_SUBVENTION_VIEWS_DETAILS,
} from "../../helpers/api-endpoints";
import { CustomDrawer } from "../common/CustomDrawer";
import { UNRECONCILED_ESTIMATE_VIEWS_DETAILS } from "../../helpers/api-endpoints";
import { CustomDataTable } from "../common/CustomDataTable";
import { useEffect } from "react";
import { Button, Checkbox, Modal } from "antd";
import { DisableNumberChecks } from "../tableHeaders/DisableNumberChecks";
import { CustomFilter } from "../common/CustomFilter";
import { CustomInput } from "../common/CustomInput";
import { RemarksHistory } from "../common/RemarksHistory";
import { CustomDropdownExports } from "../common/CustomDropdownExports";

export default function MyTaskCardTable(props) {
  const {
    filter,
    selectedData,
    dataLoading,
    initialData,
    hideNoData,
    getTableData,
    setSearchValue,
    setCurrentPage,
    pageSize,
    currentPage,
    setFromDateValue,
    setToDataValue,
    searchValue,
    selectedTab,
    columns,
    setOrderByValue,
    setPageSize,
    setSortByValue,
    getTotalCount,
    sortByValue,
    orderByValue,
    exportTabData,
    tabExportBtn,
    currentpage,
    pagesize,
    toDataValue,
    fromDateValue,
    parentData,
  } = props;
  const ctx = useContext(AppContext);
  const [reconsileData, setReconsileData] = useState([]);
  const [openDrawable, setOpenDrawable] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [drawableData, setDrawableData] = useState([]);
  const [drawerId, setDrawerId] = useState();
  const [bankId, setBankId] = useState(0);
  const [reconsileId, setReconsileId] = useState(0);
  const [selectedRowKeys, setRowKeys] = useState([]);
  const [tableProps, setTableProps] = useState(null);
  const [filterValues, setFilterValues] = useState({
    pmsSelect: undefined,
    pmsPercent: undefined,
    smsSelect: undefined,
    smsPercent: undefined,
    steps: undefined,
    Comment_Select: undefined,
    single: 0,
    location_head: undefined,
    bank: undefined,
    product: undefined,
  });
  const [isReconileProcessing, setIsReconileProcessing] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [checkBoxHelper, setCheckBoxHelper] = useState([]);
  const [selectStatus, setSelectStatus] = useState({
    single: false,
    all: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [rowData, setRowData] = useState({});
  const [openRemarks, setOpenRemarks] = useState(false);
  const [remarksHistoryData, setRemarksHistoryData] = useState([]);

  useEffect(() => {
    setFilterValues({
      pmsSelect: undefined,
      pmsPercent: undefined,
      smsSelect: undefined,
      smsPercent: undefined,
      steps: undefined,
      Comment_Select: undefined,
      single: 0,
      location_head: undefined,
      bank: undefined,
      product: undefined,
    });
    setReconsileData([]);
    setSelectedValues([]);
    setCheckBoxHelper([]);
    setRowKeys([]);
    setIsModalOpen(false);
    setRemarks("");
    setRowData({});
    setOpenRemarks(false);
    setRemarksHistoryData([]);
  }, [selectedTab]);

  useEffect(() => {
    setFilterValues(filter);
  }, [filter]);

  useEffect(() => {
    const filteredArray = selectedValues.reduce((acc, current) => {
      if (!acc.some((item) => item.rowKey === current.rowKey)) {
        acc.push(current);
      }
      return acc;
    }, []);
    setRowKeys(filteredArray.map((item) => item?.rowKey));
    selectedValues.length === 0 && setCheckBoxHelper([]);
    // setCheckBoxHelper(filteredArray.map((item) => item?.check === true && item));
    setReconsileData(
      selectedValues.filter((item) => ({ b: item?.b, e: item?.e }))
    );
  }, [selectedValues]);

  const handleCheckboxChange = (event, data) => {
    if (event) {
      const { checked } = event.target;
      // store data for api hit

      if (checked) {
        setSelectedValues((old) => [...old, data]);
        setCheckBoxHelper((old) =>
          old && old.some((item) => item.b === data.b && item.e === data.e)
            ? old.map((item) =>
                item?.b === data?.b && item?.e === data?.e
                  ? { ...data, check: true }
                  : item
              )
            : [...old, data]
        );
        setRowKeys((old) => [...old, data?.rowKey]);
      } else if (selectedTab === MY_TASK.UNRECONCILED_ESTIMATE) {
        setSelectedValues((old) =>
          old.filter((item) => !(item.b === data.b && item.e === data.e))
        );
        setCheckBoxHelper((old) =>
          old.map((item) =>
            item.b === data.b && item.e === data.e
              ? {
                  ...data,
                  check: false,
                }
              : item
          )
        );
      } else if (selectedTab === MY_TASK.UNRECONCILED_SUBVENTION) {
        setSelectedValues((old) =>
          old.filter((item) => !(item.b === data.b && item.e === data.e))
        );
        setCheckBoxHelper((old) =>
          old.map((item) =>
            item.b === data.b && item.e === data.e
              ? {
                  ...data,
                  check: false,
                }
              : item
          )
        );
      }
    } else {
      setSelectedValues((old) => old.filter((val) => val?.b != data.b));
      setCheckBoxHelper((old) => old.filter((val) => val?.b != data.b));
    }
  };

  const manualReconcileData = async (isBulk = false, bankId, id) => {
    setIsReconileProcessing(true);
    const response = await ctx.HttpPost(
      selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
        ? UNRECONCILED_MANUAL_RECONCILED_ESTIMATE
        : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION &&
            UNRECONCILED_MANUAL_RECONCILED_SUBVENTION,
      {
        id: isBulk ? reconsileData : [{ b: bankId, e: id }],
        user_id: ctx?.userInfo?.id,
        user_name: ctx?.userInfo?.username,
      }
    );
    setIsReconileProcessing(false);
    setOpenDrawable(false);
    if (response.status) {
      if (response?.error_total > 0 && response?.success_total === 0) {
        ctx.showToastAlert({
          type: "error",
          message: response?.error_data[0]?.message,
        });
        if (response?.error_data[0]?.Is_breach === 1) {
          // setSearchValue("");
          getTableData(
            searchValue || filterValues?.searchBy,
            null,
            null,
            currentPage,
            pageSize,
            fromDateValue,
            toDataValue,
            filterValues
          );
          setSelectedValues([]);
          setCheckBoxHelper([]);
          setReconsileData([]);
          setRowKeys([]);
          getTotalCount();
        }
      } else if (response?.error_total > 0 && response?.success_total > 0) {
        ctx.showToastAlert({
          type: "success",
          message: `Total Reconciled ${response?.success_total} out of ${reconsileData?.length}`,
        });
        setSelectedValues([]);
        setCheckBoxHelper([]);
        setReconsileData([]);
        setRowKeys([]);
        getTotalCount();
        getTableData(
          searchValue || filterValues?.searchBy,
          null,
          null,
          currentPage,
          pageSize,
          fromDateValue,
          toDataValue,
          filterValues
        );
      } else if (
        (response?.success_total > 0 && !isBulk) ||
        reconsileData.length == 1
      ) {
        // setSearchValue("");

        getTableData(
          searchValue || filterValues?.searchBy,
          null,
          null,
          currentPage,
          pageSize,
          fromDateValue,
          toDataValue,
          filterValues
        );
        setSelectedValues([]);
        setCheckBoxHelper([]);
        setReconsileData([]);
        setRowKeys([]);
        getTotalCount();

        ctx.showToastAlert({
          type: "success",
          message: response?.data[0]?.message,
        });
      } else if (
        response?.success_total > 0 &&
        isBulk &&
        reconsileData.length > 1
      ) {
        setSearchValue("");
        getTableData(
          null,
          null,
          null,
          currentPage,
          pageSize,
          fromDateValue,
          toDataValue,
          filterValues
        );
        setReconsileData([]);
        setSelectedValues([]);
        setCheckBoxHelper([]);
        setRowKeys([]);
        getTotalCount();

        ctx.showToastAlert({
          type: "success",
          message: `Total Reconciled ${response?.success_total} out of ${selectedRowKeys?.length}`,
        });
      } else {
        getTableData(
          null,
          null,
          null,
          currentPage,
          pageSize,
          fromDateValue,
          toDataValue,
          filterValues
        );
      }
    }
  };
  const arrangeColumns = useCallback(() => {
    let mappedColumns = [];
    if (!columns?.length) {
      return mappedColumns;
    }
    columns.forEach((item, i) => {
      if (item.dataIndex === "dataType") {
        if (selectedTab === MY_TASK.UNRECONCILED_ESTIMATE) {
          mappedColumns.push({
            ...item,
            render: (text, record) => {
              return (
                <div key={i}>
                  <div className="pb-1">Bank</div>
                  {record?.estimate.map((data, index) => {
                    const selectedValuesCheck = selectedValues.some(
                      (item) =>
                        data?.id === item?.e && record?.bank?.id === item?.b
                    );

                    const disableCheck = selectedValues.filter(
                      (item) =>
                        item?.b === record?.bank?.id &&
                        item?.rowKey === record?.index &&
                        item?.check
                    );

                    const checkVal = selectedValues.filter(
                      (item) =>
                        item?.b === record?.bank?.id &&
                        item?.index === index &&
                        item?.check
                    );

                    const checkStatus = selectedValues.filter(
                      (item) =>
                        item?.b === record?.bank?.id &&
                        item?.e === data?.id &&
                        item?.index === index
                    );
                    const allRowCheck = selectedRowKeys.map((f) => {
                      if (selectedValues.some((item) => item.index > 0)) {
                        return false;
                      } else if (f === record?.index) {
                        return true;
                      }
                    });

                    return (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        key={data?.id}
                      >
                        <Checkbox
                          key={data?.id}
                          onChange={(event) => {
                            handleCheckboxChange(event, {
                              b: record?.bank?.id,
                              e: data?.id,
                              rowKey: record?.index,
                              index,
                              check: true,
                            });
                          }}
                          checked={
                            selectedValuesCheck && index === 0
                              ? true
                              : allRowCheck[0] && index === 0
                              ? true
                              : checkStatus.length > 0
                              ? true
                              : false
                          }
                          disabled={
                            selectedValues.length == 0
                              ? false
                              : disableCheck.length === 0 &&
                                checkVal.length === 0
                              ? false
                              : checkVal.length > 0
                              ? false
                              : true
                          }
                          className="m-0"
                        >
                          <div className="pb-1">Estimate {index + 1}</div>
                        </Checkbox>
                      </span>
                    );
                  })}
                </div>
              );
            },
          });
        } else if (selectedTab === MY_TASK.UNRECONCILED_SUBVENTION) {
          mappedColumns.push({
            ...item,
            render: (text, record) => {
              return (
                <>
                  <div className="pb-1">Bank</div>
                  {record &&
                    record?.subvention?.length > 0 &&
                    record?.subvention.map((data, index) => {
                      const selectedValuesCheck = selectedValues.some(
                        (item) => (record?.bank?.id === item?.b) === item
                      );

                      const checkStatus = selectedValues.filter(
                        (item) =>
                          item?.b === record?.bank?.id && item?.index === index
                      );
                      const allRowCheck = selectedRowKeys.map((f) => {
                        if (selectedValues.some((item) => item.index > 0)) {
                          return false;
                        } else if (f === record?.index) {
                          return true;
                        }
                      });

                      return (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          key={data?.id}
                        >
                          <Checkbox
                            onChange={(e) => {
                              handleCheckboxChange(e, {
                                b: record?.bank?.id,
                                e: data?.id,
                                rowKey: record?.index,
                                index,
                                check: true,
                              });
                            }}
                            checked={
                              selectedValuesCheck
                                ? true
                                : allRowCheck[0] && index === 0
                                ? true
                                : checkStatus.length > 0
                                ? true
                                : false
                            }
                            // disabled={
                            //   selectedValues.length == 0
                            //     ? false
                            //     : disableCheck.length === 0 &&
                            //       checkVal.length === 0
                            //     ? false
                            //     : disableCheck.length == 1 &&
                            //       checkVal.length === 1
                            //     ? false
                            //     : true
                            // }
                            className="m-0"
                          >
                            <div className="pb-1">Subvention {index + 1}</div>
                          </Checkbox>
                        </span>
                      );
                    })}
                </>
              );
            },
          });
        }
      } else if (item.dataIndex === "reconsile_button_action") {
        mappedColumns.push({
          ...item,
          render: (text, record) => {
            return (
              <Button
                // disabled={intiate_status !== 2}
                style={{ height: "unset", fontSize: "unset" }}
                className={`btn btn-primary`}
                onClick={(e) => {
                  e.stopPropagation();
                  manualReconcileData(
                    false,
                    record?.bank?.id,
                    record?.estimate
                      ? record?.estimate?.id
                      : record?.subvention && record?.subvention?.id
                  );
                }}
                size="sm"
              >
                Reconcile
              </Button>
            );
          },
        });
      } else {
        mappedColumns.push({ ...item });
      }
    });
    return mappedColumns;
  }, [columns, selectedTab, filterValues, selectedValues, selectedRowKeys]);

  const sharedOnCell = (item, index) => {
    if (item?.fields === "Remarks") {
      return {
        colSpan: 0,
      };
    }
    return {};
  };

  const estimateColumnFields = [
    {
      title: "Fields",
      width: 200,
      dataIndex: "fields",
      fixed: "left",
      rowScope: "row",
      render: (text, record, index) => {
        return (
          <span className="secondary-color-text-l1 font-semibold capitalize">
            {text.split("_").join(" ")}
          </span>
        );
      },
    },
    {
      title: "Bank",
      width: 200,
      dataIndex: "bank",
      fixed: "left",
      onCell: (item, index) => ({
        colSpan:
          item?.fields === "Remarks" ? estimateColumnFields.length - 1 : 1,
      }),
    },
  ];

  const subventionColumnFields = [
    {
      title: "Fields",
      width: 200,
      dataIndex: "fields",
      fixed: "left",
      rowScope: "row",
      render: (text, record, index) => {
        return (
          <span className="secondary-color-text-l1 font-semibold capitalize">
            {text.split("_").join(" ")}
          </span>
        );
      },
    },
    {
      title: "Bank",
      width: 200,
      dataIndex: "bank",
      fixed: "left",
      onCell: (item, index) => ({
        colSpan:
          item?.fields === "Remarks" ? subventionColumnFields.length - 1 : 1,
      }),
    },
  ];

  const remarksPrint = (Remarks, Remarks_date) => {
    return (
      <>
        <div>
          {Remarks_date ? (
            <div className="flex" style={{ width: "100%" }}>
              <div style={{ width: "70%" }}>
                <span className="pe-3 font-bold">{Remarks_date}:</span>
                <span>{Remarks}</span>
              </div>
              <div
                style={{ width: "30%" }}
                className="flex justify-center items-center"
              >
                <Button
                  style={{ height: 35, fontSize: "unset" }}
                  className={`ms-2 btn btn-primary`}
                  onClick={() => {
                    setOpenRemarks(true);
                    // getRemarksHistory();
                  }}
                  size="sm"
                >
                  Remarks history
                </Button>
              </div>
            </div>
          ) : (
            <span>-</span>
          )}
        </div>
      </>
    );
  };

  const get_reconsile_details = async (bankId, id, type) => {
    setBankId(bankId);
    setReconsileId(id);
    const response = await ctx.HttpGet(
      selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
        ? UNRECONCILED_ESTIMATE_VIEWS_DETAILS + bankId + "/" + id + "/" + type
        : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION &&
            UNRECONCILED_SUBVENTION_VIEWS_DETAILS +
              bankId +
              "/" +
              id +
              "/" +
              type
    );
    if (response.status) {
      if (selectedTab === MY_TASK.UNRECONCILED_ESTIMATE) {
        let _arrangeDataForTable = [];
        // main loop started on response data from api
        response?.result?.forEach((item) => {
          setDrawerId(item["bank"].uid_no);
          // keys objects for bank and estimate
          const _bank_estimate_object_keys = {
            ...Object.keys(item.bank),
            ...Object.keys(
              Array.isArray(item.Estimate) ? item.Estimate[0] : item.Estimate
            ),
          };
          for (
            let i = 0;
            i <= Object.keys(_bank_estimate_object_keys).length;
            i++
          ) {
            // loop array from keys
            if (i === Object.keys(_bank_estimate_object_keys).length) {
              // check is last and add button
              let newObj = {
                fields: "",
                bank: "",
              };
              for (let j = 0; j < item.Estimate.length; j++) {
                // add estimate data based on array index
                newObj[[`estimate_${j + 1}`]] = (
                  <>
                    <Button
                      // disabled={intiate_status !== 2}
                      style={{ height: "unset", fontSize: "unset" }}
                      className={`btn btn-primary`}
                      onClick={(e) => {
                        // e.stopPropagation();
                        manualReconcileData(
                          false,
                          item?.bank?.id,
                          item?.Estimate[j].id
                        );
                      }}
                      size="sm"
                    >
                      Reconcile
                    </Button>
                    <Button
                      // disabled={intiate_status !== 2}
                      style={{ height: "unset", fontSize: "unset" }}
                      className={`ms-2 btn btn-primary`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen(true);
                      }}
                      size="sm"
                    >
                      Add Remarks
                    </Button>
                  </>
                );
              }
              _arrangeDataForTable.push(newObj);
            } else {
              // append obj and data if not last
              let newObj = {
                fields: Object.values(_bank_estimate_object_keys)[i],
                bank: item.bank[Object.values(_bank_estimate_object_keys)[i]]
                  ? DisableNumberChecks.includes(
                      Object.values(_bank_estimate_object_keys)[i]
                    )
                    ? item.bank[Object.values(_bank_estimate_object_keys)[i]]
                    : isNaN(
                        item.bank[Object.values(_bank_estimate_object_keys)[i]]
                      )
                    ? item.bank[Object.values(_bank_estimate_object_keys)[i]]
                    : item.bank[Object.values(_bank_estimate_object_keys)[i]] ==
                      0
                    ? "-"
                    : NumberConversion(
                        item.bank[Object.values(_bank_estimate_object_keys)[i]]
                      )
                  : "-",
              };

              for (let j = 0; j < item.Estimate.length; j++) {
                // add estimate data based on array index
                newObj[[`estimate_${j + 1}`]] = item.Estimate[j][newObj.fields]
                  ? DisableNumberChecks.includes(newObj.fields)
                    ? item.Estimate[j][newObj.fields]
                    : isNaN(item.Estimate[j][newObj.fields])
                    ? item.Estimate[j][newObj.fields]
                    : item.Estimate[j][newObj.fields] == 0
                    ? "-"
                    : NumberConversion(item.Estimate[j][newObj.fields])
                  : "-";
              }
              if (
                !["id", "uid_no"].includes(
                  Object.values(_bank_estimate_object_keys)[i]
                )
              ) {
                _arrangeDataForTable.push(newObj);
              }
            }
          }
        });
        // generate dynamic header from data keys for estimate
        Object.keys(_arrangeDataForTable[0])
          .slice(2)
          .map((innerItem, innerIndex) => {
            estimateColumnFields.push({
              title: `Estimate ${innerIndex + 1}`,
              dataIndex: `estimate_${innerIndex + 1}`,
              onCell: sharedOnCell,
            });
          });
        // 1070
        // setDrawerWidth(estimateColumnFields.length > 3 ? "1070" : "683");
        // estimateColumnFields.push({
        //   title: `Estimate ${  Object.keys(_arrangeDataForTable[0])
        //     .slice(2).length + 1}`,
        //   dataIndex: `estimate_${  Object.keys(_arrangeDataForTable[0])
        //     .slice(2).length + 1}`,
        //   onCell:sharedOnCell,
        // });
        const lastIndex = _arrangeDataForTable.findIndex(
          (item) => item?.fields === "Billed_in"
        );

        _arrangeDataForTable.splice(lastIndex + 1, 0, {
          fields: "Remarks",
          bank: "response?.Remarks_date"
            ? remarksPrint(response?.Remarks, response?.Remarks_date)
            : "-",
          estimate_1: response?.Remarks_date
            ? `${response?.Remarks_date}:  ${response?.Remarks}`
            : "-",
        });
        // _arrangeDataForTable.splice(lastIndex + 1, 0, {
        //   fields: "Remarks",
        //   bank: "response?.Remarks_date"
        //     ? `${response?.Remarks_date}:  ${response?.Remarks}`
        //     : "-",
        //   estimate_1: response?.Remarks_date
        //     ? `${response?.Remarks_date}:  ${response?.Remarks}`
        //     : "-",
        // });
        // _arrangeDataForTable.push({fields: 'Remarks', bank: 'Jan-2022', estimate_1: 'Jan-2022'})
        // _arrangeDataForTable.push({fields: 'Remarks_date', bank: 'Jan-2022', estimate_1: 'Jan-2022'})
        setTableProps({
          columns: estimateColumnFields,
          dataSource: _arrangeDataForTable,
          remarks_data: {
            Remarks: response?.Remarks,
            Remarks_date: response?.Remarks_date,
          },
        });
        setOpenDrawable(true);
      } else if (selectedTab === MY_TASK.UNRECONCILED_SUBVENTION) {
        let _arrangeDataForTable = [];
        // main loop started on response data from api
        response.result.forEach((item) => {
          setDrawerId(
            Array.isArray(item.bank)
              ? item["bank"][0].uid_no
              : item["bank"].uid_no
          );
          // keys objects for bank and Subvention
          const _bank_subvention_object_keys = {
            ...Object.keys(item.bank),
            ...Object.keys(
              Array.isArray(item.Subvention)
                ? item.Subvention[0]
                : item.Subvention
            ),
          };

          for (
            let i = 0;
            i <= Object.keys(_bank_subvention_object_keys).length;
            i++
          ) {
            // loop array from keys
            if (i === Object.keys(_bank_subvention_object_keys).length) {
              // check is last and add button
              let newObj = {
                fields: "",
                bank: "",
              };
              for (let j = 0; j < item.Subvention.length; j++) {
                // add subvention data based on array index
                newObj[[`subvention_${j + 1}`]] = (
                  <>
                    <Button
                      // disabled={intiate_status !== 2}
                      style={{ height: "unset", fontSize: "unset" }}
                      className={`btn btn-primary`}
                      onClick={(e) => {
                        // e.stopPropagation();
                        manualReconcileData(
                          false,
                          item?.bank?.id,
                          item?.Subvention[j].id
                        );
                        // setOpenDrawable(false);
                      }}
                      size="sm"
                    >
                      Reconcile
                    </Button>
                    <Button
                      // disabled={intiate_status !== 2}
                      style={{ height: "unset", fontSize: "unset" }}
                      className={`ms-2 btn btn-primary`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen(true);
                      }}
                      size="sm"
                    >
                      Add Remarks
                    </Button>
                  </>
                );
              }
              _arrangeDataForTable.push(newObj);
            } else {
              // append obj and data if not last
              let newObj = {
                fields: Object.values(_bank_subvention_object_keys)[i],
                bank: item.bank[Object.values(_bank_subvention_object_keys)[i]]
                  ? DisableNumberChecks.includes(
                      Object.values(_bank_subvention_object_keys)[i]
                    )
                    ? item.bank[Object.values(_bank_subvention_object_keys)[i]]
                    : isNaN(
                        item.bank[
                          Object.values(_bank_subvention_object_keys)[i]
                        ]
                      )
                    ? item.bank[Object.values(_bank_subvention_object_keys)[i]]
                    : item.bank[
                        Object.values(_bank_subvention_object_keys)[i]
                      ] == 0
                    ? "-"
                    : NumberConversion(
                        item.bank[
                          Object.values(_bank_subvention_object_keys)[i]
                        ]
                      )
                  : "-",
              };

              for (let j = 0; j < item.Subvention.length; j++) {
                // add subvention data based on array index
                newObj[[`subvention_${j + 1}`]] = item.Subvention[j][
                  newObj.fields
                ]
                  ? DisableNumberChecks.includes(newObj.fields)
                    ? item.Subvention[j][newObj.fields]
                    : isNaN(item.Subvention[j][newObj.fields])
                    ? item.Subvention[j][newObj.fields]
                    : item.Subvention[j][newObj.fields] == 0
                    ? "-"
                    : NumberConversion(item.Subvention[j][newObj.fields])
                  : "-";
              }
              if (
                !["id", "uid_no"].includes(
                  Object.values(_bank_subvention_object_keys)[i]
                )
              ) {
                _arrangeDataForTable.push(newObj);
              }
            }
          }
        });
        // generate dynamic header from data keys for estimate
        Object.keys(_arrangeDataForTable[0])
          .slice(2)
          .map((innerItem, innerIndex) => {
            subventionColumnFields.push({
              title: `Subvention ${innerIndex + 1}`,
              dataIndex: `subvention_${innerIndex + 1}`,
              onCell: sharedOnCell,
            });
          });
        const lastIndex = _arrangeDataForTable.findIndex(
          (item) => item?.fields === "Total_subvention"
        );

        _arrangeDataForTable.splice(lastIndex + 1, 0, {
          fields: "Remarks",
          bank: "response?.Remarks_date"
            ? remarksPrint(response?.Remarks, response?.Remarks_date)
            : "-",
          subvention_1: response?.Remarks_date
            ? `${response?.Remarks_date}:  ${response?.Remarks}`
            : "-",
        });
        // setDrawerWidth(
        //   subventionColumnFields.length > 3 ? "1070" : "683",
        // );
        setTableProps({
          columns: subventionColumnFields,
          dataSource: _arrangeDataForTable,
        });
        setOpenDrawable(true);
      }
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, checked) => {
      setSelectStatus((old) => ({ ...old, single: checked }));
      if (!checked) {
        handleCheckboxChange(false, {
          b: record?.bank?.id,
          rowKey: record?.index,
        });
      }
    },
    onChange: (selectedRowKeys, selectedRows, type) => {
      type.type === "all" &&
        setSelectStatus((old) => ({ ...old, all: !old.all }));
      const helper = checkBoxHelper.map((item) => item?.rowKey);

      if (
        selectedRows.length > 0 &&
        selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
      ) {
        const estimateArr = [];
        selectedRows.map((val) => {
          val?.estimate.map((data, i) => {
            i === 0 && type.type === "all"
              ? estimateArr.push({
                  b: val?.bank?.id,
                  e: data?.id,
                  rowKey: val?.index,
                  index: i,
                  check: true,
                })
              : !helper.includes(val?.index) &&
                i === 0 &&
                type.type === "single" &&
                estimateArr.push({
                  b: val?.bank?.id,
                  e: data?.id,
                  rowKey: val?.index,
                  index: i,
                  check: true,
                });
          });
        });
        setSelectedValues((old) =>
          type.type === "all" ? estimateArr : [...old, ...estimateArr]
        );
        setCheckBoxHelper((old) =>
          type.type === "all" ? estimateArr : [...old, ...estimateArr]
        );
        setReconsileData(estimateArr);
      } else if (
        selectedRows.length > 0 &&
        selectedTab === MY_TASK.UNRECONCILED_SUBVENTION
      ) {
        const subventionArr = [];

        selectedRows.map((val) => {
          val?.subvention.map((data, i) => {
            type.type === "all"
              ? subventionArr.push({
                  b: val?.bank?.id,
                  e: data?.id,
                  rowKey: val?.index,
                  index: i,
                  check: true,
                })
              : !helper.includes(val?.index) &&
                subventionArr.push({
                  b: val?.bank?.id,
                  e: data?.id,
                  rowKey: val?.index,
                  index: i,
                  check: true,
                });
          });
        });

        setSelectedValues((old) =>
          type.type === "all" ? subventionArr : [...old, ...subventionArr]
        );
        setCheckBoxHelper((old) =>
          type.type === "all" ? subventionArr : [...old, ...subventionArr]
        );
        setReconsileData(subventionArr);
      } else {
        setSelectedValues([]);
        setCheckBoxHelper([]);
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleRemarks = async (e) => {
    const response = await ctx.HttpPost(
      selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
        ? UNRECONCILED_ESTIMATE_REMARKS
        : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION &&
            UNRECONCILED_SUBVENTION_REMARKS,
      {
        from_id: rowData?.bankId,
        to_id:
          selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
            ? rowData?.estimateId
            : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION &&
              rowData?.subventionId,
        step: rowData?.step,
        remarks: remarks,
        user_id: ctx?.userInfo?.id,
        user_name: ctx?.userInfo?.username,
      }
    );
    if (response.status) {
      setIsModalOpen(false);
      get_reconsile_details(rowData?.bankId, rowData?.step, rowData?.type);
      getTableData(
        searchValue || filterValues?.searchBy,
        null,
        null,
        currentPage,
        pageSize,
        fromDateValue,
        toDataValue,
        filterValues
      );
      setRemarks("");
    }
  };

  useEffect(() => {
    if (openRemarks) {
      getRemarksHistory();
    }
  }, [openRemarks, setOpenRemarks]);

  const getRemarksHistory = useCallback(
    async (e) => {
      const response = await ctx.HttpGet(
        `${
          selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
            ? UNRECONCILED_ESTIMATE_REMARKS_HISTORY
            : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION &&
              UNRECONCILED_SUBVENTION_REMARKS_HISTORY
        }/${rowData?.bankId}/${
          selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
            ? rowData?.estimateId
            : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION &&
              rowData?.subventionId
        }/${rowData?.step}`
      );
      if (response.status) {
        setRemarksHistoryData(response?.remarks_result);
        // setIsModalOpen(false);
      }
    },
    [rowData]
  );

  const handleCancel = () => {
    setIsModalOpen(false);
    setRemarks("");
  };

  const items = [
    {
      key: 1,
      label: (
        <div
          onClick={() =>
            exportTabData(
              selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
                ? "estimate_pms&sms"
                : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION &&
                    "subvention_pms&sms",
              searchValue,
              sortByValue,
              orderByValue,
              currentPage,
              pageSize,
              fromDateValue,
              toDataValue,
              filterValues
            )
          }
        >
          PMS & SMS Match Count
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <div
          onClick={() =>
            exportTabData(
              selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
                ? "estimate"
                : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION &&
                    "subvention",
              searchValue,
              sortByValue,
              orderByValue,
              currentPage,
              pageSize,
              fromDateValue,
              toDataValue,
              filterValues
            )
          }
        >
          {`${
            selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
              ? "Estimate Matches"
              : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION &&
                "Subvention Matches"
          }`}
        </div>
      ),
    },
  ];

  return (
    <>
      {dataLoading || initialData ? (
        <>
          <div className="flex items-center justify-between px-4 py-2">
            <CustomSearch
              placeholder="Search by Customer Name, Location Head, Product, Bank and App No."
              onSearch={(value) => {
                setSelectedValues([]);
                if (value.length >= 2) {
                  setSearchValue(value);
                  setFilterValues({ ...filterValues, searchBy: value });

                  setCurrentPage(1);
                  getTableData(
                    value,
                    null,
                    null,
                    1,
                    pageSize,
                    fromDateValue,
                    toDataValue,
                    filterValues
                  );
                  // check here
                } else if (value.length === 0) {
                  setSearchValue("");
                  setFilterValues({ ...filterValues, searchBy: "" });
                  getTableData(
                    null,
                    null,
                    null,
                    currentPage,
                    pageSize,
                    fromDateValue,
                    toDataValue,
                    filterValues
                  );
                }
              }}
              searchType="manual"
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            <div className="flex items-center">
              <div className="mr-4">
                <CustomButton
                  type="button"
                  title="Filter"
                  // disabled={!reconsileData.length}
                  className="btn-primary font-bold py-2 px-4 rounded fs-14"
                  onClick={() => setOpenFilter(true)}
                  // icon={"https://cdn-icons-png.flaticon.com/512/107/107799.png"}
                />
              </div>
              {(selectedTab === MY_TASK.UNRECONCILED_ESTIMATE ||
                selectedTab === MY_TASK.UNRECONCILED_SUBVENTION) && (
                <div className="mr-4">
                  <CustomButton
                    type="button"
                    title="Reconcile"
                    disabled={!reconsileData.length || isReconileProcessing}
                    className="btn-primary font-bold py-2 px-4 rounded fs-14"
                    onClick={() => manualReconcileData(true)}
                  />
                </div>
              )}
              <div className="mr-4">
                <CustomDropdownExports
                  dropdownImageStyle={{ width: "15px" }}
                  uploadButtonTypeClass="upload-btn-dropdown"
                  dropdownArrowIcon="down-arrow-white.svg"
                  items={items}
                  disabled={tabExportBtn}
                />
              </div>
              <CustomDatepicker
                value={[fromDateValue, toDataValue]}
                onChangeDatePicker={(date, dateString) => {
                  if (date) {
                    setFromDateValue(FromDatePickerParams(date));
                    setToDataValue(ToDatePickerParams(date));
                    getTableData(
                      searchValue,
                      null,
                      null,
                      1,
                      pageSize,
                      FromDatePickerParams(date),
                      ToDatePickerParams(date),
                      filterValues
                    );
                  } else {
                    setFromDateValue(null);
                    setToDataValue(null);
                    getTableData(
                      searchValue,
                      null,
                      null,
                      1,
                      pageSize,
                      null,
                      null,
                      filterValues
                    );
                  }
                }}
              />
            </div>
          </div>
          <CustomDataTable
            rowKey={(records, index) => {
              return index;
            }}
            columns={arrangeColumns()}
            data={selectedData?.result}
            className="cust_datatable"
            rowClickable={true}
            tableHeight={"calc(100vh - 285px)"}
            loading={dataLoading}
            handleRowClick={(rowData) => {
              setRowData({
                bankId: rowData?.bank?.id,
                step:
                  selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
                    ? rowData?.bank?.Step
                    : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION &&
                      rowData?.bank?.Step,
                type: rowData?.Type,
                estimateId: rowData?.estimate?.[0]?.id,
                subventionId: rowData?.subvention?.[0]?.id,
              });
              get_reconsile_details(
                rowData?.bank?.id,
                selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
                  ? rowData?.bank?.Step
                  : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION &&
                      rowData?.bank?.Step,
                rowData?.Type
              );
            }}
            totalRecords={selectedData?.total_page_size}
            pageSize={
              pageSize || ctx?.sortAndPagination?.my_tasks?.[selectedTab]?.size
            }
            currentPage={currentPage}
            rowSelectionBox={{
              type: "checkbox",
              enable:
                selectedTab === MY_TASK.UNRECONCILED_ESTIMATE ||
                selectedTab === MY_TASK.UNRECONCILED_SUBVENTION,
            }}
            rowSelection={rowSelection}
            onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
              setReconsileData([]);
              setRowKeys([]);
              if (sorterOptions && sorterOptions.order) {
                setOrderByValue(SortedOrderBy(sorterOptions.order));
                setSortByValue(sorterOptions.field);
              }
              setPageSize(pageSizeOptions.pageSize);
              setCurrentPage(pageSizeOptions.current);
              setSelectedValues([]);
              setCheckBoxHelper([]);
              getTableData(
                searchValue,
                sorterOptions.order ? sorterOptions.field : "",
                SortedOrderBy(sorterOptions.order),
                pageSizeOptions.current,
                pageSizeOptions.pageSize,
                fromDateValue,
                toDataValue,
                filterValues
              );
            }}
            selectedValues={selectedValues}
            parentData={parentData}
            selectedTab={selectedTab}
          />
        </>
      ) : (
        <NoData hideNoData={hideNoData} />
      )}
      {openFilter && (
        <CustomFilter
          width={365}
          data={[]}
          setOpenDrawable={setOpenFilter}
          getTableData={getTableData}
          setFilterValues={setFilterValues}
          filterValues={filterValues}
          currentpage={currentpage}
          pagesize={pagesize}
          selectedTab={selectedTab}
        />
      )}
      {openRemarks && (
        <RemarksHistory
          width={565}
          data={remarksHistoryData}
          setOpenDrawable={setOpenRemarks}
          getTableData={getTableData}
          selectedTab={selectedTab}
          title={"Remarks History"}
        />
      )}
      {openDrawable && (
        <CustomDrawer
          setOpenDrawable={setOpenDrawable}
          // btnDisabled={intiate_status !== 2}
          tableProps={tableProps}
          data={drawableData}
          selectedTab={selectedTab}
          type="unreconsiled"
          drawerId={drawerId}
          reconsileButtonEnabled={true}
          width={780}
          primaryBtnClick={() => {
            manualReconcileData(false, bankId, reconsileId);
            setOpenDrawable(false);
          }}
        />
      )}
      <Modal
        title="Add Remarks"
        centered
        open={isModalOpen}
        // onOk={handleRemarks}
        onCancel={handleCancel}
        footer={[
          <Button
            // disabled={intiate_status !== 2}
            style={{ height: "unset", fontSize: "unset" }}
            className={` btn btn-primary-outline`}
            onClick={(e) => {
              e.stopPropagation();
              handleCancel();
            }}
            size="sm"
          >
            Cancel
          </Button>,
          <Button
            // disabled={intiate_status !== 2}
            style={{ height: "unset", fontSize: "unset" }}
            className={`ms-2 btn btn-primary`}
            onClick={(e) => {
              e.stopPropagation();
              handleRemarks();
            }}
            size="sm"
            disabled={!remarks}
          >
            Add Remarks
          </Button>,
        ]}
      >
        <CustomInput
          type="text"
          id="remark"
          name="remark"
          onChange={(e) => {
            setRemarks(e.target.value);
          }}
          value={remarks}
          heightClass={"min-h-[42px]"}
        />
      </Modal>
    </>
  );
}
