export const UploadHistoryHeader = [
  {
    dataIndex: "file_no",
  },
  {
    title: "Uploaded by",
    dataIndex: "user_name",
    render: (text) => {
      return <span className="capitalize">{text}</span>;
    },
  },
  {
    title: "Uploaded date",
    dataIndex: "createdat",
  },
  {
    dataIndex: "Status",
    key: "status",
  },
  {
    title: "",
    dataIndex: "action",
    width: 100,
    sorter: false,
  },
];
