import { useContext } from "react";
import { Navigate } from "react-router";
import { AppContext } from "../../helpers/app-context";

import { TOKEN_NAME } from "../../helpers/constant";

export const PrivateRoutes = ({ children }) => {
  const ctx = useContext(AppContext);
  const getTokenFromLs = localStorage.getItem(TOKEN_NAME);
  if(getTokenFromLs === null) {
    ctx.AppLogout(false);
  }
  // commented need confirmation
  // const location = useLocation();
  // const firstPathname = location.pathname === "/" ? "HOME" : location.pathname.split("/")[1].replace("-", "_").toUpperCase();
  
  // useEffect(() => {
  //   document.title = PAGE_NAME[firstPathname];
  // }, [location.pathname]);

  return getTokenFromLs !== null ? (
    children
  ) : (
    <Navigate to="/" replace={true} />
  );
};
