import { useNavigate } from "react-router-dom";
import { CustomButton } from "./CustomButton";
import { CustomUpload } from "./CustomUpload";
import { CustomDropdownUpload } from "./CustomDropdownUpload";

export const NoData = (props) => {
  const {
    templateDownload,
    dropdownFlag,
    title,
    isUpload,
    fileId,
    uploadFile,
    tempFailed,
    hideNoData
  } = props;
  const navigate = useNavigate();
  return (
    <div className={`${hideNoData ? "opacity-0" : ""} flex flex-col items-center justify-center tab-container`}>
      <div className="text-center">
        <img src="/images/icon/nodata.svg" alt="no data" className="mx-auto" />
        <div className="my-4 nodatatext primary-text-color-l3 fs-14">
          {(title && title) || "No data to display."}
        </div>
      </div>
      {isUpload ? (
        <>
          <div className="nodatatext primary-text-color-l3 fs-14">
            Data will appear here once files are uploaded
          </div>
          <div className="mt-4">
            {dropdownFlag ? (
              <CustomDropdownUpload uploadFile={uploadFile} templateDownload={templateDownload} />
            ) : (
              <CustomUpload
                fileId={fileId}
                uploadFile={uploadFile}
              />
            )}
          </div>
        </>
      ) : (
        ""
      )}
      {tempFailed && (
        <CustomButton
          className="btn-primary"
          title="Home"
          onClick={() => navigate("/home")}
        />
      )}
    </div>
  );
};
