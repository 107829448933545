import React, { useState } from "react";
import { Input } from "antd";

export const CustomInput = (props) => {
  const { label, id, type, value, heightClass, onChange, onBlur, onFocus , name, disabled, errors } =
    props;
  const [passwordView, setPasswordView] = useState(false);
  
  return (
    <div className="user-input">
        <Input
          type={type === "password" && passwordView ? "text" : type}
          className={`form-control form-input ${heightClass} ${errors} ${type === "password" ? "pr-9" : "pr-4"}`}
          id={id}
          name={name}
          placeholder={" "}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          onFocus={onFocus}
        />
        {type === "password" ? (
          passwordView ? (
            <img
              src="/images/icon/password-show.svg"
              alt="password"
              className="password-icon"
              onClick={() => setPasswordView(false)}
            />
          ) : (
            <img
              src="/images/icon/password-hide.svg"
              alt="password"
              className="password-icon"
              onClick={() => setPasswordView(true)}
            />
          )
        ) : (
          ""
        )}
        <div
          htmlFor={id}
          className={`form-label ${ errors ?  'label-error-color' : 'secondary-color-text-l1' } fs-16`} >
          {label}
        </div>
    </div>
  );
};
