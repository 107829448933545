import React from "react";
import { useNavigate } from "react-router-dom";

import { CustomButton } from "./common/CustomButton";
import { SignInLeftSide } from "./common/SignInLeftSide";

export const ResetPasswordSuccessful = (props) => {
  const navigate = useNavigate();

  return (
    <div
      className="flex items-center h-screen"
    >
      <SignInLeftSide />
      <div className="mx-auto loginscreen-left">
        <div className="font-semibold fs-40 highlight-text-color">
          Reset Successful
        </div>
        <div className="fs-16 secondary-color-text-l1 pe-5">
          Your password has been reset successfully.
        </div>
        <CustomButton
          title="Continue to Login"
          className="btn-primary w-full mt-7"
          onClick={() => navigate("/login")}
        />
      </div>
    </div>
  );
};
