import React, { useContext, useState } from "react";
import { Drawer } from "antd";

import { AppContext } from "../../helpers/app-context";
import { NoData } from "./NoData";
import { SkeletonLoader } from "./SkeletonLoader";

export const RemarksHistory = (props) => {
  const ctx = useContext(AppContext);
  const {
    title,
    placement,
    setOpenDrawable,
    data,
    width = 683,
    selectedTab,
  } = props;

  const [open, setOpen] = useState(data ? true : false);

  const onClose = () => {
    setOpen(false);
    setOpenDrawable(false);
  };

  return (
    <Drawer
      title={title}
      placement={placement}
      closable={false}
      onClose={onClose}
      open={open}
      width={window.innerWidth > 900 ? width : window.innerWidth - 100}
      className="custom-filter-drawer-main"
      extra={
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-2 items-center">
            <button
              type="button"
              aria-label="Close"
              onClick={onClose}
              className="ant-drawer-close"
            >
              <img
                src="/images/icon/close.svg"
                alt="close"
                style={{
                  width: "16px",
                }}
              />
            </button>
          </div>
        </div>
      }
    >
      <div
        className=" px-5 py-4 gap-4 overflow-y-scroll"
        style={{ height: "calc(100vh - 123px)" }}
      >
        <div className="">
          {title && (
            <div className="fs-20 font-semibold bg-light-grey p-4 h-[62px]">
              Activity
            </div>
          )}
          <div
            className="overflow-auto"
            style={{
              maxHeight: "calc(100vh - 140px)",
            }}
          >
            <div className="">
              <SkeletonLoader
                loading={false}
                className="py-3 px-3"
                style={{ width: "100%" }}
              >
                {data.length ? (
                  data.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="grid grid-cols-8 mx-3 py-3 border-b overflow-y-auto"
                        style={{ maxHeight: "calc(100vh - 135px)" }}
                      >
                        <div className="col-span-3">
                          <h3 className="pl-1 secondary-color-text-l1 fs-14">
                            {item.createdat}
                          </h3>
                        </div>
                        <div className=" col-span-5">
                          <p
                            className="fs-14"
                            dangerouslySetInnerHTML={{ __html: item.remarks }}
                          ></p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <NoData />
                )}
              </SkeletonLoader>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
