import React, { useContext, useEffect, useState } from "react";
import { Drawer, Select, Input, Checkbox } from "antd";

import { CustomButton } from "./CustomButton";
import { useFormik } from "formik";
import { AppContext } from "../../helpers/app-context";
import {
  ESTIMATE_LOCATION_BANK_PRODUCT_LIST,
  LOCATION_BANK_PRODUCT_LIST,
  SUBVENTION_LOCATION_BANK_PRODUCT_LIST,
} from "../../helpers/api-endpoints";
import { MY_TASK } from "../../helpers/constant";

export const CustomFilter = (props) => {
  const { filterValues, setFilterValues } = props;
  const ctx = useContext(AppContext);
  const {
    title,
    placement,
    setOpenDrawable,
    data,
    width = 683,
    getTableData,
    fromDate,
    toDate,
    sortByValue,
    orderByValue,
    currentpage,
    pagesize,
    searchValue,
    selectedTab,
  } = props;
  const [open, setOpen] = useState(data ? true : false);
  const [selectedCheck, setSelectedCheck] = useState({
    pmsSelect: false,
    pmsPercent: false,
    smsSelect: false,
    smsPercent: false,
    steps: false,
    Comment_Select: false,
    location_headSelect: false,
    bankSelect: false,
    productSelect: false,
  });
  const [hideStatus, setHideStatus] = useState(false);
  const [location_headOption, setLocation_headOption] = useState([]);
  const [bankOption, setBankOption] = useState([]);
  const [productOption, setProductOption] = useState([]);

  const onClose = () => {
    setOpen(false);
    setOpenDrawable(false);
  };

  const onChange = (e, name) => {
    setFilterValues({
      ...filterValues,
      [e.target.name]: !e.target.value
        ? null
        : Math.abs(e.target.value) > 100
        ? 100
        : Math.abs(e.target.value),
    });
    ctx?.setModuleFilter({
      ...filterValues,
      [e.target.name]: !e.target.value
        ? null
        : Math.abs(e.target.value) > 100
        ? 100
        : Math.abs(e.target.value),
    });
  };

  const onChangeCheckbox = (e) => {
    e.target.checked
      ? setFilterValues({ ...filterValues, single: 1 })
      : setFilterValues({ ...filterValues, single: 0 });

    e.target.checked
      ? ctx?.setModuleFilter({ ...filterValues, single: 1 })
      : ctx?.setModuleFilter({ ...filterValues, single: 0 });
  };

  const onBlur = (name) => {
    name && setSelectedCheck({ ...selectedCheck, [name]: true });
  };
  const formik = useFormik({
    initialValues: {
      pmsSelect: filterValues?.pmsSelect,
      pmsPercent: filterValues?.pmsPercent,
      smsSelect: filterValues?.smsSelect,
      smsPercent: filterValues?.smsPercent,
      steps: filterValues?.steps,
      Comment_Select: filterValues?.Comment_Select,
      location_headSelect: filterValues?.location_headSelect,
      bankSelect: filterValues?.bankSelect,
      productSelect: filterValues?.productSelect,
    },
    // validationSchema: CustomFilterValidation,
    onSubmit: async (values, { resetForm }) => {
      setFilterValues(values);
      ctx?.setModuleFilter(values);
      getTableData(
        searchValue,
        sortByValue,
        orderByValue,
        currentpage,
        pagesize,
        fromDate,
        toDate,
        values
      );
      // resetForm();
      onClose();
    },
  });

  let pmsOptions = [
    {
      value: ">",
      label: "Greater Than(>)",
    },
    {
      value: "<",
      label: "Less Than (<)",
    },
    {
      value: "=",
      label: "Equals To (=)",
    },
  ];
  let smsOptions = [
    {
      value: ">",
      label: "Greater Than(>)",
    },
    {
      value: "<",
      label: "Less Than (<)",
    },
    {
      value: "=",
      label: "Equals To (=)",
    },
  ];
  let stepsOptions = [
    { value: "1 ", label: "1 " },
    { value: "3a", label: "3a" },
    { value: "3b", label: "3b" },
    { value: "3c", label: "3c" },
    { value: "3d", label: "3d" },
    { value: "5a", label: "5a" },
    { value: "5b", label: "5b" },
    { value: "5c", label: "5c" },
    { value: "5d", label: "5d" },
    { value: "6a", label: "6a" },
    { value: "6b", label: "6b" },
    { value: "6c", label: "6c" },
    { value: "6d", label: "6d" },
    { value: "7a", label: "7a" },
    { value: "7b", label: "7b" },
    { value: "34", label: "34" },
    { value: "54", label: "54" },
    { value: "64", label: "64" },
  ];
  let commentOptions = [
    {
      value: "both",
      label: "Both",
    },
    {
      value: "comment",
      label: "Only Remarks",
    },
    {
      value: "without_comment",
      label: "Without Remarks",
    },
  ];
  const reset = () => {
    setSelectedCheck({
      pmsSelect: false,
      pmsPercent: false,
      smsSelect: false,
      smsPercent: false,
      steps: false,
      Comment_Select: false,
      location_headSelect: false,
      bankSelect: false,
      productSelect: false,
    });
    setFilterValues({
      pmsSelect: null,
      pmsPercent: undefined,
      smsSelect: null,
      smsPercent: undefined,
      steps: null,
      Comment_Select: null,
      single: 0,
      location_headSelect: null,
      bankSelect: null,
      productSelect: null,
    });
    ctx?.setModuleFilter({
      pmsSelect: null,
      pmsPercent: undefined,
      smsSelect: null,
      smsPercent: undefined,
      steps: null,
      Comment_Select: null,
      single: 0,
      location_headSelect: null,
      bankSelect: null,
      productSelect: null,
    });
    setHideStatus(false);
    formik.resetForm();
    formik.setValues({
      pmsSelect: null,
      pmsPercent: undefined,
      smsSelect: null,
      smsPercent: undefined,
      steps: null,
      Comment_Select: null,
      location_headSelect: null,
      bankSelect: null,
      productSelect: null,
    });
    getTableData();
    // onClose();
  };

  const listApi = async () => {
    let param1 =
      selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
        ? "Estimate/"
        : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION
        ? "Subvention/"
        : "";
    const responseLocation_head = await ctx.HttpGet(
      selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
        ? ESTIMATE_LOCATION_BANK_PRODUCT_LIST + "location_head"
        : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION &&
            SUBVENTION_LOCATION_BANK_PRODUCT_LIST + "location_head"
    );
    if (responseLocation_head?.status) {
      setLocation_headOption(
        responseLocation_head?.result.map((item) => ({
          value: item,
          label: item,
        }))
      );
    }

    const responseBank = await ctx.HttpGet(
      selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
        ? ESTIMATE_LOCATION_BANK_PRODUCT_LIST + "bank"
        : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION &&
            SUBVENTION_LOCATION_BANK_PRODUCT_LIST + "bank"
      // LOCATION_BANK_PRODUCT_LIST + param1 + "bank",
    );
    if (responseBank?.status) {
      setBankOption(
        responseBank?.result.map((item) => ({
          value: item,
          label: item,
        }))
      );
    }

    const responseProduct = await ctx.HttpGet(
      selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
        ? ESTIMATE_LOCATION_BANK_PRODUCT_LIST + "product"
        : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION &&
            SUBVENTION_LOCATION_BANK_PRODUCT_LIST + "product"
      // LOCATION_BANK_PRODUCT_LIST + param1 + "product",
    );
    if (responseProduct?.status) {
      setProductOption(
        responseProduct?.result.map((item) => ({
          value: item,
          label: item,
        }))
      );
    }
  };

  useEffect(() => {
    listApi();
  }, []);
  useEffect(() => {
    formik.setValues(ctx?.moduleFilter);
    setFilterValues(ctx?.moduleFilter);
  }, [filterValues]);

  useEffect(() => {
    if (
      filterValues?.pmsSelect &&
      (filterValues?.pmsPercent || filterValues?.pmsPercent == 0) &&
      filterValues?.smsSelect &&
      (filterValues?.smsPercent || filterValues.smsPercent == 0)
    ) {
      formik.setFieldValue("steps", "1");
      setHideStatus(true);
    }
  }, [formik.values]);
  return (
    <Drawer
      title={title}
      placement={placement}
      closable={false}
      onClose={onClose}
      open={open}
      width={window.innerWidth > 900 ? width : window.innerWidth - 100}
      className="custom-filter-drawer-main"
      extra={
        <>
          <button
            type="button"
            aria-label="Close"
            onClick={onClose}
            className="ant-drawer-close"
          >
            <img
              src="/images/icon/close.svg"
              alt="close"
              style={{
                width: "16px",
              }}
            />
          </button>
        </>
      }
    >
      <div
        className="grid frid-cols-1 px-5 py-3 border-b"
        style={{ paddingBottom: 15 }}
      >
        <div className="flex justify-between items-center">
          <div className="">
            <div className="primary-color-text-l1 fs-20 font-semibold mb-2">
              Filter
            </div>
          </div>
          <div className="grid grid-cols-2 items-center">
            <div className="">
              <h4
                className="primary-text-color cursor-pointer"
                type="reset"
                onClick={reset}
              >
                Clear
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div
        className="grid grid-cols-1 px-5 py-4 gap-4 overflow-y-scroll"
        style={{ height: "calc(100vh - 123px)" }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-col-1">
            <div className="mb-4">
              <div className="primary-color-text-l1 fs-14 font-semibold mb-2">
                PMS
              </div>
              <div className="grid grid-cols-2">
                <div className="cols-span-2">
                  <Select
                    className="w-36"
                    name="pmsSelect"
                    value={formik.values.pmsSelect}
                    onSelect={(e) => {
                      setFilterValues({ ...filterValues, pmsSelect: e });
                      ctx?.setModuleFilter({ ...filterValues, pmsSelect: e });
                    }}
                    onBlur={(_) => {
                      onBlur("pmsSelect");
                    }}
                    placeholder="Select PMS"
                    options={pmsOptions}
                    defaultValue={pmsOptions.find(
                      (val) => val.value == formik.values.pmsSelect
                    )}
                  />
                  <div className="error">
                    {filterValues?.smsSelect && filterValues?.smsPercent
                      ? ""
                      : selectedCheck?.pmsSelect &&
                        !filterValues?.pmsSelect &&
                        "Input is Required"}
                  </div>
                </div>
                <div className="">
                  <Input
                    type="number"
                    className={`form-control form-input pr-4"`}
                    // onChange={formik.handleChange("pmsPercent")}
                    value={formik.values.pmsPercent}
                    onChange={onChange}
                    onBlur={(_) => {
                      onBlur("pmsPercent");
                    }}
                    min={"0"}
                    max={"100"}
                    id="pmsPercent"
                    name="pmsPercent"
                    placeholder="PMS %"
                    disabled={filterValues?.pmsSelect ? false : true}
                  />
                  <div className="error">
                    {formik.touched.pmsPercent && formik.errors.pmsPercent}
                  </div>
                  <div className="error">
                    {filterValues?.pmsSelect &&
                    (filterValues?.pmsPercent || filterValues?.pmsPercent === 0)
                      ? ""
                      : filterValues?.pmsSelect
                      ? "Input is Required"
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="primary-color-text-l1 fs-14 font-semibold mb-2">
                SMS
              </div>
              <div className="grid grid-cols-2">
                <div className="">
                  <Select
                    className="w-36"
                    name="smsSelect"
                    value={formik.values.smsSelect}
                    onSelect={(e) => {
                      setFilterValues({ ...filterValues, smsSelect: e });
                      ctx?.setModuleFilter({ ...filterValues, smsSelect: e });
                    }}
                    onBlur={(_) => {
                      onBlur("smsSelect");
                    }}
                    placeholder="Select SMS"
                    options={smsOptions}
                    defaultValue={smsOptions.find(
                      (val) => val.value == formik.values.smsSelect
                    )}
                  />
                  <div className="error">
                    {filterValues?.pmsSelect && filterValues?.pmsPercent
                      ? ""
                      : selectedCheck?.smsSelect &&
                        !filterValues?.smsSelect &&
                        "Input is Required"}
                  </div>
                </div>
                <div className="">
                  <Input
                    type="number"
                    className={`form-control form-input pr-4"`}
                    onChange={onChange}
                    onBlur={(_) => {
                      onBlur("smsPercent");
                    }}
                    value={formik.values.smsPercent}
                    id="sms"
                    min={"0"}
                    max={"100"}
                    name="smsPercent"
                    placeholder="SMS %"
                    disabled={filterValues?.smsSelect ? false : true}
                  />
                  <div className="error">
                    {filterValues?.smsSelect &&
                    (filterValues?.smsPercent || filterValues?.smsPercent === 0)
                      ? ""
                      : filterValues?.smsSelect
                      ? "Input is Required"
                      : ""}
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className="grid grid-cols-2">
                {!hideStatus && (
                  <div className="flex flex-col mb-4 mr-3">
                    <div className="primary-color-text-l1 fs-14 font-semibold mb-2">
                      Select Steps
                    </div>
                    <Select
                      name={"steps"}
                      value={formik.values.steps}
                      onSelect={(e) => {
                        setFilterValues({ ...filterValues, steps: e });
                        ctx?.setModuleFilter({ ...filterValues, steps: e });
                      }}
                      onBlur={(_) => {
                        onBlur("steps");
                      }}
                      placeholder="Select Steps"
                      options={stepsOptions}
                    />
                  </div>
                )}
                {/* <div className={` flex flex-col mb-4 ${hideStatus && "mr-3"}`}>
                  <div className="primary-color-text-l1 fs-14 font-semibold mb-2">
                    Location Head
                  </div>
                  <Select
                    name={"location_headSelect"}
                    value={formik.values.location_headSelect}
                    onSelect={(e) => {
                      setFilterValues({
                        ...filterValues,
                        location_headSelect: e,
                      });
                      ctx?.setModuleFilter({
                        ...filterValues,
                        location_headSelect: e,
                      });
                    }}
                    onBlur={(_) => {
                      onBlur("location_headSelect");
                    }}
                    placeholder="Select Location Head"
                    options={location_headOption}
                  />
                </div> */}
                {/* <div className={` flex flex-col mb-4 ${!hideStatus && "mr-3"}`}>
                  <div className="primary-color-text-l1 fs-14 font-semibold mb-2">
                    Product
                  </div>
                  <Select
                    name={"productSelect"}
                    value={formik.values.productSelect}
                    onSelect={(e) => {
                      setFilterValues({ ...filterValues, productSelect: e });
                      ctx?.setModuleFilter({
                        ...filterValues,
                        productSelect: e,
                      });
                    }}
                    onBlur={(_) => {
                      onBlur("productSelect");
                    }}
                    placeholder="Select product"
                    options={productOption}
                  />
                </div> */}
                {/* <div className={` flex flex-col mb-4 ${hideStatus && "mr-3"}`}>
                  <div className="primary-color-text-l1 fs-14 font-semibold mb-2">
                    Bank
                  </div>
                  <Select
                    name={"bankSelect"}
                    value={formik.values.bankSelect}
                    onSelect={(e) => {
                      setFilterValues({ ...filterValues, bankSelect: e });
                      ctx?.setModuleFilter({
                        ...filterValues,
                        bankSelect: e,
                      });
                    }}
                    onBlur={(_) => {
                      onBlur("bankSelect");
                    }}
                    placeholder="Select Bank"
                    options={bankOption}
                  />
                </div> */}
              </div>
            </div>

            <div className="mb-4"></div>
            <div className="mt-3  py-5 border-t">
              <div className="flex flex-col">
                <Checkbox
                  checked={filterValues?.single}
                  onChange={onChangeCheckbox}
                >
                  <div className="primary-color-text-l1 fs-14 font-semibold">
                    Single Record's only
                  </div>
                </Checkbox>
              </div>
            </div>
            <div className="mt-3 mb-4 py-5 border-t">
              <div className="flex flex-col mb-4 mr-3">
                <div className="primary-color-text-l1 fs-14 font-semibold mb-2">
                  Select Remarks
                </div>
                <Select
                  name={"Comment_Select"}
                  value={formik.values.Comment_Select}
                  onSelect={(e) => {
                    setFilterValues({ ...filterValues, Comment_Select: e });
                    ctx?.setModuleFilter({
                      ...filterValues,
                      Comment_Select: e,
                    });
                  }}
                  onBlur={(_) => {
                    onBlur("Comment_Select");
                  }}
                  placeholder="Select Remarks"
                  options={commentOptions}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
              width: "100%",
            }}
            className="grid frid-cols-1 px-5 py-2 border-t"
          >
            <div className="flex justify-between items-center">
              <div className=""></div>
              <div className="grid grid-cols-2 items-center">
                <h4
                  className="text-center primary-text-color cursor-pointer"
                  onClick={() => {
                    setOpen(false);
                    setOpenDrawable(false);
                  }}
                >
                  Cancel
                </h4>
                <CustomButton
                  type="submit"
                  title="Apply"
                  className="btn-primary"
                  onClick={formik.handleSubmit}
                  disabled={
                    (filterValues?.pmsSelect &&
                      !filterValues?.pmsPercent &&
                      typeof filterValues?.pmsPercent != "number") ||
                    (filterValues?.smsSelect &&
                      !filterValues?.smsPercent &&
                      typeof filterValues?.smsPercent != "number")
                      ? true
                      : (filterValues?.pmsSelect &&
                          (filterValues?.pmsPercent ||
                            filterValues?.pmsPercent == 0)) ||
                        (filterValues?.smsSelect &&
                          (filterValues?.smsPercent ||
                            filterValues?.smsPercent == 0)) ||
                        filterValues?.steps ||
                        filterValues?.Comment_Select ||
                        filterValues?.single ||
                        filterValues?.location_headSelect ||
                        filterValues?.bankSelect ||
                        filterValues?.productSelect
                      ? false
                      : true
                  }
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </Drawer>
  );
};
