import React, { useContext, useEffect, useState } from "react";
import { Tabs } from "antd";

import { Header } from "./layout/Header";
import { MY_TASK, PAGINATION_DETAILS } from "../helpers/constant";
import {
  EXPORT_REVENUE_THRESHOLD,
  EXPORT_UNRECONCILED,
  INITIATE_BUTTON,
  MY_TASK_TOTAL_COUNT,
  REVENUE_THRESHOLD_DATA,
  UNRECONCILED_DATA,
  UNRECONCILED_ESTIMATE_DATA,
  UNRECONCILED_MATCH_ESTIMATE_EXPORT,
  UNRECONCILED_MATCH_SUBVENTION_EXPORT,
  UNRECONCILED_PMS_SMS_EXPORT,
  UNRECONCILED_SUBVENTION_DATA,
} from "../helpers/api-endpoints";
import { AppContext } from "../helpers/app-context";
import { SkeletonLoader } from "./common/SkeletonLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { DownloadFileAttached, DownloadFileBlob } from "../helpers/functions";
import { MyTaskTable } from "./tableContainer/MyTaskTable";
import MyTaskCardTable from "./tableContainer/MyTaskCardTable";
import {
  columnsUnreconsileEstimate,
  columnsUnreconsileSubvention,
} from "./tableHeaders/MyTaskHeader";
import { CustomButton } from "./common/CustomButton";

export const MyTask = (props) => {
  const ctx = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(
    location?.state?.selectedTab === MY_TASK.REVENUE_THRESHOLD
      ? MY_TASK.REVENUE_THRESHOLD
      : location?.state?.selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
      ? MY_TASK.UNRECONCILED_ESTIMATE
      : location?.state?.selectedTab === MY_TASK.UNRECONCILED_SUBVENTION
      ? MY_TASK.UNRECONCILED_SUBVENTION
      : MY_TASK.UNRECONCILED_ESTIMATE
  );
  const [dataLoading, setDataLoading] = useState(
    ctx.reconStatus.intiate_status !== 1 && true
  );
  const [titleLoading, setTitleLoading] = useState(
    ctx.reconStatus.intiate_status !== 1 && true
  );
  const [unreconciledList, setUnreconciledList] = useState({});
  const [unreconciledEstimateList, setUnreconciledEstimateList] = useState({});
  const [unreconciledSubventionList, setUnreconciledSubventionList] = useState(
    {}
  );
  const [revenueThresholdList, setRevenueThresholdList] = useState({});
  const [totalCountRes, setTotalCountRes] = useState();
  const [pageSize, setPageSize] = useState(
    ctx?.sortAndPagination?.my_tasks?.[selectedTab]?.size
  );
  const [currentPage, setCurrentPage] = useState(
    ctx?.sortAndPagination?.my_tasks?.[selectedTab]?.page
  );
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [initialData, setInitialData] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [fromDateValue, setFromDateValue] = useState("");
  const [toDataValue, setToDataValue] = useState("");
  const [exportBtnDisable, setExportBtnDisable] = useState(false);
  const [tabExportBtn, setTabExportBtn] = useState(false);
  const [exportFullPageLoader, setExportFullPageLoader] = useState(false);
  const [exportProgressBar, setExportProgressBar] = useState(0);
  const [filter, setFilter] = useState({});

  //initially hide no data screen before api call
  const [hideNoData, setHideNoData] = useState(true);
  const [count, setCount] = useState(0);

  // useEffect(() => {
  //   navigate(location.pathname, { replace: false });
  // }, [navigate]);

  useEffect(() => {
    getTotalCount();
    ctx.checkReconsile();
  }, [ctx.isOnline, ctx.reconStatus.intiate_status, location.pathname]);
  // useEffect(() => {
  //   let moduleStateValue = ctx.moduleState;
  //   // check if module state is available then use it

  //   if (selectedTab === MY_TASK.UNRECONCILED_ESTIMATE) {
  //     if (
  //       (moduleStateValue.moduleName === MY_TASK.UNRECONCILED_ESTIMATE &&
  //         (moduleStateValue.currentpage !== 1 ||
  //           moduleStateValue.searchValue ||
  //           moduleStateValue.pageSize !== 10)) ||
  //       moduleStateValue.fromDate ||
  //       moduleStateValue.toDate
  //     ) {
  //       setCurrentPage(moduleStateValue.currentPage);
  //       setSearchValue(moduleStateValue.searchValue);
  //       setPageSize(moduleStateValue.pageSize);
  //       setFromDateValue(moduleStateValue.fromDate);
  //       setToDataValue(moduleStateValue.toDate);
  //       getUnreconciledEstimateData(
  //         moduleStateValue.searchValue,
  //         sortByValue,
  //         orderByValue,
  //         moduleStateValue.currentPage,
  //         moduleStateValue.pageSize,
  //         moduleStateValue.fromDate,
  //         moduleStateValue.toDate,
  //       );
  //       ctx.resetModuleState();
  //     } else {
  //       setCurrentPage(1);
  //       getUnreconciledEstimateData(
  //         searchValue,
  //         sortByValue,
  //         orderByValue,
  //         PAGINATION_DETAILS.CURRENT_PAGE,
  //         PAGINATION_DETAILS.PAGE_SIZE,
  //       );
  //     }
  //   } else if (selectedTab === MY_TASK.UNRECONCILED_SUBVENTION) {
  //     if (
  //       (moduleStateValue.moduleName === MY_TASK.UNRECONCILED_SUBVENTION &&
  //         (moduleStateValue.currentpage !== 1 ||
  //           moduleStateValue.searchValue ||
  //           moduleStateValue.pageSize !== 10)) ||
  //       moduleStateValue.fromDate ||
  //       moduleStateValue.toDate
  //     ) {
  //       setCurrentPage(moduleStateValue.currentPage);
  //       setSearchValue(moduleStateValue.searchValue);
  //       setPageSize(moduleStateValue.pageSize);
  //       setFromDateValue(moduleStateValue.fromDate);
  //       setToDataValue(moduleStateValue.toDate);
  //       getUnreconciledSubventionData(
  //         moduleStateValue.searchValue,
  //         sortByValue,
  //         orderByValue,
  //         moduleStateValue.currentPage,
  //         moduleStateValue.pageSize,
  //         moduleStateValue.fromDate,
  //         moduleStateValue.toDate,
  //       );
  //       ctx.resetModuleState();
  //     } else {
  //       setCurrentPage(1);
  //       getUnreconciledSubventionData(
  //         searchValue,
  //         sortByValue,
  //         orderByValue,
  //         PAGINATION_DETAILS.CURRENT_PAGE,
  //         PAGINATION_DETAILS.PAGE_SIZE,
  //       );
  //     }
  //   } else if (selectedTab === MY_TASK.REVENUE_THRESHOLD) {
  //     if (
  //       moduleStateValue.moduleName === MY_TASK.REVENUE_THRESHOLD &&
  //       (moduleStateValue.currentpage !== 1 ||
  //         moduleStateValue.searchValue ||
  //         moduleStateValue.pageSize !== 10 ||
  //         moduleStateValue.fromDate ||
  //         moduleStateValue.toDate)
  //     ) {
  //       setCurrentPage(moduleStateValue.currentPage);
  //       setSearchValue(moduleStateValue.searchValue);
  //       setPageSize(moduleStateValue.pageSize);
  //       setFromDateValue(moduleStateValue.fromDate);
  //       setToDataValue(moduleStateValue.toDate);
  //       // if this module have filter then add if condition as done in session log
  //       getRevenueThresholdData(
  //         moduleStateValue.searchValue,
  //         sortByValue,
  //         orderByValue,
  //         moduleStateValue.currentPage,
  //         moduleStateValue.pageSize,
  //         moduleStateValue.fromDate,
  //         moduleStateValue.toDate,
  //       );
  //       ctx.resetModuleState();
  //     } else {
  //       setCurrentPage(1);
  //       getRevenueThresholdData(
  //         searchValue,
  //         sortByValue,
  //         orderByValue,
  //         PAGINATION_DETAILS.CURRENT_PAGE,
  //         PAGINATION_DETAILS.PAGE_SIZE,
  //       );
  //     }
  //   }
  // }, [
  //   selectedTab,
  //   ctx.isOnline,
  //   ctx.reconStatus.intiate_status,
  //   location.pathname,
  // ]);

  useEffect(() => {
    if (selectedTab === MY_TASK.UNRECONCILED_ESTIMATE) {
      getUnreconciledEstimateData();
    } else if (selectedTab === MY_TASK.UNRECONCILED_SUBVENTION) {
      getUnreconciledSubventionData();
    } else if (selectedTab === MY_TASK.REVENUE_THRESHOLD) {
      getRevenueThresholdData();
    }
  }, [selectedTab, ctx?.isOnline]);

  // useEffect(() => {
  //   location?.state?.selectedTab === MY_TASK.REVENUE_THRESHOLD &&
  //     onChange(MY_TASK.REVENUE_THRESHOLD);
  // }, [location.pathname]);

  const exportedData = async (
    searchBy = searchValue,
    fromDate = fromDateValue,
    toData = toDataValue
  ) => {
    setExportFullPageLoader(true);
    setExportBtnDisable(true);
    let queryParams = {
      searchItem: searchBy,
      fromDate: fromDate,
      toDate: toData,
    };

    setExportProgressBar(0);
    const progressSteps = [24, 78, 87, 90]; // Arbitrary progress values at different stages
    let currentStep = 0;
    const progressInterval = setInterval(() => {
      setExportProgressBar(progressSteps[currentStep]);
      currentStep += 1;

      if (currentStep >= progressSteps.length) {
        clearInterval(progressInterval); // Stop the progress update once all steps are completed
      }
    }, 1000); // Adjust the interval based on your needs

    const response = await ctx.HttpGet(
      selectedTab === MY_TASK.UNRECONCILED
        ? EXPORT_UNRECONCILED
        : selectedTab === MY_TASK.REVENUE_THRESHOLD && EXPORT_REVENUE_THRESHOLD,
      queryParams,
      true
    );
    // if (response.status) {
    //   DownloadFileBlob(response.data, "My_Task_data_" + selectedTab, "csv");
    //   ctx.showToastAlert({ type: "success", message: response.message });
    // }
    setExportProgressBar(100);
    setExportFullPageLoader(false);
    if (response && typeof response.data === "string") {
      if (!response.headers && response.status == 200) {
        DownloadFileBlob(response.data, "My_Task_data_" + selectedTab, "csv");
        ctx.showToastAlert({ type: "success", message: response.message });
      } else {
        DownloadFileAttached(
          response.data,
          "My_Task_data_" + selectedTab,
          "csv"
        );
        ctx.showToastAlert({
          type: "success",
          message: "Downloaded successfully",
        });
      }
    } else if (typeof response.data === "object" && !response.data.status) {
      ctx.showToastAlert({
        type: "error",
        message: response.data.message,
      });
    }

    setExportBtnDisable(false);
  };

  const exportTabData = async (
    type,
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
    fromDateBy = fromDateValue,
    toDataBy = toDataValue,
    filterValues
  ) => {
    setFilter(filterValues);
    setTabExportBtn(true);
    setExportFullPageLoader(true);
    let queryParams = {
      searchItem: searchBy,
      sortBy: sortBy,
      orderBy: orderBy,
      page: currentpage,
      size: pagesize,
      fromDate: fromDateBy,
      toDate: toDataBy,
      ...filterValues,
    };

    setExportProgressBar(0);
    const progressSteps = [24, 78, 87, 90]; // Arbitrary progress values at different stages
    let currentStep = 0;
    const progressInterval = setInterval(() => {
      setExportProgressBar(progressSteps[currentStep]);
      currentStep += 1;

      if (currentStep >= progressSteps.length) {
        clearInterval(progressInterval); // Stop the progress update once all steps are completed
      }
    }, 1000); // Adjust the interval based on your needs

    const response = await ctx.HttpGet(
      selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
        ? type === "estimate_pms&sms"
          ? UNRECONCILED_PMS_SMS_EXPORT + "Estimate"
          : UNRECONCILED_MATCH_ESTIMATE_EXPORT
        : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION &&
          type === "subvention_pms&sms"
        ? UNRECONCILED_PMS_SMS_EXPORT + "Subvention"
        : UNRECONCILED_MATCH_SUBVENTION_EXPORT,
      type === "estimate_pms&sms" || type === "subvention_pms&sms"
        ? {}
        : queryParams,
      true
    );
    setExportProgressBar(100);
    setExportFullPageLoader(false);
    setTabExportBtn(false);
    if (
      (type === "estimate_pms&sms" || type === "subvention_pms&sms") &&
      response?.status === 200
    ) {
      DownloadFileBlob(response.data?.data, "pms_sms_" + selectedTab, "xlsx");
    } else if (response && typeof response.data === "string") {
      if (!response.headers && response.status == 200) {
        DownloadFileBlob(
          response.data,
          "unreconsiled_data_" + selectedTab,
          "csv"
        );
        ctx.showToastAlert({ type: "success", message: response.message });
      } else {
        DownloadFileAttached(
          response.data,
          "unreconsiled_data_" + selectedTab,
          "csv"
        );
        ctx.showToastAlert({
          type: "success",
          message: "Downloaded successfully",
        });
      }
    } else if (typeof response.data === "object" && !response.data.status) {
      if (selectedTab === MY_TASK.UNRECONCILED_ESTIMATE) {
        getUnreconciledEstimateData(
          searchValue,
          sortByValue,
          orderByValue,
          PAGINATION_DETAILS.CURRENT_PAGE,
          PAGINATION_DETAILS.PAGE_SIZE,
          null,
          null,
          filterValues
        );
      } else if (selectedTab === MY_TASK.UNRECONCILED_SUBVENTION) {
        getUnreconciledSubventionData(
          searchValue,
          sortByValue,
          orderByValue,
          PAGINATION_DETAILS.CURRENT_PAGE,
          PAGINATION_DETAILS.PAGE_SIZE,
          null,
          null,
          filterValues
        );
      }
      ctx.showToastAlert({
        type: "error",
        message: response.data.message,
      });
    }
  };

  const getTotalCount = async () => {
    if (ctx.reconStatus.intiate_status !== 1) {
      setTitleLoading(true);
      const response = await ctx.HttpGet(MY_TASK_TOTAL_COUNT);
      setTitleLoading(false);
      if (response.status) {
        setTotalCountRes(response);
      } else {
        setTotalCountRes({});
      }
    }
  };

  const getUnreconciledEstimateData = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
    fromDateBy = fromDateValue,
    toDataBy = toDataValue,
    filterData = null
  ) => {
    setHideNoData(false);
    setTabExportBtn(true);

    const sortData = ctx?.sortAndPagination?.my_tasks?.[selectedTab];
    let queryParams =
      count === 0
        ? sortData
        : {
            searchItem: searchBy,
            sortBy: sortBy || sortData?.sortBy,
            orderBy: orderBy || sortData?.orderBy,
            page: currentpage,
            size: pagesize,
            fromDate: fromDateBy,
            toDate: toDataBy,
            ...filterData,
          };
    ctx?.setSortAndPagination((prev) => ({
      ...prev,
      my_tasks: {
        ...prev?.my_tasks,
        [selectedTab]: {
          ...queryParams,
          searchItem: null,
          fromDate: null,
          toDate: null,
        },
      },
      status: true,
    }));
    setCount((prev) => prev + 1);

    if (ctx.reconStatus.intiate_status !== 1) {
      setDataLoading(true);
      const response = await ctx.HttpGet(
        UNRECONCILED_ESTIMATE_DATA,
        queryParams
      );
      setDataLoading(false);

      if (response.status) {
        // setUnreconciledEstimateList(dummy);
        let responseArr = response;
        let changedRes = {};
        const { result } = responseArr;
        const arrayWithIndex = result.map((item, index) => ({
          ...item,
          index,
        }));
        changedRes = { ...responseArr, result: arrayWithIndex };

        setUnreconciledEstimateList(changedRes);
        setTabExportBtn(false);
        if (!initialData && Object.keys(response)?.length) {
          setInitialData(true);
        }
      } else {
        setUnreconciledEstimateList({});
        !searchBy &&
          !fromDateBy &&
          !toDataBy &&
          !filterData &&
          totalCountRes?.estimate_total_count > 0 &&
          getUnreconciledEstimateData(
            null,
            "",
            "",
            PAGINATION_DETAILS.CURRENT_PAGE,
            PAGINATION_DETAILS.PAGE_SIZE,
            "",
            ""
          );
      }
    } else {
      setInitialData(false);
    }
  };

  const getUnreconciledSubventionData = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
    fromDateBy = fromDateValue,
    toDataBy = toDataValue,
    filterData = null
  ) => {
    setTabExportBtn(true);
    setHideNoData(false);

    const sortData = ctx?.sortAndPagination?.my_tasks?.[selectedTab];
    let queryParams =
      count === 0
        ? sortData
        : {
            searchItem: searchBy,
            sortBy: sortBy || sortData?.sortBy,
            orderBy: orderBy || sortData?.orderBy,
            page: currentpage,
            size: pagesize,
            fromDate: fromDateBy,
            toDate: toDataBy,
            ...filterData,
          };
    ctx?.setSortAndPagination((prev) => ({
      ...prev,
      my_tasks: {
        ...prev?.my_tasks,
        [selectedTab]: {
          ...queryParams,
          searchItem: null,
          fromDate: null,
          toDate: null,
        },
      },
      status: true,
    }));
    setCount((prev) => prev + 1);

    if (ctx.reconStatus.intiate_status !== 1) {
      setDataLoading(true);
      const response = await ctx.HttpGet(
        UNRECONCILED_SUBVENTION_DATA,
        queryParams
      );
      setDataLoading(false);

      if (response.status) {
        setTabExportBtn(false);
        let responseArr = response;
        let changedRes = {};
        const { result } = responseArr;
        const arrayWithIndex = result.map((item, index) => ({
          ...item,
          index,
        }));
        changedRes = { ...responseArr, result: arrayWithIndex };
        setUnreconciledSubventionList(changedRes);
        // setUnreconciledSubventionList(dummy);
        if (!initialData && Object.keys(response)?.length) {
          setInitialData(true);
        }
      } else {
        setUnreconciledSubventionList({});
        !searchBy &&
          !fromDateBy &&
          !toDataBy &&
          !filterData &&
          totalCountRes?.subvention_total_count > 0 &&
          getUnreconciledSubventionData(
            null,
            "",
            "",
            PAGINATION_DETAILS.CURRENT_PAGE,
            PAGINATION_DETAILS.PAGE_SIZE,
            "",
            ""
          );
      }
    } else {
      setInitialData(false);
    }
  };

  const getRevenueThresholdData = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
    fromDateBy = fromDateValue,
    toDataBy = toDataValue,
    filterData = null
  ) => {
    setHideNoData(false);

    const sortData = ctx?.sortAndPagination?.my_tasks?.[selectedTab];
    let queryParams =
      count === 0
        ? sortData
        : {
            searchItem: searchBy,
            sortBy: sortBy || sortData?.sortBy,
            orderBy: orderBy || sortData?.orderBy,
            page: currentpage,
            size: pagesize,
            fromDate: fromDateBy,
            toDate: toDataBy,
            ...filterData,
          };
    ctx?.setSortAndPagination((prev) => ({
      ...prev,
      my_tasks: {
        ...prev?.my_tasks,
        [selectedTab]: {
          ...queryParams,
          searchItem: null,
          fromDate: null,
          toDate: null,
        },
      },
      status: true,
    }));
    setCount((prev) => prev + 1);

    if (ctx.reconStatus.intiate_status !== 1) {
      setDataLoading(true);
      const response = await ctx.HttpGet(REVENUE_THRESHOLD_DATA, queryParams);
      setDataLoading(false);
      if (response.status) {
        setRevenueThresholdList(response);
        if (!initialData && Object.keys(response)?.length) {
          setInitialData(true);
        }
      } else {
        setRevenueThresholdList({});
        !searchBy &&
          !fromDateBy &&
          !toDataBy &&
          !filterData &&
          totalCountRes?.revenue_threshold_total > 0 &&
          getRevenueThresholdData(
            null,
            "",
            "",
            PAGINATION_DETAILS.CURRENT_PAGE,
            PAGINATION_DETAILS.PAGE_SIZE,
            "",
            ""
          );
      }
    } else {
      setInitialData(false);
    }
  };
  const onChange = (key) => {
    // clearSearchInput();
    setSelectedTab(key);
    setOrderByValue(ctx?.sortAndPagination?.my_tasks?.[key]?.orderBy || "");
    setSortByValue(ctx?.sortAndPagination?.my_tasks?.[key]?.sortBy || "");
    setPageSize(ctx?.sortAndPagination?.my_tasks?.[key]?.size || 10);
    setCurrentPage(ctx?.sortAndPagination?.my_tasks?.[key]?.page || 1);
    setInitialData(false);
    setSearchValue("");
    setFromDateValue("");
    setHideNoData(true);
    setToDataValue("");
    setUnreconciledList({});
    setRevenueThresholdList({});
    setUnreconciledEstimateList({});
    setUnreconciledSubventionList({});
    ctx?.setModuleFilter({
      pmsSelect: null,
      pmsPercent: undefined,
      smsSelect: null,
      smsPercent: undefined,
      steps: null,
      Comment_Select: null,
      single: 0,
    });
  };
  const items = [
    {
      key: MY_TASK.UNRECONCILED_ESTIMATE,
      label: (
        <SkeletonLoader
          loading={titleLoading}
          style={{ height: "20px" }}
        >{`Estimate Matches (${
          totalCountRes?.estimate_total_count
            ? totalCountRes?.estimate_total_count
            : 0
        })`}</SkeletonLoader>
      ),
      children: (
        <MyTaskCardTable
          filter={filter}
          getTotalCount={getTotalCount}
          selectedData={unreconciledEstimateList}
          dataLoading={dataLoading}
          initialData={initialData}
          hideNoData={hideNoData}
          getTableData={getUnreconciledEstimateData}
          setSearchValue={setSearchValue}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          currentPage={currentPage}
          fromDate={fromDateValue}
          toDate={toDataValue}
          setFromDateValue={setFromDateValue}
          setToDataValue={setToDataValue}
          searchValue={searchValue}
          setPageSize={setPageSize}
          selectedTab={selectedTab}
          exportedData={exportedData}
          exportBtnDisable={exportBtnDisable}
          columns={columnsUnreconsileEstimate}
          setOrderByValue={setOrderByValue}
          setSortByValue={setSortByValue}
          sortByValue={sortByValue}
          orderByValue={orderByValue}
          exportTabData={exportTabData}
          fromDateValue={fromDateValue}
          toDataValue={toDataValue}
          tabExportBtn={tabExportBtn}
          parentData={"my-tasks"}
        />
      ),
    },
    {
      key: MY_TASK.UNRECONCILED_SUBVENTION,
      label: (
        <SkeletonLoader
          loading={titleLoading}
          style={{ height: "20px" }}
        >{`Subvention Matches (${
          totalCountRes?.subvention_total_count
            ? totalCountRes?.subvention_total_count
            : 0
        })`}</SkeletonLoader>
      ),
      children: (
        <MyTaskCardTable
          getTotalCount={getTotalCount}
          selectedData={unreconciledSubventionList}
          dataLoading={dataLoading}
          initialData={initialData}
          hideNoData={hideNoData}
          getTableData={getUnreconciledSubventionData}
          setSearchValue={setSearchValue}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setFromDateValue={setFromDateValue}
          setToDataValue={setToDataValue}
          searchValue={searchValue}
          selectedTab={selectedTab}
          exportedData={exportedData}
          exportBtnDisable={exportBtnDisable}
          columns={columnsUnreconsileSubvention}
          setPageSize={setPageSize}
          setOrderByValue={setOrderByValue}
          setSortByValue={setSortByValue}
          sortByValue={sortByValue}
          orderByValue={orderByValue}
          exportTabData={exportTabData}
          tabExportBtn={tabExportBtn}
          fromDateValue={fromDateValue}
          toDataValue={toDataValue}
          filter={filter}
          pageSize={pageSize}
          fromDate={fromDateValue}
          toDate={toDataValue}
          parentData={"my-tasks"}
        />
        // <MyTaskTable
        //   getTotalCount={getTotalCount}
        //   selectedData={unreconciledSubventionList}
        //   dataLoading={dataLoading}
        //   initialData={initialData}
        //   selectedTab={selectedTab}
        //   searchValue={searchValue}
        //   pageSize={pageSize}
        //   currentPage={currentPage}
        //   fromDate={fromDateValue}
        //   toDate={toDataValue}
        //   setSearchValue={setSearchValue}
        //   setCurrentPage={setCurrentPage}
        //   getTableData={getUnreconciledSubventionData}
        //   setFromDateValue={setFromDateValue}
        //   setToDataValue={setToDataValue}
        //   setPageSize={setPageSize}
        //   setOrderByValue={setOrderByValue}
        //   setSortByValue={setSortByValue}
        //   exportedData={exportedData}
        //   exportBtnDisable={exportBtnDisable}
        //   hideNoData={hideNoData}
        // />
      ),
    },
    // {
    //   key: MY_TASK.UNRECONCILED,
    //   label: (
    //     <SkeletonLoader
    //       loading={titleLoading}
    //       style={{ height: "20px" }}
    //     >{`Unreconciled (${
    //       totalCountRes?.unreconciled_total
    //         ? totalCountRes?.unreconciled_total
    //         : 0
    //     })`}</SkeletonLoader>
    //   ),
    //   children: (
    //     <MyTaskTable
    //       getTotalCount={getTotalCount}
    //       selectedData={unreconciledList}
    //       dataLoading={dataLoading}
    //       initialData={initialData}
    //       selectedTab={selectedTab}
    //       searchValue={searchValue}
    //       pageSize={pageSize}
    //       currentPage={currentPage}
    //       fromDate={fromDateValue}
    //       toDate={toDataValue}
    //       setSearchValue={setSearchValue}
    //       setCurrentPage={setCurrentPage}
    //       getTableData={getUnreconciledData}
    //       setFromDateValue={setFromDateValue}
    //       setToDataValue={setToDataValue}
    //       setPageSize={setPageSize}
    //       setOrderByValue={setOrderByValue}
    //       setSortByValue={setSortByValue}
    //       exportedData={exportedData}
    //       exportBtnDisable={exportBtnDisable}
    //       hideNoData={hideNoData}
    //     />
    //   ),
    // },
    {
      key: MY_TASK.REVENUE_THRESHOLD,
      label: (
        <SkeletonLoader
          loading={titleLoading}
          style={{ height: "20px" }}
        >{`Revenue Threshold (${
          totalCountRes?.revenue_threshold_total
            ? totalCountRes?.revenue_threshold_total
            : 0
        })`}</SkeletonLoader>
      ),
      children: (
        <MyTaskTable
          getTotalCount={getTotalCount}
          rowKey={"bank_uid_no"}
          selectedData={revenueThresholdList}
          dataLoading={dataLoading}
          setDataLoading={setDataLoading}
          initialData={initialData}
          selectedTab={selectedTab}
          searchValue={searchValue}
          pageSize={pageSize}
          currentPage={currentPage}
          fromDate={fromDateValue}
          toDate={toDataValue}
          setSearchValue={setSearchValue}
          setCurrentPage={setCurrentPage}
          getTableData={getRevenueThresholdData}
          fromDateValue={fromDateValue}
          toDataValue={toDataValue}
          setFromDateValue={setFromDateValue}
          setToDataValue={setToDataValue}
          setPageSize={setPageSize}
          setOrderByValue={setOrderByValue}
          setSortByValue={setSortByValue}
          exportedData={exportedData}
          exportBtnDisable={exportBtnDisable}
          hideNoData={hideNoData}
          parentData={"my-tasks"}
        />
      ),
    },
  ];
  const HeaderElement = () => (
    <div className="flex">
      My Tasks ({totalCountRes?.total_rows ? totalCountRes?.total_rows : 0})
      {/* {intiate_status !== 2 ? (
        <div className="flex items-end gap-2 m-auto fs-16">
          <CustomButton
            title="Reconciliation In Progress"
            className="btn-primary ml-3 px-5 fs-14"
            disabled={true}
          />
        </div>
      ) : (
        ""
      )} */}
    </div>
  );
  return (
    <>
      {exportFullPageLoader ? (
        <div className="loader flex flex-col">
          <img
            src="/images/upload-loader.gif"
            alt="loader"
            className={`${exportFullPageLoader && "rotate180"}`}
          />
          <h3>Downloading File</h3>
          {exportFullPageLoader && exportProgressBar !== 100 && (
            <div
              className="bg-gray-200 rounded-full dark:bg-gray-700"
              style={{ width: "15%", marginTop: "2rem" }}
            >
              <div
                className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                style={{
                  width: `${exportProgressBar}%`,
                  transition: "500ms all",
                }}
              >
                {exportProgressBar}%
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <Header
            // title={`My Tasks (${
            //   totalCountRes?.total_rows ? totalCountRes?.total_rows : 0
            // })`}
            title={<HeaderElement />}
          />
          <Tabs
            defaultActiveKey={selectedTab}
            items={items}
            onChange={onChange}
            className="custom-tabs"
          />
        </>
      )}
    </>
  );
};
