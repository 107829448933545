import { COLUMNS_WIDTH } from "../../helpers/constant";

export const columnsBank = [
  {
    title: "UID No.",
    dataIndex: "id",
    key: "bankData",
  },
  {
    dataIndex: "Month",
  },
  {
    dataIndex: "Bank",
    ellipsis: true,
    width: COLUMNS_WIDTH.BANK,
  },
  {
    dataIndex: "Product",
    ellipsis: true,
  },
  {
    dataIndex: "Customer_name",
    width: COLUMNS_WIDTH.CUSTOMER_NAME,
    ellipsis: true,
  },
  {
    dataIndex: "App_No",
    ellipsis: true,
    width: COLUMNS_WIDTH.APP_NO,
  },
  {
    dataIndex: "Net_disbursal",
    key: "number",
    checkIsAlphanumeric: true,
    ellipsis: true,
  },
  {
    title: "LI Premium",
    dataIndex: "LI_Premium",
    ellipsis: true,
    key: "number",
    checkIsAlphanumeric: true,
  },

  {
    dataIndex: "Revenue",
    key: "number",
    checkIsAlphanumeric: true,
  },
  {
    dataIndex: "Outstanding",
    key: "number",
    checkIsAlphanumeric: true,
  },
  {
    dataIndex: "Location_head",
    ellipsis: true,
  },
  {
    dataIndex: "Location",
    ellipsis: true,
  },
  {
    dataIndex: "Total_subvention",
    key: "number",
    checkIsAlphanumeric: true,
    width: "160px",
  },
  {
    title: "Estimated Month",
    dataIndex: "Sales_month",
    width: "170px",
  },
  {
    dataIndex: "file_no",
    sorter: false,
  },
  { dataIndex: "Company", ellipsis: true },
  { dataIndex: "Ledger", ellipsis: true },
  { dataIndex: "Sub_product", ellipsis: true },
  { dataIndex: "Nos" },
  { dataIndex: "Payout_percent_gross", width: "200px" },
  { dataIndex: "Payout_percent_net", width: "200px" },
  { dataIndex: "PF_collected_amt", key: "number", width: "200px" },
  { dataIndex: "Credit_note" },
  { dataIndex: "Invoice_no_neft_no", width: "200px" },
  { dataIndex: "Bill_amount", key: "number" },
  { dataIndex: "GST", key: "number" },
  {
    dataIndex: "Invoice_amount",
    key: "number",
    checkIsAlphanumeric: true,
    width: "200px",
  },
  { dataIndex: "Branch" },
  { dataIndex: "TDS", key: "number" },
  { dataIndex: "Collection", key: "number" },
  { title: "Collection 1", dataIndex: "Collection1", key: "number" },
  {
    dataIndex: "Total_collection",
    key: "number",
    checkIsAlphanumeric: true,
    width: "200px",
  },
  { dataIndex: "Collection_date" },
  { title: "Collection date 1", dataIndex: "Collection_date1", width: "200px" },
  { dataIndex: "Manager" },
  { dataIndex: "State" },
  { dataIndex: "Subvention", key: "number" },
  { title: "Subvention 2", dataIndex: "Subvention2", key: "number" },
  { title: "Subvention 3", dataIndex: "Subvention3", key: "number" },
  { dataIndex: "AgentCode" },
  { title: "Agent Code 2", dataIndex: "AgentCode2" },
  { title: "Agent Code 3", dataIndex: "AgentCode3" },
  { dataIndex: "Payee_name" },
  { title: "Payee name 2", dataIndex: "Payee_name2" },
  { title: "Payee name 3", dataIndex: "Payee_name3" },
  {
    title: "Invoice date / receipt date",
    dataIndex: "Invoice_date_receipt_date",
    width: "250px",
  },
  { title: "Paid date 1", dataIndex: "Paid_date1" },
  { title: "Paid date 2", dataIndex: "Paid_date2" },
  { title: "Paid date 3", dataIndex: "Paid_date3" },
  {
    title: "Other subvetion 4",
    dataIndex: "Other_subvention4",
    key: "number",
    checkIsAlphanumeric: true,
    width: "200px",
  },
  {
    title: "Other subvetion 5",
    dataIndex: "Other_subvention5",
    key: "number",
    checkIsAlphanumeric: true,
    width: "200px",
  },
  { title: "Agent Code 4", dataIndex: "AgentCode4" },
  { title: "Agent Code 5", dataIndex: "AgentCode5" },
  { title: "Payee name 4", dataIndex: "Payee_name4" },
  { title: "Payee name 5", dataIndex: "Payee_name5" },
  { title: "Paid date 4", dataIndex: "Paid_date4" },
  { title: "Paid date 5", dataIndex: "Paid_date5" },
  { dataIndex: "Check_is" },
  { dataIndex: "Memo_no" },
  { dataIndex: "PayeeName" },
  { dataIndex: "Paid_date" },
  { dataIndex: "Nature" },
  { dataIndex: "Remarks" },
  { dataIndex: "Pasting_date" },
  { dataIndex: "Posted_by" },
  { dataIndex: "Particular" },
];

export const columnsEstimate = [
  {
    title: "UID No.",
    dataIndex: "id",
    key: "estimateData",
  },
  {
    dataIndex: "App_No",
    ellipsis: true,
    width: COLUMNS_WIDTH.APP_NO,
  },
  {
    dataIndex: "Month",
  },
  {
    dataIndex: "Bank",
    ellipsis: true,
    width: COLUMNS_WIDTH.BANK,
  },
  {
    dataIndex: "Product",
    ellipsis: true,
  },
  {
    dataIndex: "Sub_product",
    ellipsis: true,
  },
  {
    dataIndex: "Customer_name",
    width: COLUMNS_WIDTH.CUSTOMER_NAME,
    ellipsis: true,
  },
  {
    dataIndex: "Location_head",
    ellipsis: true,
  },
  {
    dataIndex: "Manager",
  },
  {
    dataIndex: "Sub_manager",
    ellipsis: true,
  },
  {
    dataIndex: "Telecaller_lead_owner",
    width: "200px",
  },
  {
    dataIndex: "Team_lead",
  },
  {
    dataIndex: "Paid_date",
  },
  {
    dataIndex: "Payee_name",
  },
  {
    dataIndex: "Payout_percent_gross",
    width: "200px",
  },
  {
    title: "DMA Code",
    dataIndex: "Dma_code",
  },
  {
    dataIndex: "Net_disbursal",
    ellipsis: true,
    key: "number",
    checkIsAlphanumeric: true,
  },
  {
    title: "LI Premium",
    dataIndex: "LI_Premium",
    ellipsis: true,
    key: "number",
    checkIsAlphanumeric: true,
  },
  {
    dataIndex: "Disbursal_date",
    width: "200px",
  },
  {
    dataIndex: "Invoice_amount",
    width: "200px",
  },
  {
    dataIndex: "GST",
    key: "number",
  },
  {
    dataIndex: "Revenue",
    key: "number",
    checkIsAlphanumeric: true,
  },
  {
    dataIndex: "Segment",
  },
  {
    dataIndex: "Billed_in",
  },
  {
    dataIndex: "Location",
    ellipsis: true,
  },
  {
    dataIndex: "file_no",
    sorter: false,
  },
  {
    dataIndex: "status",
  },
  {
    dataIndex: "Subvention_amount",
    width: 250,
    key: "number",
    checkIsAlphanumeric: true,
  },
  { dataIndex: "Gender" },
  { dataIndex: "DOB" },
  { dataIndex: "Contact_no" },
  { dataIndex: "Email_id", ellipsis: true },
  {
    dataIndex: "Net_monthly_income",
    key: "number",
    checkIsAlphanumeric: true,
    width: 200,
  },
  { dataIndex: "PAN" },
  { dataIndex: "Aadhar", ellipsis: true },
  { dataIndex: "Pincode" },
  { dataIndex: "City", ellipsis: true },
];

export const columnsSubvention = [
  {
    title: "UID No.",
    dataIndex: "id",
    key: "subventionData",
  },
  {
    dataIndex: "App_No",
    ellipsis: true,
    width: COLUMNS_WIDTH.APP_NO,
  },
  {
    dataIndex: "Agent_code",
  },
  {
    dataIndex: "Month",
  },
  {
    dataIndex: "Bank",
    ellipsis: true,
    width: COLUMNS_WIDTH.BANK,
  },
  {
    dataIndex: "Product",
    ellipsis: true,
  },
  {
    dataIndex: "Channel",
  },
  {
    dataIndex: "Customer_name",
    width: COLUMNS_WIDTH.CUSTOMER_NAME,
    ellipsis: true,
  },
  {
    dataIndex: "Disb",
    key: "number",
    checkIsAlphanumeric: true,
  },
  {
    dataIndex: "Memo_no",
  },

  {
    dataIndex: "Paid_date",
    width: 170,
  },
  {
    dataIndex: "Payee_name",
    width: 250,
    ellipsis: true,
  },

  {
    title: "Payout Percent Net",
    dataIndex: "percent",
    width: "200px",
  },
  {
    dataIndex: "Subvention",
    key: "number",
    checkIsAlphanumeric: true,
  },
  {
    dataIndex: "Location",
    ellipsis: true,
  },
  {
    dataIndex: "Location_head",
    ellipsis: true,
  },
  {
    dataIndex: "file_no",
    sorter: false,
  },
  {
    dataIndex: "status",
  },
];
