import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Header } from "./layout/Header";
import { MappedDetails } from "./common/MappedDetails";
import { AppContext } from "../helpers/app-context";
import { RECONCILED_DATA_DETAILS } from "../helpers/api-endpoints";
import { UNRECONCILED_ACTIVITY } from "../helpers/api-endpoints";
import { MyActivity } from "./common/MyActivity";
import { RECONCILED_DATA_TYPE } from "../helpers/constant";
import { ConvertId } from "../helpers/functions";

export const ReconciledDetails = (props) => {
  const ctx = useContext(AppContext);
  const location = useLocation();
  const [dataLoading, setDataLoading] = useState(true);
  const [detailData, setDetailData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [bankUid, setBankUid] = useState(0);
  const [selectedTab, setSelectedTab] = useState();
  useEffect(() => {
    getReconciledDataDetails();
    get_reconciled_data_activity();
  }, []);

  const getReconciledDataDetails = async () => {
    setDataLoading(true);
    const response = await ctx.HttpGet(
      RECONCILED_DATA_DETAILS + location?.state?.id
    );
    setDataLoading(false);
    if (response.status) {
      setDetailData(response?.reconciled_data[0]);
      response.reconciled_data[0].filter((item) => {
        if (item.name === "uid_no") {
          setBankUid(item.value);
        }
      });
    }
  };
  const get_reconciled_data_activity = async () => {
    setDataLoading(true);
    const response = await ctx.HttpGet(UNRECONCILED_ACTIVITY + location?.state?.id);
    if (response.status) {
      setActivityData(response?.Activity);
    }
    setDataLoading(false);
  };
  return (
    <>
      <Header title={`UID No. #B${bankUid ? ConvertId(bankUid) : "000"}`} backarrow={true} />
      <div className="grid grid-cols-12">
        <MappedDetails
          title={"Mapped Details"}
          mappedBankData={detailData}
          loading={dataLoading}
          style={{ maxHeight: "calc(100vh - 167px)" }}
          bankUid={bankUid}
        />
        <div className="col-span-12 md:col-span-5 border-l">
          <MyActivity
            title={"Activity"}
            data={activityData}
            loading={dataLoading}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
      </div>
    </>
  );
};
