import React, { useState, createContext, useEffect } from "react";
import { message } from "antd";

import {
  AuthenticationError,
  HttpHelper,
  SessionTimeoutError,
  PartialContentError,
} from "./httpHelper";
import {
  PAGINATION_DETAILS,
  SORT_TOKEN,
  STATUS_CODE,
  TOKEN_NAME,
} from "./constant";
import {
  GET_AUTO_RECONCILED_FLAG_UPDATE,
  LOGIN,
  LOGOUT,
} from "./api-endpoints";
import { CustomModal } from "../components/common/CustomModal";
import { INITIATE_BUTTON } from "../helpers/api-endpoints";
export const AppContext = createContext();

const getEncrypted = (str) => {
  return btoa(str);
};

const getDecrypted = (str) => {
  return atob(str);
};

export const AppProvider = ({ children }) => {
  const [baseURL, setBaseURL] = useState(null);
  const [isLogout, setIsLogout] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [refreshPage, setRefreshPage] = useState(false);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [reconcileSettings, setReconcileSettings] = useState({});
  const [currentPathName, setCurrentPathName] = useState(
    window.location.pathname
  );
  const [modalData, setModalData] = useState({
    icon: "",
    showModal: false,
    titleText: "",
    messageText: "",
    showCancelButton: false,
    confirmButtonText: "Okay",
    type: null,
  });
  const [moduleState, setModuleState] = useState({
    moduleName: "",
    searchValue: "",
    currentpage: PAGINATION_DETAILS.CURRENT_PAGE,
    pageSize: PAGINATION_DETAILS.PAGE_SIZE,
    fromDate: "",
    toDate: "",
    fromDate_yyyy_mm_dd: "",
    toDate_yyyy_mm_dd: "",
    sortByValue: "",
    orderByValue: "",
  });

  const [moduleFilter, setModuleFilter] = useState({
    pmsSelect: undefined,
    pmsPercent: undefined,
    smsSelect: undefined,
    smsPercent: undefined,
    steps: undefined,
    single: 0,
    location_headSelect: undefined,
    bankSelect: undefined,
    productSelect: undefined,
  });
  const [reconStatus, setReconStatus] = useState("");
  let sortTemplate = {
    home: {
      bank: {
        searchItem: null,
        sortBy: "",
        orderBy: "",
        page: PAGINATION_DETAILS.CURRENT_PAGE,
        size: PAGINATION_DETAILS.PAGE_SIZE,
        fromDate: "",
        toDate: "",
      },
      estimate: {
        searchItem: null,
        sortBy: "",
        orderBy: "",
        page: PAGINATION_DETAILS.CURRENT_PAGE,
        size: PAGINATION_DETAILS.PAGE_SIZE,
        fromDate: "",
        toDate: "",
      },
      subvention: {
        searchItem: null,
        sortBy: "",
        orderBy: "",
        page: PAGINATION_DETAILS.CURRENT_PAGE,
        size: PAGINATION_DETAILS.PAGE_SIZE,
        fromDate: "",
        toDate: "",
      },
    },
    upload_history: {
      bank: {
        searchItem: null,
        sortBy: "",
        orderBy: "",
        page: PAGINATION_DETAILS.CURRENT_PAGE,
        size: PAGINATION_DETAILS.PAGE_SIZE,
        fromDate: "",
        toDate: "",
      },
      estimate: {
        searchItem: null,
        sortBy: "",
        orderBy: "",
        page: PAGINATION_DETAILS.CURRENT_PAGE,
        size: PAGINATION_DETAILS.PAGE_SIZE,
        fromDate: "",
        toDate: "",
      },
      subvention: {
        searchItem: null,
        sortBy: "",
        orderBy: "",
        page: PAGINATION_DETAILS.CURRENT_PAGE,
        size: PAGINATION_DETAILS.PAGE_SIZE,
        fromDate: "",
        toDate: "",
      },
    },
    duplicate_data: {
      bank: {
        searchItem: null,
        sortBy: "",
        orderBy: "",
        page: PAGINATION_DETAILS.CURRENT_PAGE,
        size: PAGINATION_DETAILS.PAGE_SIZE,
        fromDate: "",
        toDate: "",
      },
      estimate: {
        searchItem: null,
        sortBy: "",
        orderBy: "",
        page: PAGINATION_DETAILS.CURRENT_PAGE,
        size: PAGINATION_DETAILS.PAGE_SIZE,
        fromDate: "",
        toDate: "",
      },
      subvention: {
        searchItem: null,
        sortBy: "",
        orderBy: "",
        page: PAGINATION_DETAILS.CURRENT_PAGE,
        size: PAGINATION_DETAILS.PAGE_SIZE,
        fromDate: "",
        toDate: "",
      },
    },
    my_tasks: {
      "revenue-threshold": {
        searchItem: null,
        sortBy: "",
        orderBy: "",
        page: PAGINATION_DETAILS.CURRENT_PAGE,
        size: PAGINATION_DETAILS.PAGE_SIZE,
        fromDate: "",
        toDate: "",
      },
      "unreconciled-estimate": {
        searchItem: null,
        sortBy: "",
        orderBy: "",
        page: PAGINATION_DETAILS.CURRENT_PAGE,
        size: PAGINATION_DETAILS.PAGE_SIZE,
        fromDate: "",
        toDate: "",
      },
      "unreconciled-subvention": {
        searchItem: null,
        sortBy: "",
        orderBy: "",
        page: PAGINATION_DETAILS.CURRENT_PAGE,
        size: PAGINATION_DETAILS.PAGE_SIZE,
        fromDate: "",
        toDate: "",
      },
    },
    reconciled_data: {
      reconciled_data: {
        searchItem: null,
        sortBy: "",
        orderBy: "",
        page: PAGINATION_DETAILS.CURRENT_PAGE,
        size: PAGINATION_DETAILS.PAGE_SIZE,
        fromDate: "",
        toDate: "",
      },
    },
    collection_data: {
      collection_data: {
        searchItem: null,
        sortBy: "",
        orderBy: "",
        page: PAGINATION_DETAILS.CURRENT_PAGE,
        size: PAGINATION_DETAILS.PAGE_SIZE,
        fromDate: "",
        toDate: "",
      },
    },
    closed_records: {
      bank: {
        searchItem: null,
        sortBy: "",
        orderBy: "",
        page: PAGINATION_DETAILS.CURRENT_PAGE,
        size: PAGINATION_DETAILS.PAGE_SIZE,
        fromDate: "",
        toDate: "",
      },
      estimate: {
        searchItem: null,
        sortBy: "",
        orderBy: "",
        page: PAGINATION_DETAILS.CURRENT_PAGE,
        size: PAGINATION_DETAILS.PAGE_SIZE,
        fromDate: "",
        toDate: "",
      },
      subvention: {
        searchItem: null,
        sortBy: "",
        orderBy: "",
        page: PAGINATION_DETAILS.CURRENT_PAGE,
        size: PAGINATION_DETAILS.PAGE_SIZE,
        fromDate: "",
        toDate: "",
      },
    },
    status: false,
  };
  const [sortAndPagination, setSortAndPagination] = useState(
    localStorage.getItem(SORT_TOKEN)
      ? JSON.parse(getDecrypted(localStorage.getItem(SORT_TOKEN)))
      : sortTemplate
  );

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    if (sortAndPagination?.status) {
      // console.log("SET LOCAL_STORAGE");
      const encodedData = getEncrypted(JSON.stringify(sortAndPagination));
      localStorage.setItem(SORT_TOKEN, encodedData);
    } else if (
      !sortAndPagination?.status &&
      localStorage.getItem(SORT_TOKEN)?.status
    ) {
      // console.log("SET STATE");
      setSortAndPagination(
        JSON.parse(getDecrypted(localStorage.getItem(SORT_TOKEN)))
      );
    }
  }, [sortAndPagination]);

  // console.log(sortAndPagination);

  useEffect(() => {
    if (userInfo?.accessToken) {
      checkReconsile();
      let interval = null;
      //Implementing the setInterval method
      if (!isLogout && reconStatus.intiate_status === 1) {
        interval = setInterval(() => {
          checkReconsile();
        }, 4000);

        //Clearing the interval
        return () => clearInterval(interval);
      } else {
        clearInterval(interval);
      }
    }
  }, [reconStatus?.intiate_status, isLogout]);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  useEffect(() => {
    if (!userInfo && localStorage.getItem(TOKEN_NAME)) {
      setUserInfo(JSON.parse(getDecrypted(localStorage.getItem(TOKEN_NAME))));
    }
  }, [userInfo]);

  const getReconcileSettingsData = async () => {
    const response = await HttpGet(GET_AUTO_RECONCILED_FLAG_UPDATE);
    if (response?.status) {
      setReconcileSettings(response?.data);
    }
  };
  const SetHttpContext = async (aAccessToken) => {
    let webURL = process.env.REACT_APP_API_ENDPOINT;
    // USE global variable which can be changed during depoy rather than build. this is defined in env.js file inside public folder.
    if (window.hasOwnProperty("REACT_APP_API_ENDPOINT")) {
      webURL = window.REACT_APP_API_ENDPOINT;
    }
    setBaseURL(webURL);
    let oContext = {
      BaseURL: webURL,
      AccessToken: localStorage.getItem(TOKEN_NAME)
        ? JSON.parse(getDecrypted(localStorage.getItem(TOKEN_NAME))).accessToken
        : null,
    };
    HttpHelper.SetContext(oContext);
  };

  // global config for toast message
  message.config({
    maxCount: 1,
    duration: 3,
  });

  const HttpGet = async (
    aFunction,
    aParams,
    aFileDownload,
    aIsCritical = false
  ) => {
    try {
      await SetHttpContext();
      // if (aShowLoader) setShowSpinner(true);
      /*       await getAccessToken(api_type);
       */ return await HttpHelper.HttpGet(aFunction, aParams, aFileDownload);
    } catch (e) {
      ErrorHandler(e, aIsCritical);
      // showToastAlert({ type: "error", message: e.message });
    } finally {
      // if (aShowLoader) setShowSpinner(false);
    }
    return false;
  };

  const HttpPost = async (
    aFunction,
    aPayload,
    aIsCritical = false,
    uploadProgress = null
  ) => {
    try {
      await SetHttpContext();
      // if (aShowLoader) setShowSpinner(true);
      /*       await getAccessToken(api_type);
       */
      return await HttpHelper.HttpPost(aFunction, aPayload, uploadProgress);
    } catch (e) {
      ErrorHandler(e, aIsCritical);
    } finally {
      // if (aShowLoader) setShowSpinner(false);
    }
    return false;
  };
  const HttpPut = async (aFunction, aPayload, aIsCritical = false) => {
    try {
      await SetHttpContext();
      // if (aShowLoader) setShowSpinner(true);
      /*       await getAccessToken(api_type);
       */
      return await HttpHelper.HttpPut(aFunction, aPayload);
    } catch (e) {
      ErrorHandler(e, aIsCritical);
      // showToastAlert({ type: "error", message: e.message });
    } finally {
      // if (aShowLoader) setShowSpinner(false);
    }
    return false;
  };

  const HttpDelete = async (aFunction, aParams, aIsCritical = false) => {
    try {
      await SetHttpContext();
      // if (aShowLoader) setShowSpinner(true);
      // await checkRefreshToken();
      return await HttpHelper.HttpDelete(aFunction, aParams);
    } catch (e) {
      ErrorHandler(e, aIsCritical);
      // showToastAlert({ type: "error", message: e.message });
    } finally {
      // if (aShowLoader) setShowSpinner(false);
    }
    return false;
  };

  const ErrorHandler = (errorInfo, isCritical) => {
    // based on the type of error we will set the modal data and show the modal
    // setShowSpinner(false);
    // setIsTimeout(false);
    if (errorInfo instanceof SessionTimeoutError) {
      // setIsTimeout(true);
      setModalData({
        icon: "",
        showModal: true,
        titleText: "Session Expired",
        messageText: errorInfo.message,
        showCancelButton: false,
        confirmButtonText: "Okay",
        type: "SessionTimeout",
      });
    } else if (errorInfo instanceof AuthenticationError && !isLogout) {
      //setIsTimeout(true);
      AppLogout(true, {
        email: userInfo?.email
          ? userInfo?.email
          : localStorage.getItem(TOKEN_NAME)
          ? JSON.parse(getDecrypted(localStorage.getItem(TOKEN_NAME))).email
          : "",
      });
      // setModalData({
      //   icon: "",
      //   showModal: true,
      //   titleText: "",
      //   messageText: errorInfo.message,
      //   secondaryBtnClassName: "btn-primary",
      //   secondaryBtnText: "Ok",
      //   type: "error",
      //   logoutApi: true,
      // });
    } else if (errorInfo instanceof PartialContentError) {
      setModalData({
        icon: "",
        showModal: true,
        titleText: "",
        messageText: errorInfo.message,
        secondaryBtnClassName: "btn-primary",
        secondaryBtnText: "Done",
        type: "error",
      });
    } else if (isCritical) {
      setModalData({
        icon: "",
        showModal: true,
        titleText: "",
        messageText: errorInfo.message,
        secondaryBtnClassName: "btn-primary",
        secondaryBtnText: "Done",
        type: "error",
      });
    } else if (isOnline) {
      showToastAlert({ type: "error", message: errorInfo.message });
    }
  };

  const checkReconsile = async () => {
    const response = await HttpGet(INITIATE_BUTTON);
    if (response.status) {
      setReconStatus(response);
    }
  };

  const AppLogin = async (data, aAccessToken) => {
    let apiResponse = await HttpPost(LOGIN, data);
    if (apiResponse) {
      if (apiResponse.code === STATUS_CODE.SIGNIN_SUCCESS) {
        const accessObj = apiResponse.features;
        let accesses = [];
        if (accessObj) {
          accessObj.forEach((accessSet) =>
            accessSet.apis.forEach((api) => accesses.push(api))
          );
        }
        setUserInfo({
          id: apiResponse.id,
          username: apiResponse.username,
          email: apiResponse.email,
          roles: apiResponse.roles,
          accessToken: apiResponse.access_token,
        });
        const encodedData = getEncrypted(
          JSON.stringify({
            id: apiResponse.id,
            username: apiResponse.username,
            email: apiResponse.email,
            roles: apiResponse.roles,
            accessToken: apiResponse.access_token,
          })
        );
        setIsLogout(false);
        localStorage.setItem(TOKEN_NAME, encodedData);

        // const localData = getEncrypted(JSON.stringify(null));
        // localStorage.setItem(SORT_TOKEN, localData);
        setSortAndPagination(sortTemplate);
        await SetHttpContext(null, apiResponse.access_token);
      } else {
        showToastAlert({ type: "error", message: apiResponse.message });
      }
    }
    return apiResponse;
  };

  /*  const getAccessToken = async () => {
    const getToken = localStorage.getItem("scorer_app");
    await SetHttpContext(getDecrypted(getToken));
  }; */
  const AppLogout = async (callLogoutApi = true, data) => {
    try {
      /*       await getAccessToken(api_type);
       */ if (callLogoutApi) {
        const result = await HttpPost(LOGOUT, data);
        if (result.status === true) {
          showToastAlert({ type: "success", message: result.message });
          window.location.href("/login");
        }
      }
    } catch (e) {
      // showToastAlert({ type: 'error', message: e.message })
    } finally {
      if (callLogoutApi) {
        setIsLogout(true);
      }
      localStorage.clear();
      setUserInfo(null);
      HttpHelper.SetContext(null);
    }
  };

  const getTitle = (id, features) => {
    let result;

    features.forEach((item) => {
      if (item.id === id) {
        result = { title: item.name, subTitle: item.description };
      } else if (item.submenu?.length && !result) {
        const result1 = getTitle(id, item.submenu);
        if (result1) result = result1;
      }
    });
    return result;
  };

  const makeFirstLetterUppercase = (word) => {
    return word?.charAt(0).toUpperCase() + word?.slice(1);
  };

  const showToastAlert = (messageInfo) => {
    if (messageInfo.type === "error") {
      message.error(
        {
          content: messageInfo.message,
          className: "error-toast-msg",
        }
        // 2
      );
    } else if (messageInfo.type === "info") {
      message.success(
        {
          content: messageInfo.message,
          className: "info-toast-msg",
        }
        // 2
      );
    } else {
      message.success(
        {
          content: messageInfo.message,
          className: "success-toast-msg",
        }
        // 2
      );
    }
  };
  const onErrorModalButtonClick = () => {
    setModalData({ ...modalData, showModal: false });
    setRefreshPage(true);
  };

  const resetModuleState = () => {
    setModuleState({
      moduleName: "",
      searchValue: "",
      currentpage: PAGINATION_DETAILS.CURRENT_PAGE,
      pageSize: PAGINATION_DETAILS.PAGE_SIZE,
      fromDate: "",
      toDate: "",
      sortByValue: "",
      orderByValue: "",
    });
  };

  const contextValue = {
    getTitle,
    isLogout,
    AppLogin,
    AppLogout,
    HttpGet,
    HttpPost,
    HttpPut,
    HttpDelete,
    userInfo,
    setUserInfo,
    getEncrypted,
    getDecrypted,
    makeFirstLetterUppercase,
    showToastAlert,
    baseURL,
    refreshPage,
    setRefreshPage,
    moduleState,
    setModuleState,
    resetModuleState,
    isOnline,
    checkReconsile,
    reconStatus,
    moduleFilter,
    setModuleFilter,
    sortAndPagination,
    setSortAndPagination,
    reconcileSettings,
    getReconcileSettingsData,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
      <CustomModal
        icon={modalData.icon}
        isModalOpen={modalData.showModal}
        title={modalData.titleText}
        modalBody={modalData.messageText}
        secondaryBtnClassName={modalData.secondaryBtnClassName}
        secondaryBtnText={modalData.secondaryBtnText}
        handleSecondaryBtn={() => {
          if (modalData.logoutApi) {
            AppLogout(true, {
              email: userInfo?.email
                ? userInfo?.email
                : localStorage.getItem(TOKEN_NAME)
                ? JSON.parse(getDecrypted(localStorage.getItem(TOKEN_NAME)))
                    .email
                : "",
            });
            setModalData({ ...modalData, showModal: false });
          } else {
            onErrorModalButtonClick();
          }
        }}
        setIsModalOpen={() => setModalData({ ...modalData, showModal: false })}
      />
    </AppContext.Provider>
  );
};
