import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "./layout/Header";
import { RevenueTreshold } from "./common/RevenueTreshold";
import { AppContext } from "../helpers/app-context";
import { REVENUE_THRESHOLD_DETAILS } from "../helpers/api-endpoints";
import { RevenueMappedDetails } from "./common/RevenueMappedDetails";
import { ConvertId } from "../helpers/functions";
import { MY_TASK } from "../helpers/constant";

export const RevenueTresholdDetails = (props) => {
  const ctx = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [titleLoading, setTitleLoading] = useState(true);
  const [detailData, setDetailData] = useState([]);

  useEffect(() => {
    getRevenueThresholdDetails();
  }, []);

  const getRevenueThresholdDetails = async () => {
    setTitleLoading(true);
    const response = await ctx.HttpGet(
      REVENUE_THRESHOLD_DETAILS +
        location.state.id +
        "/" +
        location.state.subvention_id,
    );
    setTitleLoading(false);
    if (response.status) {
      setDetailData(response);
    }
  };

  return (
    <>
      <Header
        title={`UID No. #B${
          location?.state?.id ? ConvertId(location?.state?.id) : 0
        }`}
        backarrow={true}
        backArrowClick={() =>
          navigate("/my-tasks", {
            state: {
              selectedTab: MY_TASK.REVENUE_THRESHOLD,
            },
          })
        }
      />
      <div className="grid grid-cols-12">
        <RevenueMappedDetails
          title={"Mapped Details"}
          mappedData={detailData}
          loading={titleLoading}
          style={{ height: "calc(100vh - 167px)" }}
        />
        <div className="col-span-12 md:col-span-5 border-l">
          <RevenueTreshold
            revenueThresholdDetails={detailData}
            loading={titleLoading}
          />
        </div>
      </div>
    </>
  );
};
