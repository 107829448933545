export const CustomUpload = (props) => {
  const { dropdownFlag, uploadFile } = props;
  return (
    <label
      htmlFor="files"
      className={`${
        dropdownFlag
          ? "cursor-pointer"
          : "upload-btn btn w-max mx-auto cursor-pointer"
      }`}
    >
      <input
        type="file"
        id="files"
        accept=".csv"
        className="upload-file"
        onChange={(e) => {uploadFile(e.target.files[0]); e.target.value= ""; }}
      />
      {!dropdownFlag && (
        <label htmlFor="files" className="cursor-pointer">
          <img
            src="/images/icon/upload-file.svg"
            alt="upload"
            className="mr-2"
          />
        </label>
      )}
      {dropdownFlag ? "Upload Data" : " Upload"}
    </label>
  );
};
