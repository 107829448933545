import { Skeleton } from "antd";

export const SkeletonLoader = (props) => {
  const { loading, children, className, shape, style } = props;
  return loading ? (
    <Skeleton.Button
      loading={false}
      active
      className={`${className}`}
      shape={shape}
      style={style}
    />
  ) : (
    children 
  );
};
