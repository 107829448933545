import { COLUMNS_WIDTH } from "../../helpers/constant";

export const ReconciledDataHeader = [
  {
    title: "Reconciliation Status",
    dataIndex: "reconciled_status",
    width: "200px",
    key: "status",
  },
  {
    title: "Reconciliation Date",
    dataIndex: "reconciliation_date",
    width: "200px",
  },
  {
    title: "UID",
    dataIndex: "uid_no",
    key: "bankData",
  },
  {
    dataIndex: "Month",
  },
  {
    dataIndex: "Bank",
    ellipsis: true,
    width: COLUMNS_WIDTH.BANK,
  },
  {
    dataIndex: "Product",
    ellipsis: true,
  },
  {
    dataIndex: "Customer_name",
    width: COLUMNS_WIDTH.CUSTOMER_NAME,
    ellipsis: true,
  },
  {
    dataIndex: "App_No",
    ellipsis: true,
    width: COLUMNS_WIDTH.APP_NO,
  },
  {
    dataIndex: "Net_disbursal",
    key: "number",
    ellipsis: true,
  },
  {
    title: "LI Premium",
    dataIndex: "LI_Premium",
    ellipsis: true,
    key: "number",
  },

  {
    dataIndex: "Revenue",
    key: "number",
  },
  {
    dataIndex: "Outstanding",
    key: "number",
  },
  {
    dataIndex: "Estimated_month",
    width: "170px",
  },
  {
    dataIndex: "Location_head",
    ellipsis: true,
  },
  {
    dataIndex: "Location",
    ellipsis: true,
  },
  {
    dataIndex: "Total_subvention",
    key: "number",
    width: "160px",
  },
  {
    dataIndex: "file_no",
  },
];
