import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AppContext } from "../helpers/app-context";
import { FORGOT_PASSWORD } from "../helpers/api-endpoints";
import { SignInLeftSide } from "./common/SignInLeftSide";

export const EmailSent = (props) => {
  const navigate = useNavigate();
  const ctx = useContext(AppContext);
  const location = useLocation();  

  const resendEmail = async () => {
    const result = await ctx.HttpPut(FORGOT_PASSWORD, location.state);
      if (result.status === true) {
        ctx.showToastAlert({ type: "success", message: result.message });
      }
  }

  return (
    <div
      className="flex items-center h-screen"
    >
      <SignInLeftSide />
      <div className="mx-auto loginscreen-left">
        <div className="font-semibold fs-40 highlight-text-color">Email Sent</div>
        <div className="fs-16 secondary-color-text-l1 pe-5">
          We’ve sent a reset password link to the associated email.
        </div>
        <div className="fs-16 mt-5 secondary-color-text-l1">
          Didn’t receive email?{" "}
          <span className="primary-text-color cursor-pointer pl-4 font-semibold hover:underline" onClick={resendEmail}>Resend</span>
        </div>
        <div
          className="pt-8 primary-text-color fs-14 font-semibold cursor-pointer hover:underline"
          onClick={() => navigate("/login")}
        >
          Back to Login
        </div>
      </div>
    </div>
  );
};
