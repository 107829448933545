import { Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { CustomDataTable } from "./common/CustomDataTable";
import { CustomButton } from "./common/CustomButton";
import { CustomModal } from "./common/CustomModal";
import { AppContext } from "../helpers/app-context";
import { UPLOAD_DATA_TYPE, PAGINATION_DETAILS } from "../helpers/constant";
import { Header } from "./layout/Header";
import {
  columnsBank,
  columnsEstimate,
  columnsSubvention,
} from "./tableHeaders/TempDataHeader";
import {
  DISCARD_TEMP_DATA_BANK,
  DISCARD_TEMP_DATA_ESTIMATE,
  DISCARD_TEMP_DATA_SUBVENTION,
  PROCEED_TEMP_DATA_BANK,
  PROCEED_TEMP_DATA_ESTIMATE,
  PROCEED_TEMP_DATA_SUBVENTION,
  TEMP_DATA_BANK,
  TEMP_DATA_ESTIMATE,
  TEMP_DATA_SUBVENTION,
  EXPORT_TEMP_DATA_BANK,
  EXPORT_TEMP_DATA_SUBVENTION,
  EXPORT_TEMP_DATA_ESTIMATE,
} from "../helpers/api-endpoints";
import { SkeletonLoader } from "./common/SkeletonLoader";
import { DownloadFileAttached, SortedOrderBy } from "../helpers/functions";
import { DownloadFileBlob } from "../helpers/functions";
import { NoData } from "./common/NoData";

export const TempData = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const ctx = useContext(AppContext);
  const [isProcessed, setIsProcessed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [list, setList] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const errorEntriesList = location.state?.errorEntries
    ? location.state?.errorEntries
    : "";
  const [pageSize, setPageSize] = useState(PAGINATION_DETAILS.PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(
    PAGINATION_DETAILS.CURRENT_PAGE,
  );
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [initialData, setInitialData] = useState(false);
  const [proceedActive, setProceedActive] = useState(true);
  const [discardActive, setDiscardActive] = useState(true);
  useEffect(() => {
    if (location.state.eventKey === UPLOAD_DATA_TYPE.BANK) {
      getBankUploadedData();
    } else if (location.state.eventKey === UPLOAD_DATA_TYPE.ESTIMATE) {
      getEstimateUploadedData();
    } else if (location.state.eventKey === UPLOAD_DATA_TYPE.SUBVENTION) {
      getSubventionUploadedData();
    }
  }, []);

  const getBankUploadedData = async (
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
  ) => {
    setDataLoading(true);
    let queryParams = {
      sortBy: sortBy,
      orderBy: orderBy,
      page: currentpage,
      size: pagesize,
    };
    const response = await ctx.HttpGet(
      TEMP_DATA_BANK + location.state.fileId,
      queryParams,
    );
    setDataLoading(false);
    if (response.status) {
      setList(response);
      if (!initialData && Object.keys(response)?.length) {
        setInitialData(true);
      }
    } else {
      setList({});
    }
  };
  const getEstimateUploadedData = async (
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
  ) => {
    setDataLoading(true);
    let queryParams = {
      sortBy: sortBy,
      orderBy: orderBy,
      page: currentpage,
      size: pagesize,
    };
    const response = await ctx.HttpGet(
      TEMP_DATA_ESTIMATE + location.state.fileId,
      queryParams,
    );
    setDataLoading(false);
    if (response.status) {
      setList(response);
      if (!initialData && Object.keys(response)?.length) {
        setInitialData(true);
      }
    } else {
      setList({});
    }
  };
  const getSubventionUploadedData = async (
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
  ) => {
    setDataLoading(true);
    let queryParams = {
      sortBy: sortBy,
      orderBy: orderBy,
      page: currentpage,
      size: pagesize,
    };
    const response = await ctx.HttpGet(
      TEMP_DATA_SUBVENTION + location.state.fileId,
      queryParams,
    );
    setDataLoading(false);
    if (response.status) {
      setList(response);
      if (!initialData && Object.keys(response)?.length) {
        setInitialData(true);
      }
    } else {
      setList({});
    }
  };

  const RenderTooltip = (props) => (
    <div>
      {Object.keys(errorEntriesList).map((item, index) =>
        errorEntriesList[item] ? (
          <div key={index}>
            {item === "disbursalAndPremium" ? (
              <div>
                {errorEntriesList[item]} Net disbursal and LI Premium cannot be
                empty/filled at the same time
              </div>
            ) : item === "Month" ? (
              <div>
                {errorEntriesList[item]} Month cannot be empty/have only
                MMM-YYYY date format
              </div>
            ) : item === "Company" ? (
              <div>
                {errorEntriesList[item]} Company can have only ASDPL,PSPL,TALK
                values
              </div>
            ) : (
              <div className="lowercase">
                {errorEntriesList[item]} {item?.split(/(?=[A-Z])/).join(" ")}{" "}
                invalid
              </div>
            )}
          </div>
        ) : (
          ""
        ),
      )}
    </div>
  );

  const TempTitle = () => (
    <SkeletonLoader
      style={{ width: "200px", height: "24px" }}
      loading={dataLoading}
    >
      {list?.file_number ? (
        <div className="flex items-center">
          <div className="mr-[16px]">{list?.file_number}</div>
          <span className="fs-12 font-semibold px-2 py-[3px] primary-text-color custom-chip capitalize">
            {location.state.eventKey}
          </span>
        </div>
      ) : (
        <div className="capitalize">{location.state.eventKey}</div>
      )}
    </SkeletonLoader>
  );

  const dataDiscarded = async () => {
    let response;
    if (location.state.eventKey === UPLOAD_DATA_TYPE.BANK) {
      response = await ctx.HttpDelete(
        DISCARD_TEMP_DATA_BANK + location.state.fileId,
        {
          Uploaded_by: ctx?.userInfo?.id,
          user_name: ctx?.userInfo?.username,
        },
      );
    } else if (location.state.eventKey === UPLOAD_DATA_TYPE.ESTIMATE) {
      response = await ctx.HttpDelete(
        DISCARD_TEMP_DATA_ESTIMATE + location.state.fileId,
        {
          Uploaded_by: ctx?.userInfo?.id,
          user_name: ctx?.userInfo?.username,
        },
      );
    } else if (location.state.eventKey === UPLOAD_DATA_TYPE.SUBVENTION) {
      response = await ctx.HttpDelete(
        DISCARD_TEMP_DATA_SUBVENTION + location.state.fileId,
        {
          Uploaded_by: ctx?.userInfo?.id,
          user_name: ctx?.userInfo?.username,
        },
      );
    }
    if (response.status) {
      ctx.showToastAlert({ type: "success", message: response.message });
      navigate("/home", {
        state: { eventKey: location.state.eventKey },
      });
    }
  };

  const dataProceed = async () => {
    let errorTempResponse = "";
    if (location.state.eventKey === UPLOAD_DATA_TYPE.BANK) {
      setProceedActive(false);
      setDiscardActive(false);
      const response = await ctx.HttpGet(
        PROCEED_TEMP_DATA_BANK + location.state.fileId,
        {
          Uploaded_by: ctx?.userInfo?.id,
          user_name: ctx?.userInfo?.username,
        },
      );
      if (response.status) {
        setIsProcessed(true);
        setTimeout(() => {
          navigate("/home", {
            state: {
              eventKey: UPLOAD_DATA_TYPE.BANK,
              uploadMessage: response.uploadMessage,
            },
          });
        }, 3000);
      }
      if (list?.total_failure_rows !== 0) {
        errorTempResponse = await ctx.HttpGet(
          EXPORT_TEMP_DATA_BANK + location.state.fileId,
          null,
        );
        if (errorTempResponse.status) {
          DownloadFileBlob(
            errorTempResponse.data,
            "temp_error_" + location.state.eventKey,
          );
        }

        // if (!errorTempResponse.headers && errorTempResponse.status == 200) {
        //   DownloadFileBlob(
        //     errorTempResponse.data,
        //     "temp_error_" + location.state.eventKey,
        //     "csv",
        //   );
        //   ctx.showToastAlert({
        //     type: "success",
        //     message: errorTempResponse.message,
        //   });
        // } else {
        //   DownloadFileAttached(
        //     errorTempResponse.data,
        //     "temp_error_" + location.state.eventKey,
        //   );
        //   ctx.showToastAlert({
        //     type: "success",
        //     message: "Downloaded successfully",
        //   });
        // }
        // DownloadFileBlob(
        //   errorTempResponse.data,
        //   "temp_error_" + location.state.eventKey
        // );
      }

      setProceedActive(true);
    } else if (location.state.eventKey === UPLOAD_DATA_TYPE.ESTIMATE) {
      setProceedActive(false);
      setDiscardActive(false);
      const response = await ctx.HttpGet(
        PROCEED_TEMP_DATA_ESTIMATE + location.state.fileId,
        {
          Uploaded_by: ctx?.userInfo?.id,
          user_name: ctx?.userInfo?.username,
        },
      );

      if (response.status) {
        setIsProcessed(true);
        setTimeout(() => {
          navigate("/home", {
            state: {
              eventKey: UPLOAD_DATA_TYPE.ESTIMATE,
              uploadMessage: response.uploadMessage,
            },
          });
        }, 3000);
      }
      if (list?.total_failure_rows !== 0) {
        errorTempResponse = await ctx.HttpGet(
          EXPORT_TEMP_DATA_ESTIMATE + location.state.fileId,
          null,
        );
        if (errorTempResponse.status) {
          DownloadFileBlob(
            errorTempResponse.data,
            "temp_error_" + location.state.eventKey,
          );
        }
        // if (!errorTempResponse.headers && errorTempResponse.status == 200) {
        //   DownloadFileBlob(
        //     errorTempResponse.data,
        //     "temp_error_" + location.state.eventKey,
        //   );
        //   ctx.showToastAlert({
        //     type: "success",
        //     message: errorTempResponse.message,
        //   });
        // } else {
        //   DownloadFileAttached(
        //     errorTempResponse.data,
        //     "temp_error_" + location.state.eventKey,
        //   );
        //   ctx.showToastAlert({
        //     type: "success",
        //     message: "Downloaded successfully",
        //   });
        // }
      }
      setProceedActive(true);
    } else if (location.state.eventKey === UPLOAD_DATA_TYPE.SUBVENTION) {
      setProceedActive(false);
      setDiscardActive(false);
      const response = await ctx.HttpGet(
        PROCEED_TEMP_DATA_SUBVENTION + location.state.fileId,
        {
          Uploaded_by: ctx?.userInfo?.id,
          user_name: ctx?.userInfo?.username,
        },
      );

      if (response.status) {
        setIsProcessed(true);
        setTimeout(() => {
          navigate("/home", {
            state: {
              eventKey: UPLOAD_DATA_TYPE.SUBVENTION,
              uploadMessage: response.uploadMessage,
            },
          });
        }, 3000);
      }
      if (list?.total_failure_rows !== 0) {
        errorTempResponse = await ctx.HttpGet(
          EXPORT_TEMP_DATA_SUBVENTION + location.state.fileId,
          null,
        );
        if (errorTempResponse.status) {
          DownloadFileBlob(
            errorTempResponse.data,
            "temp_error_" + location.state.eventKey,
          );
        }
        // if (!errorTempResponse.headers && errorTempResponse.status == 200) {
        //   DownloadFileBlob(
        //     errorTempResponse.data,
        //     "temp_error_" + location.state.eventKey,
        //   );
        //   ctx.showToastAlert({
        //     type: "success",
        //     message: errorTempResponse.message,
        //   });
        // } else {
        //   DownloadFileAttached(
        //     errorTempResponse.data,
        //     "temp_error_" + location.state.eventKey,
        //   );
        //   ctx.showToastAlert({
        //     type: "success",
        //     message: "Downloaded successfully",
        //   });
        // }
      }
      setProceedActive(true);
    }
  };

  return (
    <>
      <Header title={<TempTitle />} />

      {dataLoading || initialData ? (
        <>
          <div
            className="px-3 py-2"
            style={{
              boxShadow: "1px 1px 1px #d3d3e2",
            }}
          >
            <SkeletonLoader
              style={{ width: "200px", height: "24px" }}
              loading={dataLoading}
            >
              <div className="fs-20 font-semibold highlight-text-color">
                Successful Data (
                {list?.total_success_rows ? list?.total_success_rows : 0})
              </div>
              {list?.total_failure_rows !== 0 ? (
                <div className="error mt-0 ps-0 font-semibold flex items-center pl-0">
                  Validation error in {list?.total_failure_rows} rows.
                  <Tooltip
                    title={<RenderTooltip />}
                    placement="bottom"
                    overlayInnerStyle={{
                      width: "max-content",
                    }}
                  >
                    <img
                      src="/images/icon/info-icon.svg"
                      alt="info"
                      className="ml-3 cursor-pointer"
                    />
                  </Tooltip>
                </div>
              ) : (
                ""
              )}
            </SkeletonLoader>
          </div>
          <CustomDataTable
            rowKey="id"
            columns={
              location.state.eventKey === UPLOAD_DATA_TYPE.BANK
                ? columnsBank
                : location.state.eventKey === UPLOAD_DATA_TYPE.ESTIMATE
                ? columnsEstimate
                : location.state.eventKey === UPLOAD_DATA_TYPE.SUBVENTION
                ? columnsSubvention
                : ""
            }
            data={
              location.state.eventKey === UPLOAD_DATA_TYPE.BANK
                ? list.Bank_temp_upload_data
                : location.state.eventKey === UPLOAD_DATA_TYPE.ESTIMATE
                ? list.Estimate_temp_upload_data
                : location.state.eventKey === UPLOAD_DATA_TYPE.SUBVENTION
                ? list.Subvention_temp_upload_data
                : ""
            }
            className="mt-1"
            tableHeight={
              list.total_failure_rows
                ? "calc(100vh - 300px)"
                : "calc(100vh - 283px)"
            }
            loading={dataLoading}
            totalRecords={
              location.state.eventKey === UPLOAD_DATA_TYPE.BANK
                ? Number(list.total_success_rows) +
                  Number(list.total_failure_rows)
                : location.state.eventKey === UPLOAD_DATA_TYPE.ESTIMATE
                ? Number(list.total_success_rows) +
                  Number(list.total_failure_rows)
                : location.state.eventKey === UPLOAD_DATA_TYPE.SUBVENTION
                ? Number(list.total_success_rows) +
                  Number(list.total_failure_rows)
                : 0
            }
            pageSize={pageSize}
            currentPage={currentPage}
            onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
              if (sorterOptions && sorterOptions.order) {
                setOrderByValue(SortedOrderBy(sorterOptions.order));
                setSortByValue(sorterOptions.field);
              }
              setPageSize(pageSizeOptions.pageSize);
              setCurrentPage(pageSizeOptions.current);
              location.state.eventKey === UPLOAD_DATA_TYPE.BANK
                ? getBankUploadedData(
                    sorterOptions.order ? sorterOptions.field : "",
                    SortedOrderBy(sorterOptions.order),
                    pageSizeOptions.current,
                    pageSizeOptions.pageSize,
                  )
                : location.state.eventKey === UPLOAD_DATA_TYPE.ESTIMATE
                ? getEstimateUploadedData(
                    sorterOptions.order ? sorterOptions.field : "",
                    SortedOrderBy(sorterOptions.order),
                    pageSizeOptions.current,
                    pageSizeOptions.pageSize,
                  )
                : location.state.eventKey === UPLOAD_DATA_TYPE.SUBVENTION &&
                  getSubventionUploadedData(
                    sorterOptions.order ? sorterOptions.field : "",
                    SortedOrderBy(sorterOptions.order),
                    pageSizeOptions.current,
                    pageSizeOptions.pageSize,
                  );
            }}
          />
          {list.Bank_temp_upload_data?.length > 0 ||
          list.Estimate_temp_upload_data?.length > 0 ||
          list.Subvention_temp_upload_data?.length > 0 ? (
            <div
              className="flex items-center justify-center py-2 absolute bottom-0"
              style={{
                backgroundColor: "var(--ad-highlight-color)",
                color: "var(--ad-primary-color-l1)",
                width: "calc(100vw - 65px)",
              }}
            >
              {isProcessed ? (
                <div className="flex items-center justify-center">
                  <div className="fs-16 flex items-center gap-3">
                    <img
                      width={50}
                      height={50}
                      src="/images/merge-data.gif"
                      alt="merge data"
                      className="relative bottom-2.5"
                    />
                    <span>Merging {list.total_success_rows} rows data</span>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center">
                  <div
                    className="mr-16  fs-16 "
                    style={{ color: "var(--ad-secondary-color-l3)" }}
                  >
                    Proceed to merge successful data and download failed data.
                  </div>
                  <CustomButton
                    className={`mr-6 px-6 discard-btn fs-14 ${
                      discardActive ? "" : "custom-disabled"
                    } `}
                    title="Discard"
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  />
                  <CustomButton
                    title={` ${proceedActive ? "Proceed" : "Processing"} `}
                    className={`proceed-btn fs-14 px-6 ${
                      proceedActive ? "" : "custom-disabled"
                    } `}
                    onClick={() => dataProceed()}
                  />
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <NoData tempFailed={true} title={"Failed to upload data."} />
      )}
      <CustomModal
        title={" "}
        isModalOpen={isModalOpen}
        primaryBtnText={"Discard"}
        setIsModalOpen={setIsModalOpen}
        modalBody={"Are you sure you want to discard this upload?"}
        handlePrimaryBtn={() => {
          dataDiscarded();
          setIsModalOpen(false);
        }}
        handleSecondaryBtn={() => setIsModalOpen(false)}
      />
    </>
  );
};
