import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import { AppContext } from "../helpers/app-context";
import { SignInLeftSide } from "./common/SignInLeftSide";
import { CustomInput } from "./common/CustomInput";
import { CustomButton } from "./common/CustomButton";
import { STATUS_CODE } from "../helpers/constant";

let loginSchema = yup.object().shape({
  email: yup
    .string()
    .max(100, "Email address should not be more than 100 characters")
    .email("Please enter valid email address")
    .required("Email address is required")
    .trim(),
  password: yup.string().required("Password is required").trim(),
});

export const Login = (props) => {
  const navigate = useNavigate();
  const ctx = useContext(AppContext);
  const [loginEmailDetailTextSpacer, setLoginEmailDetailTextSpacer] =
    useState(false);
  const [loginPasswordDetailTextSpacer, setLoginPasswordEmailDetailTextSpacer] =
    useState(false);
  // Formik Initialization
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      type: "Recon",
    },
    validationSchema: loginSchema, // validation schema
    onSubmit: async (values) => {
      const result = await ctx.AppLogin(values);
      if (result.code === STATUS_CODE.SIGNIN_SUCCESS) {
        ctx.showToastAlert({ type: "success", message: result.message });
        navigate("/home");
      }
    },
  });
  useEffect(() => {
    if (formik.values.email.length > 0) {
      setLoginEmailDetailTextSpacer(true);
    }
    if (formik.values.password.length > 0) {
      setLoginPasswordEmailDetailTextSpacer(true);
    }
  }, [formik]);
  return (
    <div className="flex items-center h-screen">
      <SignInLeftSide />
      <div className="mx-auto loginscreen-left min-w-[350px]">
        <div className="font-semibold fs-48 highlight-text-color pb-1.5">
          Hello,
        </div>
        <div
          className={`fs-16 secondary-color-text-l1 pr-5 ${
            loginEmailDetailTextSpacer ? "details-text-login pb-4" : ""
          }`}
        >
          Enter your details to proceed further
        </div>
        <form
          onSubmit={formik.handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              formik.handleSubmit();
            }
          }}
        >
          <div className="mt-8">
            <CustomInput
              type="email"
              id="email"
              label="Email Address"
              name="email"
              errors={
                formik.touched.email && formik.errors.email ? "error" : ""
              }
              onChange={formik.handleChange("email")}
              onBlur={() => {
                formik.handleBlur("email");
                setLoginEmailDetailTextSpacer(false);
              }}
              value={formik.values.email}
              onFocus={() => {
                setLoginEmailDetailTextSpacer(true);
              }}
              heightClass={"min-h-[42px]"}
            />
            <div className="error">
              {formik.touched.email && formik.errors.email}
            </div>
          </div>
          <div
            className={`${
              loginPasswordDetailTextSpacer ? "mt-12" : "mt-[24px]"
            }`}
          >
            <CustomInput
              type="password"
              id="password"
              name="password"
              label="Password"
              errors={
                formik.touched.password && formik.errors.password ? "error" : ""
              }
              onChange={formik.handleChange("password")}
              onBlur={() => {
                formik.handleBlur("password");
                setLoginPasswordEmailDetailTextSpacer(false);
              }}
              onFocus={() => {
                setLoginPasswordEmailDetailTextSpacer(true);
              }}
              val={formik.values.password}
              heightClass={"min-h-[42px]"}
            />
            <div className="error">
              {formik.touched.password && formik.errors.password}
            </div>
          </div>
          <div
            className="mt-4 primary-text-color fs-14 font-semibold cursor-pointer"
            onClick={() => navigate("/forgot-password")}
          >
            Forgot Password ?
          </div>
          <CustomButton
            type="submit"
            title="Login"
            className={`btn-primary w-full ${
              loginPasswordDetailTextSpacer ? "mt-4" : "mt-8"
            }`}
            onClick={formik.handleSubmit}
          />
        </form>
      </div>
    </div>
  );
};
