import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "antd";

import { CustomDataTable } from "./common/CustomDataTable";
import { CustomFilter } from "./common/CustomFilter";
import { Header } from "./layout/Header";
import { MappedDetails } from "./common/MappedDetails";
import { RevenueTreshold } from "./common/RevenueTreshold";
import {
  MY_TASK,
  MY_TASK_DETAILS,
  PAGINATION_DETAILS,
  STATUS_CODE,
  UPLOAD_DATA_TYPE,
} from "../helpers/constant";
import { AppContext } from "../helpers/app-context";
import {
  UNRECONCILED_ESTIMATE_DATA,
  UNRECONCILED_SUBVENTION_DATA,
  UPLOADED_DATA_DETAILS_BANK,
  UPLOADED_DATA_DETAILS_ESTIMATE,
  UPLOADED_DATA_DETAILS_SUBVENTION,
  UNRECONCILED_ACTIVITY,
  UNRECONCILED_ESTIMATE_SUBVENTION_COUNTS,
  UNRECONCILED_MANUAL_RECONCILED,
} from "../helpers/api-endpoints";
import {
  columnsEstimate,
  columnsSubvention,
} from "./tableHeaders/MyTaskHeader";
import { SkeletonLoader } from "./common/SkeletonLoader";
import { NoData } from "./common/NoData";
import { MyActivity } from "./common/MyActivity";
import { ConvertId, SortedOrderBy } from "../helpers/functions";

export const MyTaskDetails = (props) => {
  const ctx = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [titleLoading, setTitleLoading] = useState(true);
  const [detailDataBank, setDetailDataBank] = useState([]);
  const [detailDataEstimate, setDetailDataEstimate] = useState(null);
  const [detailDataSubvention, setDetailDataSubvention] = useState(null);
  const [estimatedData, setEstimatedData] = useState({});
  const [subventionData, setSubventionData] = useState({});
  const [estimateCount, setEstimateCount] = useState(0);
  const [subventionCount, setSubventionCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState(MY_TASK_DETAILS.MATCHES);
  const [selectedMatchedTab, setSelectedMatchedTab] = useState(
    UPLOAD_DATA_TYPE.ESTIMATE,
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [matchPercentage, setMatchPercentage] = useState(null);
  const [pageSize, setPageSize] = useState(PAGINATION_DETAILS.PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(
    PAGINATION_DETAILS.CURRENT_PAGE,
  );
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [activityData, setActivityData] = useState([]);
  const [bankUidNo, setBankUidNo] = useState();
  const [estimateUidNo, setEstimateUidNo] = useState();
  const [subventionUidNo, setSubventionUidNo] = useState();
  // const [orderByValue, setOrderByValue] = useState();
  useEffect(() => {
    if (ctx.refreshPage) {
      // used for unreconsiled data subvention reload after reconsile
      getSubventionList();
      ctx.setRefreshPage(false);
    }
  }, [ctx.refreshPage]);
  useEffect(() => {
    getBankDetails();
    getEstimateSubventionCount();
  }, []);
  useEffect(() => {
    if (selectedTab === MY_TASK_DETAILS.ACTIVITY) {
      get_unreconciled_activity();
    }
  }, [selectedTab]);
  useEffect(() => {
    if (selectedMatchedTab === UPLOAD_DATA_TYPE.ESTIMATE && estimateCount) {
      getEstimatedList();
    } else if (
      selectedMatchedTab === UPLOAD_DATA_TYPE.SUBVENTION &&
      subventionCount
    ) {
      getSubventionList();
    }
  }, [selectedMatchedTab, estimateCount, subventionCount]);

  const getBankDetails = async () => {
    setDataLoading(true);
    const response = await ctx.HttpGet(
      UPLOADED_DATA_DETAILS_BANK + location.state.id,
    );
    setDataLoading(false);
    if (response.status) {
      setDetailDataBank(response.Bank_upload_data[0]);
      response.Bank_upload_data[0].filter((item) => {
        if (item.name === "uid_no") {
          setBankUidNo(item.value);
        }
      });
    }
  };
  const getEstimateSubventionCount = async () => {
    setTitleLoading(true);
    const response = await ctx.HttpGet(
      UNRECONCILED_ESTIMATE_SUBVENTION_COUNTS + location.state.id,
    );
    setTitleLoading(false);
    if (response.status) {
      setEstimateCount(response.Estimate_total_counts);
      setSubventionCount(response.Subvention_total_counts);
      if (
        response.Estimate_total_counts === 0 &&
        response.Subvention_total_counts === 0
      ) {
        setSelectedMatchedTab(UPLOAD_DATA_TYPE.ESTIMATE);
      } else if (
        response.Estimate_total_counts === 0 ||
        estimatedData?.Is_disabled === 1
      ) {
        setSelectedMatchedTab(UPLOAD_DATA_TYPE.SUBVENTION);
      }
    }
  };
  const getEstimateDetails = async (id) => {
    setDataLoading(true);
    const response = await ctx.HttpGet(UPLOADED_DATA_DETAILS_ESTIMATE + id);
    setDataLoading(false);
    if (response.status) {
      setDetailDataEstimate(response.Estimate_upload_data[0]);
      response.Estimate_upload_data[0].filter((item) => {
        if (item.name === "uid_no") {
          setEstimateUidNo(item.value);
        }
      });
    }
  };
  const getSubventionDetails = async (id) => {
    setDataLoading(true);
    const response = await ctx.HttpGet(UPLOADED_DATA_DETAILS_SUBVENTION + id);
    setDataLoading(false);
    if (response.status) {
      setDetailDataSubvention(response.Subvention_upload_data[0]);
      response.Subvention_upload_data[0].filter((item) => {
        if (item.name === "uid_no") {
          setSubventionUidNo(item.value);
        }
      });
    }
  };

  const getEstimatedList = async (
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
  ) => {
    if (!dataLoading) {
      setDataLoading(true);
    }
    let queryParams = {
      sortBy: sortBy,
      orderBy: orderBy,
      page: currentpage,
      size: pagesize,
    };
    const response = await ctx.HttpGet(
      UNRECONCILED_ESTIMATE_DATA + location.state.id,
      queryParams,
    );
    setDataLoading(false);
    if (response.status) {
      setEstimatedData(response);
      getEstimateDetails(response?.Unreconciled_Estimate_view_data[0]?.uid_no);
      setMatchPercentage(
        response.Unreconciled_Estimate_view_data[0]?.match_percent,
      );
    }
  };
  const getSubventionList = async (
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
  ) => {
    setDataLoading(true);
    let queryParams = {
      sortBy: sortBy,
      orderBy: orderBy,
      page: currentpage,
      size: pagesize,
    };
    const response = await ctx.HttpGet(
      UNRECONCILED_SUBVENTION_DATA + location.state.id,
      queryParams,
    );
    setDataLoading(false);
    if (response.status) {
      setSubventionData(response);
      setMatchPercentage(
        response.Unreconciled_Subvention_view_data[0]?.match_percent,
      );
      getSubventionDetails(
        response.Unreconciled_Subvention_view_data[0]?.uid_no,
      );
    }
  };

  const onChange = (key) => {
    // get_unreconciled_activity();
    setSelectedTab(key);
    setOrderByValue("");
    setSortByValue("");
    setPageSize(PAGINATION_DETAILS.PAGE_SIZE);
    setCurrentPage(PAGINATION_DETAILS.CURRENT_PAGE);
    setActivityData([]);
  };
  const get_unreconciled_activity = async () => {
    setDataLoading(true);
    const response = await ctx.HttpGet(
      UNRECONCILED_ACTIVITY + location?.state?.id,
    );
    setDataLoading(false);
    if (response.status) {
      setActivityData(response?.Activity);
    }
  };

  const onChangeMatches = (key) => {
    setSelectedMatchedTab(key);
    setMatchPercentage(null);
    setEstimatedData({});
    setSubventionData({});
  };

  const rowSelection = {
    selectedRowKeys: [
      selectedRowKeys
        ? selectedRowKeys
        : selectedMatchedTab === UPLOAD_DATA_TYPE.ESTIMATE
        ? estimatedData?.Unreconciled_Estimate_view_data &&
          estimatedData?.Unreconciled_Estimate_view_data[0]?.uid_no
        : selectedMatchedTab === UPLOAD_DATA_TYPE.SUBVENTION
        ? subventionData?.Unreconciled_Subvention_view_data &&
          subventionData?.Unreconciled_Subvention_view_data[0]?.uid_no
        : 0,
    ],
    onChange: (selectedItem, selectedRowItem) => {
      setSelectedRowKeys(selectedRowItem[0].uid_no);
      if (selectedMatchedTab === UPLOAD_DATA_TYPE.ESTIMATE) {
        getEstimateDetails(selectedRowItem[0].uid_no);
        setMatchPercentage(selectedRowItem[0].match_percent);
      } else if (selectedMatchedTab === UPLOAD_DATA_TYPE.SUBVENTION) {
        getSubventionDetails(selectedRowItem[0].uid_no);
        setMatchPercentage(selectedRowItem[0].match_percent);
      }
    },
    getCheckboxProps: (record) => ({
      disabled:
        selectedMatchedTab === UPLOAD_DATA_TYPE.ESTIMATE
          ? record.Is_disabled === 1
          : selectedMatchedTab === UPLOAD_DATA_TYPE.SUBVENTION
          ? record.Is_disabled === 5
          : 0,
    }),
  };
  const manualReconcileData = async () => {
    setDataLoading(true);
    let response;
    if (selectedMatchedTab === UPLOAD_DATA_TYPE.ESTIMATE) {
      response = await ctx.HttpGet(
        UNRECONCILED_MANUAL_RECONCILED +
          bankUidNo +
          "/" +
          estimateUidNo +
          "/Estimate",
      );
      setDataLoading(false);
      if (
        response.status &&
        response.code === STATUS_CODE.DATA_FULLY_RECONCILED
      ) {
        ctx.showToastAlert({ type: "success", message: response.message });
        navigate("/my-tasks");
      } else if (
        response.status &&
        response.code === STATUS_CODE.DATA_SUCCESS
      ) {
        ctx.showToastAlert({ type: "success", message: response.message });
        setTimeout(() => {
          getEstimatedList();
          getBankDetails();
          getEstimateSubventionCount();
        }, 500);
      }
    } else if (selectedMatchedTab === UPLOAD_DATA_TYPE.SUBVENTION) {
      response = await ctx.HttpGet(
        UNRECONCILED_MANUAL_RECONCILED +
          bankUidNo +
          "/" +
          subventionUidNo +
          "/Subvnetion",
      );
      setDataLoading(false);
      if (
        response.status &&
        response.code === STATUS_CODE.DATA_FULLY_RECONCILED
      ) {
        ctx.showToastAlert({ type: "success", message: response.message });
        navigate("/my-tasks");
      } else if (
        response.status &&
        response.code === STATUS_CODE.DATA_SUCCESS
      ) {
        ctx.showToastAlert({ type: "success", message: response.message });
        getSubventionList();
        getBankDetails();
        getEstimateSubventionCount();
      }
    }
  };
  const myTaskUnreconciledItems = [
    {
      key: UPLOAD_DATA_TYPE.ESTIMATE,
      label: `Estimate (${estimateCount})`,
      disabled: estimatedData?.Is_disabled === 1,
      children: (
        <div
          className="overflow-hidden"
          style={{ maxHeight: "calc(100vh - 164px)" }}
        >
          {dataLoading || Object.keys(estimatedData)?.length ? (
            <CustomDataTable
              rowKey="uid_no"
              columns={columnsEstimate}
              data={estimatedData?.Unreconciled_Estimate_view_data}
              className="mt-1"
              rowClickable={true}
              tableHeight="calc(100vh - 278px)"
              rowSelectionBox={{
                // onClickRowSelectRadio: true,
                type: "radio",
                enable: true,
              }}
              rowSelection={rowSelection}
              loading={dataLoading}
              totalRecords={estimatedData?.total_page_size}
              pageSize={pageSize}
              currentPage={currentPage}
              onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
                if (sorterOptions && sorterOptions.order) {
                  setOrderByValue(SortedOrderBy(sorterOptions.order));
                  setSortByValue(sorterOptions.field);
                }
                setPageSize(pageSizeOptions.pageSize);
                setCurrentPage(pageSizeOptions.current);
                getEstimatedList(
                  sorterOptions.order ? sorterOptions.field : "",
                  SortedOrderBy(sorterOptions.order),
                  pageSizeOptions.current,
                  pageSizeOptions.pageSize,
                );
              }}
              hidePaginationShowTotal={true}
            />
          ) : (
            <NoData title="No matches to display" />
          )}
        </div>
      ),
    },
    {
      key: UPLOAD_DATA_TYPE.SUBVENTION,
      label: `Subvention (${subventionCount})`,
      disabled: subventionData?.Is_disabled === 5,
      children: (
        <div
          className="overflow-hidden"
          style={{ maxHeight: "calc(100vh - 164px)" }}
        >
          {dataLoading || Object.keys(subventionData)?.length ? (
            <CustomDataTable
              rowKey="uid_no"
              columns={columnsSubvention}
              data={subventionData?.Unreconciled_Subvention_view_data}
              className="mt-1"
              rowClickable={true}
              tableHeight="calc(100vh - 278px)"
              rowSelectionBox={{
                type: "radio",
                enable: true,
              }}
              loading={dataLoading}
              rowSelection={rowSelection}
              hidePaginationShowTotal={true}
              totalRecords={subventionData?.total_page_size}
              pageSize={pageSize}
              currentPage={currentPage}
              onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
                if (sorterOptions && sorterOptions.order) {
                  setOrderByValue(SortedOrderBy(sorterOptions.order));
                  setSortByValue(sorterOptions.field);
                }
                setPageSize(pageSizeOptions.pageSize);
                setCurrentPage(pageSizeOptions.current);
                getSubventionList(
                  sorterOptions.order ? sorterOptions.field : "",
                  SortedOrderBy(sorterOptions.order),
                  pageSizeOptions.current,
                  pageSizeOptions.pageSize,
                );
              }}
            />
          ) : (
            <NoData title="No matches to display" />
          )}
        </div>
      ),
    },
  ];
  const items = [
    {
      key: MY_TASK_DETAILS.MATCHES,
      label: "Matches",
      children: (
        <>
          <Tabs
            activeKey={selectedMatchedTab}
            items={myTaskUnreconciledItems}
            onChange={onChangeMatches}
            className="myTaskMatchsButton"
          />
          {/* <CustomButton title="Filter" onClick={(e) => {
            setFilterDrawer(true)
          }} /> */}
          {filterDrawer && (
            <CustomFilter
              title="Filter"
              placement="right"
              setOpenDrawable={setFilterDrawer}
              data={[]}
              width={683}
            />
          )}
        </>
      ),
    },
    {
      key: MY_TASK_DETAILS.ACTIVITY,
      label: `Activity`,
      children: (
        <MyActivity
          data={activityData}
          loading={dataLoading}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      ),
    },
  ];
  return (
    <>
      <SkeletonLoader loading={titleLoading} style={{ height: "20px" }}>
        <Header
          title={`UID No. #B${bankUidNo ? ConvertId(bankUidNo) : 0}`}
          backarrow={true}
        />
      </SkeletonLoader>
      <div className="grid grid-cols-12">
        <MappedDetails
          title={"Mapped Details"}
          mappedBankData={detailDataBank}
          mappedEstimate={detailDataEstimate}
          mappedSubvention={detailDataSubvention}
          matchpercentage={matchPercentage}
          selectedMatchedTab={selectedMatchedTab}
          loading={dataLoading}
          bankUid={bankUidNo}
          estimateUid={estimateUidNo}
          subventionUid={subventionUidNo}
          manualReconcileData={manualReconcileData}
          reconsileDisabledCheck={
            selectedMatchedTab === UPLOAD_DATA_TYPE.ESTIMATE
              ? estimatedData?.Is_disabled === 1
                ? true
                : false
              : selectedMatchedTab === UPLOAD_DATA_TYPE.SUBVENTION
              ? subventionData?.Is_disabled === 5
                ? true
                : false
              : false
          }
        />

        {/* estimatedData?.Is_disabled === 1 */}
        <div className="col-span-12 md:col-span-5 border-l">
          {location.state.activeTab === MY_TASK.UNRECONCILED ? (
            <Tabs
              defaultActiveKey={selectedTab}
              items={items}
              onChange={onChange}
              className="custom-tabs"
            />
          ) : (
            <RevenueTreshold />
          )}
        </div>
      </div>
    </>
  );
};
