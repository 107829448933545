import { Tooltip } from "antd";
import { NumberConversion } from "../../helpers/functions";
import { COLUMNS_WIDTH } from "../../helpers/constant";

export const columnsUnreconciled = [
  {
    title: "Match",
    dataIndex: "match_Per",
    render: (matchpercentage) => {
      return matchpercentage >= 70 ? (
        <div className="status-chip green">{matchpercentage}%</div>
      ) : matchpercentage >= 50 && matchpercentage < 70 ? (
        <div className="status-chip yellow">{matchpercentage}%</div>
      ) : (
        <div className="status-chip red">{matchpercentage}%</div>
      );
    },
  },
  {
    title: "UID",
    dataIndex: "uid_no",
    key: "bankData",
  },
  {
    dataIndex: "Month",
  },
  {
    dataIndex: "Bank",
    ellipsis: true,
    width: COLUMNS_WIDTH.BANK,
  },
  {
    dataIndex: "Product",
    ellipsis: true,
  },
  {
    dataIndex: "Customer_name",
    width: COLUMNS_WIDTH.CUSTOMER_NAME,
    ellipsis: true,
  },
  {
    dataIndex: "App_No",
    ellipsis: true,
    width: COLUMNS_WIDTH.APP_NO,
  },
  {
    dataIndex: "Net_disbursal",
    key: "number",
    ellipsis: true,
  },
  {
    dataIndex: "LI_Premium",
    ellipsis: true,
    key: "number",
  },
  {
    dataIndex: "Revenue",
    key: "number",
  },
];
export const columnsUnreconsileEstimate = [
  {
    title: "Step",
    dataIndex: "Step",
    render: (text, record, index) => {
      return record?.bank?.Step ? record?.bank?.Step : "-";
    },
  },
  {
    title: "Data Type",
    dataIndex: "dataType",
    sorter: false,
    render: () => {
      return (
        <div>
          <div className="pb-1">Bank</div>
          <div>Estimate</div>
        </div>
      );
    },
  },
  {
    title: "PMS",
    dataIndex: "PMS",
    render: (PMS) => {
      if (PMS) {
        return PMS >= 70 ? (
          <div className="status-chip green">{PMS}%</div>
        ) : PMS >= 50 && PMS < 70 ? (
          <div className="status-chip yellow">{PMS}%</div>
        ) : (
          <div className="status-chip red">{PMS}%</div>
        );
      } else {
        return "-";
      }
    },
  },
  {
    title: "SMS",
    dataIndex: "SMS",
    render: (SMS, record) => {
      if (SMS) {
        return SMS >= 70 ? (
          <div className="status-chip green">{SMS}%</div>
        ) : SMS >= 50 && SMS < 70 ? (
          <div className="status-chip yellow">{SMS}%</div>
        ) : (
          <div className="status-chip red">{SMS}%</div>
        );
      } else {
        return record?.Type == "LAN" ? (
          <div className="status-chip red">0%</div>
        ) : (
          "-"
        );
      }
    },
  },
  {
    title: "App No",
    dataIndex: "App_No",
    width: COLUMNS_WIDTH.APP_NO,
    ellipsis: true,
    render: (text, record, index) => {
      return (
        <>
          <span>
            {record?.bank?.App_No ? (
              <div className="pb-1">
                <Tooltip placement="topLeft" title={record?.bank?.App_No}>
                  {record?.bank?.App_No}
                </Tooltip>
              </div>
            ) : (
              <div className="pb-1">"-"</div>
            )}
          </span>
          <span>
            {record?.estimate.map((data, i) => {
              if (data?.App_No) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.App_No}>
                      {data?.App_No}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  {
    dataIndex: "Bank",
    ellipsis: true,
    width: COLUMNS_WIDTH.BANK,
    render: (text, record, index) => {
      return (
        <>
          <span className="pb-1">
            {record?.bank?.Bank ? (
              <Tooltip placement="topLeft" title={record?.bank?.Bank}>
                {record?.bank?.Bank}
              </Tooltip>
            ) : (
              "-"
            )}
            <br />
          </span>
          <span>
            {record?.estimate.map((data, i) => {
              if (data?.Bank) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.Bank}>
                      {data?.Bank}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  {
    dataIndex: "Product",
    render: (text, record, index) => {
      return (
        <>
          <span className="pb-1">
            {record?.bank?.Product ? (
              <Tooltip placement="topLeft" title={record?.bank?.Product}>
                {record?.bank?.Product}
              </Tooltip>
            ) : (
              "-"
            )}
            <br />
          </span>
          <span>
            {record?.estimate.map((data, i) => {
              if (data?.Product) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.Product}>
                      {data?.Product}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  {
    dataIndex: "Sub_product",
    render: (text, record, index) => {
      return (
        <>
          <span className="pb-1">
            {record?.bank?.Sub_product ? (
              <Tooltip placement="topLeft" title={record?.bank?.Sub_product}>
                {record?.bank?.Sub_product}
              </Tooltip>
            ) : (
              "-"
            )}
            <br />
          </span>
          <span>
            {record?.estimate.map((data, i) => {
              if (data?.Sub_product) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.Sub_product}>
                      {data?.Sub_product}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  {
    dataIndex: "Location_head",
    ellipsis: true,
    render: (text, record, index) => {
      return (
        <>
          <span className="pb-1">
            {record?.bank?.Location_head ? (
              <Tooltip placement="topLeft" title={record?.bank?.Location_head}>
                {record?.bank?.Location_head}
              </Tooltip>
            ) : (
              "-"
            )}
            <br />
          </span>
          <span>
            {record?.estimate.map((data, i) => {
              if (data?.Location_head) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.Location_head}>
                      {data?.Location_head}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  {
    title: "Customer Name",
    dataIndex: "Customer",
    ellipsis: true,
    width: COLUMNS_WIDTH.CUSTOMER_NAME,
    render: (text, record, index) => {
      return (
        <>
          <span className="pb-1">
            {record?.bank?.Customer ? (
              <Tooltip placement="topLeft" title={record?.bank?.Customer}>
                {record?.bank?.Customer}
              </Tooltip>
            ) : (
              "-"
            )}
            <br />
          </span>
          <span>
            {record?.estimate.map((data, i) => {
              if (data?.Customer) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.Customer}>
                      {data?.Customer}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  // {
  //   title: "LI Premium",
  //   dataIndex: "LI_Premium",
  //   ellipsis: true,
  //   render: (text, record, index) => {
  //     return (
  //       <>
  //         <div className="pb-1">
  //           {record?.bank?.LI_Premium
  //             ? NumberConversion(record?.bank?.LI_Premium)
  //             : "-"}
  //         </div>
  //         <div className="pb-1">
  //           {record?.estimate?.LI_Premium
  //             ? NumberConversion(record?.estimate?.LI_Premium)
  //             : "-"}
  //         </div>
  //       </>
  //     );
  //   },
  // },
  // {
  //   title: "Location Head",
  //   dataIndex: "Location_head",
  //   ellipsis: true,
  //   render: (text, record, index) => {
  //     return (
  //       <>
  //         <div className="pb-1">
  //           {record?.bank?.Location_head ? record?.bank?.Location_head : "-"}
  //         </div>
  //         <div className="pb-1">
  //           {record?.estimate?.Location_head
  //             ? record?.estimate?.Location_head
  //             : "-"}
  //         </div>
  //       </>
  //     );
  //   },
  // },
  // {
  //   title: "Manager",
  //   dataIndex: "Manager",
  //   render: (text, record, index) => {
  //     return (
  //       <>
  //         <div className="pb-1">
  //           {record?.bank?.Manager ? record?.bank?.Manager : "-"}
  //         </div>
  //         <div className="pb-1">
  //           {record?.estimate?.Manager ? record?.estimate?.Manager : "-"}
  //         </div>
  //       </>
  //     );
  //   },
  // },
  {
    dataIndex: "Net_disbursal",
    key: "number",
    render: (text, record, index) => {
      return (
        <>
          <span className="pb-1">
            {record?.bank?.Net_disbursal ? (
              <Tooltip
                placement="topLeft"
                title={NumberConversion(record?.bank?.Net_disbursal)}
              >
                {NumberConversion(record?.bank?.Net_disbursal)}
              </Tooltip>
            ) : (
              "-"
            )}
            <br />
          </span>
          <span>
            {record?.estimate.map((data, i) => {
              if (data?.Net_disbursal) {
                return (
                  <div className="pb-1">
                    <Tooltip
                      placement="topLeft"
                      title={NumberConversion(data?.Net_disbursal)}
                    >
                      {NumberConversion(data?.Net_disbursal)}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  // {
  //   title: "Revenue",
  //   dataIndex: "Revenue",
  //   key: "number",
  //   render: (text, record, index) => {
  //     return (
  //       <>
  //         <div className="pb-1">
  //           {record?.bank?.Revenue
  //             ? NumberConversion(record?.bank?.Revenue)
  //             : "-"}
  //         </div>
  //         <div className="pb-1">
  //           {record?.estimate?.Revenue
  //             ? NumberConversion(record?.estimate?.Revenue)
  //             : "-"}
  //         </div>
  //       </>
  //     );
  //   },
  // },
  {
    title: "Sub Manager",
    dataIndex: "Sub_manager",
    ellipsis: true,
    render: (text, record, index) => {
      return (
        <>
          <span className="pb-1">
            {record?.bank?.Sub_manager ? (
              <Tooltip placement="topLeft" title={record?.bank?.Sub_manager}>
                {record?.bank?.Sub_manager}
              </Tooltip>
            ) : (
              "-"
            )}
            <br />
          </span>
          <span>
            {record?.estimate.map((data, i) => {
              if (data?.Sub_manager) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.Sub_manager}>
                      {data?.Sub_manager}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  {
    title: "Month",
    width: 120,
    dataIndex: "Month",
    render: (text, record, index) => {
      return (
        <>
          <span className="pb-1">
            {record?.bank?.month ? (
              <Tooltip placement="topLeft" title={record?.bank?.month}>
                {record?.bank?.month}
              </Tooltip>
            ) : (
              "-"
            )}
            <br />
          </span>
          <span>
            {record?.estimate.map((data, i) => {
              if (data?.month) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.month}>
                      {data?.month}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  // {
  //   title: "Remarks",
  //   width: 120,
  //   dataIndex: "Remarks",
  //   sorter: false,
  //   render: (text, record, index) => {
  //     return (
  //       <>
  //         <span className="pb-1">
  //           {record?.bank?.Remarks ? (
  //             <Tooltip placement="topLeft" title={record?.bank?.Remarks}>
  //               {record?.bank?.Remarks}
  //             </Tooltip>
  //           ) : (
  //             "-"
  //           )}
  //           <br />
  //         </span>
  //         <span>
  //           {record?.estimate.map((data, i) => {
  //             if (data?.Remarks) {
  //               return (
  //                 <div className="pb-1">
  //                   <Tooltip placement="topLeft" title={data?.Remarks}>
  //                     {data?.Remarks}
  //                   </Tooltip>
  //                   {/* <br /> */}
  //                 </div>
  //               );
  //             } else {
  //               return <div className="pb-1"> - {/* <br /> */} </div>;
  //             }
  //           })}
  //         </span>
  //       </>
  //     );
  //   },
  // },
  // {
  //   title: "Remarks_date",
  //   width: 220,
  //   dataIndex: "Remarks_date",
  //   render: (text, record, index) => {
  //     return (
  //       <>
  //         <span className="pb-1">
  //           {record?.bank?.Remarks_date ? (
  //             <Tooltip placement="topLeft" title={record?.bank?.Remarks_date}>
  //               {record?.bank?.Remarks_date}
  //             </Tooltip>
  //           ) : (
  //             "-"
  //           )}
  //           <br />
  //         </span>
  //         <span>
  //           {record?.estimate.map((data, i) => {
  //             if (data?.Remarks_date) {
  //               return (
  //                 <div className="pb-1">
  //                   <Tooltip placement="topLeft" title={data?.Remarks_date}>
  //                     {data?.Remarks_date}
  //                   </Tooltip>
  //                   {/* <br /> */}
  //                 </div>
  //               );
  //             } else {
  //               return <div className="pb-1"> - {/* <br /> */} </div>;
  //             }
  //           })}
  //         </span>
  //       </>
  //     );
  //   },
  // },
  // {
  //   title: "Action",
  //   width: 120,
  //   dataIndex: "reconsile_button_action",
  //   ellipsis: true,
  //   // align: "center",
  //   fixed: "right",
  //   sorter: false,
  // },
];
export const columnsUnreconsileSubvention = [
  {
    title: "Step",
    dataIndex: "Step",
    render: (text, record, index) => {
      return record?.bank?.Step ? record?.bank?.Step : "-";
    },
  },
  {
    title: "Data Type",
    dataIndex: "dataType",
    sorter: false,
    render: () => {
      return (
        <div>
          <div className="pb-1">Bank</div>
          <div>Subvention</div>
        </div>
      );
    },
  },
  {
    title: "PMS",
    dataIndex: "PMS",
    render: (PMS) => {
      if (PMS) {
        return PMS >= 70 ? (
          <div className="status-chip green">{PMS}%</div>
        ) : PMS >= 50 && PMS < 70 ? (
          <div className="status-chip yellow">{PMS}%</div>
        ) : (
          <div className="status-chip red">{PMS}%</div>
        );
      } else {
        return "-";
      }
    },
  },
  {
    title: "SMS",
    dataIndex: "SMS",
    render: (SMS, record) => {
      if (SMS) {
        return SMS >= 70 ? (
          <div className="status-chip green">{SMS}%</div>
        ) : SMS >= 50 && SMS < 70 ? (
          <div className="status-chip yellow">{SMS}%</div>
        ) : (
          <div className="status-chip red">{SMS}%</div>
        );
      } else {
        return record?.Type == "LAN" ? (
          <div className="status-chip red">0%</div>
        ) : (
          "-"
        );
      }
    },
  },
  {
    title: "App No",
    dataIndex: "App_No",
    width: COLUMNS_WIDTH.APP_NO,
    render: (text, record, index) => {
      return (
        <>
          <span>
            {record?.bank?.App_No ? (
              <div className="pb-1">
                <Tooltip placement="topLeft" title={record?.bank?.App_No}>
                  {record?.bank?.App_No}
                </Tooltip>
              </div>
            ) : (
              <div className="pb-1">-</div>
            )}
          </span>
          <span>
            {record?.subvention.map((data, i) => {
              if (data?.App_No) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.App_No}>
                      {data?.App_No}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  {
    dataIndex: "Bank",
    ellipsis: true,
    width: COLUMNS_WIDTH.BANK,
    render: (text, record, index) => {
      return (
        <>
          <span>
            {record?.bank?.Bank ? (
              <div className="pb-1">
                <Tooltip placement="topLeft" title={record?.bank?.Bank}>
                  {record?.bank?.Bank}
                </Tooltip>
              </div>
            ) : (
              <div className="pb-1">-</div>
            )}
          </span>
          <span>
            {record?.subvention.map((data, i) => {
              if (data?.Bank) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.Bank}>
                      {data?.Bank}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  {
    dataIndex: "Product",
    render: (text, record, index) => {
      return (
        <>
          <span>
            {record?.bank?.Product ? (
              <div className="pb-1">
                <Tooltip placement="topLeft" title={record?.bank?.Product}>
                  {record?.bank?.Product}
                </Tooltip>
              </div>
            ) : (
              <div className="pb-1">-</div>
            )}
          </span>
          <span>
            {record?.subvention.map((data, i) => {
              if (data?.Product) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.Product}>
                      {data?.Product}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  {
    dataIndex: "Sub_product",
    render: (text, record, index) => {
      return (
        <>
          <span>
            {record?.bank?.Sub_product ? (
              <div className="pb-1">
                <Tooltip placement="topLeft" title={record?.bank?.Sub_product}>
                  {record?.bank?.Sub_product}
                </Tooltip>
              </div>
            ) : (
              <div className="pb-1">-</div>
            )}
          </span>
          <span>
            {record?.subvention.map((data, i) => {
              if (data?.Sub_product) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.Sub_product}>
                      {data?.Sub_product}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  {
    title: "Location Head",
    dataIndex: "Location_head",
    ellipsis: true,
    render: (text, record, index) => {
      return (
        <>
          <span>
            {record?.bank?.Location_head ? (
              <div className="pb-1">
                <Tooltip
                  placement="topLeft"
                  title={record?.bank?.Location_head}
                >
                  {record?.bank?.Location_head}
                </Tooltip>
              </div>
            ) : (
              <div className="pb-1">-</div>
            )}
          </span>
          <span>
            {record?.subvention.map((data, i) => {
              if (data?.Location_head) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.Location_head}>
                      {data?.Location_head}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  // {
  //   title: "Manager",
  //   dataIndex: "Manager",
  //   render: (text, record, index) => {
  //     return (
  //       <>
  //         <div className="pb-1">
  //           {record?.bank?.Manager ? record?.bank?.Manager : "-"}
  //         </div>
  //         <div>
  //           {record?.subvention?.Manager ? record?.subvention?.Manager : "-"}
  //         </div>
  //       </>
  //     );
  //   },
  // },
  {
    dataIndex: "Net_disbursal",
    render: (text, record, index) => {
      return (
        <>
          <span>
            {record?.bank?.Net_disbursal ? (
              <div className="pb-1">
                <Tooltip
                  placement="topLeft"
                  title={record?.bank?.Net_disbursal}
                >
                  {record?.bank?.Net_disbursal}
                </Tooltip>
              </div>
            ) : (
              <div className="pb-1">-</div>
            )}
          </span>
          <span>
            {record?.subvention.map((data, i) => {
              if (data?.Net_disbursal) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.Net_disbursal}>
                      {data?.Net_disbursal}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  {
    title: "Customer Name",
    dataIndex: "Customer",
    ellipsis: true,
    width: COLUMNS_WIDTH.CUSTOMER_NAME,
    render: (text, record, index) => {
      return (
        <>
          <span>
            {record?.bank?.Customer ? (
              <div className="pb-1">
                <Tooltip placement="topLeft" title={record?.bank?.Customer}>
                  {record?.bank?.Customer}
                </Tooltip>
              </div>
            ) : (
              <div className="pb-1">-</div>
            )}
          </span>
          <span>
            {record?.subvention.map((data, i) => {
              if (data?.Customer) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.Customer}>
                      {data?.Customer}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  {
    dataIndex: "AgentCode",
    sorter: false,
    dynamicColumn: true,
    render: (text, record, index) => {
      return (
        <>
          <span>
            {record?.bank?.AgentCode ? (
              <div className="pb-1">
                <Tooltip placement="topLeft" title={record?.bank?.AgentCode}>
                  {record?.bank?.AgentCode
                    ? record?.bank?.AgentCode2
                      ? record?.bank?.AgentCode3
                        ? record?.bank?.AgentCode4
                          ? record?.bank?.AgentCode5
                            ? record?.bank?.AgentCode5
                            : "-"
                          : "-"
                        : "-"
                      : "-"
                    : "-"}
                </Tooltip>
              </div>
            ) : (
              <div className="pb-1">-</div>
            )}
          </span>
          <span>
            {record?.subvention.map((data, i) => {
              if (data?.AgentCode) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.AgentCode}>
                      {data?.AgentCode}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  {
    dataIndex: "Payee_name",
    width: "250",
    sorter: false,
    ellipsis: true,
    render: (text, record, index) => {
      return (
        <>
          <span>
            {record?.bank?.Payee_name ? (
              <div className="pb-1">
                <Tooltip placement="topLeft" title={record?.bank?.Payee_name}>
                  {record?.bank?.Payee_name
                    ? record?.bank?.Payee_name2
                      ? record?.bank?.Payee_name3
                        ? record?.bank?.Payee_name4
                          ? record?.bank?.Payee_name5
                            ? record?.bank?.Payee_name5
                            : "-"
                          : "-"
                        : "-"
                      : "-"
                    : "-"}
                </Tooltip>
              </div>
            ) : (
              <div className="pb-1">-</div>
            )}
          </span>
          <span>
            {record?.subvention.map((data, i) => {
              if (data?.Payee_name) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.Payee_name}>
                      {data?.Payee_name}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  {
    dataIndex: "Subvention",
    dynamicColumn: true,
    sorter: false,
    render: (text, record, index) => {
      return (
        <>
          <span>
            {record?.bank?.Subvention ? (
              <div className="pb-1">
                <Tooltip placement="topLeft" title={record?.bank?.Subvention}>
                  {record?.bank?.Subvention
                    ? record?.bank?.Subvention2
                      ? record?.bank?.Subvention3
                        ? record?.bank?.Subvention4
                          ? record?.bank?.Subvention5
                            ? record?.bank?.Subvention5
                            : "-"
                          : "-"
                        : "-"
                      : "-"
                    : "-"}
                </Tooltip>
              </div>
            ) : (
              <div className="pb-1">-</div>
            )}
          </span>
          <span>
            {record?.subvention.map((data, i) => {
              if (data?.Subvention) {
                return (
                  <div className="pb-1">
                    <Tooltip
                      placement="topLeft"
                      title={NumberConversion(data?.Subvention)}
                    >
                      {NumberConversion(data?.Subvention)}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  {
    title: "Paid Date",
    dataIndex: "Paid_date",
    sorter: false,
    // ellipsis: true,
    render: (text, record, index) => {
      return (
        <>
          <span>
            {record?.bank?.Paid_date ? (
              <div className="pb-1">
                <Tooltip placement="topLeft" title={record?.bank?.Paid_date}>
                  {record?.bank?.Paid_date
                    ? record?.bank?.Paid_date2
                      ? record?.bank?.Paid_date3
                        ? record?.bank?.Paid_date4
                          ? record?.bank?.Paid_date5
                            ? record?.bank?.Paid_date5
                            : "-"
                          : "-"
                        : "-"
                      : "-"
                    : "-"}
                </Tooltip>
              </div>
            ) : (
              <div className="pb-1">-</div>
            )}
          </span>
          <span>
            {record?.subvention.map((data, i) => {
              if (data?.Paid_date) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.Paid_date}>
                      {data?.Paid_date}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  {
    dataIndex: "Memo_no",
    width: "250",
    sorter: false,
    ellipsis: true,
    render: (text, record, index) => {
      return (
        <>
          <span>
            {record?.bank?.Memo_no ? (
              <div className="pb-1">
                <Tooltip placement="topLeft" title={record?.bank?.Memo_no}>
                  {record?.bank?.Memo_no
                    ? record?.bank?.Memo_no2
                      ? record?.bank?.Memo_no3
                        ? record?.bank?.Memo_no4
                          ? record?.bank?.Memo_no5
                            ? record?.bank?.Memo_no5
                            : "-"
                          : "-"
                        : "-"
                      : "-"
                    : "-"}
                </Tooltip>
              </div>
            ) : (
              <div className="pb-1">-</div>
            )}
          </span>
          <span>
            {record?.subvention.map((data, i) => {
              if (data?.Memo_no) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.Memo_no}>
                      {data?.Memo_no}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  {
    title: "Month",
    width: 120,
    dataIndex: "Month",
    render: (text, record, index) => {
      return (
        <>
          <span>
            {record?.bank?.Month ? (
              <div className="pb-1">
                <Tooltip placement="topLeft" title={record?.bank?.Month}>
                  {record?.bank?.Month}
                </Tooltip>
              </div>
            ) : (
              <div className="pb-1">-</div>
            )}
          </span>
          <span>
            {record?.subvention.map((data, i) => {
              if (data?.Month) {
                return (
                  <div className="pb-1">
                    <Tooltip placement="topLeft" title={data?.Month}>
                      {data?.Month}
                    </Tooltip>
                    {/* <br /> */}
                  </div>
                );
              } else {
                return <div className="pb-1"> - {/* <br /> */} </div>;
              }
            })}
          </span>
        </>
      );
    },
  },
  // {
  //   title: "Remarks",
  //   width: 120,
  //   dataIndex: "Remarks",
  //   sorter: false,
  //   render: (text, record, index) => {
  //     return (
  //       <>
  //         <span>
  //           {record?.bank?.Remarks ? (
  //             <div className="pb-1">
  //               <Tooltip placement="topLeft" title={record?.bank?.Remarks}>
  //                 {record?.bank?.Remarks}
  //               </Tooltip>
  //             </div>
  //           ) : (
  //             <div className="pb-1">-</div>
  //           )}
  //         </span>
  //         <span>
  //           {record?.subvention.map((data, i) => {
  //             if (data?.Remarks) {
  //               return (
  //                 <div className="pb-1">
  //                   <Tooltip placement="topLeft" title={data?.Remarks}>
  //                     {data?.Remarks}
  //                   </Tooltip>
  //                   {/* <br /> */}
  //                 </div>
  //               );
  //             } else {
  //               return <div className="pb-1"> - {/* <br /> */} </div>;
  //             }
  //           })}
  //         </span>
  //       </>
  //     );
  //   },
  // },
  // {
  //   title: "Remarks_date",
  //   width: 220,
  //   dataIndex: "Remarks_date",
  //   render: (text, record, index) => {
  //     return (
  //       <>
  //         <span>
  //           {record?.bank?.Remarks_date ? (
  //             <div className="pb-1">
  //               <Tooltip placement="topLeft" title={record?.bank?.Remarks_date}>
  //                 {record?.bank?.Remarks_date}
  //               </Tooltip>
  //             </div>
  //           ) : (
  //             <div className="pb-1">-</div>
  //           )}
  //         </span>
  //         <span>
  //           {record?.subvention.map((data, i) => {
  //             if (data?.Remarks_date) {
  //               return (
  //                 <div className="pb-1">
  //                   <Tooltip placement="topLeft" title={data?.Remarks_date}>
  //                     {data?.Remarks_date}
  //                   </Tooltip>
  //                   {/* <br /> */}
  //                 </div>
  //               );
  //             } else {
  //               return <div className="pb-1"> - {/* <br /> */} </div>;
  //             }
  //           })}
  //         </span>
  //       </>
  //     );
  //   },
  // },
  // {
  //   title: "Action",
  //   width: 120,
  //   dataIndex: "reconsile_button_action",
  //   ellipsis: true,
  //   sorter: false,
  //   // align: "center",
  //   fixed: "right",
  // },
];

export const columnsRevenue = [
  {
    title: "UID",
    dataIndex: "bank_uid_no",
    key: "bankData",
  },
  {
    title: "Subvention id",
    dataIndex: "subvention_uid_no",
    key: "subventionData",
  },
  {
    dataIndex: "Month",
  },
  {
    dataIndex: "Bank",
    ellipsis: true,
    width: COLUMNS_WIDTH.BANK,
  },
  {
    dataIndex: "Product",
    ellipsis: true,
  },
  {
    dataIndex: "Customer_name",
    width: COLUMNS_WIDTH.CUSTOMER_NAME,
    ellipsis: true,
  },
  {
    dataIndex: "App_No",
    ellipsis: true,
    width: COLUMNS_WIDTH.APP_NO,
  },
  {
    dataIndex: "Net_disbursal",
    key: "number",
    ellipsis: true,
  },
  {
    title: "%",
    dataIndex: "Payout_percent_net",
    ellipsis: true,
  },
  {
    dataIndex: "Location_head",
    ellipsis: true,
  },
  {
    dataIndex: "Location",
    ellipsis: true,
  },
  {
    dataIndex: "Subvention",
    key: "number",
  },
];

export const columnsEstimate = [
  {
    title: "Match %",
    dataIndex: "match_percent",
    render: (matchpercentage) => {
      return matchpercentage >= 70 ? (
        <div className="status-chip green">{matchpercentage}%</div>
      ) : matchpercentage >= 50 && matchpercentage < 70 ? (
        <div className="status-chip yellow">{matchpercentage}%</div>
      ) : (
        <div className="status-chip red">{matchpercentage}%</div>
      );
    },
  },
  {
    title: "UID No",
    dataIndex: "uid_no",
    key: "estimateData",
  },
  {
    dataIndex: "Month",
  },
  {
    dataIndex: "Bank",
    ellipsis: true,
    width: COLUMNS_WIDTH.BANK,
  },
];
export const columnsSubvention = [
  {
    title: "Match %",
    dataIndex: "match_percent",
    render: (matchpercentage) => {
      return matchpercentage >= 70 ? (
        <div className="status-chip green">{matchpercentage}%</div>
      ) : matchpercentage >= 50 && matchpercentage < 70 ? (
        <div className="status-chip yellow">{matchpercentage}%</div>
      ) : (
        <div className="status-chip red">{matchpercentage}%</div>
      );
    },
  },
  {
    title: "UID No",
    dataIndex: "uid_no",
    key: "subventionData",
  },
  {
    dataIndex: "Bank",
    ellipsis: true,
    width: COLUMNS_WIDTH.BANK,
  },
  {
    dataIndex: "Month",
  },
  {
    dataIndex: "App_no",
    ellipsis: true,
    width: COLUMNS_WIDTH.APP_NO,
  },
  {
    dataIndex: "Customer_name",
    ellipsis: true,
    width: COLUMNS_WIDTH.CUSTOMER_NAME,
  },
  {
    dataIndex: "Disb",
    key: "number",
  },
  {
    dataIndex: "Product",
    ellipsis: true,
  },
];
