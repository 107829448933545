import React, { useContext, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../helpers/app-context";
import { CustomInput } from "./common/CustomInput";
import { SignInLeftSide } from "./common/SignInLeftSide";
import { CustomButton } from "./common/CustomButton";
import { RESET_PASSWORD } from "../helpers/api-endpoints";

let resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password should have min 8 characters.")
    .max(20, "Password should have maximum 20 characters.")
    .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase character")
    .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase character")
    .matches(/^(?=.*[0-9])/, "Must contain at least one number")
    .matches(/^(?=.*[!@#%&])/, "Must contain at least one special character"),
  confirmpassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Password does not match"),
  resetLink: yup.string(),
});

export const ResetPassword = (props) => {
  const ctx = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [resetPasswordTextSpacer, setResetPasswordTextSpacer] = useState(false);
  const [resetPasswordConfirmTextSpacer, setResetPasswordConfirmTextSpacer] =
    useState(false);

  // Formik Initialization
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirmpassword: "",
      resetLink: location.pathname.split("/")[2],
    },
    validationSchema: resetPasswordSchema, // validation schema
    onSubmit: async (values) => {
      const result = await ctx.HttpPut(RESET_PASSWORD, values);
      if (result.status === true) {
        ctx.showToastAlert({ type: "success", message: result.message });
        navigate("/reset-password-success");
      } else if (result.status === false) {
        ctx.showToastAlert({ type: "error", message: result.message });
      }
    },
  });
  useEffect(() => {
    if (formik.values.password.length > 0) {
      setResetPasswordTextSpacer(true);
    }
    if (formik.values.confirmpassword.length > 0) {
      setResetPasswordConfirmTextSpacer(true);
    }
  }, [formik]);
  return (
    <div className="flex items-center h-screen">
      <SignInLeftSide />
      <div className="mx-auto loginscreen-left min-w-[350px]">
        <div className="font-semibold fs-40 highlight-text-color">
          Reset Password
        </div>
        <div
          className={`fs-16 secondary-color-text-l1 pe-5 ${
            resetPasswordTextSpacer ? "pb-4" : ""
          } `}
        >
          Set your New password
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-7">
            <CustomInput
              type="password"
              id="password"
              label="New Password"
              name="password"
              errors={
                formik.touched.password && formik.errors.password ? "error" : ""
              }
              onChange={formik.handleChange("password")}
              onBlur={() => {
                formik.handleBlur("password");
                setResetPasswordTextSpacer(false);
              }}
              value={formik.values.password}
              onFocus={() => {
                setResetPasswordTextSpacer(true);
              }}
              heightClass={"min-h-[42px]"}
            />
            <div className="error">
              {formik.touched.password && formik.errors.password}
            </div>
          </div>
          <div
            className={`${
              resetPasswordConfirmTextSpacer ? "mt-12" : "mt-[24px]"
            }`}
          >
            <CustomInput
              type="password"
              id="confirmpassword"
              name="confirmpassword"
              label="Confirm New Password"
              errors={
                formik.touched.confirmpassword && formik.errors.confirmpassword
                  ? "error"
                  : ""
              }
              onChange={formik.handleChange("confirmpassword")}
              onBlur={() => {
                formik.handleBlur("confirmpassword");
                setResetPasswordConfirmTextSpacer(false);
              }}
              val={formik.values.confirmpassword}
              onFocus={() => {
                setResetPasswordConfirmTextSpacer(true);
              }}
              heightClass={"min-h-[42px]"}
            />
            <div className="error">
              {formik.touched.confirmpassword && formik.errors.confirmpassword}
            </div>
          </div>
          <CustomButton
            type="submit"
            title="Reset"
            className="btn-primary w-full mt-10"
            onClick={formik.handleSubmit}
          />
        </form>
      </div>
    </div>
  );
};
