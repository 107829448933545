export const LOGIN = "user/auth/signin";
export const LOGOUT = "user/auth/logout";
export const FORGOT_PASSWORD = "user/auth/forgot-password";
export const RESET_PASSWORD = "user/auth/reset-password";

export const UPLOAD_FILE_BANK = "recon/bank/bank_upload_file";
export const UPLOAD_FILE_ESTIMATE = "recon/estimate/estimate_upload_file";
export const UPLOAD_FILE_SUBVENTION = "recon/subvention/subvention_upload_file";
export const UPLOAD_DATA_TOTAL_COUNTS = "recon/bank/upload_total_counts";
export const UPLOADED_DATA_VIEW_BANK = "recon/bank/bank_upload";
export const UPLOADED_DATA_VIEW_ESTIMATE = "recon/estimate/estimate_upload";
export const UPLOADED_DATA_VIEW_SUBVENTION =
  "recon/subvention/subvention_upload";

export const UPLOADED_DATA_DETAILS_BANK = "recon/bank/bank_upload_details/";
export const UPLOADED_DATA_DETAILS_ESTIMATE =
  "recon/estimate/estimate_upload_details/";
export const UPLOADED_DATA_DETAILS_SUBVENTION =
  "recon/subvention/subvention_upload_details/";

export const UPLOAD_HISTORY_TOTAL_COUNTS = "recon/bank/history_total_counts";
export const UPLOADED_HISTORY_BANK = "recon/bank/bank_history";
export const UPLOADED_HISTORY_ESTIMATE = "recon/estimate/estimate_history";
export const UPLOADED_HISTORY_SUBVENTION =
  "recon/subvention/subvention_history";

export const TEMP_DATA_BANK = "recon/bank/bank_temp_upload/";
export const TEMP_DATA_ESTIMATE = "recon/estimate/estimate_temp_upload/";
export const TEMP_DATA_SUBVENTION = "recon/subvention/subvention_temp_upload/";
export const DISCARD_TEMP_DATA_BANK = "recon/bank/bank_discard_upload/";
export const DISCARD_TEMP_DATA_ESTIMATE =
  "recon/estimate/estimate_discard_upload/";
export const DISCARD_TEMP_DATA_SUBVENTION =
  "recon/subvention/subvention_discard_upload/";
export const PROCEED_TEMP_DATA_BANK = "recon/bank/bank_proceed_upload/";
export const PROCEED_TEMP_DATA_ESTIMATE =
  "recon/estimate/estimate_proceed_upload/";
export const PROCEED_TEMP_DATA_SUBVENTION =
  "recon/subvention/subvention_proceed_upload/";

export const RECONCILED_DATA = "recon/bank/reconciled_data";
export const RECONCILED_DATA_DETAILS = "recon/bank/reconciled_details/";
export const INITIATE_RECONCILIATION = "recon/bank/recon_engine";

export const UNRECONCILED_DATA = "recon/bank/unreconciled_views";
export const REVENUE_THRESHOLD_DATA = "recon/bank/revenue_threshold_views";
export const REVENUE_THRESHOLD_APPROVE_REJECT =
  "recon/bank/revenue_threshold_approval_reject_api/";
export const REVENUE_THRESHOLD_DETAILS =
  "recon/bank/revenue_threshold_details/";
export const MY_TASK_TOTAL_COUNT = "recon/bank/unreconciled_threshold_counts";
export const UNRECONCILED_ESTIMATE_DATA =
  "recon/bank/unreconciled_estimate_views";
export const UNRECONCILED_SUBVENTION_DATA =
  "recon/bank/unreconciled_subvention_views";
export const UNRECONCILED_ESTIMATE_VIEWS_DETAILS =
  "recon/bank/unreconciled_estimate_views_details/";
export const UNRECONCILED_SUBVENTION_VIEWS_DETAILS =
  "recon/bank/unreconciled_subvention_views_details/";
export const UNRECONCILED_ESTIMATE_SUBVENTION_COUNTS =
  "recon/bank/unreconciled_estimate_subvention_counts/";
export const UNRECONCILED_MANUAL_RECONCILED = "recon/bank/reconcile_data/";

export const INITIATE_BUTTON = "recon/bank/recon_initiate_button";

export const UNRECONCILED_MANUAL_RECONCILED_ESTIMATE =
  "recon/bank/reconcile_data/Estimate";
export const UNRECONCILED_ESTIMATE_REMARKS = "recon/bank/remarks_save/Estimate";
export const UNRECONCILED_SUBVENTION_REMARKS =
  "recon/bank/remarks_save/Subvention";
export const UNRECONCILED_ESTIMATE_REMARKS_HISTORY =
  "recon/bank/remarks_history/Estimate";
export const UNRECONCILED_SUBVENTION_REMARKS_HISTORY =
  "recon/bank/remarks_history/Subvention";

export const UNRECONCILED_MANUAL_RECONCILED_SUBVENTION =
  "recon/bank/reconcile_data/Subvention";

export const UNRECONCILED_MATCH_ESTIMATE_EXPORT =
  "recon/bank/unreconciled_match_estimate_export";

export const UNRECONCILED_MATCH_SUBVENTION_EXPORT =
  "recon/bank/unreconciled_match_subvention_export";

export const UNRECONCILED_PMS_SMS_EXPORT = "recon/bank/pms_sms_report/";

export const CLOSED_RECORDS_COUNT = "recon/bank/closed_records_count";
export const CLOSED_RECORDS_BANK = "recon/bank/closed_records";
export const CLOSED_RECORDS_ESTIMATE = "recon/estimate/closed_records";
export const CLOSED_RECORDS_SUBVENTION = "recon/subvention/closed_records";
export const CLOSED_RECORDS_BANK_DETAILS = "recon/bank/closed_records_details/";
export const CLOSED_RECORDS_ESTIMATE_DETAILS =
  "recon/estimate/closed_records_detail/";
export const CLOSED_RECORDS_SUBVENTION_DETAILS =
  "recon/subvention/closed_records_detail/";

export const COLLECTION_DATA_UPLOAD =
  "recon/collection/upload_collection_data_file";
export const COLLECTION_DATA_VIEW = "recon/collection/collection_upload";
export const COLLECTION_DATA_DETAILS =
  "recon/collection/collection_upload_details/";

export const UNRECONCILED_ACTIVITY = "recon/bank/unreconciled_activity/";

export const EXPORT_UPLOADED_DATA_BANK = "recon/bank/upload_module_bank_report";
export const EXPORT_UPLOADED_DATA_ESTIMATE =
  "recon/estimate/upload_module_estimate_report";
export const EXPORT_UPLOADED_DATA_SUBVENTION =
  "recon/subvention/upload_module_subvention_report";
export const EXPORT_CLOSED_DATA_BANK = "recon/bank/closed_bank_report";
export const EXPORT_CLOSED_DATA_ESTIMATE =
  "recon/estimate/closed_estimate_report";
export const EXPORT_CLOSED_DATA_SUBVENTION =
  "recon/subvention/closed_subvention_report";
export const EXPORT_RECONCILED = "recon/bank/reconciled_report";

export const EXPORT_TEMP_DATA_BANK = "recon/bank/error_bank_report/";
export const EXPORT_TEMP_DATA_SUBVENTION =
  "recon/subvention/error_subvention_report/";
export const EXPORT_TEMP_DATA_ESTIMATE =
  "recon/estimate/error_estimate_report/";

export const EXPORT_COLLECTION_DATA =
  "recon/collection/collection_upload_report";
export const EXPORT_UNRECONCILED = "recon/bank/unreconciled_views_export";
export const EXPORT_REVENUE_THRESHOLD =
  "recon/bank/revenue_threshold_views_export";

export const DOWNLOAD_BANK_TEMPLATE = "recon/bank/demo_sheet_bank_report";
export const DOWNLOAD_ESTIMATE_TEMPLATE =
  "recon/estimate/demo_sheet_estimate_report";
export const DOWNLOAD_SUBVENTION_TEMPLATE =
  "recon/subvention/demo_sheet_subvention_report";
export const DOWNLOAD_COLLECTION_TEMPLATE =
  "recon/collection/demo_collection_report";

export const DUPLICATE_DATA_TOTAL_COUNTS =
  "recon/bank/upload_duplicate_total_counts";

export const DUPLICATE_DATA_BANK_VIEW = "recon/bank/bank_upload_duplicate";
export const DUPLICATE_DATA_BANK_DETAIL_VIEW =
  "recon/bank/bank_upload_duplicate_details/";

export const DUPLICATE_DATA_ESTIMATE_VIEW =
  "recon/estimate/estimate_upload_duplicate";
export const DUPLICATE_DATA_ESTIMATE_DETAIL_VIEW =
  "recon/estimate/estimate_upload_duplicate_details/";

export const DUPLICATE_DATA_SUBVENTION_VIEW =
  "recon/subvention/subvention_upload_duplicate";
export const DUPLICATE_DATA_SUBVENTION_DETAIL_VIEW =
  "recon/subvention/subvention_upload_details_duplicate/";

export const DUPLICATE_DATA_BANK_ACTION = "recon/bank/bank_duplicate_action/";
export const DUPLICATE_DATA_ESTIMATE_ACTION =
  "recon/estimate/estimate_duplicate_action/";
export const DUPLICATE_DATA_SUBVENTION_ACTION =
  "recon/subvention/subvention_duplicate_action/";

export const LOCATION_BANK_PRODUCT_LIST = "recon/bank/product_list/";
export const ESTIMATE_LOCATION_BANK_PRODUCT_LIST =
  "recon/bank/estimate_product_list/";

export const SUBVENTION_LOCATION_BANK_PRODUCT_LIST =
  "recon/bank/subvention_product_list/";

export const GET_AUTO_RECONCILED_FLAG_UPDATE =
  "recon/bank/get_auto_reconciled_flag_update";

export const AUTO_RECONCILED_FLAG_UPDATE =
  "recon/bank/auto_reconciled_flag_update";
