import React, { useContext, useState } from "react";
import { CustomDrawer } from "./CustomDrawer";
import { AppContext } from "../../helpers/app-context";
import {
  UPLOADED_DATA_DETAILS_BANK,
  UPLOADED_DATA_DETAILS_ESTIMATE,
  UPLOADED_DATA_DETAILS_SUBVENTION,
} from "../../helpers/api-endpoints";
import { SkeletonLoader } from "./SkeletonLoader";
import { NoData } from "./NoData";
import { UPLOAD_DATA_TYPE } from "../../helpers/constant";

export const MyActivity = (props) => {
  const { title, data, loading, selectedTab, setSelectedTab } = props;
  const ctx = useContext(AppContext);
  const [openDrawable, setOpenDrawable] = useState(false);
  const [drawableData, setDrawableData] = useState([]);
  const viewActivityDetailDrawer = async (id, type) => {
    const response = await ctx.HttpGet(
      type?.toLowerCase() === UPLOAD_DATA_TYPE.BANK
        ? UPLOADED_DATA_DETAILS_BANK + id
        : type?.toLowerCase() === UPLOAD_DATA_TYPE.ESTIMATE
        ? UPLOADED_DATA_DETAILS_ESTIMATE + id
        : type?.toLowerCase() === UPLOAD_DATA_TYPE.SUBVENTION &&
          UPLOADED_DATA_DETAILS_SUBVENTION + id,
    );
    if (response.status) {
      setOpenDrawable(true);
      if (type?.toLowerCase() === UPLOAD_DATA_TYPE.BANK) {
        setDrawableData(response?.Bank_upload_data[0]);
      } else if (type?.toLowerCase() === UPLOAD_DATA_TYPE.ESTIMATE) {
        setDrawableData(response?.Estimate_upload_data[0]);
      } else if (type?.toLowerCase() === UPLOAD_DATA_TYPE.SUBVENTION) {
        setDrawableData(response?.Subvention_upload_data[0]);
      }
    }
  };
  return (
    <div className="grid grid-cols-1">
      {title && (
        <div className="fs-20 font-semibold bg-light-grey p-4 h-[62px]">
          Activity
        </div>
      )}
      <div
        className="overflow-auto"
        style={{
          maxHeight: "calc(100vh - 140px)",
        }}
      >
        <div className="grid grid-cols-1">
          <SkeletonLoader
            loading={loading}
            className="py-3 px-3"
            style={{ width: "100%" }}
          >
            {data.length ? (
              data.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="grid grid-cols-6 mx-3 py-3 border-b overflow-y-auto"
                    style={{ maxHeight: "calc(100vh - 135px)" }}
                  >
                    <div className="col-span-2">
                      <h3 className="pl-1 secondary-color-text-l1 fs-14">
                        {item.date}
                      </h3>
                    </div>
                    <div className=" col-span-4">
                      <p
                        className="fs-14"
                        dangerouslySetInnerHTML={{ __html: item.message }}
                      ></p>
                      <div
                        className="primary-text-color fs-14 font-semibold cursor-pointer"
                        onClick={() => {
                          viewActivityDetailDrawer(
                            item?.to_uid_no,
                            item?.file_type,
                          );
                          setSelectedTab(item?.file_type.toLowerCase());
                        }}
                      >
                        View Details
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <NoData />
            )}
          </SkeletonLoader>
        </div>
      </div>

      {openDrawable && (
        <CustomDrawer
          setOpenDrawable={setOpenDrawable}
          data={drawableData}
          selectedTab={selectedTab}
        />
      )}
    </div>
  );
};
