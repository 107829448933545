import { useContext, useEffect, useState } from "react";

import { CustomDataTable } from "./common/CustomDataTable";
import { Header } from "./layout/Header";
import { CustomSearch } from "./common/CustomSearch";
import { CustomDatepicker } from "./common/CustomDatepicker";
import { AppContext } from "../helpers/app-context";
import { EXPORT_RECONCILED, RECONCILED_DATA } from "../helpers/api-endpoints";
import { useNavigate } from "react-router-dom";
import {
  MY_TASK,
  PAGINATION_DETAILS,
  RECONCILED_DATA_TYPE,
} from "../helpers/constant";
import { ReconciledDataHeader } from "./tableHeaders/ReconciledDataHeader";
import {
  DownloadFileAttached,
  FromDatePickerParams,
  SortedOrderBy,
  ToDatePickerParams,
} from "../helpers/functions";
import { DownloadFileBlob } from "../helpers/functions";
import { NoData } from "./common/NoData";
import { NoInternet } from "./common/NoInternet";
// import CustomSelect from "./common/CustomSelect";
import { CustomDropdownExports } from "./common/CustomDropdownExports";

export const ReconciledData = (props) => {
  const ctx = useContext(AppContext);
  const navigate = useNavigate();
  const [dataLoading, setDataLoading] = useState(true);
  const [initialData, setInitialData] = useState(false);
  const [reconcileList, setReconcileList] = useState({});
  const [pageSize, setPageSize] = useState(
    ctx?.sortAndPagination?.reconciled_data?.reconciled_data?.size
  );
  const [currentPage, setCurrentPage] = useState(
    ctx?.sortAndPagination?.reconciled_data?.reconciled_data?.page
  );
  const [sortByValue, setSortByValue] = useState("");
  const [orderByValue, setOrderByValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [fromDateValue, setFromDateValue] = useState("");
  const [toDataValue, setToDataValue] = useState("");
  const [reconcileFromDateValue, setReconcileFromDateValue] = useState("");
  const [reconcileToDateValue, setReconcileToDateValue] = useState("");
  const [exportFullPageLoader, setExportFullPageLoader] = useState(false);
  const [exportProgressBar, setExportProgressBar] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    ctx.checkReconsile();
    let moduleStateValue = ctx.moduleState;
    if (
      (moduleStateValue.moduleName === RECONCILED_DATA_TYPE.RECONCILED_DATA &&
        (moduleStateValue.currentpage !== 1 ||
          moduleStateValue.searchValue ||
          moduleStateValue.pageSize !== 10)) ||
      moduleStateValue.fromDate ||
      moduleStateValue.toDate
    ) {
      setCurrentPage(moduleStateValue.currentPage);
      setSearchValue(moduleStateValue.searchValue);
      setPageSize(moduleStateValue.pageSize);
      setFromDateValue(moduleStateValue.fromDate);
      setToDataValue(moduleStateValue.toDate);
      setReconcileFromDateValue(moduleStateValue.fromDate_yyyy_mm_dd);
      setReconcileToDateValue(moduleStateValue.toDate_yyyy_mm_dd);
      setSortByValue(moduleStateValue.sortByValue);
      setOrderByValue(moduleStateValue.orderByValue);
      getReconciledData(
        moduleStateValue.searchValue,
        moduleStateValue.sortByValue,
        moduleStateValue.orderByValue,
        moduleStateValue.currentPage,
        moduleStateValue.pageSize,
        moduleStateValue.fromDate,
        moduleStateValue.toDate
      );
      ctx.resetModuleState();
    } else {
      setCurrentPage(1);
      getReconciledData();
    }
  }, [ctx.isOnline]);

  const getReconciledData = async (
    searchBy = searchValue,
    sortBy = sortByValue,
    orderBy = orderByValue,
    currentpage = currentPage,
    pagesize = pageSize,
    fromDateBy = fromDateValue,
    toDataBy = toDataValue,
    reconcileFromDate = reconcileFromDateValue,
    reconcileToDate = reconcileToDateValue
  ) => {
    setDataLoading(true);

    const sortData = ctx?.sortAndPagination?.reconciled_data?.reconciled_data;
    let queryParams =
      count === 0
        ? sortData
        : {
            searchItem: searchBy,
            sortBy: sortBy || sortData?.sortBy,
            orderBy: orderBy || sortData?.orderBy,
            page: currentpage,
            size: pagesize,
            fromDate: fromDateBy,
            toDate: toDataBy,
            reconcile_fromDate: reconcileFromDate,
            reconcile_toDate: reconcileToDate,
          };
    ctx?.setSortAndPagination((prev) => ({
      ...prev,
      reconciled_data: {
        ...prev?.reconciled_data,
        reconciled_data: {
          ...queryParams,
          searchItem: null,
          fromDate: null,
          toDate: null,
          reconcile_fromDate: null,
          reconcile_toDate: null,
        },
      },
      status: true,
    }));
    setCount((prev) => prev + 1);

    const response = await ctx.HttpGet(RECONCILED_DATA, queryParams);
    if (response.status) {
      setReconcileList(response);
      if (!initialData && Object.keys(response)?.length) {
        setInitialData(true);
      }
    } else {
      setReconcileList({});
      !searchBy &&
        !fromDateBy &&
        !toDataBy &&
        !reconcileFromDate &&
        !reconcileToDate &&
        reconcileList?.total_page_size > 0 &&
        getReconciledData(
          null,
          "",
          "",
          PAGINATION_DETAILS.CURRENT_PAGE,
          PAGINATION_DETAILS.PAGE_SIZE,
          "",
          "",
          ""
        );
    }
    setDataLoading(false);
  };

  const exportedData = async (
    searchBy = searchValue,
    fromDate = fromDateValue,
    toData = toDataValue,
    reconcile_fromDate = reconcileFromDateValue,
    reconcile_toDate = reconcileFromDateValue,
    type = ""
  ) => {
    let queryParams = {
      searchItem: searchBy,
      fromDate: fromDate,
      toDate: toData,
      reconcile_fromDate,
      reconcile_toDate,
      type: type,
    };
    setExportProgressBar(0);
    setExportFullPageLoader(true);

    const progressSteps = [24, 78, 87, 90]; // Arbitrary progress values at different stages
    let currentStep = 0;
    const progressInterval = setInterval(() => {
      setExportProgressBar(progressSteps[currentStep]);
      currentStep += 1;

      if (currentStep >= progressSteps.length) {
        clearInterval(progressInterval); // Stop the progress update once all steps are completed
      }
    }, 90); // Adjust the interval based on your needs

    const response = await ctx.HttpGet(EXPORT_RECONCILED, queryParams, true);

    // if (response.status) {
    //   DownloadFileBlob(
    //     response.data,
    //     "Reconciled_data_" + MY_TASK.RECONCILED,
    //     "csv"
    //   );
    //   ctx.showToastAlert({ type: "success", message: response.message });
    // } else {
    //   ctx.showToastAlert({ type: "error", message: response.message });
    // }
    setExportProgressBar(100);
    setExportFullPageLoader(false);

    if (response && typeof response.data === "string") {
      if (!response.headers && response.status == 200) {
        DownloadFileBlob(
          response.data,
          "Reconciled_data_" + MY_TASK.RECONCILED,
          "csv"
        );
        ctx.showToastAlert({ type: "success", message: response.message });
      } else {
        if (type == "partial") {
          DownloadFileAttached(
            response.data,
            "Partial_Reconciled_Bank_Data",
            "csv"
          );
        } else if (type == "fully") {
          DownloadFileAttached(
            response.data,
            "Fully_Reconciled_Bank_Data",
            "csv"
          );
        } else if (type == "both") {
          DownloadFileAttached(
            response.data,
            "Reconciled_Bank_Data_Both",
            "csv"
          );
        } else if (type == "estimate") {
          DownloadFileAttached(
            response.data,
            "Reconciled_Estimate_Data",
            "csv"
          );
        } else if (type == "subvention") {
          DownloadFileAttached(
            response.data,
            "Reconciled_Subvention_Data",
            "csv"
          );
        }
        // DownloadFileAttached(
        //   response.data,
        //   "Reconciled_data_" + MY_TASK.RECONCILED,
        //   "csv",
        // );
        ctx.showToastAlert({
          type: "success",
          message: "Downloaded successfully",
        });
      }
    } else if (typeof response.data === "object" && !response.data.status) {
      ctx.showToastAlert({
        type: "error",
        message: response.data.message,
      });
    }
  };
  const items = [
    {
      key: 1,
      label: (
        <div
          onClick={() =>
            exportedData(
              searchValue,
              fromDateValue,
              toDataValue,
              reconcileFromDateValue,
              reconcileToDateValue,
              "partial"
            )
          }
        >
          Partial Reconciled Bank Data
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <div
          onClick={() =>
            exportedData(
              searchValue,
              fromDateValue,
              toDataValue,
              reconcileFromDateValue,
              reconcileToDateValue,
              "fully"
            )
          }
        >
          Fully Reconciled Bank Data
        </div>
      ),
    },
    {
      key: 3,
      label: (
        <div
          onClick={() =>
            exportedData(
              searchValue,
              fromDateValue,
              toDataValue,
              reconcileFromDateValue,
              reconcileToDateValue,
              "both"
            )
          }
        >
          Reconciled Bank Data (Both)
        </div>
      ),
    },
    {
      key: 4,
      label: (
        <div
          onClick={() =>
            exportedData(
              searchValue,
              fromDateValue,
              toDataValue,
              reconcileFromDateValue,
              reconcileToDateValue,
              "estimate"
            )
          }
        >
          Reconciled Estimate Data
        </div>
      ),
    },
    {
      key: 5,
      label: (
        <div
          onClick={() =>
            exportedData(
              searchValue,
              fromDateValue,
              toDataValue,
              reconcileFromDateValue,
              reconcileToDateValue,
              "subvention"
            )
          }
        >
          Reconciled Subvention Data
        </div>
      ),
    },
  ];

  return (
    <>
      {exportFullPageLoader ? (
        <div className="loader flex flex-col">
          <img
            src="/images/upload-loader.gif"
            alt="loader"
            className={`${exportFullPageLoader && "rotate180"}`}
          />
          <h3>Downloading File</h3>

          {/* progress bar for export */}
          {exportFullPageLoader && exportProgressBar !== 100 && (
            <div
              className="bg-gray-200 rounded-full dark:bg-gray-700"
              style={{ width: "15%", marginTop: "2rem" }}
            >
              <div
                className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                style={{
                  width: `${exportProgressBar}%`,
                  transition: "500ms all",
                }}
              >
                {exportProgressBar}%
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          {" "}
          <Header
            title={`Reconciled Data (${
              reconcileList?.total_page_size
                ? reconcileList?.total_page_size
                : 0
            })`}
          />
          {!ctx.isOnline ? (
            <NoInternet
              onClick={() => {
                getReconciledData(null, null, null, currentPage, pageSize);
              }}
            />
          ) : dataLoading || (ctx.isOnline && initialData) ? (
            <>
              <div className="flex items-center justify-between px-4 py-2">
                <CustomSearch
                  placeholder="Search by Customer Name, Bank and App No."
                  onSearch={(value) => {
                    if (value.length >= 3) {
                      setSearchValue(value);
                      setCurrentPage(1);
                      getReconciledData(value, null, null, 1, pageSize);
                    } else if (value.length === 0) {
                      setSearchValue("");
                      getReconciledData(
                        null,
                        null,
                        null,
                        currentPage,
                        pageSize
                      );
                    }
                  }}
                  searchType="manual"
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                />
                <div className="flex items-center">
                  <CustomDatepicker
                    picker={"date"}
                    value={[reconcileFromDateValue, reconcileToDateValue]}
                    onChangeDatePicker={(date, dateString) => {
                      if (date) {
                        setReconcileFromDateValue(
                          FromDatePickerParams(date, "date")
                        );
                        setReconcileToDateValue(
                          ToDatePickerParams(date, "date")
                        );
                        getReconciledData(
                          searchValue,
                          null,
                          null,
                          1,
                          pageSize,
                          null,
                          null,
                          FromDatePickerParams(date, "date"),
                          ToDatePickerParams(date, "date")
                        );
                      } else {
                        setReconcileFromDateValue(null);
                        setReconcileToDateValue(null);
                        getReconciledData(
                          searchValue,
                          null,
                          null,
                          1,
                          pageSize,
                          null,
                          null,
                          null,
                          null
                        );
                      }
                    }}
                  />
                  <div className="ml-4"></div>

                  <CustomDatepicker
                    value={[fromDateValue, toDataValue]}
                    onChangeDatePicker={(date, dateString) => {
                      if (date) {
                        setFromDateValue(FromDatePickerParams(date));
                        setToDataValue(ToDatePickerParams(date));
                        getReconciledData(
                          searchValue,
                          null,
                          null,
                          1,
                          pageSize,
                          FromDatePickerParams(date),
                          ToDatePickerParams(date)
                        );
                      } else {
                        setFromDateValue(null);
                        setToDataValue(null);
                        getReconciledData(
                          searchValue,
                          null,
                          null,
                          1,
                          pageSize,
                          null,
                          null
                        );
                      }
                    }}
                  />

                  <div className="ml-4">
                    {/* <CustomSelect
                  options={options}
                  placeholder="Select Type"
                  onChange={handleSelectExportType}
                  disabled={
                    reconcileList?.reconciled_data?.length > 0 ? false : true
                  }
                /> */}
                  </div>
                  <CustomDropdownExports
                    dropdownImageStyle={{ width: "15px" }}
                    uploadButtonTypeClass="upload-btn-dropdown"
                    dropdownArrowIcon="down-arrow-white.svg"
                    items={items}
                    disabled={
                      !(
                        reconcileList &&
                        reconcileList?.reconciled_data?.length > 0
                      )
                    }
                  />

                  {/* <div
                className={`ml-4 export-btn ${
                  reconcileList?.reconciled_data?.length > 0
                    ? ""
                    : "custom-disabled"
                }`}
                onClick={() => {
                  exportedData();
                }}
              >
                <img src="/images/icon/export.svg" alt="export" />
              </div> */}
                </div>
              </div>
              <CustomDataTable
                rowKey="uid_no"
                columns={ReconciledDataHeader}
                data={reconcileList?.reconciled_data}
                className="mt-1"
                loading={dataLoading}
                rowClickable={true}
                totalRecords={reconcileList?.total_page_size}
                tableHeight={"calc(100vh - 240px)"}
                pageSize={pageSize}
                currentPage={currentPage}
                handleRowClick={(rowData) => {
                  ctx.setModuleState({
                    moduleName: RECONCILED_DATA_TYPE.RECONCILED_DATA,
                    searchValue: searchValue,
                    currentPage: currentPage,
                    pageSize: pageSize,
                    fromDate: fromDateValue,
                    toDate: toDataValue,
                    sortByValue: sortByValue,
                    orderByValue: orderByValue,
                  });
                  navigate("/reconciled-detail", {
                    state: {
                      id: rowData.uid_no,
                    },
                  });
                }}
                onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
                  if (sorterOptions && sorterOptions.order) {
                    setOrderByValue(SortedOrderBy(sorterOptions.order));
                    setSortByValue(sorterOptions.field);
                  }
                  setPageSize(pageSizeOptions.pageSize);
                  setCurrentPage(pageSizeOptions.current);
                  getReconciledData(
                    searchValue,
                    sorterOptions.field,
                    SortedOrderBy(sorterOptions.order),
                    pageSizeOptions.current,
                    pageSizeOptions.pageSize
                  );
                }}
                parentData={"reconciled-data"}
                selectedTab={"reconciled_data"}
              />
            </>
          ) : (
            <NoData />
          )}
        </>
      )}
    </>
  );
};
