import { COLUMNS_WIDTH } from "../../helpers/constant";

export const columnsBank = [
  {
    title: "Reconciliation Status",
    dataIndex: "reconcile_status",
    // sorter: false,
    width: "200px",
    key: "status",
  },
  {
    title: "UID No.",
    dataIndex: "uid_no",
    key: "bankData",
  },
  {
    dataIndex: "file_no",
  },
  {
    dataIndex: "Month",
  },
  {
    dataIndex: "Bank",
    ellipsis: true,
    width: COLUMNS_WIDTH.BANK,
  },
  {
    dataIndex: "Product",
    ellipsis: true,
  },
  {
    dataIndex: "Customer_name",
    width: COLUMNS_WIDTH.CUSTOMER_NAME,
    ellipsis: true,
  },
  {
    dataIndex: "App_No",
    ellipsis: true,
    width: COLUMNS_WIDTH.APP_NO,
  },
  {
    dataIndex: "Net_disbursal",
    ellipsis: true,
    key: "number",
  },
  {
    title: "LI Premium",
    dataIndex: "LI_Premium",
    ellipsis: true,
    key: "number",
  },
  {
    dataIndex: "Revenue",
    key: "number",
  },
  {
    dataIndex: "Outstanding",
    key: "number",
  },
  {
    dataIndex: "Location_head",
    ellipsis: true,
  },
  {
    dataIndex: "Location",
    ellipsis: true,
  },
  {
    dataIndex: "Total_subvention",
    key: "number",
    width: "160px",
  },
  {
    dataIndex: "Sales_month",
    width: "170px",
  },
];

export const columnsSubvention = [
  {
    dataIndex: "reconcile_status",
    title: "Reconciliation Status",
    // sorter: false,
    width: "200px",
    key: "status",
  },
  {
    title: "UID NO.",
    dataIndex: "uid_no",
    key: "subventionData",
  },
  {
    dataIndex: "file_no",
  },
  {
    dataIndex: "Month",
  },
  {
    dataIndex: "Bank",
    ellipsis: true,
    width: COLUMNS_WIDTH.BANK,
  },
  {
    dataIndex: "Product",
    ellipsis: true,
  },
  {
    dataIndex: "Customer_name",
    width: COLUMNS_WIDTH.CUSTOMER_NAME,
    ellipsis: true,
  },
  {
    dataIndex: "App_No",
    ellipsis: true,
    width: COLUMNS_WIDTH.APP_NO,
  },
  {
    title: "DISB",
    dataIndex: "Disb",
    key: "number",
  },
  {
    dataIndex: "percent",
  },
  {
    dataIndex: "Location_head",
    ellipsis: true,
  },
  {
    dataIndex: "Location",
    ellipsis: true,
  },
];

export const columnsEstimate = [
  {
    title: "Reconciliation Status",
    width: "200px",
    dataIndex: "reconcile_status",
    // sorter: false,
    key: "status",
  },
  {
    title: "UID NO.",
    dataIndex: "uid_no",
    key: "estimateData",
  },
  {
    dataIndex: "file_no",
  },
  {
    dataIndex: "Month",
  },
  {
    dataIndex: "Customer_name",
    width: COLUMNS_WIDTH.CUSTOMER_NAME,
    ellipsis: true,
  },
  {
    dataIndex: "Bank",
    ellipsis: true,
    width: COLUMNS_WIDTH.BANK,
  },
  {
    dataIndex: "App_No",
    ellipsis: true,
    width: COLUMNS_WIDTH.APP_NO,
  },
  {
    dataIndex: "Net_disbursal",
    ellipsis: true,
    key: "number",
  },
  {
    title: "LI Premium",
    dataIndex: "LI_Premium",
    ellipsis: true,
    key: "number",
  },
  {
    dataIndex: "Location_head",
  },
  {
    dataIndex: "Location",
    ellipsis: true,
  },
  {
    dataIndex: "Subvention_amount",
    key: "number",
    width: "200px",
  },
  {
    dataIndex: "Billed_in",
  },
];
