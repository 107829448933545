import React from "react";

export const SignInLeftSide = (props) => {
  return (
    <div
      className="bg-img h-full pl-6"
      style={{
        width: "48.75vw",
        backgroundImage: "url(/images/bg-login.png)",
      }}
    >
      <img src="/images/logo.svg" alt="logo" className="pt-6" />
      <div className="login-left-text pt-24">
        <div
          className="fs-64"
          style={{
            lineHeight: "1.1",
          }}
        >
          <div style={{ fontWeight: 300 }}>Welcome to</div>
          <span className="block font-semibold">iRS</span>
          <div className="fs-32 pt-7 font-thin">
            (Intelligent Reconciliation System)
          </div>
        </div>
      </div>
    </div>
  );
};
