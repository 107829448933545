export const UPLOAD_DATA_TYPE = {
  BANK: "bank",
  ESTIMATE: "estimate",
  SUBVENTION: "subvention",
};

export const TOKEN_NAME = "rwaud";
export const SORT_TOKEN = "rwasp";
export const SELECTEDMENU = "selectedMenu";

export const MY_TASK = {
  COLLECTION: "collection",
  RECONCILED: "reconciled",
  UNRECONCILED: "unreconciled",
  REVENUE_THRESHOLD: "revenue-threshold",
  UNRECONCILED_ESTIMATE: "unreconciled-estimate",
  UNRECONCILED_SUBVENTION: "unreconciled-subvention",
};

export const COLUMNS_WIDTH = {
  APP_NO: 235,
  BANK: 235,
  CUSTOMER_NAME: "385px",
};

export const RECONCILED_DATA_TYPE = {
  RECONCILED_DATA: "reconciled_data",
};

export const COLLECTION_DATA_TYPE = {
  COLLECTION_DATA: "collection_data",
};

export const MY_TASK_DETAILS = {
  MATCHES: "matches",
  ACTIVITY: "activity",
};

export const PAGINATION_DETAILS = {
  PAGE_SIZE: 10,
  CURRENT_PAGE: 1,
};

export const STATUS = {
  PENDING: "pending",
  SUCCESSFUL: "successful",
  FAILED: "failed",
  UTILISED: "utilised",
};

export const TIMEOUTMINS = 120;

export const PAGE_NAME = {
  LOGIN: "Andromeda - Login",
  FORGOT_PASSWORD: "Andromeda - Forgot Password",
  EMAIL_SENT: "Andromeda - Sent Email",
  RESET_PASSWORD: "Andromeda - Reset Password",
  RESET_PASSWORD_SUCCESS: "Andromeda - Reset Password Success",
  HOME: "Andromeda - Home",
  TEMP: "Andromeda - Temp Data",
  UPLOAD_HISTORY: "Andromeda - Upload History",
  MY_TASKS: "Andromeda - My Tasks",
  TASK_DETAIL: "Andromeda - Tasks Deatils",
  REVENUE_THRESHOLD_DETAIL: "revenue-threshold-detail",
  RECONCILED_DATA: "Andromeda - Reconciled Data",
  RECONCILED_DETAIL: "Reconciled Detail",
  COLLECTION_DATA: "Andromeda - Collection Data",
  CLOSED_RECORDS: "Andromeda - Closed Records",
};

export const STATUS_CODE = {
  DATA_FULLY_RECONCILED: "DATA_FULLY_RECONCILED",
  DATA_SUCCESS: "DATA_SUCCESS",
  SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
  DATA_MSG: "DATA_msg",
};

export const FILE_FORMAT_VALIDATON_MESSAGE = "Please upload a csv file only!";
