import { Menu } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SELECTEDMENU } from "../../helpers/constant";

export const SideMenu = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenuSideBar, setSelectedMenuSideBar] = useState(
    localStorage.getItem(SELECTEDMENU)
      ? JSON.parse(localStorage.getItem(SELECTEDMENU))
      : "SubMenu"
  );
  const items = [
    {
      label: "",
      key: "SubMenu",
      icon: <img src="/images/icon/upload-menu.svg" alt="upload" />,
      children: [
        {
          type: "group",
          label: "Upload",
          children: [
            {
              label: "Uploaded Data",
              key: "/home",
            },
            {
              label: "Upload History",
              key: "/upload-history",
            },
            {
              label: "Duplicate Data",
              key: "/duplicate-data",
            },
          ],
        },
      ],
    },
    {
      label: "",
      key: "mytask1",
      icon: <img src="/images/icon/task-menu.svg" alt="my-tasks" />,
      children: [
        {
          label: "My Task",
          key: "/my-tasks",
        },
      ],
    },
    {
      label: "",
      key: "reconcile1",
      icon: <img src="/images/icon/reconcile-menu.svg" alt="reconciled-data" />,
      children: [
        {
          type: "group",
          label: "Reconciliation",
          children: [
            {
              label: "Reconciled Data",
              key: "/reconciled-data",
            },
            {
              label: "Collection Data",
              key: "/collection-data",
            },
            {
              label: "Closed Records",
              key: "/closed-records",
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    if (selectedMenuSideBar !== location.pathname) {
      if (location.pathname === "/task-detail" || location.pathname === "/revenue-threshold-detail") {
        setSelectedMenuSideBar("/my-tasks");
      } else if(location.pathname === "/reconciled-detail") {
        setSelectedMenuSideBar("/reconciled-data");
      } else {
        setSelectedMenuSideBar(location.pathname);
      }
    }
  }, [location.pathname]);

  return (
    <div className="sidebar-menu">
      <div
        className="flex items-center justify-center small-logo cursor-pointer"
        onClick={() => navigate("/home")}
      >
        <img src="/images/logo1.svg" alt="logo" />
      </div>
      <Menu
        onClick={({ key, keyPath }) => {
          localStorage.setItem(
            SELECTEDMENU,
            JSON.stringify(keyPath[1])
          );
          setSelectedMenuSideBar(keyPath[1]);
          navigate(key);
        }}
        style={{
          width: 40,
          background: "transparent",
          border: "0",
        }}
        selectedKeys={selectedMenuSideBar}
        className="vertical-menu"
        mode="vertical"
        items={items}
      />
    </div>
  );
};
