import React, { useEffect, useState } from "react";
import { Drawer, Table } from "antd";
import {
  UPLOAD_DATA_TYPE,
  MY_TASK,
  RECONCILED_DATA_TYPE,
  COLLECTION_DATA_TYPE,
  STATUS,
} from "../../helpers/constant";
import { CustomButton } from "./CustomButton";
import { ConvertId, NumberConversion } from "../../helpers/functions";
import { DisableNumberChecks } from "../tableHeaders/DisableNumberChecks";

const getColumnWidth = (column) => {
  let columnsTotalWidth = 0;
  column &&
    column.forEach((item) => {
      if (item.width) {
        columnsTotalWidth += parseFloat(item.width);
      } else {
        columnsTotalWidth += 150; // 150px is default width for columns if horizontal scroll is enabled
      }
    });
  return columnsTotalWidth;
};

export const CustomDrawer = (props) => {
  const {
    placement,
    setOpenDrawable,
    tableProps,
    data,
    width = 683,
    selectedTab,
    btnDisabled,
    bodyStyle,
    buttonEnabled,
    primaryBtnClick,
    secondaryBtnClick,
    type,
    drawerId,
    reconsileButtonEnabled,
  } = props;

  // if row data present than only side drawer will open.
  const [open, setOpen] = useState(data ? true : false);
  const [id, setId] = useState();
  const onClose = () => {
    setOpen(false);
    // passed extra state to update prevs component state to false for on clicking event.
    setOpenDrawable(false);
  };
  useEffect(() => {
    data.filter((item) => {
      if (item.name === "uid_no" || item.name === "id") {
        setId(item.value);
      }
    });
  }, [data]);
  const listObject = data.filter(
    (item) => !["uid_no", "id"].includes(item.name)
  );
  return (
    <Drawer
      title={`UID No. ${
        selectedTab === UPLOAD_DATA_TYPE.BANK
          ? "#B"
          : selectedTab === UPLOAD_DATA_TYPE.ESTIMATE
          ? "#E"
          : selectedTab === UPLOAD_DATA_TYPE.SUBVENTION
          ? "#S"
          : selectedTab === MY_TASK.UNRECONCILED ||
            selectedTab === RECONCILED_DATA_TYPE.RECONCILED_DATA ||
            selectedTab === COLLECTION_DATA_TYPE.COLLECTION_DATA
          ? "#B"
          : selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
          ? ""
          : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION
          ? ""
          : ""
      }${
        selectedTab === MY_TASK.UNRECONCILED_ESTIMATE ||
        selectedTab === MY_TASK.UNRECONCILED_SUBVENTION
          ? `#B${ConvertId(drawerId)}`
          : ConvertId(id) || "000"
      }`}
      placement={placement ? placement : "right"}
      closable={false}
      onClose={onClose}
      open={open}
      bodyStyle={bodyStyle}
      width={window.innerWidth > 900 ? width : window.innerWidth - 100}
      extra={
        <>
          <button
            type="button"
            aria-label="Close"
            onClick={onClose}
            className="ant-drawer-close"
          >
            <img
              src="/images/icon/close.svg"
              alt="close"
              style={{
                width: "16px",
              }}
            />
          </button>
        </>
      }
    >
      {selectedTab === MY_TASK.UNRECONCILED_ESTIMATE ||
      selectedTab === MY_TASK.UNRECONCILED_SUBVENTION ? (
        <Table
          className={"mytask-drawer-table"}
          columns={tableProps.columns}
          dataSource={tableProps.dataSource}
          pagination={false}
          bordered
          scroll={{
            x: getColumnWidth(tableProps.columns),
            y: "calc(100vh - 175px)",
          }}
        />
      ) : (
        <div className="container-fluid">
          <div className="grid grid-cols-1">
            {data?.length > 0 &&
              listObject?.map((item, index) => {
                return (
                  <React.Fragment
                    key={
                      type === "unreconsiled"
                        ? `unreconsiled-${index}`
                        : `drawer-${index}`
                    }
                  >
                    {type === "unreconsiled" ? (
                      <div
                        // key={`unreconsiled-${index}`}
                        className="grid grid-cols-3"
                      >
                        {index === 0 && (
                          <>
                            <div
                              className={`col-span-1 form-label secondary-color-text-l1 fs-14 p-1 font-semibold capitalize`}
                            ></div>
                            <div className="col-span-1 font-semibold pb-2">
                              Bank
                            </div>
                            <div className="col-span-1 font-semibold pb-2">
                              {selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
                                ? "Estimate"
                                : selectedTab ===
                                    MY_TASK.UNRECONCILED_SUBVENTION &&
                                  "Subvention"}
                            </div>
                          </>
                        )}
                        <div
                          className={`col-span-1 form-label secondary-color-text-l1 fs-14 p-1 font-semibold capitalize`}
                        >
                          {item?.name
                            ? item?.name.split("_").join(" ")
                            : item.name}
                          :
                        </div>
                        <div className="col-span-1 break-words">
                          {item?.bank
                            ? DisableNumberChecks.includes(item.name)
                              ? item?.bank
                              : isNaN(item?.bank)
                              ? item?.bank
                              : NumberConversion(item.bank)
                            : "-"}
                        </div>
                        <div className="col-span-1 break-words">
                          {selectedTab === MY_TASK.UNRECONCILED_ESTIMATE
                            ? item?.estimate
                              ? DisableNumberChecks.includes(item.name)
                                ? item?.estimate
                                : isNaN(item?.estimate)
                                ? item?.estimate
                                : NumberConversion(item.estimate)
                              : "-"
                            : selectedTab === MY_TASK.UNRECONCILED_SUBVENTION &&
                              item?.subvention
                            ? DisableNumberChecks.includes(item.name)
                              ? item?.subvention
                              : isNaN(item?.subvention)
                              ? item?.subvention
                              : NumberConversion(item.subvention)
                            : "-"}
                        </div>
                      </div>
                    ) : (
                      <div
                        // key={`drawer-${index}`}
                        className="grid grid-cols-6"
                      >
                        <div
                          className={`col-span-2 ${
                            item?.reconcile &&
                            item?.reconcile != 0 &&
                            item?.reconcile != false &&
                            item?.reconcile != 2 &&
                            "border border-r-0 rounded"
                          } form-label secondary-color-text-l1 fs-14 p-1 font-semibold capitalize`}
                          style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                        >
                          {item?.name
                            ? item?.name.split("_").join(" ")
                            : item.name}
                          :
                        </div>
                        <div
                          className={`col-span-3 ${
                            item?.reconcile &&
                            item?.reconcile != 0 &&
                            item?.reconcile != false &&
                            item?.reconcile != 2 &&
                            "border border-x-0 rounded rounded-t-none rounded-b-none"
                          } fs-14 py-1`}
                        >
                          {item.name === "reconcile_status" ? (
                            <div
                              className={`status-chip ${
                                [STATUS.PENDING, STATUS.SUCCESSFUL].includes(
                                  item?.value.toLowerCase()
                                )
                                  ? "green"
                                  : item?.value.toLowerCase() === STATUS.FAILED
                                  ? "red"
                                  : item?.value.toLowerCase() ===
                                    STATUS.UTILISED
                                  ? "gray"
                                  : ""
                              }`}
                            >
                              {item?.value}
                            </div>
                          ) : item?.value ? (
                            DisableNumberChecks.includes(item.name) ? (
                              item?.value
                            ) : isNaN(item?.value) ? (
                              item?.value
                            ) : (
                              NumberConversion(item.value)
                            )
                          ) : (
                            "-"
                          )}
                        </div>
                        {item?.reconcile &&
                        item?.reconcile != 0 &&
                        item?.reconcile != false &&
                        item?.reconcile != 2 ? (
                          <div
                            className="border p-1 text-right rounded border-l-0"
                            style={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            }}
                          >
                            <div
                              className="status-chip"
                              style={{
                                width: "max-content",
                                height: "23px",
                              }}
                            >
                              Reconciled
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
          </div>
          {reconsileButtonEnabled && (
            <div className="flex justify-end absolute bottom-0 right-0 py-2 bg-white w-full border-t">
              <CustomButton
                disabled={btnDisabled}
                className="btn-primary font-bold py-2 px-4 rounded fs-14 mx-3"
                title="Reconcile"
                onClick={primaryBtnClick}
              />
            </div>
          )}
          {buttonEnabled && (
            <div className="flex justify-end absolute bottom-0 right-0 py-2 bg-white w-full border-t">
              <CustomButton
                className="btn-light-red font-bold py-2 px-4 rounded fs-16 mx-3 w-28"
                title="Reject"
                onClick={secondaryBtnClick}
              />
              <CustomButton
                className="btn-primary font-bold py-2 px-4 rounded fs-16 w-28 mx-3"
                title="Approve"
                onClick={primaryBtnClick}
              />
            </div>
          )}
        </div>
      )}
    </Drawer>
  );
};
