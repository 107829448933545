import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../helpers/app-context";
import { MY_TASK } from "../../helpers/constant";
import {
  FromDatePickerParams,
  SortedOrderBy,
  ToDatePickerParams,
} from "../../helpers/functions";
import { CustomDataTable } from "../common/CustomDataTable";
import { CustomDatepicker } from "../common/CustomDatepicker";
import { CustomSearch } from "../common/CustomSearch";
import { NoData } from "../common/NoData";
import {
  columnsRevenue,
  columnsUnreconciled,
} from "../tableHeaders/MyTaskHeader";
import { NoInternet } from "../common/NoInternet";

export const MyTaskTable = (props) => {
  const {
    getTotalCount,
    selectedData,
    dataLoading,
    initialData,
    selectedTab,
    searchValue,
    pageSize,
    currentPage,
    fromDate,
    toDate,
    setSearchValue,
    setCurrentPage,
    getTableData,
    fromDateValue,
    toDataValue,
    setFromDateValue,
    setToDataValue,
    setPageSize,
    setOrderByValue,
    setSortByValue,
    exportedData,
    exportBtnDisable,
    rowKey,
    hideNoData,
    parentData,
  } = props;
  const navigate = useNavigate();
  const ctx = useContext(AppContext);
  return !ctx.isOnline ? (
    <NoInternet
      onClick={() => {
        getTableData(null, null, null, currentPage, pageSize);
        getTotalCount();
      }}
    />
  ) : dataLoading || (ctx.isOnline && initialData) ? (
    <>
      <div className="flex items-center justify-between px-4 py-2">
        <CustomSearch
          placeholder="Search by Customer Name, Bank and App No."
          onSearch={(value) => {
            if (value.length >= 3) {
              setSearchValue(value);
              setCurrentPage(1);
              getTableData(value, null, null, 1, pageSize);
            } else if (value.length === 0) {
              setSearchValue("");
              getTableData(null, null, null, currentPage, pageSize);
            }
          }}
          searchType="manual"
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <div className="flex items-center">
          <CustomDatepicker
            value={[fromDateValue, toDataValue]}
            onChangeDatePicker={(date, dateString) => {
              if (date) {
                setFromDateValue(FromDatePickerParams(date));
                setToDataValue(ToDatePickerParams(date));
                getTableData(
                  searchValue,
                  null,
                  null,
                  1,
                  pageSize,
                  FromDatePickerParams(date),
                  ToDatePickerParams(date),
                );
              } else {
                setFromDateValue(null);
                setToDataValue(null);
                getTableData(searchValue, null, null, 1, pageSize, null, null);
              }
            }}
          />
          <div
            className={`ml-4 btn-primary p-2 rounded fs-14 cursor-pointer /*export-btn*/ ${
              selectedTab === MY_TASK.UNRECONCILED
                ? selectedData?.match_percent?.length > 0
                  ? exportBtnDisable
                    ? "custom-disabled"
                    : ""
                  : "custom-disabled"
                : selectedTab === MY_TASK.REVENUE_THRESHOLD &&
                  selectedData?.Revenue_threshold_view_data?.length > 0
                ? exportBtnDisable
                  ? "custom-disabled"
                  : ""
                : "custom-disabled"
            }`}
            onClick={() => {
              exportedData(searchValue, fromDate, toDate);
            }}
          >
            Export
            {/* <img src="/images/icon/export.svg" alt="export" /> */}
          </div>
        </div>
      </div>
      <CustomDataTable
        rowKey={rowKey ? rowKey : "uid_no"}
        columns={
          selectedTab === MY_TASK.UNRECONCILED
            ? columnsUnreconciled
            : selectedTab === MY_TASK.REVENUE_THRESHOLD && columnsRevenue
        }
        data={
          selectedTab === MY_TASK.UNRECONCILED
            ? selectedData?.match_percent
            : selectedTab === MY_TASK.REVENUE_THRESHOLD &&
              selectedData?.Revenue_threshold_view_data
        }
        className="mt-1"
        loading={dataLoading}
        rowClickable={true}
        tableHeight={"calc(100vh - 282px)"}
        totalRecords={selectedData?.total_page_size}
        pageSize={pageSize}
        currentPage={currentPage}
        handleRowClick={(rowData) => {
          if (selectedTab === MY_TASK.UNRECONCILED) {
            ctx.setModuleState({
              moduleName: MY_TASK.UNRECONCILED,
              searchValue: searchValue,
              currentPage: currentPage,
              pageSize: pageSize,
              fromDate: fromDate,
              toDate: toDate,
            });
            navigate("/task-detail", {
              state: {
                id: rowData.uid_no,
                activeTab: selectedTab,
              },
            });
          } else if (selectedTab === MY_TASK.REVENUE_THRESHOLD) {
            navigate("/revenue-threshold-detail", {
              state: {
                id: rowData.bank_uid_no,
                subvention_id: rowData.subvention_uid_no,
                activeTab: selectedTab,
              },
            });
            ctx.setModuleState({
              moduleName: MY_TASK.REVENUE_THRESHOLD,
              searchValue: searchValue,
              currentPage: currentPage,
              pageSize: pageSize,
              fromDate: fromDate,
              toDate: toDate,
            });
          }
        }}
        onChange={(pageSizeOptions, filterOptions, sorterOptions) => {
          if (sorterOptions && sorterOptions.order) {
            setOrderByValue(SortedOrderBy(sorterOptions.order));
            setSortByValue(
              sorterOptions.field === "uid_no" ? "id" : sorterOptions.field,
            );
          }
          setPageSize(pageSizeOptions.pageSize);
          setCurrentPage(pageSizeOptions.current);
          getTableData(
            searchValue,
            sorterOptions.order
              ? sorterOptions.field === "uid_no"
                ? "id"
                : sorterOptions.field
              : "",
            SortedOrderBy(sorterOptions.order),
            pageSizeOptions.current,
            pageSizeOptions.pageSize,
          );
        }}
        parentData={parentData}
        selectedTab={selectedTab}
      />
    </>
  ) : (
    <NoData hideNoData={hideNoData} />
  );
};
