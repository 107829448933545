import { useContext, useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import "antd/dist/reset.css";
import "./App.css";
import "./theme/font.css";
import "./theme/dark.css";
import "./theme/light.css";

import { Login } from "./components/Login";
import { ForgotPassword } from "./components/ForgotPassword";
import { EmailSent } from "./components/EmailSent";
import { ResetPassword } from "./components/ResetPassword";
import { ResetPasswordSuccessful } from "./components/ResetPasswordSuccessful";
import { Layout } from "./components/layout";
import { Home } from "./components/Home";
import { TempData } from "./components/TempData";
import { MyTask } from "./components/MyTask";
import { MyTaskDetails } from "./components/MyTaskDetails";
import { UploadHistory } from "./components/UploadHistory";
import { ReconciledData } from "./components/ReconciledData";
import { CollectionData } from "./components/CollectionData";
import { ClosedRecords } from "./components/ClosedRecords";
import { OpenRoutes } from "./components/routing/OpenRoutes";
import { PrivateRoutes } from "./components/routing/PrivateRoutes";
import { ReconciledDetails } from "./components/ReconciledDetails";
import { RevenueTresholdDetails } from "./components/RevenueThresholdDetails";
import { TIMEOUTMINS, TOKEN_NAME } from "./helpers/constant";
import { AppContext } from "./helpers/app-context";
import { CustomModal } from "./components/common/CustomModal";
import { DuplicateData } from "./components/DuplicateData";

function App() {
  const ctx = useContext(AppContext);
  // reference for timer
  const timerRef = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState("120");
  const [showInactivityModal, setShowInactivityModal] = useState(false);

  // commented for time being
  useEffect(() => {
    if (TIMEOUTMINS) {
      resetTimer();
    }
  }, [TIMEOUTMINS]);

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    // setTimer('60');

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec

    if (timerRef.current) clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      let total = new Date(e) - new Date();
      let seconds = Math.floor((total / 1000) % 60);

      if (total >= 0) {
        // update the timer
        // check if less than 10 then we need to
        // add '0' at the begining of the variable
        setTimer(seconds > 9 ? seconds : "0" + seconds);
      } else if (ctx.userInfo?.email || localStorage.getItem(TOKEN_NAME)) {
        clearInterval(timerRef.current);
        timerRef.current = undefined;
        setShowInactivityModal(false);
        ctx.AppLogout(true, {
          email: ctx.userInfo?.email
            ? ctx.userInfo?.email
            : localStorage.getItem(TOKEN_NAME)
            ? JSON.parse(ctx.getDecrypted(localStorage.getItem(TOKEN_NAME)))
                .email
            : "",
        });
      }
    }, 1000);
  };

  window.onmousemove = resetTimer; // catches mouse movements
  // window.onmousedown = resetTimer; // catches mouse movements
  window.onclick = resetTimer; // catches mouse clicks - commented for time being
  // window.onscroll = resetTimer;      // catches scrolling
  // window.onkeypress = resetTimer;    //catches keyboard actions

  function resetTimer() {
    if (!TIMEOUTMINS || showInactivityModal) {
      return;
    }
    let timeout = TIMEOUTMINS * 1000 * 60 + 1000;
    clearTimeout(window["timeoutVariable"]);
    window["timeoutVariable"] = setTimeout(() => {
      if (!showInactivityModal && !timerRef.current && (ctx.userInfo?.email || localStorage.getItem(TOKEN_NAME))) {
        setShowInactivityModal(true);
        clearTimer(new Date().getTime() + 60000); // set 60 second countdown
      }
    }, timeout); // time is in milliseconds (1000 is 1 second)
  }

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <OpenRoutes>
                <Outlet />
              </OpenRoutes>
            }
          >
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="email-sent" element={<EmailSent />} />
            <Route path="reset-password?/:any" element={<ResetPassword />} />
            <Route
              path="reset-password-success"
              element={<ResetPasswordSuccessful />}
            />
          </Route>
          <Route
            path="/"
            element={
              <PrivateRoutes>
                <Layout />
              </PrivateRoutes>
            }
          >
            <Route path="home" element={<Home />} />
            <Route path="temp" element={<TempData />} />
            <Route path="my-tasks" element={<MyTask />} />
            <Route path="task-detail" element={<MyTaskDetails />} />
            <Route
              path="revenue-threshold-detail"
              element={<RevenueTresholdDetails />}
            />
            <Route path="reconciled-detail" element={<ReconciledDetails />} />
            <Route path="upload-history" element={<UploadHistory />} />
            <Route path="duplicate-data" element={<DuplicateData />} />
            <Route path="reconciled-data" element={<ReconciledData />} />
            <Route path="collection-data" element={<CollectionData />} />
            <Route path="closed-records" element={<ClosedRecords />} />
          </Route>
        </Routes>
      </Router>
      <CustomModal
        // icon={modalData.icon}
        isModalOpen={showInactivityModal}
        title={""}
        modalBody={`User will be logged out in ${timer} seconds`}
        handleSecondaryBtn={() => {
          setShowInactivityModal(false);
          clearInterval(timerRef.current);
          timerRef.current = null;
          // localStorage.setItem('inactivity-timeout', moment().add(ctx.userInfo.TimeoutMins, 'minutes').format('YYYY-MM-DD HH:mm:ss'));
        }}
        setIsModalOpen={setShowInactivityModal}
      />
    </>
  );
}

export default App;
