import { Dropdown, Table, Tooltip } from "antd";
import { useCallback, useContext, useState } from "react";
import { STATUS } from "../../helpers/constant";
import {
  ConvertId,
  isAlphanumeric,
  NumberConversion,
} from "../../helpers/functions";
import { AppContext } from "../../helpers/app-context";

export const TableTitle = (props) => {
  const { title, sorting, className } = props;
  return (
    <div className={`flex cursor-pointer items-center capitalize ${className}`}>
      {title ? title?.split("_").join(" ") : title}
      {sorting ? (
        <img src="/images/icon/sort.svg" alt="sort" className="ml-2" />
      ) : (
        ""
      )}
    </div>
  );
};

export const CustomDataTable = (props) => {
  const {
    className,
    data,
    rowClickable,
    columns,
    handleRowClick,
    tableHeight,
    rowSelectionBox,
    loading,
    rowKey,
    onChange,
    rowSelection,
    hidePaginationShowTotal,
    currentPage,
    pageSize,
    totalRecords,
    onItemClick,
    parentData,
    selectedTab,
  } = props;

  const ctx = useContext(AppContext);

  let mainPage = parentData && parentData.replaceAll("-", "_");
  let currentTab = selectedTab && selectedTab.replaceAll("-", "_");

  const [selectedId, setSelectedId] = useState(0);

  const getColumnWidth = (column) => {
    let columnsTotalWidth = 0;
    column &&
      column.forEach((item) => {
        if (item.width) {
          columnsTotalWidth += parseFloat(item.width);
        } else {
          columnsTotalWidth += 150; // 150px is default width for columns if horizontal scroll is enabled
        }
      });
    return columnsTotalWidth;
  };

  const mapColumnsForTable = useCallback(() => {
    let mappedColumns = [];
    if (!columns?.length) {
      return mappedColumns;
    }
    columns.forEach((item) => {
      let columnObj = {
        title: (
          <TableTitle
            title={item.title ? item.title : item.dataIndex}
            sorting={item.sorter === false ? false : true}
            className={
              item?.dataIndex &&
              item?.dataIndex.toLowerCase() ===
                ctx?.sortAndPagination?.[mainPage]?.[
                  selectedTab
                ]?.sortBy.toLowerCase()
                ? "columnBorder"
                : ""
            }
          />
        ),
        dataIndex: item.dataIndex,
        key: item.dataIndex,
        sorter: item.sorter === false ? false : true,
        width: item.width,
        fixed: item.fixed,
        align: item.align,
        ellipsis: item.ellipsis
          ? {
              showTitle: false,
            }
          : false,
        render: (text, record) => {
          if (item.render && typeof item.render === "function") {
            return item.render(text, record, item.dataIndex);
          } else if (item.ellipsis) {
            return text ? (
              <Tooltip
                placement="topLeft"
                title={
                  item.key === "number"
                    ? isNaN(text)
                      ? item?.checkIsAlphanumeric
                        ? isAlphanumeric(text)
                          ? text
                          : "-"
                        : "-"
                      : NumberConversion(text)
                    : text
                }
              >
                {item.key === "number"
                  ? isNaN(text)
                    ? item?.checkIsAlphanumeric
                      ? isAlphanumeric(text)
                        ? text
                        : "-"
                      : "-"
                    : NumberConversion(text)
                  : text}
              </Tooltip>
            ) : (
              "-"
            );
          } else if (item.key === "bankData") {
            return text ? "#B" + ConvertId(text) : "-";
          } else if (item.key === "estimateData") {
            return text ? "#E" + ConvertId(text) : "-";
          } else if (item.key === "subventionData") {
            return text ? "#S" + ConvertId(text) : "-";
          } else if (item.key === "status") {
            return text ? (
              <div
                className={`status-chip ${
                  [STATUS.PENDING, STATUS.SUCCESSFUL].includes(
                    text.toLowerCase(),
                  )
                    ? "green"
                    : text.toLowerCase() === STATUS.FAILED
                    ? "red"
                    : text.toLowerCase() === STATUS.UTILISED
                    ? "gray"
                    : ""
                }`}
              >
                {text}
              </div>
            ) : (
              "-"
            );
          } else if (item.dataIndex === "action") {
            const items = [
              {
                key: "1",
                disabled: record.Is_success === 0,
                label: (
                  <div
                    className={`flex items-center cursor-pointer fs-14 primary-text-color-l3 dropdown-download-item p-1 mb-2 ${
                      record.Is_success === 0 && "custom-disabled"
                    } }`}
                    onClick={() => {
                      onItemClick(selectedId, "success");
                    }}
                  >
                    <img
                      src="/images/icon/download.svg"
                      alt="download"
                      className="mr-2"
                    />
                    Download Successful Data
                  </div>
                ),
              },
              {
                key: "2",
                disabled: record.Is_error === 0,
                label: (
                  <div
                    className={`flex items-center cursor-pointer fs-14 primary-text-color-l3 dropdown-download-item p-1 ${
                      record.Is_error === 0 && "custom-disabled"
                    } }`}
                    onClick={() => onItemClick(selectedId, "unsuccess")}
                  >
                    <img
                      src="/images/icon/download.svg"
                      alt="download"
                      className="mr-2"
                    />
                    Download Unsuccessful Data
                  </div>
                ),
              },
              {
                key: "3",
                disabled: record.Is_duplicate === 0,
                label: (
                  <div
                    className={`flex items-center cursor-pointer fs-14 primary-text-color-l3 dropdown-download-item p-1 ${
                      record.Is_duplicate === 0 && "custom-disabled"
                    } }`}
                    onClick={() => onItemClick(selectedId, "duplicate")}
                  >
                    <img
                      src="/images/icon/download.svg"
                      alt="download"
                      className="mr-2"
                    />
                    Download Duplicate Data
                  </div>
                ),
              },
            ];
            return (
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                onClick={() => {
                  setSelectedId(record.id);
                }}
              >
                <div className="p-2 cursor-pointer">
                  <img
                    src="/images/icon/more-menu.svg"
                    alt="sidemenu"
                    style={{
                      transform: "rotate(90deg)",
                    }}
                  />
                </div>
              </Dropdown>
            );
          } else if (item.key === "number") {
            return text
              ? isNaN(text)
                ? item.checkIsAlphanumeric
                  ? isAlphanumeric(text)
                    ? text
                    : "-"
                  : "-"
                : NumberConversion(text)
              : "-";
          } else {
            return text ? text : "-";
          }
        },
      };
      mappedColumns.push(columnObj);
    });
    return mappedColumns;
  }, [
    columns,
    selectedId,
    ctx?.sortAndPagination?.[mainPage]?.[selectedTab]?.sortBy,
  ]);
  return (
    <Table
      rowKey={rowKey}
      dataSource={data}
      columns={mapColumnsForTable()}
      rowClassName={(record, index) => (rowClickable ? "cursor-pointer" : "")}
      className={`customtable ${className}`}
      scroll={{
        y: tableHeight ? tableHeight : "calc(100vh - 242px)",
        x: getColumnWidth(columns),
      }}
      loading={{
        indicator: (
          <img
            src="/images/loader_recon.gif"
            alt="loader"
            style={{
              width: "70px",
              height: "70px",
            }}
          />
        ),
        wrapperClassName: "loader-spin",
        spinning: loading,
      }}
      rowSelection={
        rowSelectionBox && rowSelectionBox.enable
          ? {
              type: rowSelectionBox.type,
              ...rowSelection,
            }
          : ""
      }
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            return typeof handleRowClick === "function"
              ? handleRowClick(record)
              : "";
          }, // code added for on click for ant table
        };
      }}
      pagination={{
        current: currentPage,
        className: "custom-antd-pagination",
        pageSize: pageSize,
        total: totalRecords,
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        showQuickJumper: true,
        locale: {
          items_per_page: "",
          jump_to: <span className="fs-12">Go to</span>,
          page: <span className="fs-12">Page</span>,
        },
        showTotal: (total, range) => {
          return hidePaginationShowTotal ? (
            ""
          ) : (
            <span className="fs-12">
              Showing {range[0]}-{range[1]} of {total}
            </span>
          );
        },
      }}
      onChange={onChange}
    />
  );
};
