import React, { useState } from "react";
import { CustomUpload } from "./CustomUpload";
import { Dropdown } from "antd";
import { CustomButton } from "./CustomButton";

export const CustomDropdownExports = (props) => {
  const {
    disabled,
    dropdown = true,
    onExportClick,
    items,
    uploadButtonTypeClass = "upload-btn",
    dropdownArrowIcon = "up-angle.svg",
    dropdownImageStyle,
  } = props;
  const [dropdownClick, setDropdownClick] = useState(false);

  return (
    <>
      {dropdown ? (
        <Dropdown
          disabled={disabled}
          menu={{
            items,
          }}
          trigger={["click"]}
          onClick={(event) => {
            setDropdownClick(!dropdownClick);
          }}
        >
          <label
            className={`${uploadButtonTypeClass} /*upload-btn*/ btn w-max mx-auto cursor-pointer ${
              disabled && "custom-disabled"
            }`}
          >
            {/* <label className="cursor-pointer">
          <img src="/images/icon/export.svg" alt="upload" className="mr-2" />
        </label> */}
            Export
            <span
              className={`${
                dropdownClick ? "p-0  " : "p-0 "
              } transition duration-75 ease-in-out pl-2 flex `}
            >
              <img
                className={`${dropdownClick && "rotate-180"} `}
                src={`/images/icon/${dropdownArrowIcon}`}
                alt="up-arrow"
                style={{ ...dropdownImageStyle }}
              />
            </span>
          </label>
        </Dropdown>
      ) : (
        // <label
        //   className="upload-btn btn w-max mx-auto cursor-pointer"
        //   onClick={onExportClick}
        // >
        //   Export
        // </label>
        <CustomButton
          type="button"
          title="Export"
          disabled={disabled}
          className="btn-primary btn font-bold py-2 px-4 rounded fs-14"
          onClick={onExportClick}
        />
      )}
    </>
  );
};
