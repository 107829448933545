import { Button } from "antd";
import React from "react";
export const CustomButton = (props) => {
  const { type, className, title, disabled, onClick, icon, style } = props;
  return (
    <Button
      type={type}
      disabled={disabled}
      className={`btn ${className ? className : ""} ${icon ? 'flex items-center' : '' } ${disabled ? "disabled" : ""}`}
      style={style}
      onClick={onClick}
    >
      {icon ? <img src={icon} alt="icon" className="me-2"/> : ""}
      <span className={`${icon ? 'pl-2' : '' }`}>{title}</span>
    </Button>
  );
};
