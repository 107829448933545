import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MY_TASK, UPLOAD_DATA_TYPE } from "../../helpers/constant";
import { ConvertId, NumberConversion } from "../../helpers/functions";
import { DisableNumberChecks } from "../tableHeaders/DisableNumberChecks";
import { columnLabels } from "../tableHeaders/MyTaskSelectedLabels";

import { CustomButton } from "./CustomButton";
import { CustomDrawer } from "./CustomDrawer";
import { CustomModal } from "./CustomModal";
import { SkeletonLoader } from "./SkeletonLoader";

export const MappedDetails = (props) => {
  const {
    title,
    mappedBankData,
    mappedEstimate,
    mappedSubvention,
    matchpercentage,
    selectedMatchedTab,
    loading,
    style,
    reconsileDisabledCheck,
    manualReconcileData,
    bankUid,
    estimateUid,
    subventionUid,
  } = props;
  const location = useLocation();
  const [openDrawable, setOpenDrawable] = useState(false);
  const [drawableData, setDrawableData] = useState(null);
  const [selectedColumnsData, setSelectedColumnsData] = useState([]);
  const [selectedTab, setSelectedtab] = useState("");
  const [menuUpdateCount, setMenuUpdateCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getSelectedMappedDetails();
  }, [mappedBankData]);

  useEffect(() => {
    mappedEstimate && mappedEstimate?.forEach((item) => {
      if (columnLabels.includes(item.name)) {
        selectedColumnsData.forEach((object) => {
          let result = mappedEstimate.filter((res) => res.name === object.key);
          object.estimate = result[0]?.value;
        });
      }
    });
    setSelectedColumnsData(selectedColumnsData);
    setMenuUpdateCount(menuUpdateCount + 1);
  }, [selectedColumnsData, mappedEstimate]);

  useEffect(() => {
    mappedSubvention && mappedSubvention?.forEach((item) => {
      if (columnLabels.includes(item.name)) {
        selectedColumnsData.forEach((object) => {
          let result = mappedSubvention.filter(
            (res) => res.name === object.key
          );
          object.subvention = result[0]?.value;
        });
      }
    });
    setSelectedColumnsData(selectedColumnsData);
    setMenuUpdateCount(menuUpdateCount + 1);
  }, [selectedColumnsData, mappedSubvention]);

  const getSelectedMappedDetails = () => {
    let res = [];
    mappedBankData &&
      mappedBankData?.map((item) => {
        if (columnLabels.includes(item.name)) {
          res.push({
            key: item.name,
            bank: item.value,
          });
        }
      });
    setSelectedColumnsData(res);
  };

  return (
    <div className="col-span-12 md:col-span-7 border-t">
      <div className="col-span-8">
        <div className="grid grid-cols-5 bg-white">
          <div className="col-span-3">
            <h2 className="fs-20 p-4 col-span-4 font-semibold">{title}</h2>
          </div>
          {location.state.activeTab === MY_TASK.UNRECONCILED &&
            matchpercentage && (
              <div className="relative col-span-2">
                <h3
                  className={`absolute bottom-0 status-chip font-semibold -ml-1	 ${
                    matchpercentage >= 70
                      ? "green"
                      : matchpercentage >= 50 && matchpercentage < 70
                      ? "yellow"
                      : "red"
                  }`}
                >
                  {matchpercentage}% Match
                </h3>
              </div>
            )}

          <div className="col-span-5">
            <div className="grid grid-cols-5 border-b border-t">
              <div className="border-r p-2 col-span-1 fs-14 secondary-color-text-l1 font-semibold">
                Column Name
              </div>
              <div className=" p-2 fs-14 col-span-2 secondary-color-text-l1 font-semibold">
                <SkeletonLoader loading={loading} style={{ height: "20px" }}>
                  Mapped Bank UID No. #B{ConvertId(bankUid)}
                </SkeletonLoader>
              </div>
              {mappedEstimate &&
              selectedMatchedTab === UPLOAD_DATA_TYPE.ESTIMATE ? (
                <div className=" p-2 fs-14 col-span-2 lightgrey-bg-color secondary-color-text-l1 font-semibold -ml-1">
                  <SkeletonLoader loading={loading} style={{ height: "20px" }}>
                    Mapped Estimate UID No. #E{ConvertId(estimateUid)}
                  </SkeletonLoader>
                </div>
              ) : mappedSubvention &&
                selectedMatchedTab === UPLOAD_DATA_TYPE.SUBVENTION ? (
                <div className=" p-2 fs-14 col-span-2 lightgrey-bg-color secondary-color-text-l1 font-semibold -ml-1">
                  <SkeletonLoader loading={loading} style={{ height: "20px" }}>
                    Mapped Subvention UID No. #S{ConvertId(subventionUid)}
                  </SkeletonLoader>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div
          className="grid grid-cols-1 border-b-2 overflow-y-auto"
          style={style ? { ...style } : { maxHeight: "calc(100vh - 235px)" }}
        >
          {selectedColumnsData.map((item, index) => (
            <div className="grid grid-cols-5" key={index}>
              <div className="fs-14 col-span-1 pt-2 pl-3 pr-2 border-r secondary-color-text-l1 font-semibold capitalize">
                {item?.key ?  item?.key.split("_").join(" ") : "-"}
              </div>
              <div className="fs-14 col-span-2 py-2 pl-3 pr-2 font-semibold">
                {item?.bank
                      ? DisableNumberChecks.includes(item.key) ? item?.bank  :  isNaN(item?.bank)
                        ? item?.bank
                        : NumberConversion(item.bank)
                      : "-"}
              </div>
              {mappedEstimate &&
                selectedMatchedTab === UPLOAD_DATA_TYPE.ESTIMATE && (
                  <div className="fs-14 col-span-2 py-2 pl-3 pr-2 lightgrey-bg-color font-semibold">
                    {item?.estimate ? DisableNumberChecks.includes(item.key) ? item?.estimate : isNaN(item?.estimate) ? item?.estimate : NumberConversion(item.estimate) : "-"}
                  </div>
                )}
              {mappedSubvention &&
                selectedMatchedTab === UPLOAD_DATA_TYPE.SUBVENTION && (
                  <div className="fs-14 col-span-2 py-2 pl-3 pr-2 lightgrey-bg-color font-semibold">
                    {item?.subvention ? DisableNumberChecks.includes(item.key) ? item?.subvention  : isNaN(item?.subvention) ? item?.subvention : NumberConversion(item.subvention) : "-"}
                  </div>
                )}
            </div>
          ))}
          <div className="grid grid-cols-5">
            <div className="fs-14 col-span-1 pt-2 pl-3 pr-2 border-r secondary-color-text-l1 font-semibold"></div>
            <div
              className="col-span-2 primary-text-color fs-14 font-semibold py-2 pl-3 pr-2 cursor-pointer"
              onClick={() => {
                setDrawableData(mappedBankData);
                setOpenDrawable(true);
                setSelectedtab(UPLOAD_DATA_TYPE.BANK);
              }}
            >
              View More Bank Details
            </div>
            {selectedMatchedTab === UPLOAD_DATA_TYPE.ESTIMATE &&
            mappedEstimate ? (
              <div
                className="col-span-2 primary-text-color fs-14 font-semibold py-2 pl-3 pr-2 cursor-pointer"
                onClick={() => {
                  setDrawableData(mappedEstimate);
                  setOpenDrawable(true);
                  setSelectedtab(UPLOAD_DATA_TYPE.ESTIMATE);
                }}
              >
                View More Estimate Details
              </div>
            ) : selectedMatchedTab === UPLOAD_DATA_TYPE.SUBVENTION &&
              mappedSubvention ? (
              <div
                className="col-span-2 primary-text-color fs-14 font-semibold py-2 pl-3 pr-2 cursor-pointer"
                onClick={() => {
                  setDrawableData(mappedSubvention);
                  setOpenDrawable(true);
                  setSelectedtab(UPLOAD_DATA_TYPE.SUBVENTION);
                }}
              >
                View More Subvention Details
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {location.state.activeTab === MY_TASK.UNRECONCILED && (
        <div className="grid grid-cols-5 py-2">
          <div className="col-span-5 text-right px-2">
            <CustomButton
              type="button"
              title="Reconcile"
              disabled={
                selectedMatchedTab === UPLOAD_DATA_TYPE.ESTIMATE
                  ? reconsileDisabledCheck || !mappedEstimate
                  : selectedMatchedTab === UPLOAD_DATA_TYPE.SUBVENTION
                  ? reconsileDisabledCheck || !mappedSubvention
                  : false
              }
              className="btn-primary font-bold py-2 px-4 rounded fs-14"
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        </div>
      )}
      {openDrawable && (
        <CustomDrawer
          setOpenDrawable={setOpenDrawable}
          data={drawableData}
          selectedTab={selectedTab}
        />
      )}
      <CustomModal
        title={" "}
        isModalOpen={isModalOpen}
        primaryBtnText={"Reconcile"}
        setIsModalOpen={setIsModalOpen}
        modalBody={
          <div className="fs-16 highlight-text-color">
            Are you sure you want to reconcile the current data?
            <br /> Reconciled data cannot be edited.
          </div>
        }
        handlePrimaryBtn={() => {
          manualReconcileData();
          setIsModalOpen(false);
        }}
        handleSecondaryBtn={() => setIsModalOpen(false)}
      />
    </div>
  );
};
